<template>
  <CheckboxItemStyled>
    <div class="checkbox">
      <input type="checkbox" :checked="isChecked" :disabled="isDisabled" @input="$emit('change', $event.target.checked)" />
    </div>
    <div class="label">
      <span :title="label"> {{ label }} </span>
    </div>
  </CheckboxItemStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const CheckboxItemStyled = styled('label')`
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  max-width: 300px;
  padding: 0.25rem;
  .label {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;
    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .checkbox {
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    input {
      accent-color: ${({ theme }) => theme.colors.atomic.primary};
      cursor: pointer;
    }
  }
  .muted {
    color: ${({ theme }) => (theme.isDark ? theme.colors.muted : theme.colors.dimGrey)};
  }
  @media (max-width: 768px) {
  }
`
export default {
  components: {
    CheckboxItemStyled,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
