var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InfoBannerStyled', {
    attrs: {
      "canDismiss": _vm.canDismiss
    }
  }, [_c('div', [_c('AlertCircleIcon'), _vm._v(" " + _vm._s(_vm.message) + ". "), _vm.actionText ? _c('span', {
    staticClass: "link",
    on: {
      "click": _vm.navigateTo
    }
  }, [_vm._v(_vm._s(_vm.actionText) + ".")]) : _vm._e()], 1), _vm.canDismiss ? _c('XIcon', {
    staticClass: "closeIcon",
    on: {
      "click": _vm.closeBanner
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }