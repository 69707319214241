<template>
  <BadgeStyled :marginLeft="marginLeft">
    {{ label }}
  </BadgeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
// import chroma from 'chroma-js'

import { flexCenter } from '@styles/mixins'

const BadgeStyled = styled('div')`
  ${flexCenter}
  width: 32px;
  height: 32px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  color: ${p => p.theme.colors.primary};
  background: ${p => p.theme.colors.circleIconBG};
  ${props => (props.marginLeft ? 'margin-left: ' + props.marginLeft + ';' : '')}
`

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    marginLeft: {
      type: String,
    },
  },
  components: {
    BadgeStyled,
  },
}
</script>
