var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ActionIconStyled', {
    attrs: {
      "size": _vm.size
    }
  }, [_vm._t("icon")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }