<template>
  <PrivacyPolicyStyled :isMenuExpanded="isMenuExpanded" id="privacy-policy">
    <div class="popover-wrapper">
      <span @click="togglePopOver()">
        <span class="popover-trigger">
          {{ $t('privacyPolicy.menu') }}
          <ShieldIcon />
        </span>
        <PrivacyPolicyMobileIcon :isMenuExpanded="isMenuExpanded" />
      </span>
      <VPopover
        :placement="'right'"
        :open.sync="isActive"
        :popperOptions="popperOptions"
        :popoverClass="'userGuidePopOver'"
        :container="'#privacy-policy'"
        :trigger="'manual'"
        :autoHide="false"
      >
        <template v-slot:popover>
          <PopoverInnerWrapperStyled>
            <div class="content">
              {{ $t('privacyPolicy.bannerMessage') }}
            </div>
            <ActionsStyled>
              <router-link :to="{ name: 'privacyPolicy' }">
                <AButtonSolid @click="closePopUp">
                  {{ $t('actions.read') }}
                </AButtonSolid>
              </router-link>
            </ActionsStyled>
          </PopoverInnerWrapperStyled>
        </template>
      </VPopover>
    </div>
  </PrivacyPolicyStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import permissionsMixin from '@/mixins/permissions'
import { VPopover, VClosePopover } from 'v-tooltip'
import { flexCenter, flexStartCenter, buttonReset } from '@styles/mixins'
import chroma from 'chroma-js'
import PrivacyIconSrc from '@/assets/icons/PrivacyIcon.svg'
import { useStore } from '@/stores/userSettings'
import { get } from 'lodash'

const PrivacyPolicyStyled = styled('div')`
  grid-gap: 0.1rem;
  flex-direction: column;
  position: absolute;
  bottom: 4rem;
  align-self: center;
  font-size: 0.7rem;
  color: ${p => p.theme.colors.navFontNormal};
  .popover-trigger {
    display: none;
  }
  @media (min-width: 768px) {
    .popover-trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      margin-left: 0.1rem;
      ${props => (props.isMenuExpanded ? `display: flex;` : 'display: none;')};
      color: ${p => p.theme.colors.primary};
      &:hover {
        color: ${p => p.theme.colors.primaryLighter};
        > svg {
          fill: ${p => p.theme.colors.primaryLighter};
        }
      }
      & > svg {
        margin-left: 0.2rem;
      }
    }
    & > .userGuidePopOver {
      margin-left: 2rem !important;
    }
  }

  .popover-wrapper {
    ${flexCenter}
  }
`
const PopoverInnerWrapperStyled = styled('div')`
  grid-gap: 0.5rem;
  width: 300px;
  justify-content: left;
  box-sizing: border-box;
  padding: 0.5rem;
  color: ${p => p.theme.colors.navFontNormal};
  background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.4).css()};
  border-radius: 10px;
  .title {
    ${flexStartCenter}
    justify-content: left;
    align-items: center;
    padding: 0.6rem 0.6rem 0.4rem 0.6rem;
    > h5 {
      margin: 0 0 0 0.3rem;
      font-weight: normal;
      font-size: 0.83rem;
    }
  }
  .content {
    ${flexStartCenter}
    padding:  0.4rem 0.8rem 0.4rem 0.8rem;
    font-size: 0.8rem;
    font-weight: lighter;
  }
`

const AButtonSolid = styled('button')`
  ${buttonReset}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)}!important;
  background: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 0.8rem;
  &:hover {
    color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)};
    background: ${props => props.theme.colors.primaryActive};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

const ShieldIcon = styled(PrivacyIconSrc)`
  fill: ${({ theme }) => theme.colors.primary};
  width: 15px;
  height: 15px;
`

const PrivacyPolicyMobileIcon = styled(PrivacyIconSrc)`
  fill: ${({ theme }) => theme.colors.primary};
  width: 18px;
  height: 18px;
  cursor: pointer;
  @media (min-width: 768px) {
    ${props =>
      props.isMenuExpanded
        ? 'display:none;'
        : `width: 16px;
  height: 16px;`};
    &:hover {
      fill: ${p => p.theme.colors.primaryLighter};
    }
  }
`
const ActionsStyled = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 0.2rem;
  & > a {
    text-decoration: none;
  }
`
export default {
  mixins: [permissionsMixin],
  inject: ['theme', 'uiSettings'],
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    PrivacyPolicyStyled,
    PopoverInnerWrapperStyled,
    VPopover,
    ActionsStyled,
    AButtonSolid,
    ShieldIcon,
    PrivacyPolicyMobileIcon,
  },
  directives: {
    closePopover: VClosePopover,
  },
  watch: {
    isMenuExpanded: {
      handler() {
        this.isActive = false
      },
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    this.isActive = !get(this.uiSettings, 'hasAcceptedPrivacyPolicy', false)
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
    hasAcceptedPrivacyPolicy() {
      return get(this.uiSettings, 'hasAcceptedPrivacyPolicy', false)
    },
  },
  methods: {
    closePopUp() {
      if (!this.hasAcceptedPrivacyPolicy) {
        this.$root.$emit('hasViewedPrivacyPolicy')
      }
      this.isActive = false
    },
    togglePopOver() {
      if (!this.hasAcceptedPrivacyPolicy) {
        this.isActive = !this.isActive
      } else {
        this.$router.push({
          name: 'privacyPolicy',
        })
      }
    },
  },
}
</script>
