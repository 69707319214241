var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PasteIdsStyled', {
    on: {
      "click": _vm.activatePasteIdsOverlay
    }
  }, [_vm._v(" Paste IDs "), _c('FileTextIcon')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }