<template>
  <CopyToClipboardAtom @click.stop="copyToClipboard" v-tooltip="{ content: tooltipContent, classes: 'notification' }">
    <CopyIcon />
  </CopyToClipboardAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CopyIcon } from 'vue-feather-icons'
import { VTooltip } from 'v-tooltip'

import { FlashMessages } from '@common/singletons'

const CopyToClipboardAtom = styled('span')`
  cursor: pointer;
`

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    tooltipContent: {
      type: String,
      default: function () {
        return `Copy '${this.value}' to clipboard`
      },
    },
    showCopiedValueInFlashMessage: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    tooltip: VTooltip,
  },
  components: {
    CopyToClipboardAtom,
    CopyIcon,
  },
  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.value)
      if (this.showCopiedValueInFlashMessage) {
        FlashMessages.$emit('success', `Copied to '${this.value}' clipboard.`, { timeout: 1000 })
      } else {
        FlashMessages.$emit('success', `Copied to clipboard.`, { timeout: 1000 })
      }
    },
  },
}
</script>
