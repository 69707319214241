var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchInputAtom', [_c('input', {
    ref: "searchInput",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('actions.search')
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update', $event === null || $event === void 0 ? void 0 : $event.target.value);
      },
      "focus": function focus($event) {
        return _vm.$emit('focus');
      },
      "blur": function blur($event) {
        return _vm.$emit('blur');
      }
    }
  }), _c('SearchIcon', {
    class: {
      'is-searching': _vm.isSearching
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }