var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedSelectStyled', [_c('Multiselect', {
    staticClass: "custom_multiselect",
    attrs: {
      "track-by": "id",
      "label": _vm.label,
      "value": _vm.value,
      "disabled": _vm.disabled,
      "placeholder": _vm.placeholder,
      "options": _vm.options,
      "close-on-select": _vm.closeOnSelect,
      "max-height": _vm.maxHeight,
      "show-no-results": _vm.showNoResults,
      "hide-selected": _vm.hideSelected,
      "show-labels": false,
      "searchable": _vm.searchable,
      "resetAfter": _vm.resetAfter,
      "multiple": _vm.multiple,
      "internal-search": _vm.internalSearch,
      "loading": _vm.isLoading,
      "limit-text": _vm.limitText,
      "open-direction": _vm.openDirection,
      "clear-on-select": _vm.clearOnSelect,
      "allow-empty": _vm.allowEmpty
    },
    on: {
      "search-change": function searchChange($event) {
        return _vm.$emit('search-change', $event);
      },
      "select": function select($event) {
        return _vm.$emit('select', $event);
      },
      "remove": function remove($event) {
        return _vm.$emit('remove', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var isOpen = _ref.isOpen;
        return [_vm.value && _vm.value.length === 0 && isOpen ? _c('span', [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e()];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var option = _ref2.option;
        return [_vm.categoryIcon ? _c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Feed',
            "name": "".concat(option.label)
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.customOptions ? _vm.$t(_vm.customOptions + option.label) : option.label) + " "), _c('CheckIcon')];
      }
    }, {
      key: "singleLabel",
      fn: function fn(_ref3) {
        var option = _ref3.option;
        return [_vm._t("customLabelIcon"), _vm.categoryIcon ? _c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Feed',
            "name": "".concat(option.label)
          }
        }) : _vm._e(), _c('span', {
          staticClass: "labelText"
        }, [_vm._v(" " + _vm._s(_vm.customLabels ? _vm.$t(_vm.customLabels + option.label) : option.label) + " ")])];
      }
    }, {
      key: "caret",
      fn: function fn(_ref4) {
        var toggle = _ref4.toggle;
        return _vm.customCaretIcon ? _c('MoreHorizontalIcon', {
          staticClass: "multiselect__select caretIcon",
          on: {
            "mousedown": function mousedown($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }) : _c('ChevronDownIcon', {
          staticClass: "multiselect__select caretIcon",
          on: {
            "mousedown": function mousedown($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        });
      }
    }, {
      key: "tag",
      fn: function fn(_ref5) {
        var option = _ref5.option;
        return _vm.customTagSlot ? [_vm._t("tag", null, {
          "option": option
        })] : undefined;
      }
    }], null, true)
  }, [_vm.customNoOptionsSlot ? _c('template', {
    slot: "noOptions"
  }, [_vm._t("noOptions")], 2) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }