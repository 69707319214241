<template>
  <ShowAsLabelStyled>
    {{ label }}
  </ShowAsLabelStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const ShowAsLabelStyled = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`
export default {
  components: {
    ShowAsLabelStyled,
  },
  props: {
    label: {
      type: String,
    },
  },
}
</script>
