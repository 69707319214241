import PROFILE_QUERY from '#/graphql/profile/show.gql'
import DefaultHeader from '@/components/App/Header'
import Breadcrumbs from '@/components/App/Breadcrumbs'
import get from 'lodash/get'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
function createRouter(apolloProvider) {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'home',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Home/Home.vue'),
        },
        meta: {
          name: 'home',
          icon: 'HomeIcon',
          isNavigationItem: true,
        },
      },
      {
        path: '/assets',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/Assets.vue'),
        },
        meta: {
          isNavigationItem: true,
          name: 'assetList',
          icon: 'FleetIcon',
        },
        children: [
          {
            path: '',
            name: 'assetList',
            component: () => import('@/components/Assets/AssetsMain.vue'),
            meta: {
              isNavigationItem: true,
            },
          },
          {
            path: ':id',
            component: () => import('@/components/Assets/Asset.vue'),
            redirect: 'assetList/:id/overview',
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'overview',
                name: 'AssetOverview',
                component: () => import('@/components/Assets/Asset/AssetOverview.vue'),
              },
              {
                path: 'log',
                name: 'AssetLog',
                component: () => import('@/components/Assets/Asset/AssetLog.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/map',
        name: 'map',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Map/MapMain.vue'),
        },
        meta: {
          name: 'map',
          icon: 'MapIcon',
          permissionsRequired: ['map_read'],
          isNavigationItem: true,
        },
      },
      {
        path: '/dashboards',
        components: {
          header: () => import('@/components/Dashboard/DashboardHeader.vue'),
          main: () => import('@/pages/Dashboards.vue'),
        },
        meta: {
          name: 'dashboards',
          icon: 'TrendingUpIcon',
          permissionsRequired: ['dashboard_read'],
          isNavigationItem: true,
        },
        children: [
          {
            path: '',
            name: 'dashboards',
            component: () => import('@/components/Dashboards/DashboardsMain.vue'),
            meta: {
              permissionsRequired: ['dashboard_read'],
              isNavigationItem: true,
            },
          },
          {
            path: ':id',
            name: 'dashboard',
            component: () => import('@/components/Dashboard/DashboardMain.vue'),
            props: route => {
              return {
                id: route.params.id,
                assetID: route.query.asset,
              }
            },
            meta: {
              permissionsRequired: ['dashboard_read'],
            },
          },
        ],
      },
      {
        path: '/signals',
        name: 'signals',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Signals/SignalsMain.vue'),
        },
        meta: {
          name: 'signals',
          icon: 'ActivityIcon',
          permissionsRequired: ['signal_read'],
          isNavigationItem: true,
        },
      },
      {
        path: '/reporting',
        name: 'reporting',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Reporting/ReportingMain.vue'),
        },
        meta: {
          name: 'reporting',
          icon: 'FolderIcon',
          permissionsRequired: ['reporting_csv_read', 'reporting_pdf_read'],
          isNavigationItem: true,
        },
      },
      {
        path: '/organizations',
        name: 'organizations',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Organizations/OrganizationsMain.vue'),
        },
        meta: {
          name: 'organizations',
        },
      },
      {
        path: '/subsidiary/:id',
        name: 'subsidiary',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Subsidiary/SubsidiaryMain.vue'),
        },
        meta: {
          name: 'subsidiary',
        },
      },
      {
        path: '/planning',
        name: 'Planning',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/PlanningApp/PlanningApp.vue'),
        },
        meta: {
          icon: 'CalendarIcon',
          name: 'Planning',
          isNavigationItem: true,
          permissionsRequired: ['has_planning_app'],
        },
      },
      {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/PrivacyPolicy.vue'),
        },
        meta: {
          name: 'privacyPolicy',
        },
      },
      {
        path: '/shift-insight/:id',
        name: 'ShiftInsights',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/ShiftInsight.vue'),
        },
        props: {
          header: {
            emptyTitle: true,
          },
        },
      },
      {
        path: '/maintenance',
        components: {
          header: Breadcrumbs,
          main: () => import('@/pages/MaintenancePage.vue'),
        },
        meta: {
          name: 'maintenance',
          isNavigationItem: true,
          hasChildRoutesSubmenu: true,
          icon: 'ToolIcon',
          permissionsRequired: ['has_maintenance_app_v2'],
        },
        children: [
          {
            path: 'health-explorer',
            name: 'HealthExplorer',
            component: () => import('@/pages/Maintenance/HealthExplorerPage.vue'),
            meta: {
              name: 'HealthExplorer',
              isSubNavItem: true,
              permissionsRequired: ['has_maintenance_app_v2'],
            },
          },
          {
            path: 'asset-health/:id',
            name: 'AssetHealth',
            redirect: 'asset-health/:id/overview',
            component: () => import('@/pages/Maintenance/AssetHealthPage.vue'),
            meta: {
              name: 'AssetHealth',
              permissionsRequired: ['has_maintenance_app_v2'],
            },
            children: [
              {
                path: 'overview',
                name: 'AssetHealthOverview',
                component: () => import('@/pages/Maintenance/MaintenanceAssetHealth/MaintenanceAssetHealthOverviewPage.vue'),
                meta: {
                  name: 'AssetHealthOverview',
                  breadcrumbName: 'maintenance',
                  permissionsRequired: ['has_maintenance_app_v2'],
                },
              },
              {
                path: 'insights',
                name: 'AssetHealthInsight',
                component: () => import('@/pages/Maintenance/MaintenanceAssetHealth/MaintenanceAssetHealthInsightPage.vue'),
                meta: {
                  name: 'AssetHealthInsight',
                  breadcrumbName: 'maintenance',
                  permissionsRequired: ['has_maintenance_app_v2'],
                },
              },
              {
                path: 'statistics',
                name: 'AssetHealthStatistics',
                component: () => import('@/pages/Maintenance/MaintenanceAssetHealth/MaintenanceAssetHealthStatisticsPage.vue'),
                meta: {
                  name: 'AssetHealthStatistics',
                  breadcrumbName: 'maintenance',
                  permissionsRequired: ['has_maintenance_app_v2'],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/service-dealer',
        components: {
          header: Breadcrumbs,
          main: () => import('@/pages/ServiceDealerPage.vue'),
        },
        meta: {
          name: 'serviceDealer',
          isNavigationItem: true,
          hasChildRoutesSubmenu: true,
          icon: 'ServiceDealerIcon',
          permissionsRequired: ['has_service_dealer_app'],
        },
        children: [
          {
            path: 'health-explorer',
            name: 'ServiceDealerHealthExplorer',
            component: () => import('@/pages/ServiceDealer/HealthExplorerPage.vue'),
            meta: {
              name: 'ServiceDealerHealthExplorer',
              isSubNavItem: true,
              isSDApp: true,
              breadcrumbName: 'serviceDealer',
              permissionsRequired: ['has_service_dealer_app'],
            },
          },
          {
            path: 'asset-health/:id',
            name: 'ServiceDealerAssetHealth',
            redirect: 'asset-health/:id/overview',
            component: () => import('@/pages/ServiceDealer/AssetHealthPage.vue'),
            meta: {
              name: 'ServiceDealerAssetHealth',
              isSDApp: true,
              breadcrumbName: 'serviceDealer',
              permissionsRequired: ['has_service_dealer_app', 'has_uptime_service'],
            },
            children: [
              {
                path: 'overview',
                name: 'ServiceDealerAssetHealthOverview',
                component: () => import('@/pages/ServiceDealer/ServiceDealerAssetHealth/ServiceDealerAssetHealthOverviewPage.vue'),
                meta: {
                  name: 'ServiceDealerAssetHealthOverview',
                  isSDApp: true,
                  breadcrumbName: 'serviceDealer',
                  permissionsRequired: ['has_service_dealer_app', 'has_uptime_service'],
                },
              },
              {
                path: 'insights',
                name: 'ServiceDealerAssetHealthInsight',
                component: () => import('@/pages/ServiceDealer/ServiceDealerAssetHealth/ServiceDealerAssetHealthInsightPage.vue'),
                meta: {
                  name: 'ServiceDealerAssetHealthInsight',
                  isSDApp: true,
                  breadcrumbName: 'serviceDealer',
                  permissionsRequired: ['has_service_dealer_app', 'has_uptime_service'],
                },
              },
              {
                path: 'statistics',
                name: 'ServiceDealerAssetHealthStatistics',
                component: () => import('@/pages/ServiceDealer/ServiceDealerAssetHealth/ServiceDealerAssetHealthStatisticsPage.vue'),
                meta: {
                  name: 'ServiceDealerAssetHealthStatistics',
                  isSDApp: true,
                  breadcrumbName: 'serviceDealer',
                  permissionsRequired: ['has_service_dealer_app'],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/marketplace',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/MarketplaceApp.vue'),
        },
        meta: {
          name: 'marketplace',
          icon: 'ShoppingBagIcon',
          isNavigationItem: true,
          permissionsRequired: ['has_marketplace'],
        },
        props: {
          header: {
            emptyTitle: true,
          },
        },
        children: [
          {
            path: '',
            name: 'ProductCatalog',
            component: () => import('@/pages/MarketplaceApp/ProductCatalogPage.vue'),
            meta: {
              isNavigationItem: true,
              permissionsRequired: ['has_marketplace'],
            },
          },
          {
            path: ':id',
            name: 'ProductDetails',
            component: () => import('@/pages/MarketplaceApp/ProductDetailsPage.vue'),
            props: route => {
              return {
                id: route.params.id,
                permissionsRequired: ['has_marketplace'],
              }
            },
          },
        ],
      },
      {
        path: '/extensions',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/ExtensionBuilderPage.vue'),
        },
        meta: {
          name: 'extension',
          isNavigationItem: true,
          icon: 'SlidersIcon',
          permissionsRequired: ['has_extension_app'],
        },
        children: [
          {
            path: '',
            name: 'ListExtensions',
            component: () => import('@/pages/ExtensionBuilderApp/ListExtensionsPage.vue'),
            meta: {
              name: 'ListExtensions',
              permissionsRequired: ['has_extension_app'],
            },
          },
          {
            path: ':id/create-extension',
            name: 'CreateExtension',
            component: () => import('@/pages/ExtensionBuilderApp/CreateExtensionsPage.vue'),
            meta: {
              name: 'CreateExtensions',
              permissionsRequired: ['has_extension_app'],
            },
          },
          {
            path: ':id/assets',
            name: 'AddAssets',
            component: () => import('@/pages/ExtensionBuilderApp/ListAssetsSelectorPage.vue'),
            meta: {
              name: 'AddAssets',
              permissionsRequired: ['has_extension_app'],
            },
          },
          {
            path: ':id/asset-dimension/:assetDimensionId/create-assetDimension',
            name: 'AddAssetDimension',
            component: () => import('@/pages/ExtensionBuilderApp/AssetDimensionCreatorPage.vue'),
            meta: {
              name: 'AddAssetDimension',
              permissionsRequired: ['has_extension_app'],
            },
          },
        ],
      },
      {
        path: '/historical-asset-health',
        components: {
          header: Breadcrumbs,
          main: () => import('@/pages/HistoricalAssetHealthPage.vue'),
        },
        meta: {
          name: 'historicalAssetHealth',
          isNavigationItem: true,
          hasChildRoutesSubmenu: true,
          icon: 'HealthIcon',
          permissionsRequired: ['has_historical_asset_health'],
        },
        children: [
          {
            path: '',
            name: 'HistoricalAssetHealthList',
            component: () => import('@/pages/HistoricalAssetHealth/ListPage.vue'),
            meta: {
              name: 'HistoricalAssetHealthList',
              isSubNavItem: true,
              permissionsRequired: ['has_historical_asset_health'],
            },
          },
          {
            path: ':id/overview',
            name: 'HistoricalAssetHealthOverview',
            component: () => import('@/pages/HistoricalAssetHealth/OverviewPage.vue'),
            meta: {
              name: 'HistoricalAssetHealthOverview',
              breadcrumbName: 'historicalAssetHealth',
              permissionsRequired: ['has_historical_asset_health'],
            },
          },
        ],
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Misc/Unauthorized.vue'),
        },
        props: {
          header: {
            emptyTitle: true,
          },
        },
      },
      {
        path: '*',
        name: 'notFound',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Misc/NotFound.vue'),
        },
        props: {
          header: {
            emptyTitle: true,
          },
        },
      },
    ],
    scrollBehavior: function (to) {
      if (to?.name === 'ServiceDealerAssetHealthStatistics' || to?.name === 'AssetHealthStatistics') {
        const layoutId = document.getElementById('app-main')
        if (layoutId) {
          layoutId.scrollTop = 0
        }
      }
    },
  })

  router.beforeEach(async (to, from, next) => {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.title)
    let appName = 'Cockpit'
    let poweredBy = 'powered by talpasolutions.com'
    let pageTitle = `${appName} - ${poweredBy}`
    if (nearestWithTitle) pageTitle = `${nearestWithTitle.meta.title} - ${appName} - ${poweredBy}`
    document.title = pageTitle
    const permissionsRequired = get(to, 'meta.permissionsRequired', [])
    if (permissionsRequired.length > 0) {
      const apolloClient = get(apolloProvider, 'defaultClient')
      if (!apolloClient) {
        next({ name: 'unauthorized' })
      } else {
        const profileQuery = await apolloClient.query({
          query: PROFILE_QUERY,
        })
        const permissions = get(profileQuery, 'data.profile.permissions', [])
        const userHasOneOfRequiredPermissions = permissionsRequired.some(permissionRequired => {
          return permissions.find(permission => permissionRequired === permission.name)
        })
        if (userHasOneOfRequiredPermissions) {
          next()
        } else {
          next({ name: 'unauthorized' })
        }
      }
    } else {
      next()
    }
  })

  return router
}

export default createRouter
