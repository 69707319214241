<template>
  <UnitsSettingsStyled>
    <UnitsSettingStyled
      v-for="ts in unitsSettings"
      :key="ts.id"
      :isActive="ts.isActive"
      :class="{ active: ts.isActive }"
      @click="setUnitsSetting(ts.id)"
    >
      <span>{{ ts.name }}</span>
      <transition name="fade">
        <CheckIcon v-if="ts.isActive" />
      </transition>
    </UnitsSettingStyled>
  </UnitsSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { getUserIdFromToken } from '@common/utils'

import UPDATE_UI_SETTINGS_MUTATION from '#/graphql/user/uiSettings/update.gql'

import { CheckIcon } from 'vue-feather-icons'

import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'

const UnitsSettingsStyled = styled('div')`
  ${flexColumns}
`

const UnitsSettingStyled = styled('div')`
  ${flexCenter}
  ${props => buttonReset(props)}
  position: relative;
  margin-top: 0.75rem;
  padding: 0.5rem 2rem;
  height: 2rem;
  align-items: flex-start;
  .feather {
    position: absolute;
    top: 0.25rem;
    right: 0;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    UnitsSettingsStyled,
    UnitsSettingStyled,
    CheckIcon,
  },
  inject: ['uiSettings'],
  computed: {
    unitsSettings() {
      const activeUnitsSetting = get(this.uiSettings, 'units')
      return ['METRIC', 'IMPERIAL'].map(id => ({
        id,
        name: this.$t(`uiSettings.units.${id}`),
        isActive: activeUnitsSetting === id,
      }))
    },
  },
  methods: {
    async setUnitsSetting(id) {
      await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            units: id,
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
    },
  },
}
</script>
