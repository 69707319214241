import { Duration } from 'luxon'

function getTimeBucketKeys(selectedTimeframeInterval, granularity) {
  const granularityISO = Duration.fromISO(granularity)
  const timeBuckets = selectedTimeframeInterval.splitBy(granularityISO)
  return timeBuckets.map(bucket => bucket.start.toUTC().toISO())
}

function getTimeBuckets(selectedTimeframeInterval, granularity) {
  const granularityISO = Duration.fromISO(granularity)
  return selectedTimeframeInterval.splitBy(granularityISO)
}

export { getTimeBucketKeys, getTimeBuckets }
