var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ImageUploadStyled', [_vm.isUploading ? _c('UploadingStyled', [_c('LoadingDots')], 1) : _vm.showPreview ? _c('ImagePreviewStyled', [_c('img', {
    attrs: {
      "src": _vm.image.url
    }
  }), _c('ButtonStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('disconnect');
      }
    }
  }, [_c('XIcon')], 1)], 1) : _c('FileInputStyled', {
    on: {
      "click": _vm.triggerFileUpload
    }
  }, [_c('div', {
    staticClass: "trigger"
  }, [_c('ImageIcon')], 1), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.upload
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }