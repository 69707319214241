var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ErrorInspectorStyled', [_vm.hasErrors ? _c('VPopover', {
    attrs: {
      "placement": 'bottom-start',
      "popoverClass": 'notificationPopOver',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions,
      "container": '#app'
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('ErrorListStyled', [_c('div', {
          staticClass: "content"
        }, [_c('div', [_c('strong', [_vm._v("Application encountered the following errors:")])]), _c('div', {
          staticClass: "error-list"
        }, [_vm._l(_vm.errors, function (error) {
          var _error$, _error$2, _error$3, _error$3$graphQLError, _error$4, _error$6, _error$6$lastReceived, _vm$showDetailsForKey, _error$1$graphQLError, _error$7;
          return [_c('div', {
            key: error[0] + '_type',
            staticClass: "type"
          }, [_c('span', [_vm._v(" " + _vm._s(((_error$ = error[1]) === null || _error$ === void 0 ? void 0 : _error$.networkError) !== undefined ? 'NetworkError' : '') + " ")]), _c('span', [_vm._v(" " + _vm._s(((_error$2 = error[1]) === null || _error$2 === void 0 ? void 0 : _error$2.graphQLErrors) !== undefined ? 'GraphQLErrors' : '') + " ")]), _c('span', [_vm._v("in")])]), ((_error$3 = error[1]) === null || _error$3 === void 0 ? void 0 : (_error$3$graphQLError = _error$3.graphQLErrors) === null || _error$3$graphQLError === void 0 ? void 0 : _error$3$graphQLError.length) > 0 ? _c('div', {
            key: error[0] + '_key',
            staticClass: "key toggle",
            on: {
              "click": function click($event) {
                return _vm.toggle(error[0]);
              }
            }
          }, [_vm._v(" " + _vm._s(error[0]) + " ")]) : _c('div', {
            key: error[0] + '_key',
            staticClass: "key"
          }, [_vm._v(" " + _vm._s(error[0]) + " ")]), _c('div', {
            key: error[0] + '_trace',
            staticClass: "copy-trace-id"
          }, [(_error$4 = error[1]) !== null && _error$4 !== void 0 && _error$4.traceId ? _c('CopyIcon', {
            on: {
              "click": function click($event) {
                var _error$5;
                return _vm.copyTraceId((_error$5 = error[1]) === null || _error$5 === void 0 ? void 0 : _error$5.traceId);
              }
            }
          }) : _vm._e()], 1), _c('div', {
            key: error[0] + '_time',
            staticClass: "time"
          }, [_vm._v(" " + _vm._s((_error$6 = error[1]) === null || _error$6 === void 0 ? void 0 : (_error$6$lastReceived = _error$6.lastReceived) === null || _error$6$lastReceived === void 0 ? void 0 : _error$6$lastReceived.toRelative({
            style: 'narrow'
          })) + " ")]), (_vm$showDetailsForKey = _vm.showDetailsForKeys) !== null && _vm$showDetailsForKey !== void 0 && _vm$showDetailsForKey.includes(error[0]) ? _c('div', {
            key: error[0] + '_details',
            staticClass: "details"
          }, _vm._l((_error$1$graphQLError = (_error$7 = error[1]) === null || _error$7 === void 0 ? void 0 : _error$7.graphQLErrors) !== null && _error$1$graphQLError !== void 0 ? _error$1$graphQLError : [], function (error, i) {
            return _c('span', {
              key: i
            }, [_vm._v(" " + _vm._s(i + 1) + ": " + _vm._s(error.message) + " ")]);
          }), 0) : _vm._e()];
        })], 2)])])];
      },
      proxy: true
    }], null, false, 3428353644)
  }, [_c('ButtonStyleless', {
    staticClass: "tooltip-target"
  }, [_c('AlertTriangleIcon')], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }