var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BrandStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_c('TalpaCockpitIcon', {
    staticClass: "brand brand-desktop",
    attrs: {
      "width": "80",
      "height": "35"
    }
  }), _c('TalpaCockpitIconMobile', {
    staticClass: "brand brand-mobile",
    attrs: {
      "width": "35",
      "height": "35"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }