var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CloseButtonStyled', [_c('XIcon', {
    staticClass: "close-icon",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }