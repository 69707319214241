var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.icon === 'HomeIcon' ? _c('HomeIcon') : _vm.icon === 'ToolIcon' ? _c('ToolIcon') : _vm.icon === 'MapIcon' ? _c('MapIcon') : _vm.icon === 'FolderIcon' ? _c('FolderIcon') : _vm.icon === 'ActivityIcon' ? _c('ActivityIcon') : _vm.icon === 'TrendingUpIcon' ? _c('TrendingUpIcon') : _vm.icon === 'FleetIcon' ? _c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": 'FleetIcon'
    }
  }) : _vm.icon === 'ShoppingBagIcon' ? _c('ShoppingBagIcon') : _vm.icon === 'BriefcaseIcon' ? _c('BriefcaseIcon') : _vm.icon === 'ServiceDealerIcon' ? _c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": 'ServiceDealerIcon'
    }
  }) : _vm.icon === 'CalendarIcon' ? _c('CalendarIcon') : _vm.icon === 'SlidersIcon' ? _c('SlidersIcon') : _vm.icon === 'HealthIcon' ? _c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": 'HealthIcon'
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }