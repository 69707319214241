var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ActionMessageStyled', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _c('ActionIconAtom', {
    attrs: {
      "size": '24px'
    }
  }, [_c('template', {
    slot: "icon"
  }, [_c('XIcon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  })], 1)], 2), _c('ActionIconAtom', {
    attrs: {
      "size": '24px'
    }
  }, [_c('template', {
    slot: "icon"
  }, [_c('CheckIcon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('accept');
      }
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }