var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SettingsStyled', {
    class: {
      active: _vm.isActive
    },
    on: {
      "click": _vm.activateSettingsOverlay
    }
  }, [_c('SettingsIcon')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }