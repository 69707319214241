import { styled } from '@egoist/vue-emotion'
import { buttonReset, flexCenter } from '@styles/mixins'

export default styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.primary};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0.5rem;
  &:hover,
  &.active {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
  &:disabled {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.muted};
  }
  svg {
    width: 100%;
  }
`
