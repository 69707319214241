var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsListMoleculeStyled', [_c('NotificationsListHeaderMolecule', {
    attrs: {
      "showAllNotifications": _vm.showAllNotifications,
      "unreadCount": _vm.unreadCount
    },
    on: {
      "toggle-show-all-notifications": function toggleShowAllNotifications($event) {
        return _vm.$emit('toggle-show-all-notifications');
      },
      "mark-all-notifications-as-read": function markAllNotificationsAsRead($event) {
        return _vm.$emit('mark-all-notifications-as-read');
      }
    }
  }), _c('NotificationsListMainMolecule', {
    attrs: {
      "notifications": _vm.notifications,
      "notificationsError": _vm.notificationsError,
      "infiniteId": _vm.infiniteId,
      "isLoading": _vm.isLoading,
      "canLoadMore": _vm.canLoadMore
    },
    on: {
      "load-more": function loadMore($event) {
        return _vm.$emit('load-more', $event);
      },
      "toggle-notification-is-unread": function toggleNotificationIsUnread($event) {
        return _vm.$emit('toggle-notification-is-unread', $event);
      },
      "close-pop-over": function closePopOver($event) {
        return _vm.$emit('close-pop-over');
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }