<template>
  <TimeAgoStyled>
    <template v-if="isNull"> - </template>
    <template v-else-if="!isValid">
      {{ $t('times.invalid') }}
    </template>
    <div v-else class="count">
      <span> {{ count }} </span>
      <SimplePopover :popoverContent="tooltip" :placement="placement" :trigger="trigger">
        <template v-slot:trigger>
          <ClockIcon size="1.2x" class="icon" />
        </template>
      </SimplePopover>
    </div>
  </TimeAgoStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import { ClockIcon } from 'vue-feather-icons'
import SimplePopover from '@common/components/src/SimplePopover'
import resizeMixin from '@/mixins/resize'

const TimeAgoStyled = styled('div')`
  .count {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      width: 8rem;
    }
  }
  .icon {
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      color: ${p => p.theme.colors.primaryLighter};
    }
    &.:active {
      color: ${p => p.theme.colors.primaryLighter};
    }
  }
`

export default {
  inject: ['uiSettings'],
  mixins: [resizeMixin],
  props: {
    time: {
      type: String,
    },
    follow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TimeAgoStyled,
    ClockIcon,
    SimplePopover,
  },
  data() {
    return {
      now: DateTime.local(),
      timer: null,
      placement: 'top-start',
      trigger: 'hover',
    }
  },
  mounted() {
    if (this.follow) {
      this.timer = setInterval(() => {
        this.now = DateTime.local()
      }, 5000)
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  computed: {
    dt() {
      return DateTime.fromISO(this.time)
    },
    isNull() {
      return this.time === null
    },
    isValid() {
      return this.dt.isValid
    },
    count() {
      if (!this.isValid) {
        return 0
      }

      return DateTime.fromISO(this.time).toRelative({
        locale: this.locale,
      })
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
    tooltip() {
      const localTime = DateTime.fromISO(this.dt).setLocale(this.locale)
      return `${localTime.toLocaleString(DateTime.DATE_SHORT)} - ${localTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}`
    },
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        const size = get(window, 'innerWidth', 768)
        this.placement = size < 768 ? 'top-end' : 'top-start'
        this.trigger = size < 768 ? 'click' : 'hover'
      })
    },
  },
}
</script>
