<template>
  <TitleWithBorderAtomStyled>
    {{ title }}
  </TitleWithBorderAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const TitleWithBorderAtomStyled = styled('div')`
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    TitleWithBorderAtomStyled,
  },
}
</script>
