var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.label ? _c('OnOffToggleWithLabelStyled', [_c('OnOffToggleStyled', {
    attrs: {
      "alternativeStyle": _vm.alternativeStyle,
      "isActive": _vm.value,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('InnerStyled', {
    attrs: {
      "alternativeStyle": _vm.alternativeStyle,
      "isActive": _vm.value
    }
  })], 1), _vm.label ? _c('label', {
    on: {
      "click": _vm.onClick
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()], 1) : _c('OnOffToggleStyled', {
    attrs: {
      "alternativeStyle": _vm.alternativeStyle,
      "isActive": _vm.value,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('InnerStyled', {
    attrs: {
      "alternativeStyle": _vm.alternativeStyle,
      "isActive": _vm.value
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }