<template>
  <OnOffToggleWithLabelStyled v-if="label">
    <OnOffToggleStyled :alternativeStyle="alternativeStyle" :isActive="value" @click="onClick" :disabled="disabled">
      <InnerStyled :alternativeStyle="alternativeStyle" :isActive="value" />
    </OnOffToggleStyled>
    <label v-if="label" @click="onClick">{{ label }}</label>
  </OnOffToggleWithLabelStyled>
  <OnOffToggleStyled v-else :alternativeStyle="alternativeStyle" :isActive="value" @click="onClick" :disabled="disabled">
    <InnerStyled :alternativeStyle="alternativeStyle" :isActive="value" />
  </OnOffToggleStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const OnOffToggleWithLabelStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  label {
    cursor: pointer;
    user-select: none;
    font-size: 13px;
  }
`

const OnOffToggleStyled = styled('div')`
  user-select: none;
  position: relative;
  width: 32px;
  height: 16px;
  border-radius: 0.75rem;
  padding: 1px;
  background: ${p =>
    p.isActive
      ? p.theme.isDark
        ? p.theme.colors.primary
        : p.alternativeStyle
        ? p.theme.colors.secondary
        : p.theme.colors.primary
      : p.disabled
      ? p.theme.colors.toggleDisabled
      : p.theme.colors.toggleInActive};
  transition: border-color 0.5s ease;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
`

const InnerStyled = styled('div')`
  position: absolute;
  top: 2px;
  right: ${p => (p.isActive ? '12px' : '18px')};
  width: 14px;
  height: 14px;
  background: ${p =>
    p.isActive
      ? p.theme.isDark
        ? p.theme.colors.black
        : p.alternativeStyle
        ? p.theme.colors.secondary
        : p.theme.colors.white
      : p.theme.isDark
      ? p.theme.colors.black
      : p.theme.colors.white};
  border-radius: 0.5rem;
  transform: translateX(${p => (!p.isActive ? '0' : '10px')});
  transition: transform 0.5s ease;
`

export default {
  props: {
    alternativeStyle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  components: {
    OnOffToggleWithLabelStyled,
    OnOffToggleStyled,
    InnerStyled,
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('toggle')
      }
    },
  },
}
</script>
