var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CheckboxItemStyled', [_c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    attrs: {
      "type": "checkbox",
      "disabled": _vm.isDisabled
    },
    domProps: {
      "checked": _vm.isChecked
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change', $event.target.checked);
      }
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_c('span', {
    attrs: {
      "title": _vm.label
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }