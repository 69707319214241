<template>
  <FeedSelectStyled>
    <Multiselect
      class="feed_multiselect"
      track-by="id"
      label="label"
      :value="filter"
      :disabled="!options"
      :openDirection="'below'"
      :placeholder="placeholder"
      :options="options"
      :close-on-select="closeOnSelect"
      :max-height="500"
      :show-no-results="false"
      :hide-selected="false"
      :show-labels="false"
      :searchable="false"
      :resetAfter="true"
      @select="selectedFilter"
    >
      <template slot="option" slot-scope="{ option }" class="icon">
        <TalpaIcon v-if="categoryIcon" class="icon" :scope="'Feed'" :name="`${option.label}`" />
        {{ customOptions ? $t(customOptions + option.label) : option.label }} <CheckIcon />
      </template>
      <template slot="singleLabel" slot-scope="{ option }">
        <slot name="customLabelIcon" class="labelIcon" />
        <TalpaIcon v-if="categoryIcon" class="icon" :scope="'Feed'" :name="`${option.label}`" />
        <span class="labelText"> {{ customLabels ? $t(customLabels + option.label) : option.label }} </span>
      </template>
      <MoreHorizontalIcon
        slot-scope="{ toggle }"
        slot="caret"
        class="multiselect__select caretIcon"
        @mousedown.prevent.stop="toggle"
        v-if="customCaretIcon"
      />
      <ChevronDownIcon slot-scope="{ toggle }" slot="caret" class="multiselect__select caretIcon" @mousedown.prevent.stop="toggle" v-else />
    </Multiselect>
  </FeedSelectStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronDownIcon, CheckIcon, MoreHorizontalIcon } from 'vue-feather-icons'
import Multiselect from 'vue-multiselect'
import TalpaIcon from '@common/components/src/TalpaIcon'

const FeedSelectStyled = styled('div')`
  display: flex;
  align-items: center;
  .feed_multiselect {
    margin-top: 0.1rem;
    width: 100%;
    background: transparent;
    cursor: pointer;
    font-size: 14px;
    border: none;
    &:hover {
      border: none;
    }
    & .multiselect__option--selected {
      background: ${p => p.theme.colors.primary} !important;
      color: white !important;
      > svg {
        display: inline-block !important;
        float: right;
        width: 15px;
        height: 15px;
      }
      .icon {
        width: 20px;
        height: 20px;
        display: inline-block !important;
      }
    }
    .multiselect__option--highlight {
      background: ${p => p.theme.colors.atomic.hover};
      color: ${p => (p.theme.isDark ? p.theme.colors.white : p.theme.colors.black)};
    }
    .multiselect__option {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      justify-content: space-between;
      > svg {
        display: none;
      }
      .icon {
        width: 20px;
        height: 20px;
        display: inline-block !important;
      }

      &.multiselect__option--disabled {
        background: transparent;
      }
    }
    .multiselect__content-wrapper {
      top: 3rem;
      border-radius: 5px;
      border: none;
    }
    .multiselect__single {
      font-size: 14px;
      top: 0.1rem;
      background: transparent;
      color: ${p => p.theme.colors.primary};
      display: flex;
      flex-direction: row;
      grid-gap: 0.75rem;
      > svg {
        vertical-align: middle;
      }
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    .multiselect__tags {
      background: transparent;
    }
    .caretIcon {
      stroke: ${({ theme }) => theme.colors.normalFontColor};
    }
    .multiselect__input {
      background: transparent;
    }
  }
`
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    customOptions: {
      type: String,
      required: false,
    },
    customLabels: {
      type: String,
      required: false,
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    customCaretIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    categoryIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedOption: {
      type: Object,
      required: false,
    },
    shouldNotResetSelectedOption: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
    },
  },
  components: {
    FeedSelectStyled,
    Multiselect,
    ChevronDownIcon,
    CheckIcon,
    MoreHorizontalIcon,
    TalpaIcon,
  },
  watch: {
    selectedOption: {
      handler() {
        this.selectedValue()
      },
      immediate: true,
    },
  },
  data() {
    return {
      filter: {},
    }
  },
  created() {
    this.$root.$on('reset', this.resetFilter) // resets the filter & loads the first option by default
    if (!this.shouldNotResetSelectedOption) {
      this.resetFilter() // preselected option
    }
  },
  computed: {},
  methods: {
    selectedFilter(option) {
      this.filter = []
      this.filter = option
      this.$emit('selectedFilter', this.filter)
    },
    resetFilter() {
      this.filter = this.options[0]
    },
    selectedValue() {
      this.filter = this.selectedOption
    },
  },
}
</script>
