var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SubNavigationItemStyled', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.navigationItem.name
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("navigation.cockpit.".concat(_vm.navigationItem.name))) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }