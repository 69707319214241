var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.releaseNotesReadPermission ? _c('ReleaseNotesStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded,
      "id": "release-notes"
    }
  }, [_c('ProductNameStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_vm._v(_vm._s(_vm.$t('releaseNotes.productName')))]), _c('div', {
    staticClass: "popover-wrapper"
  }, [_c('VersionStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_vm._v(_vm._s(_vm.$t('releaseNotes.version')))]), _c('VPopover', {
    attrs: {
      "placement": 'top-start',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions,
      "popoverClass": 'userGuidePopOver',
      "container": '#release-notes'
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverInnerWrapperStyled', [_c('div', {
          staticClass: "title"
        }, [_c('StarIcon'), _c('h5', [_vm._v(_vm._s(_vm.$t('releaseNotes.title')))])], 1), _c('div', {
          staticClass: "content"
        }, [_vm._v(" " + _vm._s(_vm.$t('releaseNotes.content')) + " ")]), _c('ActionsStyled', [_c('ButtonBorderedStyled', {
          attrs: {
            "closePopover": ""
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")]), _vm.pdfLink ? _c('AButtonSolid', {
          attrs: {
            "href": _vm.pdfLink,
            "target": "_blank"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('releaseNotes.learn_more')) + " ")]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 2097194972)
  }, [_c('span', {
    staticClass: "popover-trigger"
  }, [_vm._v(" - " + _vm._s(_vm.$t('releaseNotes.learn_more')) + " "), _c('HelpCircleIcon', {
    attrs: {
      "size": "1.2x"
    }
  })], 1), _c('ReleaseNotesMobileIcon', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }), _c('circle-icon', {
    staticClass: "mobileMenuIcon",
    attrs: {
      "size": "0.5x"
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }