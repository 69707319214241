import chroma from 'chroma-js'

import BaseColors from './base-colors'

const colors = [BaseColors.charts.first, BaseColors.charts.third, BaseColors.charts.second]

export function distinctColors(c = 6, isSignalSampling) {
  if (isSignalSampling) {
    return chroma.scale('Spectral').colors(c)
  }
  return chroma.scale(colors).colors(c)
}
