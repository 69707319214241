<template>
  <ModelAssociatorStyled>
    <div class="title">
      <div class="label">
        {{ title }}
        <Badge :label="models.length + ''" />
      </div>
      <slot v-if="canBulkAddAssets" name="bulkAddAssets"></slot>
    </div>
    <div class="models">
      <div class="model" v-for="model in models" :key="model.id">
        <div class="label">
          {{ model.label }}
        </div>
        <button class="delete" @click="$emit('disconnect', model)">
          <XIcon />
        </button>
      </div>
    </div>

    <div class="add-icon">
      <PlusCircleIcon />
    </div>
    <Multiselect
      class="multiselect"
      track-by="id"
      placeholder="Type to search"
      :options="targetsFiltered"
      :label="targetModelLabelName"
      :searchable="true"
      :multiple="multipleSelect"
      :loading="$apollo.loading"
      :internal-search="true"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @search-change="findTargets"
      @select="$emit('connect', $event)"
    />
  </ModelAssociatorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import gql from 'graphql-tag'
import get from 'lodash/get'
import Multiselect from 'vue-multiselect'
import chroma from 'chroma-js'
import { PlusCircleIcon, XIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'
import Badge from './Badge'

const ModelAssociatorStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'title title'
    'models models'
    'add multiselect';
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  .add-icon {
    ${flexCenter}
    grid-area: add;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  }
  .title {
    ${flexCenter}
    padding: 1.5rem 0.75rem;
    grid-area: title;
    font-size: 1.2rem;
    justify-content: space-between;
    > .label {
      ${flexCenter}
      gap: 1rem;
    }
  }

  .button {
    margin-left: 1rem;
  }
  .models {
    grid-area: models;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr;
    grid-auto-rows: 2rem;
    margin: 2px;
    overflow: auto;
    padding: 1rem 0;
    max-height: 400px;
    .model {
      display: grid;
      grid-template-columns: auto 2rem;
      grid-template-rows: 1fr;
      grid-gap: 2px;
      .label {
        ${flexCenter}
        justify-content: flex-start;
        padding-left: 0.5rem;
        background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
      }
      button {
        ${buttonReset}
        ${flexCenter}
        background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
      }
    }
  }
  .multiselect {
    grid-area: multiselect;
  }
`
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    models: {
      type: Array,
      required: true,
    },
    sourceModelName: {
      type: String,
      required: true,
    },
    targetModelName: {
      type: String,
      required: true,
    },
    targetModelNamePlural: {
      type: String,
      required: true,
    },
    targetModelLabelName: {
      type: String,
      default: 'name',
    },
    whereSearchVariable: {
      type: Array,
    },
    whereSearchInputType: {
      type: String,
    },
    multipleSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    canBulkAddAssets: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ModelAssociatorStyled,
    Multiselect,
    XIcon,
    PlusCircleIcon,
    Badge,
  },
  data() {
    return {
      targets: [],
    }
  },
  computed: {
    targetsFiltered() {
      return this.targets.filter(f => !this.models.find(m => m.id === f.id))
    },
    selectedIDs() {
      return this.models.map(model => model.id)
    },
  },
  async mounted() {
    await this.findTargets('')
  },
  methods: {
    deleteItem(model) {
      this.$emit('disconnect', model)
    },
    limitText(count) {
      return `and ${count} other ${this.targetModelName}s`
    },
    async findTargets(s) {
      let res
      if (this.whereSearchInputType && this.whereSearchVariable) {
        const ORs = this.whereSearchVariable.map(key => ({
          [key]: s,
        }))
        res = await this.$apollo.query({
          query: gql`
            query ${this.targetModelNamePlural}($where: ${this.whereSearchInputType}) {
              ${this.targetModelNamePlural}(where: $where) {
                id
                ${this.targetModelLabelName}
              }
            }
          `,
          variables: {
            where: {
              OR: ORs,
              id_not_in: this.selectedIDs,
            },
          },
        })
      } else {
        res = await this.$apollo.query({
          query: gql`
            query ${this.targetModelNamePlural} {
              ${this.targetModelNamePlural} {
                id
                ${this.targetModelLabelName}
              }
            }
          `,
        })
      }
      this.targets = get(res, ['data', this.targetModelNamePlural], [])
    },
  },
}
</script>
