import { DateTime, Interval } from 'luxon'

import { Schedule } from '@rschedule/core/generators'

export function getScheduleOccurencesInIntervalOld(schedule, interval) {
  if (!schedule) {
    throw new Error(`missing schedule parameter`)
  }
  if (!interval) {
    throw new Error(`missing interval parameter`)
  }
  if (!Interval.isInterval(interval)) {
    throw new Error(`interval is not a luxon interval`)
  }

  const start = interval.start
  const end = interval.end

  const s = new Schedule({
    rrules: schedule.recurrences.map(recurrence => {
      const r = {
        start: DateTime.fromISO(recurrence.start),
        frequency: recurrence.frequency,
        duration: recurrence.duration,
        interval: recurrence.interval ? recurrence.interval : undefined,
        count: recurrence.count ? recurrence.count : undefined,
        weekStart: recurrence.weekStart ? recurrence.weekStart : undefined,
        bySecondOfMinute: undefined,
        byMinuteOfHour: undefined,
        byHourOfDay: undefined,
        byDayOfWeek: recurrence.byDayOfWeek.length > 0 ? recurrence.byDayOfWeek : undefined,
        byDayOfMonth: recurrence.byDayOfMonth.length > 0 ? recurrence.byDayOfMonth : undefined,
        byMonthOfYear: recurrence.byMonthOfYear.length > 0 ? recurrence.byMonthOfYear : undefined,
      }
      return r
    }),
  })
  const occurrences = s.occurrences({ start, end })
  return occurrences.toArray()
}

export function getRScheduleBySchedule(schedule) {
  if (!schedule) {
    throw new Error(`missing schedule parameter`)
  }

  const s = new Schedule({
    timezone: schedule.timezone,
    maxDuration: schedule.recurrences.reduce((acc, scheduleRecurrence) => {
      return Math.max(scheduleRecurrence.duration || 0, acc)
    }, 0),
    rrules: schedule.recurrences.map(recurrence => {
      const start = DateTime.fromISO(recurrence.start).setZone(schedule.timezone)
      const r = {
        start,
        frequency: recurrence.frequency,
        duration: recurrence.duration,
        interval: recurrence.interval ? recurrence.interval : undefined,
        count: recurrence.count ? recurrence.count : undefined,
        weekStart: recurrence.weekStart ? recurrence.weekStart : undefined,
        bySecondOfMinute: recurrence.bySecondOfMinute.length > 0 ? recurrence.bySecondOfMinute : undefined,
        byMinuteOfHour: recurrence.byMinuteOfHour.length > 0 ? recurrence.byMinuteOfHour : undefined,
        byHourOfDay: recurrence.byHourOfDay.length > 0 ? recurrence.byHourOfDay : undefined,
        byDayOfWeek: recurrence.byDayOfWeek.length > 0 ? recurrence.byDayOfWeek : undefined,
        byDayOfMonth: recurrence.byDayOfMonth.length > 0 ? recurrence.byDayOfMonth : undefined,
        byMonthOfYear: recurrence.byMonthOfYear.length > 0 ? recurrence.byMonthOfYear : undefined,
      }
      return r
    }),
  })
  return s
}

export function getScheduleOccurencesInInterval(schedule, interval) {
  if (!interval) {
    throw new Error(`missing interval parameter`)
  }
  if (!Interval.isInterval(interval)) {
    throw new Error(`interval is not a luxon interval`)
  }

  if (!schedule.useTimezoneInRSchedule) {
    return getScheduleOccurencesInIntervalOld(schedule, interval)
  }

  const rSchedule = getRScheduleBySchedule(schedule)
  const { start, end } = interval

  const occurrences = rSchedule.occurrences({ start, end })
  return occurrences.toArray()
}

export function getnScheduleOccurences(schedule, take, reverse = false, start = null, end = null) {
  if (!take) {
    throw new Error(`missing count parameter`)
  }

  if (!schedule.useTimezoneInRSchedule) {
    return getnScheduleOccurencesOld(schedule, take, reverse, start, end)
  }

  const rSchedule = getRScheduleBySchedule(schedule)

  const occurrences = rSchedule.occurrences({ schedule, take, reverse, start, end })
  return occurrences.toArray()
}

export function getnScheduleOccurencesOld(schedule, take, reverse = false, start = null, end = null) {
  if (!schedule) {
    throw new Error(`missing schedule parameter`)
  }
  if (!take) {
    throw new Error(`missing count parameter`)
  }

  const s = new Schedule({
    rrules: schedule.recurrences.map(recurrence => {
      const r = {
        start: DateTime.fromISO(recurrence.start),
        frequency: recurrence.frequency,
        duration: recurrence.duration,
        interval: recurrence.interval ? recurrence.interval : undefined,
        count: recurrence.count ? recurrence.count : undefined,
        weekStart: recurrence.weekStart ? recurrence.weekStart : undefined,
        bySecondOfMinute: undefined,
        byMinuteOfHour: undefined,
        byHourOfDay: undefined,
        byDayOfWeek: recurrence.byDayOfWeek.length > 0 ? recurrence.byDayOfWeek : undefined,
        byDayOfMonth: recurrence.byDayOfMonth.length > 0 ? recurrence.byDayOfMonth : undefined,
        byMonthOfYear: recurrence.byMonthOfYear.length > 0 ? recurrence.byMonthOfYear : undefined,
      }
      return r
    }),
  })
  const occurrences = s.occurrences({ start, end, take, reverse })
  return occurrences.toArray()
}
