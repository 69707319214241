import { styled } from '@egoist/vue-emotion'
import { interactiveColors, flexCenter } from '@styles/mixins'

const d = styled('button')`
  ${props => interactiveColors(props)}
  ${flexCenter}
  ${props => (props.border ? 'border-radius: .25rem;' : '')};
  ${props => (props.margin ? 'margin: .5rem;' : '')};
  ${props => (props.padding ? 'padding: .5rem;' : '')};
  cursor: pointer;
  outline: none;
  border: none;
`

export default d
