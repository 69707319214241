var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TabbedPanelStyled', [_vm.title ? _c('HeaderStyled', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('TabsStyled', _vm._l(_vm.tabs, function (tab) {
    return _c('TabStyled', {
      key: tab.id,
      class: {
        active: tab.isActive
      },
      attrs: {
        "isActive": tab.isActive
      },
      on: {
        "click": function click($event) {
          return _vm.activateTab(tab.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(tab.name)) + " ")]);
  }), 1), _c('ActiveTabStyled', {
    attrs: {
      "firstIsActive": _vm.firstIsActive
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }