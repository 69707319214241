var render = function () {
  var _vm$notification$asse, _vm$notification$asse2, _vm$notification, _vm$notification$asse3, _vm$notification2, _vm$notification2$ass, _vm$notification3, _vm$notification3$ass, _vm$notification4, _vm$notification4$ass, _vm$notification5, _vm$notification5$ass, _vm$notification6, _vm$notification6$ass;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetNotificationWrapperStyled', [_c('AssetNotificationStyled', {
    class: {
      muted: !_vm.notification.isUnread
    },
    attrs: {
      "unread": _vm.notification.isUnread
    }
  }, [_vm.iconName ? _c('TalpaIcon', {
    staticClass: "asset-icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  }) : _c('span', {
    staticClass: "asset-icon unknown"
  }, [_c('HelpCircleIcon')], 1), _c('AssetNotificationContentStyled', [_vm.notification.assetNotificationEvent.asset ? _c('router-link', {
    staticClass: "asset-name",
    attrs: {
      "to": _vm.to
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$notification$asse = (_vm$notification$asse2 = _vm.notification.assetNotificationEvent.asset) === null || _vm$notification$asse2 === void 0 ? void 0 : _vm$notification$asse2.name) !== null && _vm$notification$asse !== void 0 ? _vm$notification$asse : _vm.$tc('deleted.assets', 1)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$tc('deleted.assets', 1)) + " ")]), _c('div', {
    staticClass: "count"
  }, [((_vm$notification = _vm.notification) === null || _vm$notification === void 0 ? void 0 : (_vm$notification$asse3 = _vm$notification.assetNotificationEvent) === null || _vm$notification$asse3 === void 0 ? void 0 : _vm$notification$asse3.notificationEventType) === 'ENGINE' ? _c('span', [_vm._v(" " + _vm._s(_vm.$tc('notifications.engineIssue', _vm.notification.assetNotificationEvent.assetEventIds.length, {
    count: _vm.notification.assetNotificationEvent.assetEventIds.length
  })) + " ")]) : _vm._e(), ((_vm$notification2 = _vm.notification) === null || _vm$notification2 === void 0 ? void 0 : (_vm$notification2$ass = _vm$notification2.assetNotificationEvent) === null || _vm$notification2$ass === void 0 ? void 0 : _vm$notification2$ass.notificationEventType) === 'HIGH_NUMBER_OF_PASSES' || ((_vm$notification3 = _vm.notification) === null || _vm$notification3 === void 0 ? void 0 : (_vm$notification3$ass = _vm$notification3.assetNotificationEvent) === null || _vm$notification3$ass === void 0 ? void 0 : _vm$notification3$ass.notificationEventType) === 'OVERLOAD_EVENT' ? _c('span', [_vm._v(_vm._s(_vm.$tc('notifications.productionIssue', _vm.notification.assetNotificationEvent.assetEventIds.length, {
    count: _vm.notification.assetNotificationEvent.assetEventIds.length
  })) + " ")]) : _vm._e(), ((_vm$notification4 = _vm.notification) === null || _vm$notification4 === void 0 ? void 0 : (_vm$notification4$ass = _vm$notification4.assetNotificationEvent) === null || _vm$notification4$ass === void 0 ? void 0 : _vm$notification4$ass.notificationEventType) === 'PRIORITY' ? _c('span', [_vm._v(_vm._s(_vm.$tc('notifications.priorityError', _vm.notification.assetNotificationEvent.assetEventIds.length, {
    count: _vm.notification.assetNotificationEvent.assetEventIds.length
  })) + " ")]) : _vm._e(), ((_vm$notification5 = _vm.notification) === null || _vm$notification5 === void 0 ? void 0 : (_vm$notification5$ass = _vm$notification5.assetNotificationEvent) === null || _vm$notification5$ass === void 0 ? void 0 : _vm$notification5$ass.notificationEventType) === 'BOMAG_ERROR' ? _c('span', [_vm._v(_vm._s(_vm.$tc('notifications.bomagError', _vm.notification.assetNotificationEvent.assetEventIds.length, {
    count: _vm.notification.assetNotificationEvent.assetEventIds.length
  })) + " ")]) : _vm._e(), ((_vm$notification6 = _vm.notification) === null || _vm$notification6 === void 0 ? void 0 : (_vm$notification6$ass = _vm$notification6.assetNotificationEvent) === null || _vm$notification6$ass === void 0 ? void 0 : _vm$notification6$ass.notificationEventType) === 'OVERSPEED_EVENT' ? _c('span', [_vm._v(" " + _vm._s(_vm.overSpeedEventMessage()) + " ")]) : _vm._e()]), _c('footer', [_c('div', {
    staticClass: "ago"
  }, [_vm._v(_vm._s(_vm.ago))])])], 1), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip,
      expression: "tooltip"
    }],
    class: {
      unread: _vm.notification.isUnread
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle-is-unread');
      }
    }
  }, [_c('circleIcon', {
    attrs: {
      "size": "0.5x"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }