<template>
  <RuleCreateStyled>
    <TitleWithBorderSimpleAtom :title="title">
      <template v-slot:right>
        <CloseButtonAtom @close="$emit('cancel')" />
      </template>
    </TitleWithBorderSimpleAtom>
    <div>
      <CustomMultiSelect
        class="multi-selector"
        track-by="id"
        openDirection="bottom"
        :value="selectedKPI"
        :options="assetDimensionsMapped"
        :shouldNotResetSelectedOption="true"
        :disabled="assetDimensions.length === 0"
        :closeOnSelect="true"
        :searchable="true"
        :multiple="false"
        :placeholder="$t('planning.selectAssetDimension')"
        :optionsLimit="6"
        :applyFilter="false"
        :internalSearch="true"
        :customNoOptionsSlot="true"
        :maxHeight="500"
        :loading="$apollo.queries.assetDimensions.loading"
        @select="selectKPI"
      >
        <template v-slot:customLabelIcon>
          <ShowAsLabelAtom :label="`${$tc('assetDimension', 1)}:`" />
        </template>
        <template v-slot:noOptions>
          <strong>{{ $t('messages.noData') }}</strong>
        </template>
      </CustomMultiSelect>
    </div>
    <div class="value row">
      <div>
        <CustomMultiSelect
          class="multi-selector"
          track-by="id"
          openDirection="bottom"
          :value="selectedRuleType"
          :options="ruleTypes"
          :shouldNotResetSelectedOption="true"
          :closeOnSelect="true"
          :searchable="false"
          :multiple="false"
          :placeholder="$t('planning.selectRuleType')"
          :maxHeight="256"
          :optionsLimit="6"
          :applyFilter="false"
          :internalSearch="true"
          :customNoOptionsSlot="true"
          @select="selectedRuleType = $event"
        >
          <template v-slot:customLabelIcon>
            <ShowAsLabelAtom :label="`${$t('planning.ruleType')}:`" />
          </template>
          <template v-slot:noOptions>
            <strong>{{ $t('messages.noData') }}</strong>
          </template>
        </CustomMultiSelect>
      </div>
      <div class="value-input">
        <template v-if="selectedRuleType">
          <MinMaxInputAtom v-if="selectedRuleType.id === 'RANGE'" :min.sync="min" :max.sync="max" :unit="unitUI"/>
          <ThresholdInputAtom v-else :threshold.sync="threshold" :unit="unitUI"/>
        </template>
      </div>
    </div>
    <ActionStyled>
      <ButtonSolid :disabled="canAdd" @click="createRule"> {{ buttonLabel }} </ButtonSolid>
    </ActionStyled>
  </RuleCreateStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import units from '@/utils/units'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import localesMixin from '@/mixins/locales'

import { CustomMultiSelect } from '@common/components'

import MinMaxInputAtom from '../Atoms/MinMaxInputAtom'
import ThresholdInputAtom from '../Atoms/ThresholdInputAtom'
import ASSET_DIMENSION_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'
import ShowAsLabelAtom from '../../Atomic/Atoms/ShowAsLabelAtom'
import TitleWithBorderSimpleAtom from '../../Atomic/Atoms/TitleWithBorderSimpleAtom'
import CloseButtonAtom from '../../Atomic/Atoms/CloseButtonAtom'
import ButtonSolid from '../../Atomic/Atoms/ButtonSolid'


const RuleCreateStyled = styled('div')`
  display: grid;
  font-size: 14px;
  margin: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: ${({ theme }) =>
    theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  position: absolute;
  top: 0px;
  left: -24px;
  width: 100%;
  z-index: 1000;
  .row {
    align-items: center;
  }
  .value {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-template-rows: 1fr;
    .value-input {
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
  .multi-selector {
    margin: 1rem;
    background-color: ${({ theme }) => theme.colors.panelBG};
    border-radius: 0.5rem;
  }
  .kpi-selector {
    width: 100%;
  }
`
const ActionStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
  border-top: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  button {
    font-size: 14px;
  }
`

export default {
  mixins: [validationMixin, localesMixin],
  components: {
    RuleCreateStyled,
    TitleWithBorderSimpleAtom,
    CustomMultiSelect,
    MinMaxInputAtom,
    ThresholdInputAtom,
    ShowAsLabelAtom,
    CloseButtonAtom,
    ActionStyled,
    ButtonSolid,
  },
  props: {
    rule: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      selectedKPIId: null,
      assetDimensions: [],
      selectedRuleType: null,
      threshold: 0,
      min: 0,
      max: 1,
      performanceGoalRule: null,
    }
  },
  watch: {
    rule: {
      handler(newRule) {
        if (newRule) {
          this.selectedKPIId = newRule.assetDimensionId
          this.selectedRuleType = this.ruleTypes.find(r => r.id === newRule.thresholdTypeEnum)
          this.threshold = newRule.threshold
          this.min = newRule.min
          this.max = newRule.max
          this.name = newRule.name
        }
      },
      deep: true,
      immediate: true,
    },
    mode: {
      handler(mode) {
        if (mode === 'create') {
          this.selectedKPIId = null
          this.selectedRuleType = null
          this.threshold = 0
          this.min = 0
          this.max = 1
        }
      },
      immediate: true,
    },
  },
  validations() {
    return {
      selectedKPIId: {
        required,
      },
      selectedRuleType: {
        required,
      },
      min: {
        required: requiredIf(selectedRuleType => selectedRuleType?.id === 'RANGE'),
      },
      max: {
        required: requiredIf(selectedRuleType => selectedRuleType?.id === 'RANGE'),
        validateMax: this.validateMax,
      },
      threshold: {
        required: requiredIf(selectedRuleType => selectedRuleType?.id !== 'RANGE'),
        validateThreshold: this.validateThreshold,
      },
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    title() {
      return this.mode === 'create' ? this.$t('planning.createRule') : this.$t('planning.updateRule')
    },
    buttonLabel() {
      return this.mode === 'create' ? this.$t('actions.add') : this.$t('actions.update')
    },
    canAdd() {
      return this.$v.$invalid
    },
    ruleTypes() {
      return [
        { id: 'RANGE', label: this.$t('planning.range') },
        { id: 'ABOVE', label: this.$t('planning.above') },
        { id: 'BELOW', label: this.$t('planning.below') },
      ]
    },
    assetDimensionsMapped() {
      return this.assetDimensions.map(a => {
        return {
          ...a,
          label: a.nameTranslations
            ? getAssetDimensionNameByLocale(a.nameTranslations, this.locale)
            : a.name,
        }
      })
    },
    selectedKPI() {
      return this.assetDimensionsMapped.find(a => a.id === this.selectedKPIId)
    },
    unitUI() {
      if(!this.selectedKPI){
        return null
      }
      const unitUIMetric = this.selectedKPI.physicalUnitSI === '%' ? '%' : this.selectedKPI.physicalUnitUIMetric
      const unitUIImperial = this.selectedKPI.physicalUnitSI === '%' ? '%' : this.selectedKPI.physicalUnitUIImperial
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return unitUI
    },
    unitSI() {
      return this.selectedKPI?.physicalUnitSI ?? null
    },
  },
  methods: {
    validateMax(value){
      if(this.selectedRuleType?.id === 'RANGE'){
        return value > this.min
      } else {
        return true
      }
    },
    validateThreshold(value){
      if(this.selectedRuleType?.id !== 'RANGE'){
        return value > 0
      } else {
        return true
      }
    },
    selectKPI({ id }) {
      this.selectedKPIId = id
    },
    createRule() {
      this.performanceGoalRule = {
        assetDimensionId: this.selectedKPIId,
        assetDimension: {
          name: this.selectedKPI.name,
          physicalUnitSI: this.selectedKPI.physicalUnitSI,
          physicalUnitUIMetric: this.selectedKPI.physicalUnitUIMetric,
          physicalUnitUIImperial: this.selectedKPI.physicalUnitUIImperial,
          translatedName:  this.selectedKPI?.nameTranslations
            ? getAssetDimensionNameByLocale(this.selectedKPI?.nameTranslations, this.locale)
            : this.selectedKPI?.name,
        },
        thresholdTypeEnum: this.selectedRuleType.id,
        threshold: this.selectedRuleType.id === 'RANGE' ? null : units(this.threshold, this.unitSI, this.unitUI, 2, false, false, true, this.thousandsSeperator, this.decimalSeperator, false),
        min: this.selectedRuleType.id === 'RANGE' ? units(this.min, this.unitSI, this.unitUI, 2, false, false, true, this.thousandsSeperator, this.decimalSeperator, false) : null,
        max: this.selectedRuleType.id === 'RANGE' ? units(this.max, this.unitSI, this.unitUI, 2, false, false, true, this.thousandsSeperator, this.decimalSeperator, false) : null,
      }
      this.$emit('ruleCreated', this.performanceGoalRule)
    },
  },
  apollo: {
    assetDimensions: {
      query: ASSET_DIMENSION_QUERY,
      variables() {
        return {
          where: {},
        }
      },
    },
  },
}
</script>
