var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('LoadingDotsStyled', {
    attrs: {
      "fill": _vm.fill
    }
  }, [_c('svg', {
    attrs: {
      "id": "dots",
      "viewBox": "0 0 132 58"
    }
  }, [_c('g', {
    attrs: {
      "id": "Page-1",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "dots"
    }
  }, [_c('circle', {
    attrs: {
      "id": "dot1",
      "cx": "25",
      "cy": "30",
      "r": "13"
    }
  }), _c('circle', {
    attrs: {
      "id": "dot2",
      "cx": "65",
      "cy": "30",
      "r": "13"
    }
  }), _c('circle', {
    attrs: {
      "id": "dot3",
      "cx": "105",
      "cy": "30",
      "r": "13"
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }