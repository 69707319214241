var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AdvancedSearchMolecule', [_c('header', [_c('SearchInputAtom', {
    attrs: {
      "isSearching": _vm.isSearching
    },
    on: {
      "blur": _vm.onBlur,
      "update": _vm.onUpdate,
      "focus": _vm.onFocus
    }
  })], 1), _c('main', [_vm.error ? _c('SearchErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  }) : _vm.showInstructions ? _c('SearchInstructionsAtom', {
    attrs: {
      "searchableEntities": _vm.searchableEntities
    }
  }) : _vm.noResults ? _c('NoSearchResultsAtom', {
    attrs: {
      "searchParams": _vm.searchParams
    }
  }) : _vm._l(_vm.searchResultGroups, function (group) {
    return _c('SearchResultGroupMolecule', {
      key: group.id,
      attrs: {
        "group": group
      },
      on: {
        "goto-item": function gotoItem($event) {
          return _vm.$emit('goto-item', $event);
        }
      }
    });
  })], 2), _c('footer', [_c('HotkeyExplanationAtom', {
    attrs: {
      "explanation": _vm.$t('advancedSearch.hotkeyExplanations.enter')
    }
  }, [_c('template', {
    slot: "hotkey"
  }, [_c('CornerDownLeftIcon')], 1)], 2), _c('HotkeyExplanationAtom', {
    attrs: {
      "explanation": _vm.$t('advancedSearch.hotkeyExplanations.arrows')
    }
  }, [_c('template', {
    slot: "hotkey"
  }, [_c('ArrowDownIcon'), _c('ArrowUpIcon')], 1)], 2), _c('HotkeyExplanationAtom', {
    attrs: {
      "explanation": _vm.$t('advancedSearch.hotkeyExplanations.esc')
    }
  }, [_c('template', {
    slot: "hotkey"
  }, [_c('strong', [_vm._v("esc")])])], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }