var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ThresholdInputStyled', [_c('div', {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.$t('planning.threshold')))]), _c('div', {
    staticClass: "input"
  }, [_c('InputStyled', {
    attrs: {
      "type": "number",
      "min": "0",
      "value": _vm.threshold
    },
    on: {
      "input": function input($event) {
        _vm.$emit('update:threshold', Number($event.target.value));
      }
    }
  }), _vm.unit ? _c('div', [_vm._v(_vm._s(_vm.unit))]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }