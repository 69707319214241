var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BasicStyled', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.away,
      expression: "away"
    }],
    staticClass: "basic-select",
    class: {
      disabled: _vm.isDisabled,
      small: _vm.small
    }
  }, [_c('div', {
    staticClass: "current",
    on: {
      "click": _vm.toggle
    }
  }, [_vm.target ? _c('div', {
    staticClass: "target"
  }, [_vm._v(_vm._s(_vm.target) + ":")]) : _vm._e(), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))])]), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isOpen ? _c('OptionsStyled', {
    class: {
      open: _vm.isOpen
    }
  }, _vm._l(_vm.optionsMapped, function (option) {
    return _c('OptionStyled', {
      key: option.id,
      staticClass: "basic-select-option",
      class: {
        selected: option.isSelected
      },
      on: {
        "click": function click($event) {
          return _vm.selectOption(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  }), 1) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('ChevronDownIcon', {
    class: {
      icon: _vm.isOpen
    },
    on: {
      "click": _vm.toggle
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }