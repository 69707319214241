<template>
  <InfoBannerStyled :canDismiss="canDismiss">
    <div>
      <AlertCircleIcon /> {{ message }}.
      <span v-if="actionText" class="link" @click="navigateTo">{{ actionText }}.</span>
    </div>
    <XIcon v-if="canDismiss" class="closeIcon" @click="closeBanner" />
  </InfoBannerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { AlertCircleIcon, XIcon } from 'vue-feather-icons'

const InfoBannerStyled = styled('div')`
  grid-area: topBanner;
  align-items: center;
  ${({ canDismiss }) => canDismiss && ' display: grid;'};
  grid-template-columns: 1fr 50px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.solidBG};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.atomic.textMain};
  @media (max-width: 456px) {
    font-size: 11px;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    .feather {
      margin: 0 0.5rem;
    }
  }
  .link {
    margin-left: 0.25rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: ${p => p.theme.colors.primaryActive};
      opacity: 1;
    }
  }
  .closeIcon {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-self: center;
    &:hover {
      color: ${p => p.theme.colors.primaryActive};
      opacity: 1;
    }
  }
`

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    actionText: {
      type: String,
      required: false,
    },
    route: {
      type: String,
      required: false,
    },
    canDismiss: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    InfoBannerStyled,
    AlertCircleIcon,
    XIcon,
  },
  methods: {
    navigateTo() {
      if (this.actionText && this.route) {
        this.$emit('hideBanner', true)
        this.$router.push({
          name: this.route,
        })
      }
    },
    closeBanner() {
      this.$emit('hideBanner', false)
    },
  },
}
</script>
