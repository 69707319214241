var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MembershipManangerStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "memberships"
  }, _vm._l(_vm.membershipsMapped, function (membership) {
    return _c('div', {
      key: membership.id,
      staticClass: "membership"
    }, [_c('div', {
      staticClass: "label"
    }, [_c('EntityLinkAtom', {
      attrs: {
        "type": membership.entityType,
        "id": membership.entityId,
        "label": membership.label
      }
    })], 1), _c('div', {
      staticClass: "role"
    }, [_c('BasicSelect', {
      attrs: {
        "target": _vm.$tc('role', 1),
        "options": _vm.roles,
        "selected": membership.selectedRole
      },
      on: {
        "change": function change($event) {
          return _vm.setRole($event, membership);
        }
      }
    })], 1), _c('button', {
      staticClass: "delete",
      on: {
        "click": function click($event) {
          return _vm.deleteMemberConfirmation(membership);
        }
      }
    }, [_c('XIcon')], 1)]);
  }), 0), _c('div', {
    staticClass: "add-icon"
  }, [_c('PlusCircleIcon')], 1), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "track-by": "id",
      "label": "label",
      "placeholder": "Type to search",
      "options": _vm.addables,
      "searchable": true,
      "multiple": true,
      "loading": _vm.addablesLoading,
      "internal-search": false,
      "clear-on-select": false,
      "close-on-select": false,
      "options-limit": 300,
      "limit": 3,
      "max-height": 600,
      "show-no-results": false,
      "hide-selected": true
    },
    on: {
      "search-change": _vm.findAddables,
      "select": function select($event) {
        return _vm.add($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }