import cloneDeep from 'lodash/cloneDeep'
import chroma from 'chroma-js'

import BaseTheme from './base-theme'

const DarkTheme = cloneDeep(BaseTheme)

const primaryGradientStart = chroma(BaseTheme.colors.primaryDarker).alpha(0.9).css()
const primaryGradientEnd = chroma(BaseTheme.colors.primaryDarker).darken(2).alpha(0.9).css()
const primaryGradientDarkerEnd = chroma(BaseTheme.colors.primaryDarker).darken(4).alpha(0.9).css()
const primaryGradient = `linear-gradient(45deg, ${primaryGradientStart}, ${primaryGradientEnd})`
const primaryGradientDarker = `linear-gradient(45deg, ${primaryGradientStart}, ${primaryGradientDarkerEnd})`

const darkColors = {
  ...BaseTheme.colors,
  secondary: BaseTheme.colors.azul,
  secondaryLighter: BaseTheme.colors.pastelBlue,
  secondaryDarker: '#0b3994',
  primary: BaseTheme.colors.orange,
  primaryLighter: chroma(BaseTheme.colors.orange).brighten(1).hex(),
  primaryDarker: chroma(BaseTheme.colors.orange).darken(1).hex(),
  primaryContrast: BaseTheme.colors.white,
  colorSets: {
    primary: {
      background: primaryGradient,
      color: BaseTheme.colors.white,
      interactive: BaseTheme.colors.secondary,
      interactiveActive: BaseTheme.colors.white,
      input: {
        color: BaseTheme.colors.white,
        background: chroma(BaseTheme.colors.transparentBlack).alpha(0.4).css(),
      },
      border: BaseTheme.colors.transparentWhite,
    },
    primaryDarker: {
      background: primaryGradientDarker,
      color: BaseTheme.colors.white,
      interactive: BaseTheme.colors.secondary,
      interactiveActive: BaseTheme.colors.white,
    },
  },
}

DarkTheme.isDark = true
DarkTheme.colors = darkColors
DarkTheme.app.color = darkColors.white
DarkTheme.app.background = darkColors.darkestGrey
DarkTheme.header.background = darkColors.talpaGrey
DarkTheme.header.brandIconRingFill = '#ccc'
DarkTheme.footer.background = darkColors.mediumGrey
// DarkTheme.widget.background = chroma(talpaGrey).darken(0.2).css()
DarkTheme.widget.background = darkColors.talpaGrey
DarkTheme.widget.backgroundContrast = darkColors.white
DarkTheme.widget.backgroundAlt = darkColors.darkGrey
DarkTheme.widget.backgroundAltContrast = darkColors.white
DarkTheme.widget.backgroundSlightContrast = chroma(darkColors.talpaGrey).brighten(0.75).css()
DarkTheme.widget.footer.background = chroma(darkColors.talpaGrey).brighten(0.5).css()
DarkTheme.aside.background = chroma(darkColors.talpaGrey)

export default DarkTheme
