<template>
  <TopBarWrapper>
    <Logo />
    <HeaderStyled class="item header">
      <router-view name="header" />
      <ErrorInspector v-if="hasErrorInspectorPermission" />
    </HeaderStyled>
    <Settings
      class="item settings"
      :isActive="overlayType === 'SettingsOverlay'"
      @activateOverlay="overlay => $emit('activateOverlay', overlay)"
    />
    <Notifications class="item notifications" />
    <Profile class="item profile" />
  </TopBarWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { useStore } from '@/stores/userSettings'
import permissionsMixin from '@/mixins/permissions'

import Settings from './Settings'
import Notifications from './Notifications.vue'
import Profile from './Profile'
import Logo from './Logo'
import ErrorInspector from '../../Misc/ErrorInspector'

const TopBarWrapper = styled('div')`
  grid-area: top-bar;
  display: flex;
  background: ${p => p.theme.colors.mainBG};

  .item {
    width: 50px;
    margin-left: 1px;

    &:first-child {
      margin-left: 0;
    }
  }

  .item.header {
    width: 50px;
    flex-grow: 1;
  }

  .item.profile {
    width: 50px;
  }

  @media (min-width: 768px) {
    .item.profile {
      width: 190px;
    }
  }
`

const HeaderStyled = styled('header')`
  grid-area: header;
  position: relative;
`

export default {
  mixins: [permissionsMixin],
  props: {
    overlayType: {
      type: String,
    },
  },
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    TopBarWrapper,
    Settings,
    Notifications,
    Profile,
    HeaderStyled,
    Logo,
    ErrorInspector,
  },
  computed: {
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
  },
}
</script>
