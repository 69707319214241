var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WheelAssetManagerStyled', [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.title))])]), _c('FormWrapperStyled', [_vm.isadding ? _c('AssetWheelForm', [_c('div', [_c('div', {
    staticClass: "input-fields"
  }, [_c('InputField', {
    staticClass: "input",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.axle.$model,
            expression: "$v.axle.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "number",
            "placeholder": "Axle"
          },
          domProps: {
            "value": _vm.$v.axle.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.axle, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Axle")])];
      },
      proxy: true
    }, _vm.$v.axle.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.axle.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(_vm.$tc('messages.required')))]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "input",
    attrs: {
      "labelWidth": 8
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.axlePosition.$model,
            expression: "$v.axlePosition.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "number",
            "placeholder": "Axle Position"
          },
          domProps: {
            "value": _vm.$v.axlePosition.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.axlePosition, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Axle Position")])];
      },
      proxy: true
    }, _vm.$v.axlePosition.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.axlePosition.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(_vm.$tc('messages.required')))]) : _vm._e(), !_vm.$v.axlePosition.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v(" " + _vm._s(_vm.$t('messages.minValue', {
          value: _vm.$v.axlePosition.$params.minLength.min
        })) + " ")]) : _vm._e(), !_vm.$v.axlePosition.maxLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v(" " + _vm._s(_vm.$t('messages.maxValue', {
          value: _vm.$v.axlePosition.$params.maxLength.max
        })) + " ")]) : _vm._e(), !_vm.$v.axlePosition.isUnique ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(_vm.$tc('messages.notUnique')))]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('div', {
    staticClass: "input"
  }, [_c('BasicSelect', {
    attrs: {
      "target": 'Tyre',
      "options": _vm.usableTyres,
      "selected": _vm.tyreSelectedWhileCreation
    },
    on: {
      "change": function change($event) {
        _vm.tyreSelectedWhileCreation = $event;
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "signals"
  }, [_c('h4', [_vm._v("Select ULAN Signals")]), _c('div', [_c('span', {
    staticClass: "title"
  }, [_vm._v(" Temperature ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.signalsNames,
      "value": _vm.ulanTemperatureSignalName,
      "loading": _vm.$apollo.queries.signalsByAsset.loading
    },
    on: {
      "select": function select($event) {
        _vm.ulanTemperatureSignalName = $event;
      }
    }
  })], 1), _c('div', [_c('span', {
    staticClass: "title"
  }, [_vm._v(" Pressure ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.signalsNames,
      "value": _vm.ulanPressureSignalName,
      "loading": _vm.$apollo.queries.signalsByAsset.loading
    },
    on: {
      "select": function select($event) {
        _vm.ulanPressureSignalName = $event;
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "add-icon",
    on: {
      "click": _vm.createWheelPosition
    }
  }, [_c('div', [_vm._v("Add")]), _c('PlusCircleIcon')], 1)]) : _c('div', {
    staticClass: "add-button add-icon",
    on: {
      "click": function click($event) {
        _vm.isadding = true;
      }
    }
  }, [_vm._v("Add a new wheel Position")]), _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _vm.assetWheelPositions.length < 1 ? _c('div', {
    staticClass: "alert"
  }, [_vm._v("No wheel Positions, use above button to add one.")]) : _c('table', [_c('tr', [_c('th', [_vm._v("Axle")]), _c('th', [_vm._v("Axle Position")]), _c('th', [_vm._v("Wheel Assigned")]), _c('th', [_vm._v("Temperature Signal")]), _c('th', [_vm._v("Pressure Signal")]), _c('th', [_vm._v("Tyre")]), _c('th', [_vm._v("Mounted At")]), _c('th', [_vm._v("Unmounted At")])]), _vm._l(_vm.assetWheelPositions, function (wheelPosition) {
    return _c('WheelPositionStyled', {
      key: wheelPosition.id,
      class: {
        disabled: wheelPosition.unmountedAt
      }
    }, [_c('td', [_vm._v(_vm._s(wheelPosition.axle))]), _c('td', [_vm._v(_vm._s(wheelPosition.axlePosition))]), _c('td', [wheelPosition.wheel ? _c('span', [_vm._v(_vm._s(wheelPosition.wheel.id))]) : _c('span', [_vm._v("false")])]), _c('td', [_c('Multiselect', {
      attrs: {
        "options": _vm.signalsNames,
        "value": wheelPosition.ulanTemperatureSignalName,
        "disabled": !!wheelPosition.unmountedAt
      },
      on: {
        "select": function select($event) {
          return _vm.updateSignalNames($event, 'ulanTemperatureSignalName', wheelPosition);
        }
      }
    })], 1), _c('td', [_c('Multiselect', {
      attrs: {
        "options": _vm.signalsNames,
        "value": wheelPosition.ulanPressureSignalName,
        "disabled": !!wheelPosition.unmountedAt
      },
      on: {
        "select": function select($event) {
          return _vm.updateSignalNames($event, 'ulanPressureSignalName', wheelPosition);
        }
      }
    })], 1), _c('td', [_vm.getFittedTyre(wheelPosition) ? _c('div', {
      staticClass: "tyre-cell"
    }, [_vm._v(" " + _vm._s(_vm.getFittedTyre(wheelPosition).tyre.model) + "-" + _vm._s(_vm.getFittedTyre(wheelPosition).tyre.serialNumber) + " "), _c('XCircleIcon', {
      staticClass: "add-icon remove-tyre",
      on: {
        "click": function click($event) {
          _vm.confirmDelete(_vm.getFittedTyre(wheelPosition), 'tyreUnmount', _vm.unMountTyre);
        }
      }
    })], 1) : wheelPosition.unmountedAt !== null ? _c('div', [_vm._v("Tyre Unmounted")]) : _c('div', [_c('BasicSelect', {
      attrs: {
        "target": '',
        "options": _vm.usableTyres
      },
      on: {
        "change": function change($event) {
          return _vm.assignTyre($event, wheelPosition);
        }
      }
    })], 1)]), _c('td', [_vm._v(_vm._s(_vm.getTime(wheelPosition.mountedAt)))]), _c('td', [_vm.getTime(wheelPosition.unmountedAt) === '-' ? _c('div', {
      staticClass: "add-button add-icon unmount-button",
      on: {
        "click": function click($event) {
          return _vm.confirmDelete(wheelPosition, 'wheelPosition', _vm.unMountWheelPosition);
        }
      }
    }, [_vm._v(" Unmount ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.getTime(wheelPosition.unmountedAt)) + " ")])])]);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }