<template>
  <BrandStyled :isMenuExpanded="isMenuExpanded">
    <TalpaCockpitIcon class="brand brand-desktop" width="80" height="35" />
    <TalpaCockpitIconMobile class="brand brand-mobile" width="35" height="35" />
  </BrandStyled>
</template>

<script>
import TalpaCockpitIcon from '@/assets/talpasolutions_white.svg'
import TalpaCockpitIconMobile from '@/assets/talpasolutions_icon_white_on_darkgrey.svg'
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import { useStore } from '@/stores/userSettings'

const BrandStyled = styled('div')`
  ${flexCenter}
  grid-area: brand;
  background: ${props => props.theme.colors.solidBG};

  .brand-desktop {
    display: inline-block;
  }
  .brand-mobile {
    display: none;
  }

  &.scale {
    @media (min-width: 768px) {
      svg {
        ${props => (props.isMenuExpanded ? 'transform: scale(1.4);' : 'transform: none;')};
      }
    }
  }

  &.adjust {
    .brand-mobile {
      display: inline-block;
      circle {
        display: none;
      }
    }
    .brand-desktop {
      display: none;
    }
    @media (min-width: 768px) {
      .brand-desktop {
        ${props => (props.isMenuExpanded ? 'display: inline-block;' : 'display: none;')};
      }
      .brand-mobile {
        ${props => (props.isMenuExpanded ? 'display: none;' : 'display: inline-block;')};
      }
    }
  }

  .brand {
    path {
      fill: ${({ theme }) => (theme.isDark ? theme.colors.white : theme.colors.primary)};
      transition: fill 3s linear;
    }
  }
`

export default {
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    BrandStyled,
    TalpaCockpitIcon,
    TalpaCockpitIconMobile,
  },
  computed: {
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
  },
}
</script>
