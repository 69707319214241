var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeaderStyled', [_c('div', [_vm._v(_vm._s(_vm.$t("navigation.cockpit.".concat(_vm.mainRouteName))))]), _vm.showBreadcrumb ? [_c('ChevronRightIcon', {
    staticClass: "icon"
  }), _c('div', [_vm.$apollo.loading ? _c('LoadingDots') : _vm.asset ? _c('span', [_vm._v(_vm._s(_vm.asset.name))]) : _vm._e()], 1), _c('ChevronRightIcon', {
    staticClass: "icon"
  }), _c('div', [_vm._v(_vm._s(_vm.$t("navigation.subRoutes.".concat(_vm.routeName))))])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }