<template>
  <ReleaseNotesStyled v-if="releaseNotesReadPermission" :isMenuExpanded="isMenuExpanded" id="release-notes">
    <ProductNameStyled :isMenuExpanded="isMenuExpanded">{{ $t('releaseNotes.productName') }}</ProductNameStyled>
    <div class="popover-wrapper">
      <VersionStyled :isMenuExpanded="isMenuExpanded">{{ $t('releaseNotes.version') }}</VersionStyled>
      <VPopover
        :placement="'top-start'"
        :open.sync="isActive"
        :popperOptions="popperOptions"
        :popoverClass="'userGuidePopOver'"
        :container="'#release-notes'"
      >
        <span class="popover-trigger">
          - {{ $t('releaseNotes.learn_more') }}
          <HelpCircleIcon size="1.2x" />
        </span>
        <ReleaseNotesMobileIcon :isMenuExpanded="isMenuExpanded" />
        <circle-icon size="0.5x" class="mobileMenuIcon" />
        <template v-slot:popover>
          <PopoverInnerWrapperStyled>
            <div class="title">
              <StarIcon />
              <h5>{{ $t('releaseNotes.title') }}</h5>
            </div>
            <div class="content">
              {{ $t('releaseNotes.content') }}
            </div>
            <ActionsStyled>
              <ButtonBorderedStyled closePopover @click="close">
                {{ $t('actions.ok') }}
              </ButtonBorderedStyled>
              <AButtonSolid v-if="pdfLink" :href="pdfLink" target="_blank" @click="close">
                {{ $t('releaseNotes.learn_more') }}
              </AButtonSolid>
            </ActionsStyled>
          </PopoverInnerWrapperStyled>
        </template>
      </VPopover>
    </div>
  </ReleaseNotesStyled>
</template>

<script>
import get from 'lodash/get'
import { styled } from '@egoist/vue-emotion'
import Vue from 'vue'
import permissionsMixin from '@/mixins/permissions'
import { VPopover, VClosePopover } from 'v-tooltip'

import { flexCenter, flexStartCenter, buttonReset } from '@styles/mixins'
import { HelpCircleIcon, CircleIcon } from 'vue-feather-icons'
import { ButtonBordered } from '@styles/buttons'
import chroma from 'chroma-js'
import StarIconSrc from '@/assets/icons/starIcon.svg'
import { useStore } from '@/stores/userSettings'

const ReleaseNotesStyled = styled('div')`
  grid-gap: 0.1rem;
  flex-direction: column;
  position: absolute;
  bottom: 7rem;
  align-self: center;
  font-size: 0.7rem;
  color: ${p => p.theme.colors.navFontNormal};
  .popover-trigger {
    display: none;
  }
  @media (min-width: 768px) {
    .popover-trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      margin-left: 0.1rem;
      ${props => (props.isMenuExpanded ? `display: flex;` : 'display: none;')};

      &:hover {
        color: ${p => p.theme.colors.primaryLighter};
      }
      & > svg {
        margin-left: 0.2rem;
      }
    }
    > .userGuidePopOver {
      ${props => (props.isMenuExpanded ? `margin-left: 0;` : 'margin-left: 2rem!important;')};
    }
  }

  .popover-wrapper {
    ${flexCenter}
  }
  .mobileMenuIcon {
    fill: ${p => p.theme.colors.red};
    color: ${p => p.theme.colors.red};
    position: absolute;
    left: 12px;
    top: -4px;
    @media (min-width: 768px) {
      ${props => (props.isMenuExpanded ? 'display: none;' : `display: block`)};
    }
  }
`
const PopoverInnerWrapperStyled = styled('div')`
  grid-gap: 0.5rem;
  width: 300px;
  justify-content: left;
  box-sizing: border-box;
  padding: 1rem;
  color: ${p => p.theme.colors.navFontNormal};
  background-color: ${p => chroma(p.theme.colors.solidBG).alpha(1).css()};
  border-radius: 10px;
  .title {
    ${flexStartCenter}
    justify-content: left;
    align-items: center;
    padding: 0.6rem 0.6rem 0.4rem 0.6rem;
    > h5 {
      margin: 0 0 0 0.3rem;
      font-weight: normal;
      font-size: 0.83rem;
    }
  }
  .content {
    ${flexStartCenter}
    padding:  0.4rem 0.8rem 0.4rem 0.8rem;
    font-size: 0.8rem;
    font-weight: lighter;
  }
`
const ProductNameStyled = styled('div')`
  display: none;
  @media (min-width: 768px) {
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0.1rem;
    ${props => (props.isMenuExpanded ? ` display: block;` : 'display: none;')};
  }
`
const VersionStyled = styled('span')`
  display: none;
  @media (min-width: 768px) {
    ${props => (props.isMenuExpanded ? 'display: inline;' : 'display: none;')};
  }
`

const AButtonSolid = styled('a')`
  ${buttonReset}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)}!important;
  background: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 0.8rem;
  &:hover {
    color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)};
    background: ${props => props.theme.colors.primaryActive};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`
const ButtonBorderedStyled = styled(ButtonBordered)`
  padding: 0.5rem 1.5rem;
  color: ${props => props.theme.colors.white};
  background: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  border-color: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
`

const StarIcon = styled(StarIconSrc)`
  & > path:nth-of-type(1) {
    fill: ${({ theme }) => theme.colors.primary};
  }

  & > path:nth-of-type(2) {
    fill: ${({ theme }) => (theme.isDark ? theme.colors.talpaGrey : theme.colors.gainsBoro)};
  }

  & > path:nth-of-type(3) {
    fill: ${({ theme }) => theme.colors.primary};
  }

  width: 15px;
  height: 15px;
`

const ReleaseNotesMobileIcon = styled(StarIconSrc)`
  fill: ${({ theme }) => theme.colors.primary};

  width: 18px;
  height: 18px;
  cursor: pointer;
  @media (min-width: 768px) {
    ${props =>
      props.isMenuExpanded
        ? 'display:none;'
        : `width: 18px;
  height: 18px;`};
    &:hover {
      fill: ${p => p.theme.colors.primaryLighter};
    }
  }
`
const ActionsStyled = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 0.2rem;
`
export default {
  mixins: [permissionsMixin],
  inject: ['theme', 'uiSettings'],
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    ReleaseNotesStyled,
    HelpCircleIcon,
    CircleIcon,
    PopoverInnerWrapperStyled,
    VPopover,
    ButtonBorderedStyled,
    ActionsStyled,
    AButtonSolid,
    StarIcon,
    ProductNameStyled,
    VersionStyled,
    ReleaseNotesMobileIcon,
  },
  directives: {
    closePopover: VClosePopover,
  },
  data() {
    return {
      isActive: false,
      pdfBaseUrl: '/PDFDocuments',
      pdfDocumentName: 'ReleaseNotes.pdf',
    }
  },
  mounted() {
    this.isActive = Vue.localStorage.get('viewReleaseNotes')
  },
  computed: {
    pdfLink() {
      const language = get(this.uiSettings, 'language', '').toLowerCase()
      if (!language) {
        return null
      }
      return `${this.pdfBaseUrl}/de/${this.pdfDocumentName}`
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
  },
  methods: {
    close() {
      Vue.localStorage.set('viewReleaseNotes', false)
      this.isActive = false
    },
  },
}
</script>
