import { styled } from '@egoist/vue-emotion'
import { buttonReset, flexCenter } from '@styles/mixins'

export default styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  padding: .5rem 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
    border: 1px solid ${props => props.theme.colors.primaryActive};
  }
`
