import base from './base'
import { styled } from '@egoist/vue-emotion'

export default styled(base)`
  height: ${props => props.theme.selected.button.height}px;
  padding: 0.25rem;
  margin: 0.25rem;
  color: ${props => (props.isActive ? props.theme.selected.colors.secondary : props.theme.selected.colors.primary)};
  &:hover,
  &:focus {
    color: ${props => props.theme.selected.colors.secondary};
  }

  &:disabled {
    color: ${props => props.theme.selected.colors.grey};
  }
`
