var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('AssetLoggerManagerStyled', [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "type"
  }, [_c('span', {
    staticClass: "type-label"
  }, [_vm._v(" Asset ")]), _c('Multiselect', {
    attrs: {
      "loading": _vm.$apollo.queries.sourceAssets.loading,
      "options": _vm.sourceAssets,
      "value": _vm.selectedAsset,
      "open-direction": "top",
      "trackBy": "id",
      "label": "name",
      "placeholder": 'Type to search',
      "disabled": _vm.mode === 'asset' || !!_vm.hasLoggerAssigned
    },
    on: {
      "select": _vm.setSelectedAsset
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('span', {
    staticClass: "type-label"
  }, [_vm._v(" Logger ")]), _c('Multiselect', {
    attrs: {
      "loading": _vm.$apollo.queries.loggers.loading,
      "options": _vm.loggersMapped,
      "value": _vm.selectedLogger,
      "open-direction": "top",
      "trackBy": "id",
      "label": "name",
      "placeholder": 'Type to search',
      "disabled": _vm.mode === 'logger' || !!_vm.hasLoggerAssigned
    },
    on: {
      "select": _vm.setSelectedLogger
    }
  })], 1), _c('InputField', {
    staticClass: "start",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.fromTime.$model,
            expression: "$v.fromTime.$model",
            modifiers: {
              "trim": true
            }
          }],
          staticClass: "date-picker",
          attrs: {
            "type": "datetime-local",
            "placeholder": "eg. 2021-06-21, 00:00"
          },
          domProps: {
            "value": _vm.$v.fromTime.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.fromTime, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" start * ")];
      },
      proxy: true
    }])
  }), _c('InputField', {
    staticClass: "end",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.$v.tillTime.$model,
            expression: "$v.tillTime.$model"
          }],
          staticClass: "date-picker",
          attrs: {
            "type": "datetime-local",
            "placeholder": "eg. 2021-06-21, 00:00"
          },
          domProps: {
            "value": _vm.$v.tillTime.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.tillTime, "$model", $event.target.value);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" end ")];
      },
      proxy: true
    }, _vm.$v.tillTime.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.tillTime.isGreater ? _c('div', {
          staticClass: "error"
        }, [_vm._v("End Date should be higher than Start Date")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('InputField', {
    staticClass: "height",
    attrs: {
      "labelWidth": 8
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.arpHeight,
            expression: "arpHeight",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "number",
            "placeholder": "height"
          },
          domProps: {
            "value": _vm.arpHeight
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.arpHeight = $event.target.value.trim();
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" ARP height (cm)")];
      },
      proxy: true
    }])
  }), _c('InputField', {
    staticClass: "comment",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.comment,
            expression: "comment",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "notes"
          },
          domProps: {
            "value": _vm.comment
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.comment = $event.target.value.trim();
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Notes ")];
      },
      proxy: true
    }])
  }), !_vm.hasLoggerAssigned ? _c('ButtonSolid', {
    attrs: {
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.createLoggerRelation
    }
  }, [!_vm.isConfirming ? [_vm._v(" Add ")] : _vm._e(), _c('PlusCircleIcon', {
    staticClass: "add-icon"
  })], 2) : _vm._e(), !!_vm.hasLoggerAssigned ? _c('ButtonSolid', {
    attrs: {
      "disabled": _vm.isDisabled || !_vm.canUpdate
    },
    on: {
      "click": _vm.updateLoggerRelation
    }
  }, [!_vm.isConfirming ? [_vm._v(" Update ")] : _vm._e()], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }