import { FlashMessages } from '@common/singletons'

const FlashMessagesMixin = {
  created() {
    FlashMessages.$on('error', this.handleFlashError)
    FlashMessages.$on('warning', this.handleFlashWarning)
    FlashMessages.$on('info', this.handleFlashInfo)
    FlashMessages.$on('success', this.handleFlashSuccess)
  },
  methods: {
    handleFlashError(err, options) {
      this.flashError(err.message, options)
    },
    handleFlashWarning(message, options) {
      this.flashWarning(message, options)
    },
    handleFlashInfo(message, options) {
      this.flashInfo(message, options)
    },
    handleFlashSuccess(message, options) {
      this.flashSuccess(message, options)
    },
  },
}

export default FlashMessagesMixin
