import chroma from 'chroma-js'
import BaseColors from './base-colors'

const colors = {}

colors.talpaBlue = '#1c1cb2'
colors.liebherrYellow = '#fdc400'
colors.mainBG = '#eeedf3'
colors.primary = colors.talpaBlue
colors.textPrimary = colors.talpaBlue
colors.textActivePrimary = BaseColors.darkGrey
colors.backgroundPrimary = colors.mainBG
colors.primaryLighter = chroma(colors.primary).brighten(0.7).css()
colors.primaryDarker = chroma(colors.primary).darken(0.5).css()
colors.primaryDarkest = chroma(colors.primary).darken(2).css()
colors.secondary = BaseColors.orange
colors.secondaryLighter = chroma(colors.secondary).brighten(0.7).css()
colors.secondaryDarker = chroma(colors.secondary).darken(0.5).css()
colors.primaryActive = colors.primaryLighter
colors.solidBG = '#fff'
colors.disabled = '#D3C6F2'
colors.solidBGDarker = '#eee'
colors.normalFontColor = BaseColors.darkGrey
colors.toggleInActive = '#CACAD4'
colors.navFontNormal = chroma(BaseColors.mediumGrey).darken(0.5).css()
colors.panelBorder = BaseColors.lighterGrey
colors.panelBG = BaseColors.white
colors.assetPanelBG = BaseColors.white
colors.panelHeader = BaseColors.darkGrey
colors.panelHeaderBorderBottom = BaseColors.lightGrey
colors.muted = BaseColors.lightGrey
colors.tableBorderBottom = BaseColors.lighterGrey
colors.circleIconBG = chroma(colors.primary).alpha(0.2).css()
colors.divBlackWhiteBackground = chroma(colors.primary).alpha(0.2).css()
colors.widgetBorder = 'transparent'
colors.widgetBG = BaseColors.white
colors.widgetShadowEnforced = '1px 2px 4px rgba(0, 0, 0, .25)'
colors.widgetShadow = '1px 2px 4px rgba(0, 0, 0, .25)'
colors.widgetEditBG = chroma(BaseColors.white).alpha(0.95).css()
colors.tableWidgetHightlight = chroma(BaseColors.darkGrey).alpha(0.2).css()
colors.subheaderInactiveBG = BaseColors.white
colors.inputOnSolid = chroma(colors.primary).alpha(0.2).css()
colors.talpaLoaderMarkerFill = colors.talpaGrey
colors.talpaLoaderBG = chroma(BaseColors.white).alpha(0.3).css()
colors.charts = {}
colors.charts.first = colors.primary
colors.charts.second = chroma([88, 106, 243]).css()
colors.charts.third = '#80a5fa'
colors.inputBG = BaseColors.lighterGrey
colors.inputColor = colors.primary
colors.inputPlaceholderColor = BaseColors.white
colors.solidBGContrastColor = BaseColors.darkerGrey
colors.solidBGContrastBG = BaseColors.lighterGrey
colors.selectBGDrop = '#FFFFFF'
colors.selectBG = '#F2F2F2'
colors.selectText = '#737373'
colors.togglerBodyActive = colors.primary
colors.togglerBodyInactive = '#EEEEEE'
colors.togglerBallActive = BaseColors.white
colors.togglerBallInactive = BaseColors.white
colors.title = '#292929'
colors.headerBG = '#EEEEEE'

colors.atomic = {}
colors.atomic.green = '#00C853'
colors.atomic.red = '#D50000'
colors.atomic.white = '#FFFFFF'
colors.atomic.black = '#000000'
// Feed
// Main pallette
colors.atomic.egyptianBlue = '#1C1CB2' // primary 900-100%
colors.atomic.dune = '#323232' // white-70%
colors.atomic.BalticSea = '#292929' // white-50%
colors.atomic.alabaster = '#FAFAFA' // surface-dp-00-2%
colors.atomic.porcelain = '#F2F2F2' // surface-dp-01-5%
colors.atomic.mercury = '#E5E5E5' // surface-dp-02-10%
colors.atomic.lightGrey = '#D9D9D9' // surface-dp-03-15%
colors.atomic.pastelGrey = '#CCCCCC' // surface-dp-04-20%
colors.atomic.gray95 = '#F2F2F2'
colors.atomic.hoverDark = '#fff'
colors.atomic.turbo = '#FFC525'
colors.atomic.bunkerLight = '#F1F1F1'
colors.atomic.pinkSwan = '#B8B8BC' // white-70%
colors.atomic.purpleHeart = '#673CD4' // secondary 500-100%
colors.atomic.blackLighter = '#000000B3' // primary - black - 70%
colors.atomic.chart = {}
colors.atomic.chart.green = '#69F0AE'
colors.atomic.chart.red = '#FF5252'
colors.atomic.chart.awesomeRed = '#FF1744'
colors.atomic.chart.rossaCorsa = '#D50000'
colors.atomic.chart.pinkRed = '#F50057'
colors.atomic.chart.burntPink = '#C51162'
colors.atomic.chart.hotPurple = '#D500F9'
colors.atomic.chart.brightViolet = '#AA00FF'
colors.atomic.chart.purplishBlue = '#651FFF'
colors.atomic.chart.electricIndigo = '#6200EA'
colors.atomic.chart.ultraMarineBlue = '#3D5AFE'
colors.atomic.chart.blueOrchid = '#304FFE'
colors.atomic.chart.clearBlue = '#2979FF'
colors.atomic.chart.azul = '#2962FF'
colors.atomic.chart.artyCllickSkyBlue = '#00B0FF'
colors.atomic.chart.azure = '#0091EA'
colors.atomic.chart.brightTurquoise = '#00E5FF'
colors.atomic.chart.brightCerulean = '#00B8D4'
colors.atomic.chart.aquaMarine = '#1DE9B6'
colors.atomic.chart.topaz = '#00BFA5'
colors.atomic.chart.tealishGreen = '#00E676'
colors.atomic.chart.darkMintGreen = '#00C853'
colors.atomic.chart.springGreen = '#76FF03'
colors.atomic.chart.greenApple = '#64DD17'
colors.atomic.chart.greenishYellow = '#C6FF00'
colors.atomic.chart.BitterLemon = '#AEEA00'
colors.atomic.chart.aureolin = '#FFEA00'
colors.atomic.chart.yellow = '#FFD600'
colors.atomic.chart.mikadoYellow = '#FFC400'
colors.atomic.chart.chromeYellow = '#FFAB00'
colors.atomic.chart.darkOrange = '#FF9100'
colors.atomic.chart.blazeOrange = '#FF6D00'
colors.atomic.chart.warmRed = '#FF3D00'
colors.atomic.chart.darkRed = '#DD2C00'
colors.atomic.chart.turquoiseBlue = '#40C4FF'
colors.atomic.mildgrey = '#eeedf3'
colors.atomic.artylickOrange = '#FC8308'
colors.atomic.davyGrey = '#4e535b'
colors.atomic.statusRed = '#FB2121'
colors.atomic.statusYellow = '#EBB113'
colors.atomic.statusGreen = '#18DB38'
colors.atomic.quarz = '#d2d2f0'
colors.atomic.severityNoData = '#757575'

// combinations
colors.atomic.primary = colors.atomic.egyptianBlue
colors.atomic.primaryLighter = colors.atomic.quarz
colors.atomic.hover = colors.atomic.mercury
colors.atomic.dropdownButton = colors.atomic.turbo
colors.atomic.circleIconBG = chroma(colors.primary).alpha(0.2).css()
colors.atomic.feedListItemBg = colors.atomic.white
colors.atomic.actionMenuBg = colors.atomic.white
colors.atomic.actionMenuItemBg = colors.atomic.porcelain
colors.atomic.textMain = colors.atomic.black
colors.atomic.textSecondary = colors.atomic.blackLighter
colors.atomic.filterButton = colors.atomic.porcelain
colors.atomic.insightPageBackground = colors.mainBG
colors.atomic.insightPageIcon = colors.atomic.purpleHeart
colors.atomic.arrowWrapperBg = colors.atomic.gray95
colors.atomic.multiChartColors = [colors.primary, chroma([88, 106, 243]).css(), '#80a5fa']
colors.atomic.chartColorsV2 = ['#5470c6', '#91cc75', '#9a60b4', '#668bee', '#ea7ccc', '#3ba272', '#fc8452', '#9a60b4', '#73c0de']
colors.atomic.chartCardBG = colors.atomic.white
colors.atomic.borderMain = colors.atomic.white
colors.atomic.pieChartColors = [
  colors.atomic.chart.awesomeRed,
  colors.atomic.chart.rossaCorsa,
  colors.atomic.chart.ultraMarineBlue,
  colors.atomic.chart.blueOrchid,
  colors.atomic.chart.aquaMarine,
  colors.atomic.chart.topaz,
  colors.atomic.chart.aureolin,
  colors.atomic.chart.yellow,
  colors.atomic.chart.pinkRed,
  colors.atomic.chart.burntPink,
  colors.atomic.chart.clearBlue,
  colors.atomic.chart.azul,
  colors.atomic.chart.tealishGreen,
  colors.atomic.chart.darkMintGreen,
  colors.atomic.chart.mikadoYellow,
  colors.atomic.chart.chromeYellow,
  colors.atomic.chart.hotPurple,
  colors.atomic.chart.brightViolet,
  colors.atomic.chart.artyCllickSkyBlue,
  colors.atomic.chart.azure,
  colors.atomic.chart.springGreen,
  colors.atomic.chart.greenApple,
  colors.atomic.chart.darkOrange,
  colors.atomic.chart.blazeOrange,
  colors.atomic.chart.purplishBlue,
  colors.atomic.chart.electricIndigo,
  colors.atomic.chart.brightTurquoise,
  colors.atomic.chart.brightCerulean,
  colors.atomic.chart.greenishYellow,
  colors.atomic.chart.BitterLemon,
  colors.atomic.chart.warmRed,
  colors.atomic.chart.darkRed,
]
colors.atomic.border1 = BaseColors.atomic.border1
colors.atomic.boxShadow1 = BaseColors.atomic.boxShadow1
colors.atomic.avatar = BaseColors.atomic.avatar
colors.atomic.healthStatusIconBG = colors.atomic.porcelain
colors.atomic.iconBG = chroma(colors.atomic.davyGrey).alpha(0.5).css()
colors.atomic.divider = chroma(colors.atomic.black).alpha(0.2).css()
colors.atomic.toolsMenuBG = colors.atomic.white
colors.atomic.title1 = colors.atomic.black
colors.atomic.title2 = colors.atomic.black
colors.atomic.toolMenuHover = colors.atomic.lightGrey
colors.atomic.tableBG = colors.atomic.white
colors.atomic.tableColumnHeaderBG = colors.atomic.mildgrey
colors.atomic.feedItemIconColor = colors.atomic.egyptianBlue
colors.atomic.feedItemCategoryColor = '#808389'
colors.atomic.bigListItemBg = colors.atomic.quarz
colors.atomic.bigListItemHover = colors.atomic.mercury
colors.atomic.stormDust = '#636363'
colors.atomic.tableTotal = 'whitesmoke'
colors.atomic.subTitle = '#808389'
colors.atomic.filterBadge = colors.atomic.tableTotal
colors.atomic.feedList = colors.atomic.white
colors.atomic.feedListCard = colors.headerBG
colors.atomic.notificationTime = colors.atomic.blackLighter
colors.atomic.severityLampOff = BaseColors.grey88
colors.atomic.severityPriority = '#1C1CB2'
colors.atomic.severityRed = '#FB2121'
colors.atomic.severityAmber = '#FFC525'
colors.atomic.severityMalfunction = '#FA2FFE'
colors.atomic.severityProtection = '#00B8D4'
colors.atomic.badge = colors.atomic.porcelain
colors.atomic.cardHover = chroma(colors.solidBG).darken(0.3).css()
colors.atomic.accordionBorder = chroma(colors.solidBG).darken(0.5).css()
colors.atomic.accordionBG = chroma(colors.solidBG).darken(0.5).css()

colors.atomic.healthyFill = '#B3E5B2'
colors.atomic.healthyStroke = '#A2D1A1'
colors.atomic.healthyBorder = '#A2D1A1'
colors.atomic.seperator = '#BBBBBB'
colors.atomic.sickFill = '#F1C1C1'
colors.atomic.sickStroke = '#EA9E9E'
colors.atomic.sickBorder = '#EA9E9E'
colors.atomic.selection = colors.atomic.primary
colors.atomic.noData = '#DDDDDD'
colors.atomic.unknown = '#EEEEEE'
colors.atomic.unknownFill = '#EEEEEE'
colors.atomic.unknownBorder = '#D7D7D9'
colors.atomic.icon = '#586575'
colors.atomic.trendLabel = colors.atomic.iconBG
colors.atomic.sicknessCardBorder = '#C6C6C6'
colors.atomic.positiveTrend = '#00BF59'
colors.atomic.negativeTrend = '#FF5252'
colors.atomic.hourSeperator = '#FFFFFF'

export default colors
