<template>
  <Calendar>
    <CalendarView
      :items="items"
      :show-date="showDate"
      :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
      :enable-drag-drop="true"
      :disable-past="false"
      :disable-future="false"
      :show-times="false"
      :display-period-uom="displayPeriodUom"
      :display-period-count="displayPeriodCount"
      :starting-day-of-week="startingDayOfWeek"
      :period-changed-callback="periodChanged"
      :current-period-label="useTodayIcons ? 'icons' : ''"
      :displayWeekNumbers="displayWeekNumbers"
      :enable-date-selection="true"
      :selection-start="selectionStart"
      :selection-end="selectionEnd"
      :locale="locale"
      :itemContentHeight="'2.5rem'"
      @date-selection-start="setSelection"
      @date-selection="setSelection"
      @date-selection-finish="finishSelection"
      @drop-on-date="onDrop"
      @click-date="onClickDay"
      @click-item="onClickItem"
    >
      <template #header="{ headerProps }">
        <header>
          <CalendarViewHeader :header-props="headerProps" @input="setShowDate" />
          <CustomSingleSelect
            class="select-uom"
            :selectedOption="selectedUom"
            @selectedFilter="selectUom"
            :options="availableUoms"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $t('plan.showAs') }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
          <CustomSingleSelect
            class="select-timezone"
            :selectedOption="selectedTimezone"
            @selectedFilter="selectTimezone"
            :options="availableTimezones"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $t('timezone.timezone') }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
        </header>
      </template>
    </CalendarView>
    <transition name="fade">
      <ScheduleDialog
        v-if="showScheduleDialog"
        :calendar="calendar"
        :calendarDates="calendarDates"
        :selectedSchedule="selectedSchedule"
        @close-dialog="closeScheduleDialog"
        @preview="preview = $event"
      />
    </transition>
  </Calendar>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Duration } from 'luxon'
import { Schedule } from '@rschedule/core/generators'
import { CalendarView, CalendarViewHeader, CalendarMathMixin } from 'vue-simple-calendar'
import { CustomSingleSelect } from '@common/components'
import ScheduleDialog from './ScheduleDialog'
import localesMixin from '@/mixins/locales'

function getDateTimeInTimezoneBySetting(dt, timezone, setting) {
  if (setting === 'schedule') {
    return dt.setZone(timezone)
  } else if (setting === 'utc') {
    return dt.setZone('UTC')
  } else if (setting === 'local') {
    return dt.setZone('local')
  }
}

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const Calendar = styled('div')`
  position: relative;
  margin: 1rem;
  display: flex;

  height: calc(100% - 2rem);
  flex-direction: column;
  flex-grow: 1;
  .cv-wrapper {
    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .select-uom,
      .select-timezone {
        border-radius: 0.5rem;
        background: ${({ theme }) => theme.colors.solidBG};
      }
      button {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.solidBG};
        border: none;
        border-radius: 0.5rem;
        padding: 0.6rem 0.7rem;
        margin: 0.25rem;
        font-size: 14px;
        &:hover {
          background-color: ${({ theme }) => theme.colors.atomic.hover};
        }
      }
      .cv-header {
        border: none;
      }
    }
    .cv-weeknumber {
      border: none;
    }
    .cv-header-days {
      border: none;
      .cv-header-day {
        border: none;
      }
    }
    .cv-weeks {
      border: none;
      border-radius: 0.5rem;
      .cv-week {
        .cv-weeknumber {
          display: flex;
          border: none;
          align-items: center;
        }
        .cv-day {
          border: none;
          padding: 0.2rem;
          margin: 1px 1px 0px 0px;
          background-color: ${({ theme }) => theme.colors.solidBG};
          &:hover {
            background-color: ${({ theme }) => theme.colors.atomic.hover};
          }
          &[aria-selected] {
            background-color: ${({ theme }) => theme.colors.atomic.hover};
          }
        }
      }
    }
    .cv-item {
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.mainBG};
      border-color: ${({ theme }) => theme.colors.mainBG};
      border: none;
      padding: 0.25rem 0.5rem;
      height: 2.5rem;
      &.toBeContinued {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.continued {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.shift {
        background-color: ${({ theme }) => theme.colors.atomic.dropdownButton};
      }
      &.planned_downtime {
        background-color: ${({ theme }) => theme.colors.atomic.avatar.pink};
      }
      .times {
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
      }
    }
  }
`

export default {
  inject: ['uiSettings'],
  mixins: [CalendarMathMixin, localesMixin],
  props: {
    calendar: {
      type: Object,
      required: true,
    },
  },
  components: {
    Calendar,
    CalendarView,
    CalendarViewHeader,
    CustomSingleSelect,
    ScheduleDialog,
    ShowAsLabel,
  },
  data() {
    return {
      showDate: new Date(),
      message: '',
      calendarDates: {},
      startingDayOfWeek: 0,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: 'month',
      displayPeriodCount: 1,
      displayWeekNumbers: false,
      showTimes: true,
      selectionStart: null,
      selectionEnd: null,
      preview: null,
      newItemTitle: '',
      newItemStartDate: '',
      newItemEndDate: '',
      useDefaultTheme: true,
      useHolidayTheme: true,
      useTodayIcons: false,
      showScheduleDialog: false,
      periodRange: null,
      Calendar: [],
      selectedSchedule: null,
      selectedTimezoneId: 'schedule',
    }
  },
  computed: {
    locale() {
      return this.uiSettings?.dates?.toLowerCase().replace('_', '-')
    },
    selectedTimezone() {
      return this.availableTimezones?.find(f => f.id === this.selectedTimezoneId)
    },
    availableTimezones() {
      return [
        { id: 'schedule', label: 'schedule' },
        { id: 'local', label: 'local' },
        { id: 'utc', label: 'utc' },
      ]
    },
    selectedUom() {
      return this.availableUoms?.find(f => f.id === this.displayPeriodUom)
    },
    availableUoms() {
      return ['week', 'month', 'year'].map(i => ({ id: i, label: this.$tc(`times.${i}`, 1) }))
    },
    items() {
      const items = []
      const start = DateTime.fromJSDate(this.periodRange?.displayFirstDate)
      const end = DateTime.fromJSDate(this.periodRange?.displayLastDate)
      if (!end.invalid) {
        this.calendar?.schedules
          .filter(schedule => schedule.id !== this.selectedSchedule?.id)
          .forEach(schedule => {
            const s = new Schedule({
              timezone: schedule.timezone,
              rrules: schedule.recurrences.map(recurrence => {
                const r = {
                  start: DateTime.fromISO(recurrence.start).setZone(schedule.timezone),
                  frequency: recurrence.frequency,
                  duration: recurrence.duration,
                  interval: recurrence.interval ? recurrence.interval : undefined,
                  count: recurrence.count ? recurrence.count : undefined,
                  weekStart: recurrence.weekStart ? recurrence.weekStart : undefined,
                  bySecondOfMinute: recurrence.bySecondOfMinute.length > 0 ? recurrence.bySecondOfMinute : undefined,
                  byMinuteOfHour: recurrence.byMinuteOfHour.length > 0 ? recurrence.byMinuteOfHour : undefined,
                  byHourOfDay: recurrence.byHourOfDay.length > 0 ? recurrence.byHourOfDay : undefined,
                  byDayOfWeek: recurrence.byDayOfWeek.length > 0 ? recurrence.byDayOfWeek : undefined,
                  byDayOfMonth: recurrence.byDayOfMonth.length > 0 ? recurrence.byDayOfMonth : undefined,
                  byMonthOfYear: recurrence.byMonthOfYear.length > 0 ? recurrence.byMonthOfYear : undefined,
                }
                return r
              }),
            })
            const occurrences = s.occurrences({ start, end })
            occurrences.toArray().forEach((occurrence, i) => {
              const duration = Duration.fromMillis(occurrence.duration)
              const scheduleTimezone = schedule.timezone
              const start = getDateTimeInTimezoneBySetting(occurrence.date, scheduleTimezone, this.selectedTimezoneId)
              const timezoneLabel = this.selectedTimezoneId === 'schedule' ? scheduleTimezone : this.selectedTimezoneId
              const end = start.plus(duration)
              const title = `
              <div class="times">@${timezoneLabel} ${start.toLocaleString(DateTime.TIME_SIMPLE)} - ${end.toLocaleString(
                DateTime.TIME_SIMPLE,
              )}</div>
              <div class="name">${schedule.name}</div>`
              items.push({
                id: `${schedule.id}_${i}`,
                schedule: schedule,
                startDate: start.toJSDate(),
                classes: schedule.type?.toLowerCase(),
                endDate: end.toJSDate(),
                title,
              })
            })
          })
      }
      if (!end.invalid && this.showScheduleDialog && this.preview?.rrule && this.preview?.duration) {
        const previewSchedule = new Schedule({
          timezone: this.preview.timezone,
          rrules: [this.preview.rrule],
        })
        const occurrences = previewSchedule.occurrences({ start, end })
        occurrences.toArray().forEach((occurrence, i) => {
          const duration = Duration.fromMillis(occurrence.duration)
          const scheduleTimezone = this.preview.timezone
          const start = getDateTimeInTimezoneBySetting(occurrence.date, scheduleTimezone, this.selectedTimezoneId)
          const timezoneLabel = this.selectedTimezoneId === 'schedule' ? scheduleTimezone : this.selectedTimezoneId
          const end = start.plus(duration)
          const title = `
          <div class="times">@${timezoneLabel} ${start.toLocaleString(DateTime.TIME_SIMPLE)} - ${end.toLocaleString(
            DateTime.TIME_SIMPLE,
          )}</div>
          <div class="name">${this.preview.title}</div>`
          items.push({
            id: `preview_${i}`,
            startDate: occurrence.date.toJSDate(),
            classes: this.preview.type?.toLowerCase(),
            endDate: occurrence.date.plus(this.preview.duration).toJSDate(),
            title,
          })
        })
      }
      return items
    },
  },
  methods: {
    closeScheduleDialog() {
      this.showScheduleDialog = false
      this.selectedSchedule = null
      this.preview = null
      this.$emit('reload')
    },
    periodChanged(range) {
      this.periodRange = range
    },
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    onClickDay(d) {
      this.selectionStart = d
      this.selectionEnd = d
      this.message = `You clicked: ${d.toLocaleDateString()}`
      this.selectedSchedule = null
      this.setCalendar()
    },
    onClickItem(item) {
      this.selectedSchedule = {
        ...item.originalItem.schedule,
      }
      this.showScheduleDialog = true
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`
      this.showDate = d
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1]
      this.selectionStart = dateRange[0]
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange)
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`
      this.setCalendar()
    },
    setCalendar() {
      // const selectionStart = DateTime.fromJSDate(this)
      this.calendarDates = {
        start: this.selectionStart,
        end: this.selectionEnd,
      }
      this.showScheduleDialog = true
    },
    onDrop(item, date) {
      if (!item) {
        return
      }
      this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the item.
      const eLength = this.dayDiff(item.startDate, date)
      item.originalItem.startDate = this.addDays(item.startDate, eLength)
      item.originalItem.endDate = this.addDays(item.endDate, eLength)
    },
    clickTestAddItem() {
      this.items.push({
        startDate: this.newItemStartDate,
        endDate: this.newItemEndDate,
        title: this.newItemTitle,
        id: 'e' + Math.random().toString(36).substr(2, 10),
      })
      this.message = 'You added a calendar item!'
    },
    selectUom({ id }) {
      this.displayPeriodUom = id
    },
    selectTimezone({ id }) {
      this.selectedTimezoneId = id
    },
  },
  // apollo: {
  //   calendar: {
  //     query: CALENDAR_QUERY,
  //   },
  // },
}
</script>
