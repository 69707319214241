<template>
  <NotificationsListMoleculeStyled>
    <NotificationsListHeaderMolecule
      :showAllNotifications="showAllNotifications"
      :unreadCount="unreadCount"
      @toggle-show-all-notifications="$emit('toggle-show-all-notifications')"
      @mark-all-notifications-as-read="$emit('mark-all-notifications-as-read')"
    />
    <NotificationsListMainMolecule
      :notifications="notifications"
      :notificationsError="notificationsError"
      :infiniteId="infiniteId"
      :isLoading="isLoading"
      :canLoadMore="canLoadMore"
      @load-more="$emit('load-more', $event)"
      @toggle-notification-is-unread="$emit('toggle-notification-is-unread', $event)"
      @close-pop-over="$emit('close-pop-over')"
    />
  </NotificationsListMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import NotificationsListHeaderMolecule from './NotificationsListMolecule/NotificationsListHeaderMolecule'
import NotificationsListMainMolecule from './NotificationsListMolecule/NotificationsListMainMolecule'

const NotificationsListMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 4.25rem 400px;
  box-sizing: border-box;
  padding: 0.75rem 0rem;
  color: ${p => (p.theme.isDark ? p.theme.colors.white : p.theme.colors.navFontNormal)};
  background: ${props => chroma(props.theme.colors.solidBG).darken(0.2).css()};
  gap: 1rem;
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  @media (min-width: 768px) {
    grid-template-columns: 28rem;
  }
  @media (max-height: 1023px) {
    grid-template-rows: 4.25rem calc(100vh - 250px);
  }
  @media (min-height: 1024px) {
    grid-template-rows: 4.25rem 750px;
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    canLoadMore: {
      type: Boolean,
      required: true,
    },
    infiniteId: {
      type: Number,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
    showAllNotifications: {
      type: Boolean,
      required: true,
    },
    unreadCount: {
      type: Number,
      required: true,
    },
    notificationsError: {
      type: Error,
    },
  },
  components: {
    NotificationsListMoleculeStyled,
    NotificationsListHeaderMolecule,
    NotificationsListMainMolecule,
  },
}
</script>
