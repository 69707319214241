import get from 'lodash/get'
import { DateTime, Interval, Duration } from 'luxon'

export function timeAgo(isoTime, daysLimit = -1) {
  if (!isoTime) {
    return ''
  }
  const now = DateTime.local()
  const t = DateTime.fromISO(isoTime)
  const diff = now.diff(t)
  const diffInDays = diff.as('days')
  if (daysLimit > -1 && diffInDays >= daysLimit) {
    return t.toLocaleString(DateTime.DATETIME_MED)
  }
  if (diffInDays >= 1) {
    return `${Math.round(diffInDays)} days ago`
  } else {
    const diffInHours = diff.as('hours')
    if (diffInHours < 1) {
      const diffInMinutes = diff.as('minutes')
      if (diffInMinutes < 1) {
        const diffInSeconds = diff.as('seconds')
        return `${Math.round(diffInSeconds)} seconds ago`
      }
      return `${Math.round(diffInMinutes)} minutes ago`
    }
    return `${Math.round(diffInHours)} hours ago`
  }
}

export function formatDateAccordingToSettings(value, uiSettings, tz = 'local') {
  const locale = get(uiSettings, 'dates', 'DE_DE').toLowerCase().replace('_', '-')
  return DateTime.fromISO(value)
    .setZone(tz)
    .setLocale(locale)
    .toLocaleString({
      ...DateTime.DATETIME_SHORT,
      ...DateTime.TIME_WITH_SECONDS,
    })
}

export function getLast30DaysInterval() {
  return Interval.fromDateTimes(DateTime.now().startOf('day').minus({ days: 30 }), DateTime.now().startOf('minute'))
}

export function getLast21DaysInterval() {
  return Interval.fromDateTimes(DateTime.now().startOf('day').minus({ days: 21 }), DateTime.now().startOf('minute'))
}

export function getLast14DaysInterval() {
  return Interval.fromDateTimes(DateTime.now().startOf('day').minus({ days: 14 }), DateTime.now().startOf('minute'))
}

export function getLast7DaysInterval() {
  return Interval.fromDateTimes(DateTime.now().startOf('day').minus({ days: 7 }), DateTime.now().startOf('minute'))
}

export function getLast24HoursInterval() {
  return Interval.fromDateTimes(DateTime.now().startOf('hour').minus({ hours: 23 }), DateTime.now().endOf('hour'))
}

export function getCurrentMonthInterval() {
  return Interval.fromDateTimes(DateTime.now().startOf('month'), DateTime.now().endOf('month'))
}

export function getMonthAndYearFormatted(month, locale) {
  return DateTime.fromISO(month).setLocale(locale).toFormat('LLL yyyy')
}

export function getShortDate(value, locale) {
  return DateTime.fromISO(value)
    .setLocale(locale)
    .toLocaleString({
      ...DateTime.DATE_SHORT,
    })
}

export function getInterval(startDate, endDate) {
  let interval = Interval.fromDateTimes(DateTime.fromISO(startDate), DateTime.fromISO(endDate))
  interval = getLast24HoursInterval()
  return interval
}

export function getIntervalBetweenDates(startDate, endDate) {
  return Interval.fromDateTimes(DateTime.fromISO(startDate), DateTime.fromISO(endDate))
}

export function millisToMinutes(milliseconds) {
  return Math.ceil(milliseconds / 60)
}

export function millisToHours(milliseconds) {
  return Math.ceil(milliseconds / (1000 * 60 * 60))
}

export function isDateInFuture(date) {
  var currentDate = new Date()
  var currentDateMillis = currentDate.getTime()
  var inputDateMillis = date.getTime()
  return inputDateMillis > currentDateMillis
}

export function formatDate(dateString, locale) {
  const dateTime = DateTime.fromISO(dateString)
  return dateTime.setLocale(locale).toFormat('dd MMM yyyy')
}

export function daysOfMonth(date) {
  const dateTime = DateTime.fromISO(date)
  return dateTime.daysInMonth
}

export function getTotalDaysBetweenDates(startISODateStr, endISODateStr) {
  const startDate = DateTime.fromISO(startISODateStr)
  const endDate = DateTime.fromISO(endISODateStr)
  const diffInDays = Math.ceil(endDate.diff(startDate, 'days').as('days'))
  return diffInDays
}

export function getTotalHoursBetweenDates(startISODateStr, endISODateStr) {
  const startDate = DateTime.fromISO(startISODateStr)
  const endDate = DateTime.fromISO(endISODateStr)
  const diffInHours = Math.ceil(endDate.diff(startDate, 'hours').as('hours'))
  return diffInHours
}

export function getTotalSecondsBetweenDates(startISODateStr, endISODateStr) {
  const startDate = DateTime.fromISO(startISODateStr)
  const endDate = DateTime.fromISO(endISODateStr)
  const diffInMinutes = Math.ceil(endDate.diff(startDate, 'seconds').as('seconds'))
  return diffInMinutes
}
export function timeDifference(start, end) {
  const startDate = DateTime.fromISO(start)
  const endDate = DateTime.fromISO(end)
  let diff = endDate.diff(startDate, ['hours', 'minutes', 'seconds'])

  // Ensure a minimum duration of 1 second
  if (diff.as('seconds') < 1) {
    diff = Duration.fromObject({ hours: 0, minutes: 0, seconds: 1 })
  }

  let humanReadableDiff = diff?.toHuman({ unitDisplay: 'narrow' })

  // toHuman() returns all time units even if they are in 0s.
  // But we display only if the time unit has some value > 0
  humanReadableDiff = humanReadableDiff
    .split(', ')
    .filter(part => !part.startsWith('0'))
    .join(' ')

  return humanReadableDiff
}

export function millisFromIsoDate(date) {
  return DateTime.fromISO(date).toMillis()
}

export function getLongDate(date, locale) {
  return DateTime.fromISO(date).setLocale(locale).toFormat('dd MMM yyyy')
}

export function getFromToTime(start, end) {
  const startHour = DateTime.fromISO(start).hour
  const endHour = DateTime.fromISO(end).plus({ hours: 1 }).hour
  return `${startHour}:00 - ${endHour}:00`
}

export function getLocaleDate(date, locale, format = DateTime.DATE_MED) {
  return DateTime.fromISO(date).setLocale(locale).toFormat(format)
}

export function formatTimePerLocale(date, isTwentyFourFormat, locale) {
  const timeFormat = isTwentyFourFormat === 'TWENTYFOUR_FORMAT' ? 'HH:mm' : 'ha'
  return DateTime.fromISO(date).setLocale(locale).toFormat(timeFormat)
}

export function getStartTime(dateTime, unit) {
  return DateTime.fromISO(dateTime).startOf(unit)
}

export function getEndTime(dateTime, unit) {
  return DateTime.fromISO(dateTime).endOf(unit)
}
