var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ScheduleGoalSettingsStyled', [!_vm.isCreatingNewGoal ? _c('PerformanceGoalsListStyled', [_c('PerformanceGoalsListMolecule', {
    attrs: {
      "performanceGoals": _vm.performanceGoals
    },
    on: {
      "removeGoal": _vm.removePerformanceGoal,
      "updateGoal": _vm.updateGoal
    }
  }), _c('AddGoalStyled', [_c('ButtonSolid', {
    on: {
      "click": _vm.addGoal
    }
  }, [_c('PlusIcon', {
    staticClass: "icon"
  }), _vm._v(" " + _vm._s(_vm.$tc('planning.addGoal')) + " ")], 1)], 1)], 1) : _vm._e(), _vm.isCreatingNewGoal ? _c('PerformanceGoalCreateMolecule', {
    attrs: {
      "schedule": _vm.schedule,
      "assetsByRecipient": _vm.assetsByRecipient,
      "performanceGoalToEdit": _vm.performanceGoalToEdit,
      "mode": _vm.mode
    },
    on: {
      "appendGoal": _vm.appendGoal,
      "cancel": function cancel($event) {
        _vm.isCreatingNewGoal = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }