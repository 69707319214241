var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidatableFieldMolecule', [_vm._t("default"), _vm.validationErrors.length > 0 ? _c('div', {
    staticClass: "validation-errors"
  }, _vm._l(_vm.validationErrors, function (validationError) {
    return _c('ValidationErrorAtom', {
      key: validationError.id,
      attrs: {
        "validationError": validationError
      }
    });
  }), 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }