var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AvatarBadgeStyled', {
    class: {
      edit: _vm.isEditing,
      select: _vm.isSelected
    },
    attrs: {
      "id": "sensitive",
      "size": _vm.size,
      "color": _vm.avatarColor,
      "fontSize": _vm.fontSize
    }
  }, [_vm._v(" " + _vm._s(_vm.avatarName) + " "), _vm.isOwner ? _c('CircleIcon', {
    staticClass: "icon"
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }