var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProfileWrapperStyled', [_c('VPopover', {
    attrs: {
      "placement": 'bottom-start',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions,
      "data-testid": "profile-menu"
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('ProfileStyled', [_c('ButtonStyled', {
          on: {
            "click": _vm.redirectToAccountManagementConsole
          }
        }, [_vm._v(_vm._s(_vm.$tc('myAccount', 1)))])], 1), _c('ProfileStyled', [_c('ButtonStyled', {
          on: {
            "click": _vm.redirectToOrganizationManagement
          }
        }, [_vm._v(_vm._s(_vm.$tc('myOrganizations', _vm.membershipCount)))])], 1), _c('ProfileStyled', [_c('ButtonStyled', {
          attrs: {
            "data-testid": "logout"
          },
          on: {
            "click": _vm.signOut
          }
        }, [_vm._v(_vm._s(_vm.$t('actions.logout')))])], 1)];
      },
      proxy: true
    }])
  }, [_c('ProfileTriggerStyled', {
    class: {
      active: _vm.isActive
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('AvatarBadge', {
    staticClass: "profile",
    attrs: {
      "size": '30px',
      "fontSize": '14px',
      "userData": _vm.profile
    }
  })], 1), _c('div', {
    staticClass: "details"
  }, [_c('span', {
    attrs: {
      "id": "sensitive",
      "title": _vm.username
    }
  }, [_vm._v(_vm._s(_vm.username))]), _c('small', {
    attrs: {
      "id": "sensitive"
    }
  }, [_vm._v(_vm._s(_vm.email))])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }