<template>
  <ScheduleGoalSettingsStyled>
    <PerformanceGoalsListStyled v-if="!isCreatingNewGoal">
      <PerformanceGoalsListMolecule :performanceGoals="performanceGoals" @removeGoal="removePerformanceGoal" @updateGoal="updateGoal" />
      <AddGoalStyled>
        <ButtonSolid @click="addGoal">
          <PlusIcon class="icon" />
          {{ $tc('planning.addGoal') }}
        </ButtonSolid>
      </AddGoalStyled>
    </PerformanceGoalsListStyled>
    <PerformanceGoalCreateMolecule
      v-if="isCreatingNewGoal"
      :schedule="schedule"
      :assetsByRecipient="assetsByRecipient"
      :performanceGoalToEdit="performanceGoalToEdit"
      :mode="mode"
      @appendGoal="appendGoal"
      @cancel="isCreatingNewGoal = false"
    />
  </ScheduleGoalSettingsStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { PlusIcon } from 'vue-feather-icons'

import PerformanceGoalsListMolecule from './PerformanceGoalsListMolecule'
import PerformanceGoalCreateMolecule from './PerformanceGoalCreateMolecule.vue'
import ButtonSolid from '../../Atomic/Atoms/ButtonSolid'

const ScheduleGoalSettingsStyled = styled('div')`
  display: flex;
  height: calc(100% - 1rem);
  padding: 0.5rem 2rem;
  border-radius: 5px;
`
const PerformanceGoalsListStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content;
  width: 100%;
  background: ${({ theme }) => theme.colors.atomic.tableTotal};
  border-radius: 5px;
`

const AddGoalStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0.5rem 0rem;
  button {
    width: 90%;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
`

export default {
  components: {
    ScheduleGoalSettingsStyled,
    PerformanceGoalsListStyled,
    PerformanceGoalsListMolecule,
    PerformanceGoalCreateMolecule,
    AddGoalStyled,
    ButtonSolid,
    PlusIcon,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    assetsByRecipient: {
      type: Array,
    },
  },
  data() {
    return {
      isCreatingNewGoal: false,
      performanceGoals: [],
      goalIndexToUpdate: -1,
      mode: 'create',
    }
  },
  watch: {
    schedule: {
      handler(schedule) {
        this.performanceGoals = schedule.performanceGoals
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    performanceGoalToEdit() {
      if (this.goalIndexToUpdate === -1) {
        return null
      } else {
        return this.performanceGoals[this.goalIndexToUpdate]
      }
    },
  },
  methods: {
    addGoal() {
      this.mode = 'create'
      this.goalIndexToUpdate = -1
      this.isCreatingNewGoal = true
    },
    appendGoal(goal) {
      if (this.mode === 'create') {
        this.performanceGoals.push(goal)
      } else {
        this.performanceGoals[this.goalIndexToUpdate] = goal
      }
      this.goalIndexToUpdate = -1
      this.$emit('setPerformanceGoals', this.performanceGoals)
      this.isCreatingNewGoal = false
    },
    removePerformanceGoal(index) {
      this.performanceGoals.splice(index, 1)
    },
    updateGoal(goalIndex) {
      this.mode = 'edit'
      this.goalIndexToUpdate = goalIndex
      this.isCreatingNewGoal = true
    },
  },
}
</script>
