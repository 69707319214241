import base from './base'
import { styled } from '@egoist/vue-emotion'

export default styled(base)`
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 50%;
  margin: 1rem;
  color: ${props => props.theme.selected.colors.white};
  background: ${props => props.theme.selected.colors.primary};
  box-shadow: ${props => props.theme.selected.misc.defaultBoxShadow};
  &:hover,
  &:focus {
    color: ${props => props.theme.selected.colors.white};
    background: ${props => props.theme.selected.colors.secondary};
  }

  &:disabled {
    background: ${props => props.theme.selected.colors.lightGrey};
  }
`
