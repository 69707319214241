import { FlashMessages, Errors } from '@common/singletons'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import fetch from 'node-fetch'
import unfetch from 'unfetch'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { cache, initCache } from './cache'
import { resolvers } from './resolvers'
import typeDefs from './typeDefs.gql'

// eslint-disable-next-line no-undef
const config = appConfig?.graphql
const useDynamicHostname = (config?.dynamicHostName || 'false')?.toLowerCase() === 'true'
const hostname = location && location.origin ? location.hostname : false
const httpEndpoint = useDynamicHostname && hostname ? 'http://' + hostname + ':4004/graphql' : config?.http
const wsEndpoint = useDynamicHostname && hostname ? 'ws://' + hostname + ':4004/graphql' : config?.ws

// Call this in the Vue app file
export function createProvider(ssr, keycloak) {
  const linkOpts = {
    uri: httpEndpoint,
  }

  if (ssr) {
    linkOpts.fetch = fetch
  } else {
    linkOpts.fetch = unfetch
  }

  const httpLink = createUploadLink(linkOpts)

  const authLink = setContext(async (_, { headers }) => {
    if (keycloak.isTokenExpired(5)) {
      await keycloak.updateToken()
    }
    const token = keycloak.token
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const authMiddlewareLink = new ApolloLink((operation, forward) =>
    forward(operation).map(response => {
      return response
    }),
  )

  const links = [authMiddlewareLink, authLink, httpLink]
  const withAuthMiddleware = ApolloLink.from(links)

  const subscriptionClient = new SubscriptionClient(wsEndpoint, {
    reconnect: true,
    connectionParams: async () => {
      if (keycloak.isTokenExpired(5)) {
        await keycloak.updateToken()
      }
      const token = keycloak.token
      return {
        authorization: token ? `Bearer ${token}` : '',
      }
    },
  })

  keycloak.onAuthRefreshSuccess = () => {
    subscriptionClient.close(false, true)
  }

  // Create the subscription websocket link
  const wsLink = ssr ? undefined : new WebSocketLink(subscriptionClient)

  const link = ssr
    ? withAuthMiddleware
    : split(
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink,
        withAuthMiddleware,
      )

  // const link = withAuthMiddleware

  const errorLink = onError(foo => {
    Errors.$emit('error', foo)
  })

  // Create apollo client
  const apolloClient = new ApolloClient({
    link: errorLink.concat(link),
    cache,
    ...(ssr
      ? {
          // Set this on the server to optimize queries when SSR
          ssrMode: true,
        }
      : {
          // This will temporary disable query force-fetching
          ssrForceFetchDelay: 100,
        }),
    resolvers,
    typeDefs,
    addTypename: true,
  })

  apolloClient.onResetStore(() => {
    initCache()
  })

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
  })

  return apolloProvider
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  Vue.localStorage.remove('talpa-auth-token')
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    FlashMessages.$emit('error', e.message)
  }
}
