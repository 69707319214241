import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { buttonReset, flexCenter } from '@styles/mixins'

export default styled('button')`
  ${props => buttonReset(props)}
  ${flexCenter}
  margin: .5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.primary};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`
