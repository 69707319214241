<template>
  <DropdownSelectorStyled v-away="closeDropdownList">
    <ToggleStyled
      v-bind:class="{ flash: doFlashing }"
      ref="toggle"
      :disabled="isDisabled"
      @click="toggleShowList"
      v-tooltip.bottom="tooltip"
      id="addButton"
    >
      <PlusSquare size="1.4x" class="icon" />
      <span>{{ $t('feed.card.addCard') }}</span>
      <CountCircle v-if="selectedCount" :count="selectedCount" />
    </ToggleStyled>
    <ListWrapperStyled v-if="!isDisabled">
      <transition name="fade-in-down">
        <div :class="noAssetDimensions ? 'list-empty' : 'list-inner'" v-if="showList">
          <template v-if="!isLoading && !noAssetDimensions">
            <ListSearchStyled>
              <input v-model="searchQuery" :placeholder="$t('feed.card.search')" />
            </ListSearchStyled>
            <DropdownList :dataList="dataListFiltered" :totalCount="totalCount" @selectedValue="selectedValue" />
          </template>
          <NoDataAlert
            v-else-if="!isLoading && noAssetDimensions"
            :popoverContent="$t('feed.card.popover.info')"
            :popoverTitle="$t('feed.card.popover.title')"
          />
          <template v-else-if="isLoading">
            <TalpaLoaderWrapper />
          </template>
        </div>
      </transition>
    </ListWrapperStyled>
  </DropdownSelectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { flexColumns, buttonReset } from '@styles/mixins'
import { CountCircle, TalpaLoaderWrapper } from '@common/components'
import PlusSquare from '@common/components/src/assets/icons/PlusSquare.svg'

import DropdownList from './Dropdown/DropdownList'
import { directive as onClickaway } from 'vue-clickaway'
import NoDataAlert from '@common/components/src/NoDataAlert'
import { VTooltip } from 'v-tooltip'

const DropdownSelectorStyled = styled('div')`
  .flash {
    animation-name: flash;
    animation-duration: 500ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: flash;
    -webkit-animation-duration: 500ms;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;

    -moz-animation-name: flash;
    -moz-animation-duration: 500ms;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;

    @keyframes flash {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes flash {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    @-moz-keyframes flash {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
`
const ToggleStyled = styled('button')`
  display: flex;
  justify-content: space-between;
  ${buttonReset}
  width: 100%;
  background: ${p => (p.disabled ? p.theme.colors.disabled : p.theme.colors.atomic.dropdownButton)};
  align-items: center;
  padding: 0.6rem;
  transition: border-radius 0.25s ease;
  border-radius: 8px;
  font-size: 14px;
  color: ${p => p.theme.colors.normalFontColor};
  .icon {
    margin-left: 0;
    path {
      stroke: ${p => p.theme.colors.normalFontColor};
    }
  }
  span {
    display: none;
  }
  .feather {
    color: ${p => (p.disabled ? p.theme.colors.muted : p.theme.colors.primary)};
  }
  &.active {
    color: inherit;
  }
  @media (min-width: 768px) {
    span {
      flex-grow: 1;
      display: inline-block;
    }
    margin-right: 2rem;
  }
`
const ListWrapperStyled = styled('div')`
  position: absolute;
  max-height: 14rem;
  z-index: 100;
  right: 0;
  margin-top: 0.7rem;

  .list-inner {
    ${flexColumns}
    width: 22rem;
    max-height: 16rem;
    border-top: none;
    background: ${p => p.theme.colors.solidBG};
    border-radius: 8px 8px 0 0;
    @media (max-width: 767px) {
      width: 15rem;
    }
  }
  .list-empty {
    ${flexColumns}
    width: 18rem;
    max-height: 12rem;
    border-top: none;
    background: ${p => p.theme.colors.solidBG};
    border-radius: 8px 8px 0 0;
  }
`
const ListSearchStyled = styled('div')`
  width: 100%;
  input {
    width: calc(100% - 2rem);
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0.5rem;
    padding: 0.8rem;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.inputOnSolid};
    border-bottom: 1px solid ${props => chroma(props.theme.colors.white).alpha(0.8).css()};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
    }
  }
`

export default {
  directives: {
    away: onClickaway,
    tooltip: VTooltip,
  },
  props: {
    dataList: {
      type: Array,
    },
    totalCount: {
      type: Number,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    DropdownSelectorStyled,
    ToggleStyled,
    CountCircle,
    PlusSquare,
    ListWrapperStyled,
    DropdownList,
    ListSearchStyled,
    TalpaLoaderWrapper,
    NoDataAlert,
  },
  data() {
    return {
      showList: false,
      selectedCount: null,
      searchQuery: '',
      doFlashing: false,
      isOpen: false,
    }
  },
  mounted() {
    this.$root.$on('flashing-initiate', () => {
      this.startflashingAndShowMsg()
    })
  },
  computed: {
    noAssetDimensions() {
      return this.dataList.length < 1
    },
    dataListFiltered() {
      return this.searchQuery && this.searchQuery !== ''
        ? this.dataList.filter(d => d.label.toLowerCase().includes(this.searchQuery.toLowerCase()))
        : this.dataList
    },
    tooltip() {
      return {
        content: this.$t('feed.card.addCardHere'),
        boundariesElement: '#addButton',
        classes: 'addCard',
        trigger: 'manual',
        show: this.isOpen,
        autoHide: true,
      }
    },
  },
  methods: {
    toggleShowList() {
      this.showList = !this.showList
    },
    selectedValue(value) {
      this.$emit('selectedValue', value)
      this.closeDropdownList()
    },
    closeDropdownList() {
      this.showList = false
    },
    startflashingAndShowMsg() {
      this.doFlashing = true
      setTimeout(() => {
        this.doFlashing = false
        this.isOpen = true
        setTimeout(() => {
          this.isOpen = false
        }, 3000)
      }, 1000)
    },
  },
}
</script>
