export default function () {
  return `  
.pvtUi {
  table-layout: fixed;
  width: 100%;
  color: #2a3f5f;
  font-family: 'Open Sans', sans-serif;
  border-collapse: collapse;
} 
.pvtUi select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  border-radius: 5px ;
}
.pvtUi td {
  overflow-x: auto;
}
.pvtUi td.pvtOutput {
  padding: 6px;
  vertical-align: top;
  border: 1px solid #a2b1c6;
  overflow-x: auto;
  background: #fff;
}

table.pvtTable {
  font-size: 9pt;
  text-align: left;
  border-collapse: collapse;
  font-family: 'Open Sans', sans-serif;
}

table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #ebf0f8;
  border: 1px solid #c8d4e3;
  font-size: 9pt;
  padding: 5px;
}

table.pvtTable .pvtColLabel {
  text-align: center;
}
table.pvtTable .pvtTotalLabel {
  text-align: right;
}

table.pvtTable tbody tr td {
  color: #2a3f5f;
  padding: 3px;
  background-color: #fff;
  border: 1px solid #c8d4e3;
  vertical-align: top;
  text-align: right;
}

.pvtTotal,
.pvtGrandTotal {
  font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
  display: inline-block;
  user-select: none;
  text-decoration: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
  border: 1px solid #a2b1c6;
  background: #fff;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
}
.pvtVals.pvtText {
  vertical-align: middle;
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  user-select: none;
  padding-left: 16px;
  text-align: start;
}

.pvtRenderers {
  border: 1px solid #a2b1c6;
  user-select: none;
  width: 25%;
  padding: 0.5rem;
  background: #fff;
}
@media screen and (max-width:576px) {
  .pvtRenderers {
    width: 100%;
  }
  .pvtAxisContainer{
    display: none;
  }
}
@media screen and (width: 768px) {
  .pvtRenderers {
    width: 40%;
  }
}
.pvtDropdown {
  border: 1px solid #a2b1c6;
  align-items: center;
  display: block;
  background-color: transparent;
  background-image: none;
  border-radius: 0px;
  padding: 0px 0px 0px 3px;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  line-height: 1 !important;
  margin: 3px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtRenderers .pvtDropdown {
  padding: 0;
  border: none;
  width: 65%;
  margin: 0px;
}

.pvtDropdown:last-child {
  margin-bottom: 0px;
}

.pvtVals {
  white-space: nowrap;
  vertical-align: top;
  padding-bottom: 12px;
}

.pvtRows {
  height: 35px;
}

.pvtAxisContainer li {
  padding: 8px 6px;
  list-style-type: none;
  cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
  -webkit-border-radius: 5px;
  padding: 3px 15px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
  display: none;
}

.pvtAxisContainer li span.pvtAttr {
  -webkit-text-size-adjust: 100%;
  background: #f3f6fa;
  border: 1px solid #c8d4e3;
  padding: 4px 7px;
  white-space: nowrap;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.pvtAxisContainer li span.pvtAttr.sortonly {
  border: 1px solid #c8d4e3;
}
.pvtAxisContainer li span.pvtAttr.disabled {
  border: 1px solid #c8d4e3;
  color: #c5c5c5;
  cursor: not-allowed;
}
.pvtTriangle {
  cursor: pointer;
  color: #506784;
}

.pvtHorizList li {
  display: inline-block;
}

.pvtVertList {
  vertical-align: top;
}

.pvtFilteredAttribute {
  font-style: italic;
}

.sortable-chosen .pvtFilterBox {
  display: none !important;
}

.pvtFilterTextClear {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none !important;
}

.pvtButton {
  display: inline-block;
  color: #506784;
  border-radius: 5px;
  padding: 3px 6px;
  background: #fff;
  border: 1px solid;
  border-color: #c8d4e3;
  font-size: 14px;
  margin: 3px 3px 6px 0px;
  transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none !important;
}

.pvtButton:hover {
  background: #e2e8f0;
  border-color: #a2b1c6;
}

.pvtButton:active {
  background: #d1dae6;
}

.pvtFilterBox input {
  border: 1px solid #c8d4e3;
  border-radius: 5px;
  color: #506784;
  padding: 0 3px;
  font-size: 14px;
}

.pvtFilterBox input:focus {
  border-color: #119dff;
  outline: none;
}

.pvtFilterBox {
  z-index: 100;
  border: 1px solid #506784;
  max-width: 600px;
  min-width: 210px;
  background-color: #fff;
  position: absolute;
  padding: 6px;
  user-select: none;
  min-height: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtFilterBox input[type='text'] {
  display: block;
  width: 100%;
  color: #2a3f5f;
  margin-bottom: 5px;
  padding: 6px;
}

.pvtFilterBox {
  padding: 3px;
}

.pvtCheckContainer {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow-y: scroll;
  width: 100%;
  max-height: 30vh;
  border-top: 1px solid #dfe8f3;
}

.pvtCheckContainer p {
  margin: 0;
  margin-bottom: 1px;
  padding: 3px;
  cursor: default;
}

.pvtCheckContainer p.selected {
  background: #ebf0f8;
  min-width: 100%;
  width: max-content;
}

.pvtOnly {
  display: none;
  width: 35px;
  float: right;
  font-size: 12px;
  padding-left: 5px;
  cursor: pointer;
}

.pvtOnlySpacer {
  display: block;
  width: 35px;
  float: right;
}

.pvtCheckContainer p:hover .pvtOnly {
  display: block;
}

.pvtCheckContainer p:hover .pvtOnlySpacer {
  display: none;
}

.pvtRendererArea {
  padding: 5px;
}

  `
}
