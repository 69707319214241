<template>
  <BasicStyled class="basic-select" v-away="away" :class="{ disabled: isDisabled, small }">
    <div class="current" @click="toggle">
      <div class="target" v-if="target">{{ target }}:</div>
      <div class="label">{{ label }}</div>
    </div>
    <transition name="fade">
      <OptionsStyled :class="{ open: isOpen }" v-if="isOpen">
        <OptionStyled
          class="basic-select-option"
          v-for="option in optionsMapped"
          :key="option.id"
          @click="selectOption(option)"
          :class="{ selected: option.isSelected }"
        >
          {{ option.label }}
        </OptionStyled>
      </OptionsStyled>
    </transition>
    <transition name="fade">
      <ChevronDownIcon :class="{ icon: isOpen }" @click="toggle" />
    </transition>
  </BasicStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { directive as onClickaway } from 'vue-clickaway'
import { flexCenter, flexColumns } from '@styles/mixins'

import { ChevronDownIcon } from 'vue-feather-icons'

const BasicStyled = styled('div')`
  ${flexCenter}
  position: relative;
  background-color: ${p => p.theme.colors.selectBGDrop};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  appearance: none;
  border: none;
  cursor: pointer;
  .target {
    width: 3rem;
    overflow: hidden;
    font-size: 10px;
    padding: 0 0.5rem;
  }
  .current {
    cursor: pointer;
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8rem 0 0.8rem 1rem;
    margin-right: 2rem;
    height: 1.25rem;
    min-width: 8rem;
  }
  > .feather {
    transition-duration: 0.25s;
    position: absolute;
    /* top: 0.5rem; */
    right: 0.5rem;
    color: ${p => p.theme.colors.primary};
    cursor: pointer;
  }
  &:hover {
    .feather {
      color: ${p => p.theme.colors.primary};
      cursor: pointer;
    }
  }
  .icon {
    transform: rotate(180deg);
    transition-duration: 0.25s;
    .feather {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      color: ${p => p.theme.colors.primary};
      cursor: pointer;
    }
    &:hover {
      .feather {
        color: ${p => p.theme.colors.primary};
        cursor: pointer;
      }
    }
  }
  &.small {
    .current {
      padding: 0.25rem 0 0.25rem 1rem;
    }
  }

  &.disabled {
    color: ${props => props.theme.colors.muted};
    background: ${props => props.theme.colors.white};
    pointer-events: none;
    cursor: none;
  }
`

const OptionsStyled = styled('div')`
  ${flexColumns}
  position: absolute;
  left: 0;
  top: 2.5rem;
  min-width: 8rem;
  width: 100%;
  box-shadow: ${p => p.theme.colors.widgetShadow};
  z-index: 10000;
  &.open {
    z-index: 10010;
  }
`

const OptionStyled = styled('div')`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  padding-left: 1.25rem;
  min-height: 2rem;
  min-width: 5rem;
  background-color: ${p => p.theme.colors.selectBG};
  border-top: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.widgetBG : theme.colors.primary)};
  &.selected {
    color: ${p => p.theme.colors.normalFontColor};
  }
  &:hover {
    color: ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.primary};
  }
  &.disabled {
    color: ${props => props.theme.colors.muted};
    background: ${props => props.theme.colors.white};
  }
`

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
    },
    target: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BasicStyled,
    OptionsStyled,
    OptionStyled,
    ChevronDownIcon,
  },
  directives: {
    away: onClickaway,
  },
  data() {
    return {
      localSelected: null,
      isOpen: false,
    }
  },
  watch: {
    selected: {
      handler(s) {
        this.localSelected = s
      },
      immediate: true,
    },
  },
  computed: {
    optionsFiltered() {
      if (this.filter) {
        return this.options.filter(f => f.id !== this.localSelected.id)
      }
      return this.options
    },
    optionsMapped() {
      const labelKey = this.labelKey
      return this.optionsFiltered.map(o => ({
        ...o,
        label: get(o, labelKey),
        isSelected: o.id === get(this.localSelected, 'id', null),
      }))
    },
    label() {
      const l = get(this.localSelected, this.labelKey)
      if (l !== undefined) {
        return l
      }
      if (this.placeholder) {
        return this.placeholder
      }
      return this.$tc('actions.choose')
    },
  },
  methods: {
    away() {
      this.isOpen = false
    },
    selectOption(option) {
      this.isOpen = false
      this.$emit('change', option)
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
