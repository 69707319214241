<template>
  <AssetNotificationWrapperStyled>
    <AssetNotificationStyled :class="{ muted: !notification.isUnread }" :unread="notification.isUnread">
      <TalpaIcon v-if="iconName" class="asset-icon" :scope="'AssetTypes'" :name="iconName" />
      <span class="asset-icon unknown" v-else>
        <HelpCircleIcon />
      </span>
      <AssetNotificationContentStyled>
        <router-link v-if="notification.assetNotificationEvent.asset" class="asset-name" :to="to" @click.native="handleClick">
          {{ notification.assetNotificationEvent.asset?.name ?? $tc('deleted.assets', 1) }}
        </router-link>
        <span v-else>
          {{ $tc('deleted.assets', 1) }}
        </span>
        <div class="count">
          <span v-if="notification?.assetNotificationEvent?.notificationEventType === 'ENGINE'">
            {{
              $tc('notifications.engineIssue', notification.assetNotificationEvent.assetEventIds.length, {
                count: notification.assetNotificationEvent.assetEventIds.length,
              })
            }}
          </span>
          <span
            v-if="
              notification?.assetNotificationEvent?.notificationEventType === 'HIGH_NUMBER_OF_PASSES' ||
              notification?.assetNotificationEvent?.notificationEventType === 'OVERLOAD_EVENT'
            "
            >{{
              $tc('notifications.productionIssue', notification.assetNotificationEvent.assetEventIds.length, {
                count: notification.assetNotificationEvent.assetEventIds.length,
              })
            }}
          </span>
          <span v-if="notification?.assetNotificationEvent?.notificationEventType === 'PRIORITY'"
            >{{
              $tc('notifications.priorityError', notification.assetNotificationEvent.assetEventIds.length, {
                count: notification.assetNotificationEvent.assetEventIds.length,
              })
            }}
          </span>
          <span v-if="notification?.assetNotificationEvent?.notificationEventType === 'BOMAG_ERROR'"
            >{{
              $tc('notifications.bomagError', notification.assetNotificationEvent.assetEventIds.length, {
                count: notification.assetNotificationEvent.assetEventIds.length,
              })
            }}
          </span>
          <span v-if="notification?.assetNotificationEvent?.notificationEventType === 'OVERSPEED_EVENT'">
            {{ overSpeedEventMessage() }}
          </span>
        </div>
        <footer>
          <div class="ago">{{ ago }}</div>
        </footer>
      </AssetNotificationContentStyled>
      <button @click="$emit('toggle-is-unread')" :class="{ unread: notification.isUnread }" v-tooltip="tooltip">
        <circleIcon size="0.5x" />
      </button>
    </AssetNotificationStyled>
  </AssetNotificationWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Interval } from 'luxon'
import { get } from 'lodash'
import chroma from 'chroma-js'
import { CircleIcon, HelpCircleIcon } from 'vue-feather-icons'
import { VTooltip } from 'v-tooltip'

import { getLast24HoursInterval } from '../../../../utils/time'
import { TalpaIcon } from '@common/components'
import { buttonReset, flexCenter } from '@styles/mixins'
import units from '@/utils/units'
import localesMixin from '@/mixins/locales'
import permissionsMixin from '@/mixins/permissions'

const AssetNotificationWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: calc(100vw - 3.6rem);
  grid-template-rows: min-content;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.solidBG};
  border: 1px solid ${({ theme }) => theme.colors.atomic.divider};
  box-shadow: 0px 1px 4px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  &:hover {
    background: ${({ theme }) =>
      theme.isDark ? chroma(theme.colors.atomic.textMain).alpha(0.08).css() : chroma(theme.colors.atomic.textMain).alpha(0.01).css()};
  }
  @media (min-width: 768px) {
    grid-template-columns: 25rem;
  }
`
const AssetNotificationStyled = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr 1rem;
  gap: 1.25rem;
  > .asset-name {
    width: fit-content;
  }
  &:hover {
    > button {
      border: 1px solid ${p => p.theme.colors.muted};
    }
  }
  > .asset-icon {
    align-self: center;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.atomic.textMain};
    margin: 0.5rem;
  }
  > button {
    ${buttonReset};
    ${flexCenter};
    padding: 0;
    font-size: 12px;
    font: inherit;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    > svg {
      fill: ${p => p.theme.colors.primary};
      color: ${p => p.theme.colors.primary};
    }
    &:hover {
      border-radius: 50%;
      border: 1px solid ${p => p.theme.colors.primary};
    }
  }

  &.muted {
    color: ${({ theme }) => theme.colors.muted};
    > button {
      svg {
        fill: transparent;
        color: ${p => p.theme.colors.atomic.notificationTime};
      }
    }
    .ago,
    .asset-icon,
    .count,
    a {
      color: ${({ theme }) => theme.colors.muted};
      &.router-link-exact-active {
        color: ${({ theme }) => theme.colors.muted};
      }
    }
  }
`

const AssetNotificationContentStyled = styled('div')`
  display: grid;
  gap: 0.5rem;
  grid-column: span 1;
  color: ${({ theme }) => theme.colors.atomic.textMain};
  > .asset-name {
    &.router-link-exact-active {
      color: ${p => p.theme.colors.primary};
      &:hover {
        color: ${p => p.theme.colors.primaryLighter};
      }
    }
  }
  > footer {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    color: ${({ theme }) => theme.colors.muted};
    > .ago {
      color: ${({ theme }) => theme.colors.atomic.notificationTime};
      font-size: 12px;
      align-self: center;
    }
  }
`

export default {
  inject: ['uiSettings'],
  mixins: [permissionsMixin, localesMixin],
  directives: {
    tooltip: VTooltip,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetNotificationWrapperStyled,
    AssetNotificationStyled,
    AssetNotificationContentStyled,
    TalpaIcon,
    CircleIcon,
    HelpCircleIcon,
  },
  computed: {
    to() {
      try {
        const assetNotificationEvent = this.notification?.assetNotificationEvent
        if (
          assetNotificationEvent.notificationEventType === 'HIGH_NUMBER_OF_PASSES' ||
          assetNotificationEvent.notificationEventType === 'OVERLOAD_EVENT' ||
          assetNotificationEvent.notificationEventType === 'OVERSPEED_EVENT'
        ) {
          return ''
        }

        if (!this.hasMaintenanceAppPermission && !this.hasSDAppPermission) {
          return {
            name: 'AssetLog',
            params: { id: this.notification.assetNotificationEvent.asset.id },
          }
        }
        const issues = assetNotificationEvent?.assetEvents
        if (issues.length === 1) {
          return {
            name: this.routeValidator,
            params: { id: this.notification.assetNotificationEvent.asset.id },
            query: { issue: issues[0]?.id },
          }
        }

        const interval = issues.reduce((acc, item) => {
          const issueInterval = Interval.fromISO(`${item.start}/${item.start}`)
          if (acc === null) {
            return issueInterval
          }
          return acc.union(issueInterval)
        }, null)

        const lastIssue = issues.find(f => DateTime.fromISO(f.start).toUTC().toISO() === interval.end.toUTC().toISO())

        if (issues.every(issue => issue.isActive)) {
          return {
            name: this.routeValidator,
            params: { id: this.notification.assetNotificationEvent.asset.id },
            query: {
              issue: lastIssue?.id,
              cardId: 'active',
            },
          }
        }

        const last24Hours = getLast24HoursInterval()
        if (last24Hours.engulfs(interval)) {
          return {
            name: this.routeValidator,
            params: { id: this.notification.assetNotificationEvent.asset.id },
            query: {
              issue: lastIssue?.id,
              cardId: 'last24Hours',
            },
          }
        }

        return {
          name: this.routeValidator,
          params: { id: this.notification.assetNotificationEvent.asset.id },
          query: {
            issue: lastIssue?.id,
            cardId: 'last7Days',
          },
        }
      } catch (err) {
        return {
          name: this.routeValidator,
          params: { id: this.notification.assetNotificationEvent.asset.id },
          query: {},
        }
      }
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
    tooltip() {
      return {
        content: this.notification.isUnread ? this.$t('notifications.tooltip.markRead') : this.$t('notifications.tooltip.markUnread'),
        placement: 'left',
        classes: 'notification',
        trigger: 'hover',
      }
    },
    ago() {
      return DateTime.fromISO(this.notification?.assetNotificationEvent?.updatedAt).setLocale(this.locale).toRelative()
    },
    iconName() {
      return get(this.notification?.assetNotificationEvent?.asset, 'type.name', '').split(' ').join('')
    },
    hasMaintenanceAppPermission() {
      return Boolean(this.hasMaintenanceAppV2Permission)
    },
    hasSDAppPermission() {
      return Boolean(this.hasServiceDealerAppPermission)
    },
    routeValidator() {
      let routeName = 'AssetHealthInsight'
      if (this.hasMaintenanceAppPermission && this.hasSDAppPermission) {
        const selectedApp = get(this.uiSettings, 'defaultApp', 'MAINTENANCE_APP')
        routeName = selectedApp === 'SD_APP' ? 'ServiceDealerAssetHealthInsight' : 'AssetHealthInsight'
      } else if (!this.hasMaintenanceAppPermission && this.hasSDAppPermission) {
        routeName = 'ServiceDealerAssetHealthInsight'
      }
      return routeName
    },
  },
  methods: {
    handleClick() {
      if (this.notification.isUnread) {
        this.$emit('toggle-is-unread')
      }
      this.$emit('closed')
    },
    overSpeedEventMessage() {
      const overSpeedEvent = this.notification.assetNotificationEvent.assetEvents[0]
      DateTime.fromISO(this.dt).setLocale(this.locale)
      const start =
        DateTime.fromISO(overSpeedEvent.start).setLocale(this.locale).toLocaleString(DateTime.DATE_SHORT) +
        ' ' +
        DateTime.fromISO(overSpeedEvent.start).setLocale(this.locale).toLocaleString(DateTime.TIME_WITH_SECONDS)
      const end =
        DateTime.fromISO(overSpeedEvent.end).setLocale(this.locale).toLocaleString(DateTime.DATE_SHORT) +
        ' ' +
        DateTime.fromISO(overSpeedEvent.end).setLocale(this.locale).toLocaleString(DateTime.TIME_WITH_SECONDS)

      const unitSI = 'm/s'
      const unitUIMetric = 'km/h'
      const unitUIImperial = 'mi/h'
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return this.$t('notifications.overspeedIssue', {
        geofence: overSpeedEvent.geofence,
        speed: units(
          overSpeedEvent.velocityMax,
          unitSI,
          unitUI,
          2,
          true,
          false,
          false,
          this.thousandsSeperator,
          this.decimalSeperator,
          true,
        ),
        duration: overSpeedEvent.duration,
        startTime: start,
        endTime: end,
      })
    },
  },
}
</script>
