var render = function () {
  var _vm$ruleToRemove, _vm$ruleToRemove$asse;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceGoalRulesStyled', [_c('RuleListStyled', [_c('RulesListMolecule', {
    attrs: {
      "performanceGoalRules": _vm.performanceGoalRules
    },
    on: {
      "removeRule": _vm.initiateRuleRemoval,
      "updateRule": _vm.updateRule
    }
  }), _c('AddRuleStyled', [_c('ButtonSolid', {
    on: {
      "click": _vm.addRule
    }
  }, [_c('PlusIcon', {
    staticClass: "icon"
  }), _vm._v(" " + _vm._s(_vm.$tc('planning.addRule')) + " ")], 1)], 1)], 1), _vm.isCreatingNewRule ? _c('RuleCreateMolecule', {
    attrs: {
      "rule": _vm.ruleToUpdate,
      "mode": _vm.mode
    },
    on: {
      "ruleCreated": _vm.appendRule,
      "cancel": function cancel($event) {
        _vm.isCreatingNewRule = false;
      }
    }
  }) : _vm._e(), _vm.showRemoveRuleMessage ? _c('RemoveRuleStyled', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('planning.messages.removeRule', {
        x: (_vm$ruleToRemove = _vm.ruleToRemove) === null || _vm$ruleToRemove === void 0 ? void 0 : (_vm$ruleToRemove$asse = _vm$ruleToRemove.assetDimension) === null || _vm$ruleToRemove$asse === void 0 ? void 0 : _vm$ruleToRemove$asse.name
      }))
    }
  }), _c('ActionIconAtom', {
    attrs: {
      "size": '24px'
    }
  }, [_c('template', {
    slot: "icon"
  }, [_c('XIcon', {
    on: {
      "click": _vm.cancelRuleRemoval
    }
  })], 1)], 2), _c('ActionIconAtom', {
    attrs: {
      "size": '24px'
    }
  }, [_c('template', {
    slot: "icon"
  }, [_c('CheckIcon', {
    on: {
      "click": _vm.removeRule
    }
  })], 1)], 2)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }