var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "modal",
    attrs: {
      "hasPadding": false,
      "errors": _vm.errors
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.title) + " ")];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('TabsMolecule', {
          attrs: {
            "activeIndex": _vm.activeIndex,
            "tabs": _vm.tabs
          },
          on: {
            "update:activeIndex": function updateActiveIndex($event) {
              _vm.activeIndex = $event;
            },
            "update:active-index": function updateActiveIndex($event) {
              _vm.activeIndex = $event;
            }
          }
        }, [_c('template', {
          slot: "main"
        }, [_vm.activeIndex === 0 ? _c('ScheduleOverviewMolecule', {
          attrs: {
            "validations": _vm.$v,
            "assetsByRecipient": _vm.assetsByRecipient,
            "schedule": _vm.scheduleLocal
          },
          on: {
            "setScheduleType": function setScheduleType($event) {
              return _vm.setScheduleType($event);
            },
            "setCreateShiftReport": function setCreateShiftReport($event) {
              _vm.scheduleLocal.createShiftReport = $event;
            },
            "setAssignedAssets": function setAssignedAssets($event) {
              _vm.scheduleLocal.assignedAssets = $event;
            }
          }
        }) : _vm._e(), _vm.activeIndex === 1 ? _c('ScheduleTimeSettingsMolecule', {
          attrs: {
            "schedule": _vm.scheduleLocal
          },
          on: {
            "setScheduleStartDateTime": function setScheduleStartDateTime($event) {
              _vm.scheduleLocal.scheduleStartDateTime = $event;
            },
            "setStartTime": function setStartTime($event) {
              _vm.scheduleLocal.startTime = $event;
            },
            "setEndTime": function setEndTime($event) {
              _vm.scheduleLocal.endTime = $event;
            },
            "setFrequency": function setFrequency($event) {
              _vm.scheduleLocal.frequency = $event;
            },
            "setTimezone": function setTimezone($event) {
              _vm.scheduleLocal.timezone = $event;
            },
            "setInterval": function setInterval($event) {
              _vm.scheduleLocal.interval = $event;
            },
            "setByDayOfWeek": function setByDayOfWeek($event) {
              _vm.scheduleLocal.byDayOfWeek = $event;
            }
          }
        }) : _vm._e(), _vm.activeIndex === 2 ? _c('ScheduleGoalSettingsMolecule', {
          attrs: {
            "schedule": _vm.scheduleLocal,
            "assetsByRecipient": _vm.assetsByRecipient
          },
          on: {
            "setPerformanceGoals": function setPerformanceGoals($event) {
              _vm.scheduleLocal.performanceGoals = $event;
            }
          }
        }) : _vm._e()], 1)], 2)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('ActionsStyled', [_c('ButtonBorderedStyled', {
          on: {
            "click": function click($event) {
              return _vm.$emit('cancel');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.cancel')) + " ")]), false ? _c('ButtonSolidStyled', {
          attrs: {
            "disabled": !_vm.canRestore
          },
          on: {
            "click": _vm.restore
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.restore')) + " ")]) : _vm._e(), _c('ButtonSolidStyled', {
          attrs: {
            "disabled": !_vm.canConfirm
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('confirm', _vm.scheduleLocal);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }