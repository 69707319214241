<template>
  <AddSubsidiaryAssetsToSiteStyled @click="activateAddSubsidiaryAssetsToSiteOverlay">
    <PlusCircleIcon :size="'1.5x'" /> Add User-groups
  </AddSubsidiaryAssetsToSiteStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { PlusCircleIcon } from 'vue-feather-icons'
import { flexCenter } from '@styles/mixins'

const AddSubsidiaryAssetsToSiteStyled = styled('div')`
  font-size: 14px;
  cursor: pointer;
  ${flexCenter};
  height: 40px;
  background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  border-radius: 0.5rem;
  padding: 10px;
  gap: 0.5rem;
  &:hover {
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
  }
`

export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddSubsidiaryAssetsToSiteStyled,
    PlusCircleIcon,
  },
  methods: {
    activateAddSubsidiaryAssetsToSiteOverlay() {
      this.$root.$emit('activateOverlay', 'AddSubsidiaryAssetsToSiteOverlay', { site: this.site }, () => this.$emit('refetch'))
    },
  },
}
</script>
