<template>
  <TextTagStyled>
    {{ text }}
  </TextTagStyled>
</template>
<script>
const TextTagStyled = styled('div')`
  padding: 0.25rem 0.5rem;
  border-radius: 24px;
  background: ${p => p.theme.colors.circleIconBG};
  width: max-content;
`
import { styled } from '@egoist/vue-emotion'

export default {
  components: {
    TextTagStyled,
  },
  props: {
    text: {
      type: String,
    },
  },
}
</script>
