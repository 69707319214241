// import get from 'lodash/get'
// import gql from 'graphql-tag'

const ScrolledMixin = {
  data() {
    return {
      mainHasScrolled: false,
      scrollThreshold: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const main = this.$refs.main
      if (main) {
        this.checkMainHasScrolled()
        main.addEventListener('scroll', this.checkMainHasScrolled)
      }
    })
  },
  updated() {
    this.$nextTick(() => {
      const main = this.$refs.main
      if (main) {
        this.checkMainHasScrolled()
        main.addEventListener('scroll', this.checkMainHasScrolled)
      }
    })
  },
  destroyed() {
    const main = this.$refs.main
    if (main) {
      main.removeEventListener('scroll', this.checkMainHasScrolled)
    }
  },
  methods: {
    checkMainHasScrolled() {
      this.mainHasScrolled = this.$refs.main.scrollTop > this.scrollThreshold
    },
  },
}

export default ScrolledMixin
