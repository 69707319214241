<template>
  <transition name="fade">
    <OverlayStyled v-if="isActive" :shouldHover="shouldHover" @mouseover="mouseover" @mouseleave="mouseleave" @click="closeFromOutside">
      <component :is="overlayComponent" :args="args" @close="close" />
      <CloseOverlayStyled @click="close" :class="{ hover: shouldHover }">
        <XIcon />
      </CloseOverlayStyled>
    </OverlayStyled>
  </transition>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { XIcon } from 'vue-feather-icons'

import { flexCenter } from '@styles/mixins'

import LoadingOverlay from './Overlays/LoadingOverlay'
import ErrorOverlay from './Overlays/ErrorOverlay'

const OverlayStyled = styled('div')`
  ${flexCenter}
  cursor: ${p => (p.shouldHover ? 'pointer' : 'default')};
  align-items: flex-start;
  position: fixed;
  left: 0;
  top: 51px;
  width: 100%;
  height: calc(100% - 50px);
  background: ${p => (p.theme.isDark ? chroma(p.theme.colors.black).alpha(0.8).css() : chroma(p.theme.colors.white).alpha(0.7).css())};
  backdrop-filter: blur(7px);
  transition: opacity 0.25s ease;
  z-index: 110;
`

const CloseOverlayStyled = styled('button')`
  ${flexCenter}
  width: 50px;
  height: 50px;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  color: ${p => p.theme.colors.primary};
  position: absolute;
  top: 0px;
  right: 0px;

  &:hover,
  &.hover {
    color: ${p => p.theme.colors.primaryLighter};
  }
`

export default {
  props: {
    type: {
      type: String,
    },
    args: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
  components: {
    OverlayStyled,
    CloseOverlayStyled,
    XIcon,
    LoadingOverlay,
    ErrorOverlay,
  },
  data() {
    return {
      overlayComponent: null,
      shouldHover: false,
    }
  },
  computed: {
    isActive() {
      return !!this.type
    },
  },
  watch: {
    isActive: {
      handler(b) {
        if (b) {
          window.addEventListener('keyup', this.handleKeyUp)
        } else {
          window.removeEventListener('keyup', this.handleKeyUp)
        }
      },
    },
    type: {
      handler(type) {
        this.overlayComponent = () => {
          if (!type) {
            return
          }
          const path = `./Overlays/${type}.vue`
          return {
            component: import(path + ''),
            loading: LoadingOverlay,
            error: ErrorOverlay,
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('closeOverlay')
    },
    mouseover(e) {
      const p = e.path || (e.composedPath && e.composedPath())
      if (p[0] === this.$el) {
        this.shouldHover = true
      } else {
        this.shouldHover = false
      }
    },
    mouseleave() {
      this.shouldHover = false
    },
    closeFromOutside(e) {
      const p = e.path || (e.composedPath && e.composedPath())
      if (p[0] === this.$el) {
        this.close()
      }
    },
    handleKeyUp(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    },
  },
}
</script>
