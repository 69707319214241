<template>
  <ThresholdValueStyled>
    <div class="type">{{ $t(`planning.${thresholdType.toLowerCase()}`) }}</div>
    <div class="value">
      <div>{{ value }}</div>
      <div v-if="unit">{{ unit }}</div>
    </div>
  </ThresholdValueStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const ThresholdValueStyled = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.atomic.iconBG};
  color: ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.7).css()};
  width: max-content;
  align-items: center;
  font-size: 14px;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  width: auto;
  .type {
    text-transform: capitalize;
  }
  .value {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    background: ${p => chroma(p.theme.colors.atomic.healthStatusIconBG).css()};
    align-items: center;
    padding: 0 0.2rem;
    font-size: 12px;
  }
`

export default {
  components: {
    ThresholdValueStyled,
  },
  props: {
    thresholdType: {
      type: String,
    },
    value: {
      type: Number,
    },
    unit: {
      type: String,
    },
  },
}
</script>
