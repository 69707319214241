var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('LanguageSelectorStyled', _vm._l(_vm.languages, function (lang) {
    return _c('LanguageStyled', {
      key: lang.shortLang,
      class: {
        active: lang.isActive
      },
      attrs: {
        "isActive": lang.isActive
      },
      on: {
        "click": function click($event) {
          return _vm.setLocale(lang);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$t('languages.' + lang.shortLang)))]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [lang.isActive ? _c('CheckIcon') : _vm._e()], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }