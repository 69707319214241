export default {
  // Saint Gobain
  'INTERIM-STORAGE': [255, 138, 128, 140],
  DISPATCH: [140, 158, 255, 140],
  MINERAL: [234, 128, 252, 140],
  WASTE: [167, 255, 235, 140],
  // Pescher
  BR_OB: [255, 138, 128, 140],
  BR: [140, 158, 255, 140],
  OB: [234, 128, 252, 140],
  ROCK: [167, 255, 235, 140],
  WET_WASTE: [204, 255, 144, 140],
  UNKNOWN: [255, 255, 141, 140],
}
