var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CopyToClipboardAtom', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.tooltipContent,
        classes: 'notification'
      },
      expression: "{ content: tooltipContent, classes: 'notification' }"
    }],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.copyToClipboard.apply(null, arguments);
      }
    }
  }, [_c('CopyIcon')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }