var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsWrapperStyled', [_c('VPopover', {
    attrs: {
      "placement": 'bottom-start',
      "popoverClass": 'notificationPopOver',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions,
      "container": '#app-main'
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('NotificationsListOrganism', {
          attrs: {
            "isActive": _vm.isActive
          },
          on: {
            "close-pop-over": function closePopOver($event) {
              return _vm.closePopOver();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('NotificationsBellOrganism', {
    attrs: {
      "isActive": _vm.isActive
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }