var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WizardStyled', [_c('WizardHeader', [_c('span', [_vm._v(_vm._s(_vm.title))])]), _c('WizardStepsStyled', _vm._l(_vm.steps, function (step) {
    return _c('WizardStepStyled', {
      key: step.id,
      class: {
        active: step.isActive,
        disabled: !step.isEnabled
      },
      attrs: {
        "isEnabled": step.isEnabled,
        "isCompleted": step.isCompleted,
        "isRequired": step.isRequired,
        "isActive": step.isActive
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('activateStep', step.id);
        }
      }
    }, [_c('StepLabel', [_vm._v(_vm._s(_vm._f("capitalize")(step.label)))]), _c('CircleStyled', {
      staticClass: "circle"
    }, [_vm._v(_vm._s(step.id))])], 1);
  }), 1), _c('WizardCurrentStepStyled', [_vm._t("default")], 2), _c('WizardActionsStyled', [_c('div', [_c('ButtonRound', {
    attrs: {
      "round": true,
      "disabled": !_vm.prevStepEnabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('activatePrevStep');
      }
    }
  }, [_c('ChevronLeftIcon')], 1), _c('ButtonRound', {
    attrs: {
      "round": true,
      "disabled": !_vm.nextStepEnabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('activateNextStep');
      }
    }
  }, [_c('ChevronRightIcon')], 1)], 1), _c('div', [_c('ButtonRound', {
    staticClass: "confirm",
    attrs: {
      "round": true,
      "disabled": !_vm.canConfirm
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('confirm');
      }
    }
  }, [_c('CheckIcon')], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }