var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RuleCreateStyled', [_c('TitleWithBorderSimpleAtom', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function fn() {
        return [_c('CloseButtonAtom', {
          on: {
            "close": function close($event) {
              return _vm.$emit('cancel');
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', [_c('CustomMultiSelect', {
    staticClass: "multi-selector",
    attrs: {
      "track-by": "id",
      "openDirection": "bottom",
      "value": _vm.selectedKPI,
      "options": _vm.assetDimensionsMapped,
      "shouldNotResetSelectedOption": true,
      "disabled": _vm.assetDimensions.length === 0,
      "closeOnSelect": true,
      "searchable": true,
      "multiple": false,
      "placeholder": _vm.$t('planning.selectAssetDimension'),
      "optionsLimit": 6,
      "applyFilter": false,
      "internalSearch": true,
      "customNoOptionsSlot": true,
      "maxHeight": 500,
      "loading": _vm.$apollo.queries.assetDimensions.loading
    },
    on: {
      "select": _vm.selectKPI
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabelAtom', {
          attrs: {
            "label": "".concat(_vm.$tc('assetDimension', 1), ":")
          }
        })];
      },
      proxy: true
    }, {
      key: "noOptions",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t('messages.noData')))])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "value row"
  }, [_c('div', [_c('CustomMultiSelect', {
    staticClass: "multi-selector",
    attrs: {
      "track-by": "id",
      "openDirection": "bottom",
      "value": _vm.selectedRuleType,
      "options": _vm.ruleTypes,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true,
      "searchable": false,
      "multiple": false,
      "placeholder": _vm.$t('planning.selectRuleType'),
      "maxHeight": 256,
      "optionsLimit": 6,
      "applyFilter": false,
      "internalSearch": true,
      "customNoOptionsSlot": true
    },
    on: {
      "select": function select($event) {
        _vm.selectedRuleType = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabelAtom', {
          attrs: {
            "label": "".concat(_vm.$t('planning.ruleType'), ":")
          }
        })];
      },
      proxy: true
    }, {
      key: "noOptions",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t('messages.noData')))])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "value-input"
  }, [_vm.selectedRuleType ? [_vm.selectedRuleType.id === 'RANGE' ? _c('MinMaxInputAtom', {
    attrs: {
      "min": _vm.min,
      "max": _vm.max,
      "unit": _vm.unitUI
    },
    on: {
      "update:min": function updateMin($event) {
        _vm.min = $event;
      },
      "update:max": function updateMax($event) {
        _vm.max = $event;
      }
    }
  }) : _c('ThresholdInputAtom', {
    attrs: {
      "threshold": _vm.threshold,
      "unit": _vm.unitUI
    },
    on: {
      "update:threshold": function updateThreshold($event) {
        _vm.threshold = $event;
      }
    }
  })] : _vm._e()], 2)]), _c('ActionStyled', [_c('ButtonSolid', {
    attrs: {
      "disabled": _vm.canAdd
    },
    on: {
      "click": _vm.createRule
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }