<template>
  <AnalyticsSettingsStyled>
    <AnalyticsSettingStyled
      v-for="ts in analyticsSettings"
      :key="ts.id"
      :isActive="ts.isActive"
      :class="{ active: ts.isActive }"
      @click="setAnlayticsSetting(ts.id)"
    >
      <span>{{ ts.name }}</span>
      <transition name="fade">
        <CheckIcon v-if="ts.isActive" />
      </transition>
    </AnalyticsSettingStyled>
  </AnalyticsSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CheckIcon } from 'vue-feather-icons'
import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'

const AnalyticsSettingsStyled = styled('div')`
  ${flexColumns}
`
const AnalyticsSettingStyled = styled('div')`
  ${flexCenter}
  ${props => buttonReset(props)}
  position: relative;
  margin-top: 0.75rem;
  padding: 0.5rem 2rem;
  height: 2rem;
  align-items: flex-start;
  .feather {
    position: absolute;
    top: 0.25rem;
    right: 0;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    AnalyticsSettingsStyled,
    AnalyticsSettingStyled,
    CheckIcon,
  },
  data() {
    return {
      activeSetting: this.$posthog.has_opted_out_capturing() ? 'OPT_OUT' : 'OPT_IN',
    }
  },
  inject: ['uiSettings'],
  computed: {
    analyticsSettings() {
      return ['OPT_IN', 'OPT_OUT'].map(id => ({
        id,
        name: this.$t(`uiSettings.analytics.${id}`),
        isActive: this.activeSetting === id,
      }))
    },
  },
  methods: {
    async setAnlayticsSetting(id) {
      this.activeSetting = id
      if (id === 'OPT_IN') {
        this.$posthog.opt_in_capturing()
      } else {
        this.$posthog.opt_out_capturing()
      }
    },
  },
}
</script>
