<template>
  <NotificationsBellMolecule :unreadCount="myUnreadNotificationsCount" :isActive="isActive" />
</template>

<script>
import { DateTime } from 'luxon'

import permissionsMixin from '@/mixins/permissions'
import NotificationsBellMolecule from '../../Molecules/Notifications/NotificationsBellMolecule'

import MY_NOTIFICATIONS_COUNT_QUERY from '#/graphql/notifications/myNotificationsCountQuery.gql'
import MY_UNREAD_USER_NOTIFICATIONS_COUNT_SUBSCRIPTION from '#/graphql/notifications/myUnreadUserNotificationsCountSubscription.gql'

export default {
  mixins: [permissionsMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    NotificationsBellMolecule,
  },
  data() {
    return {
      myUnreadNotificationsCount: 0,
    }
  },
  apollo: {
    myUnreadNotificationsCount: {
      query: MY_NOTIFICATIONS_COUNT_QUERY,
      skip() {
        return !this.hasNotificationsPermission
      },
      variables() {
        return {
          where: {
            isUnread: {
              equals: true,
            },
            assetNotificationEvent: {
              is: {
                updatedAt: {
                  gte: DateTime.now().minus({ day: 6 }).startOf('day').toUTC().toISO(),
                },
              },
            },
          },
        }
      },
      subscribeToMore: {
        document: MY_UNREAD_USER_NOTIFICATIONS_COUNT_SUBSCRIPTION,
        variables() {
          return {
            where: {
              isUnread: {
                equals: true,
              },
              assetNotificationEvent: {
                is: {
                  updatedAt: {
                    gte: DateTime.now().minus({ day: 6 }).startOf('day').toUTC().toISO(),
                  },
                },
              },
            },
          }
        },
        error(err) {
          if (err?.message?.includes('ECONNREFUSED')) {
            return false
          } else {
            return true
          }
        },
        updateQuery(_, { subscriptionData: { data } }) {
          return {
            myNotificationsCount: data.myUnreadUserNotificationsCount,
          }
        },
      },
      update: data => data.myNotificationsCount,
    },
  },
}
</script>
