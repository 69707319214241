var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isMarker ? _c('MarkerIconStyled', [_c('div', {
    staticClass: "circle"
  }, [_vm.hasIcon ? _c(_vm.icon, {
    tag: "component",
    staticClass: "icon"
  }) : _vm._e()], 1), _c('svg', {
    staticClass: "triangle",
    attrs: {
      "viewBox": "0 0 400 450"
    }
  }, [_c('path', {
    attrs: {
      "d": "M 0 0 q 200 100 400 0 L 200 225 Z"
    }
  })])]) : _c('TalpaIconStyled', [_vm.hasIcon ? _c(_vm.icon, {
    tag: "component",
    class: _vm.isCustomColor ? 'customColor' : 'icon'
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }