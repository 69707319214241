<template>
  <NavExpanderStyled>
    <button @click.stop="$emit('toggleIsMenuExpanded', !isMenuExpanded)">
      <template v-if="customIcon">
        <slot name="collapseIcon" v-if="isMenuExpanded" />
        <slot name="ExpandIcon" v-else />
      </template>
      <template v-else>
        <ChevronsLeftIcon v-if="isMenuExpanded" />
        <ChevronsRightIcon v-else />
      </template>
    </button>
  </NavExpanderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { flexCenter, buttonReset } from '@styles/mixins'
import { ChevronsLeftIcon, ChevronsRightIcon } from 'vue-feather-icons'

const NavExpanderStyled = styled('div')`
  display: flex;
  > button {
    ${flexCenter}
    ${buttonReset}
    justify-content: flex-end;
    flex-grow: 1;
    outline: none;
    border: none;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    color: ${p => p.theme.colors.primary};
    min-width: 2rem;
    height: 2rem;
    transition: all 0.25s ease;
    > span {
      padding-left: 0.5rem;
      text-transform: capitalize;
    }
    &.active {
      color: ${p => p.theme.colors.primary};
      background: ${p => chroma(p.theme.colors.solidBG).alpha(0.7).css()};
    }
    &:hover {
      color: ${p => p.theme.colors.primaryLighter};
      .feather {
        opacity: 1;
      }
    }
  }
`

export default {
  props: {
    customIcon: {
      type: Boolean,
      default: false,
    },
    isMenuExpanded: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    NavExpanderStyled,
    ChevronsLeftIcon,
    ChevronsRightIcon,
  },
}
</script>
