<template>
  <ActionIconStyled :size="size">
    <slot name="icon" />
  </ActionIconStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const ActionIconStyled = styled('div')`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  height: ${({ size }) => `${size}`};
  width: ${({ size }) => `${size}`};
  > svg {
    width: ${({ size }) => `${size}`};
    height: ${({ size }) => `${size}`};
  }
  &:hover {
    color: ${props => props.theme.colors.primaryActive};
  }
`
export default {
  components: {
    ActionIconStyled,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: '15px',
    },
  },
}
</script>
