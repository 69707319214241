<template>
  <a class="main-navigation-item" :class="{ active: isActive }" v-if="hasSubMenu">
    <MainNavigationItemIcon :icon="icon" />
    <span> {{ $t(`navigation.cockpit.${routeMetaName}`) }} </span>
    <CountCircle v-if="count !== undefined" class="count" :count="count" />
  </a>
  <router-link
    v-else
    class="main-navigation-item"
    :class="{ active: isActive }"
    :to="routeTo"
    :exact="routeMetaName === 'home'"
    :data-testid="`menu-${routeMetaName}`"
  >
    <MainNavigationItemIcon :icon="icon" />
    <span> {{ $t(`navigation.cockpit.${routeMetaName}`) }} </span>
    <CountCircle v-if="count !== undefined" class="count" :count="count" />
  </router-link>
</template>

<script>
import { CountCircle } from '@common/components'

import MainNavigationItemIcon from './MainNavigationItemIcon.vue'

export default {
  props: {
    routeMetaName: {
      type: String,
      required: true,
    },
    routeTo: {
      type: Object,
      required: true,
    },
    hasSubMenu: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    count: {
      type: Number,
    },
    hasActiveSubMenuItem: {
      type: Boolean,
      default: false,
    },
    hasActiveParent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CountCircle,
    MainNavigationItemIcon,
  },
  computed: {
    isActive() {
      return this.hasActiveParent || this.hasActiveSubMenuItem
    },
  },
}
</script>
