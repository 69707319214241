var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TopBarWrapper', [_c('Logo'), _c('HeaderStyled', {
    staticClass: "item header"
  }, [_c('router-view', {
    attrs: {
      "name": "header"
    }
  }), _vm.hasErrorInspectorPermission ? _c('ErrorInspector') : _vm._e()], 1), _c('Settings', {
    staticClass: "item settings",
    attrs: {
      "isActive": _vm.overlayType === 'SettingsOverlay'
    },
    on: {
      "activateOverlay": function activateOverlay(overlay) {
        return _vm.$emit('activateOverlay', overlay);
      }
    }
  }), _c('Notifications', {
    staticClass: "item notifications"
  }), _c('Profile', {
    staticClass: "item profile"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }