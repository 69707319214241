<template>
  <HeaderStyled>
    <div>{{ $t(`navigation.cockpit.${mainRouteName}`) }}</div>
    <template v-if="showBreadcrumb">
      <ChevronRightIcon class="icon" />
      <div>
        <LoadingDots v-if="$apollo.loading" />
        <span v-else-if="asset">{{ asset.name }}</span>
      </div>
      <ChevronRightIcon class="icon" />
      <div>{{ $t(`navigation.subRoutes.${routeName}`) }}</div>
    </template>
  </HeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import { ChevronRightIcon } from 'vue-feather-icons'
import { LoadingDots } from '@common/components'

import ASSET_NAME_QUERY from '#/graphql/operations/asset/assetName.gql'

const HeaderStyled = styled('header')`
  ${flexCenter}
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  height: calc(50px - 1rem);
  font-size: 14px;
  background: ${({ theme }) => theme.colors.solidBG};
  color: ${({ theme }) => theme.colors.navFontNormal};
  .icon {
    color: ${p => p.theme.colors.primary};
  }
`

export default {
  props: {
    adops: {
      type: String,
      required: false,
    },
  },
  components: {
    HeaderStyled,
    ChevronRightIcon,
    LoadingDots,
  },
  data() {
    return {
      asset: null,
      isMobile: false,
    }
  },
  computed: {
    assetId() {
      return this.$route?.params?.id
    },
    routeName() {
      return this.$route?.name
    },
    breadcrumbName() {
      return this.$route?.meta?.breadcrumbName
    },
    mainRouteName() {
      return this.breadcrumbName ?? this.$route?.meta?.name
    },
    showBreadcrumb() {
      return !this.isMobile && this.breadcrumbName && this.assetId
    },
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth
      this.isMobile = this.screenWidth < 1025
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  apollo: {
    asset: {
      query: ASSET_NAME_QUERY,
      variables() {
        return {
          id: this.assetId,
        }
      },
      skip() {
        return !this.assetId
      },
    },
  },
}
</script>
