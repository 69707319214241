var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InputFieldStyled', {
    attrs: {
      "labelWidth": _vm.labelWidth
    }
  }, [_vm._t("input"), _c('div', {
    staticClass: "label"
  }, [_vm._t("label")], 2), _c('div', {
    staticClass: "errors"
  }, [_vm._t("errors")], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }