<template>
  <Modal class="modal" :hasPadding="false" :errors="errors">
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:main>
      <TabsMolecule :activeIndex.sync="activeIndex" :tabs="tabs">
        <template slot="main">
          <ScheduleOverviewMolecule
            v-if="activeIndex === 0"
            :validations="$v"
            :assetsByRecipient="assetsByRecipient"
            :schedule="scheduleLocal"
            @setScheduleType="setScheduleType($event)"
            @setCreateShiftReport="scheduleLocal.createShiftReport = $event"
            @setAssignedAssets="scheduleLocal.assignedAssets = $event"
          />
          <ScheduleTimeSettingsMolecule
            v-if="activeIndex === 1"
            :schedule="scheduleLocal"
            @setScheduleStartDateTime="scheduleLocal.scheduleStartDateTime = $event"
            @setStartTime="scheduleLocal.startTime = $event"
            @setEndTime="scheduleLocal.endTime = $event"
            @setFrequency="scheduleLocal.frequency = $event"
            @setTimezone="scheduleLocal.timezone = $event"
            @setInterval="scheduleLocal.interval = $event"
            @setByDayOfWeek="scheduleLocal.byDayOfWeek = $event"
          />
          <ScheduleGoalSettingsMolecule
            v-if="activeIndex === 2"
            :schedule="scheduleLocal"
            :assetsByRecipient="assetsByRecipient"
            @setPerformanceGoals="scheduleLocal.performanceGoals = $event"
          />
        </template>
      </TabsMolecule>
    </template>
    <template v-slot:footer>
      <ActionsStyled>
        <ButtonBorderedStyled @click="$emit('cancel')">
          {{ $t('actions.cancel') }}
        </ButtonBorderedStyled>
        <ButtonSolidStyled v-if="false" @click="restore" :disabled="!canRestore">
          {{ $t('actions.restore') }}
        </ButtonSolidStyled>
        <ButtonSolidStyled @click="$emit('confirm', scheduleLocal)" :disabled="!canConfirm">
          {{ $t('actions.ok') }}
        </ButtonSolidStyled>
      </ActionsStyled>
    </template>
  </Modal>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { validationMixin } from 'vuelidate'
import { DateTime, Duration } from 'luxon'
import { required, minLength } from 'vuelidate/lib/validators'

import { Modal } from '@common/components'

import { ButtonBordered as ButtonBorderedStyled, ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import ScheduleOverviewMolecule from './Molecules/ScheduleOverviewMolecule.vue'
import ScheduleTimeSettingsMolecule from './Molecules/ScheduleTimeSettingsMolecule'
import ScheduleGoalSettingsMolecule from './Molecules/ScheduleGoalSettingsMolecule'
import TabsMolecule from '../Atomic/Molecules/TabsMolecule'

const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`

export default {
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    schedule: {
      type: Object,
    },
    assetsByRecipient: {
      type: Array,
      required: true,
    },
  },
  components: {
    Modal,
    ButtonBorderedStyled,
    ButtonSolidStyled,
    ActionsStyled,
    ScheduleOverviewMolecule,
    ScheduleTimeSettingsMolecule,
    ScheduleGoalSettingsMolecule,
    TabsMolecule,
  },
  data() {
    return {
      activeIndex: 0,
      errors: [],
      scheduleLocal: {
        name: '',
        type: null,
        assignedAssets: [],
        scheduleStartDateTime: null,
        startTime: null,
        endTime: null,
        timezone: 'UTC',
        interval: 1,
        frequency: 'DAILY',
        byDayOfWeek: ['MO', 'TU', 'WE', 'TH', 'FR'],
        createShiftReport: false,
        performanceGoals: [],
      },
    }
  },
  validations() {
    return {
      scheduleLocal: {
        name: {
          required,
          minLength: minLength(3),
        },
        type: {
          required,
        },
        scheduleStartDateTime: {
          required,
        },
        startTime: {
          required,
        },
        endTime: {
          required,
        },
        frequency: {
          required,
        },
        timezone: {
          required,
        },
        interval: {
          required,
        },
        byDayOfWeek: {
          required,
          minLength: minLength(1),
        },
      },
    }
  },
  computed: {
    tabs() {
      return [
        {
          name: 'Overview',
          label: this.$tc('overview', 1),
        },
        {
          name: 'TimeSettings',
          label: this.$tc('planning.timeSettings'),
        },
        {
          name: 'Goals',
          label: this.$tc('planning.goals'),
        },
      ]
    },
    canConfirm() {
      return !this.$v.$invalid
    },
    canRestore() {
      return this.$v.$dirty || this.$v.$anyDirty
    },
  },
  watch: {
    schedule: {
      handler(schedule) {
        if (schedule) {
          const scheduleRecurrence = schedule.recurrences[0]
          this.scheduleLocal.name = schedule.name
          this.scheduleLocal.type = schedule.type
          this.scheduleLocal.timezone = schedule.timezone
          const scheduleStartOn = DateTime.fromISO(scheduleRecurrence.start).setZone(scheduleRecurrence.timezone)
          const s = {
            hour: scheduleRecurrence?.byHourOfDay[0],
            minute: scheduleRecurrence?.byMinuteOfHour[0],
            second: scheduleRecurrence?.bySecondOfMinute[0],
          }
          const start = scheduleStartOn.setZone(schedule.timezone).set(s)
          this.scheduleLocal.scheduleStartDateTime = scheduleStartOn.toJSDate()
          const duration = Duration.fromMillis(scheduleRecurrence.duration)
          const end = start.plus(duration)
          this.scheduleLocal.startTime = start.toFormat('HH:mm:ss')
          this.scheduleLocal.endTime = end.toFormat('HH:mm:ss')
          this.scheduleLocal.frequency = scheduleRecurrence.frequency
          this.scheduleLocal.byDayOfWeek = scheduleRecurrence.byDayOfWeek
          this.scheduleLocal.interval = scheduleRecurrence.interval || 1
          this.scheduleLocal.assignedAssets = JSON.parse(JSON.stringify(schedule.assignedAssets))
          this.scheduleLocal.createShiftReport = schedule.createShiftReport
          this.scheduleLocal.performanceGoals = JSON.parse(JSON.stringify(schedule.performanceGoals))
        }
      },
      immediate: true,
    },
  },
  methods: {
    restore() {
    },
    setScheduleType(scheduleType) {
      this.scheduleLocal.type = scheduleType.id
      this.$v.scheduleLocal.type.$touch()
    },
  },
}
</script>
