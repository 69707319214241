<template>
  <RuleItemStyled>
    <div class="count">
      {{ index }}
    </div>
    <div class="dimension" :title="rule?.assetDimension?.translatedName">
      {{ rule?.assetDimension?.translatedName }}
    </div>
    <template v-if="rule?.thresholdTypeEnum === 'RANGE'">
      <MinMaxvalueAtom :min="min" :max="max" :unit="unitUI"/>
    </template>
    <template v-else>
      <ThresholdValueAtom :value="threshold" :thresholdType="rule?.thresholdTypeEnum" :unit="unitUI"/>
    </template>
    <div class="actions">
      <ActionIconAtom>
        <template slot="icon">
          <Edit2Icon @click="$emit('updateRule', index - 1)" />
        </template>
      </ActionIconAtom>
      <ActionIconAtom>
        <template slot="icon">
          <Trash2Icon @click="$emit('removeRule', index - 1)" />
        </template>
      </ActionIconAtom>
    </div>
  </RuleItemStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { Trash2Icon, Edit2Icon } from 'vue-feather-icons'
import units from '@/utils/units'

import localesMixin from '@/mixins/locales'

import ThresholdValueAtom from '../Atoms/ThresholdValueAtom'
import MinMaxvalueAtom from '../Atoms/MinMaxvalueAtom'
import ActionIconAtom from '../../Atomic/Atoms/ActionIconAtom'

const RuleItemStyled = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: min-content 185px 1fr min-content;
  gap: 0.5rem;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  border: solid 1px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  font-size: 14px;
  align-items: center;
  .count {
    display: flex;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${p => p.theme.colors.circleIconBG};
  }
  .dimension {
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .value {
    display: flex;
    justify-content: flex-start;
    > div {
      width: 100%;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`

export default {
  mixins: [localesMixin],
  components: {
    RuleItemStyled,
    Trash2Icon,
    Edit2Icon,
    ThresholdValueAtom,
    MinMaxvalueAtom,
    ActionIconAtom,
  },
  props: {
    rule: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  computed:{
    unitUI() {
      if(!this.rule?.assetDimension){
        return null
      }
      const unitUIMetric = this.rule?.assetDimension?.physicalUnitSI === '%' ? '%' : this.rule?.assetDimension?.physicalUnitUIMetric
      const unitUIImperial = this.rule?.assetDimension?.physicalUnitSI === '%' ? '%' : this.rule?.assetDimension?.physicalUnitUIImperial
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return unitUI
    },
    unitSI() {
      return this.rule?.assetDimension?.physicalUnitSI ?? null
    },
    max(){
      return this.rule?.max ? units(this.rule?.max, this.unitSI, this.unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, false) : null
    },
    min(){
      return this.rule?.min ? units(this.rule?.min, this.unitSI, this.unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, false) : null
    },
    threshold(){
      return this.rule?.threshold ? units(this.rule?.threshold, this.unitSI, this.unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, false) : null
    },
  },
}
</script>
