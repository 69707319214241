<template>
  <EntityLinkAtom>
    <router-link v-if="to" :to="to">{{ computedLabel }}</router-link>
    <span v-else>{{ computedLabel }}</span>
  </EntityLinkAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const routerMap = new Map([
  ['User', { name: 'user' }],
  ['WheelBasedVehicle', { name: 'asset' }],
  ['TrackBasedVehicle', { name: 'asset' }],
  ['RailBasedVehicle', { name: 'asset' }],
  ['DrumBasedVehicle', { name: 'asset' }],
  ['StationaryMachine', { name: 'asset' }],
  ['Wheel', { name: 'wheel' }],
  ['Tyre', { name: 'tyre' }],
  ['MachineType', { name: 'editAssetType' }],
  ['AssetUtilizationCategory', { name: 'editAssetUtilizationCategory' }],
  ['Logger', { name: 'logger' }],
  ['Organization', { name: 'organization' }],
  ['Subsidiary', { name: 'subsidiary' }],
  ['Site', { name: 'site' }],
  ['Calendar', { name: 'calendar' }],
  ['Job', { name: 'job' }],
  ['ShiftReport', { name: 'event' }],
  ['Schedule', { name: 'EditSchedule' }],
  ['Acquisition', { name: 'acquisition' }],
  ['Product', { name: 'EditProduct', paramsKey: 'productId' }],
])

const EntityLinkAtom = styled('span')``

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  components: {
    EntityLinkAtom,
  },
  computed: {
    to() {
      const to = routerMap.get(this.type)
      if (to && to?.name) {
        return {
          name: to.name,
          params: {
            [to.paramsKey ?? 'id']: this.id,
          },
        }
      }
      return null
    },
    computedLabel() {
      return this.label || this.name || `${this.type}:${this.id}`
    },
  },
}
</script>
