var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AddSubsidiaryAssetsToSiteStyled', {
    on: {
      "click": _vm.activateAddSubsidiaryAssetsToSiteOverlay
    }
  }, [_c('PlusCircleIcon', {
    attrs: {
      "size": '1.5x'
    }
  }), _vm._v(" Add User-groups ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }