<template>
  <CollapsableStyled class="collapsable" :isCollapsed="isCollapsed">
    <header @click="$emit('toggle')">
      <ChevronRightIcon />
      <slot name="header" />
    </header>
    <div class="collapsable-inner" v-if="!isCollapsed">
      <slot name="main" />
    </div>
  </CollapsableStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { ChevronRightIcon } from 'vue-feather-icons'

const CollapsableStyled = styled('div')`
  > header {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.solidBG};
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    > .feather {
      transition: transform 0.25s;
      transform: rotate(${({ isCollapsed }) => (isCollapsed ? '0deg' : '90deg')});
    }
  }
`

export default {
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CollapsableStyled,
    ChevronRightIcon,
  },
}
</script>
