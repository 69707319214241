<template>
  <ConfidentialClientManagerStyled>
    <div class="title">
      {{ title }}
    </div>
    <div class="clients">
      <div class="client" v-for="client in confidentialClientsMapped" :key="client.id">
        <div class="label">
          {{ client.label }}
        </div>
        <button class="delete" @click="disconnectConfirmation(client)">
          <XIcon />
        </button>
      </div>
    </div>
    <div class="add-icon">
      <PlusCircleIcon />
    </div>
    <Multiselect
      class="multiselect"
      track-by="id"
      label="label"
      placeholder="Type to search"
      :options="addables"
      :searchable="true"
      :multiple="true"
      :loading="addablesLoading"
      :internal-search="true"
      :clear-on-select="true"
      :close-on-select="true"
      :options-limit="300"
      :limit="3"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @select="add($event)"
    />
  </ConfidentialClientManagerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'
import chroma from 'chroma-js'
import { PlusCircleIcon, XIcon } from 'vue-feather-icons'
import { flexCenter, buttonReset } from '@styles/mixins'

import { FlashMessages } from '@common/singletons'

import CONFIDENTIAL_CLIENTS_QUERY from '#/graphql/clients/confidentialClients.gql'
import UPSERT_CONFIDENTIAL_CLIENT_MUTATION from '#/graphql/clients/upsertConfidentialClient.gql'
import UPDATE_CONFIDENTIAL_CLIENT_MUTATION from '#/graphql/clients/updateConfidentialClient.gql'
import KEYCLOAK_CLIENTS_QUERY from '#/graphql/clients/keycloakClients.gql'

const ConfidentialClientManagerStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'title title'
    'clients clients'
    'add multiselect';
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  .add-icon {
    ${flexCenter}
    grid-area: add;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  }
  .title {
    ${flexCenter}
    justify-content: flex-start;
    padding-left: 1rem;
    grid-area: title;
    font-size: 1.2rem;
  }
  .clients {
    grid-area: clients;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr;
    grid-auto-rows: 2.5rem;
    margin: 2px;
    overflow: auto;
    padding: 1rem 0;
    padding-bottom: 5rem;
    max-height: 800px;
    .client {
      display: grid;
      grid-template-columns: 1fr 2rem;
      grid-template-rows: 1fr;
      grid-gap: 2px;
      > .label {
        ${flexCenter}
        justify-content: flex-start;
        padding-left: 0.5rem;
        background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
      }
      button {
        ${buttonReset}
        ${flexCenter}
        background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
      }
    }
  }
  .multiselect {
    grid-area: multiselect;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'organization',
    },
    targetId: {
      type: String,
      required: true,
    },
  },
  components: {
    ConfidentialClientManagerStyled,
    Multiselect,
    XIcon,
    PlusCircleIcon,
  },
  data() {
    return {
      keycloakClients: [],
      confidentialClients: [],
    }
  },
  computed: {
    confidentialClientsMapped() {
      return this.confidentialClients.map(m => ({
        ...m,
        label: m.clientId,
      }))
    },
    addables() {
      return this.keycloakClients
        .filter(
          keycloakClient => !this.confidentialClients.find(confidentialClient => confidentialClient.clientId === keycloakClient.clientId),
        )
        .map(m => ({
          ...m,
          label: m.clientId,
        }))
    },
    addablesLoading() {
      return this.$apollo.loading
    },
  },
  methods: {
    disconnectConfirmation(client) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'ConfidenitalClient',
        instance: client.user,
        labelKey: 'clientId',
        onConfirm: this.disconnect,
        onConfirmArgs: client,
      })
    },

    async disconnect(client) {
      const targetId = this.targetId
      if (!targetId) {
        FlashMessages.$emit('error', new Error(`Missing targetId.`), {
          timeout: 3000,
        })
        return
      }
      const variables = {
        where: {
          clientId: client.clientId,
        },
      }
      if (this.mode === 'subsidiary') {
        variables.data = {
          assignedSubsidiaries: {
            disconnect: {
              id: this.targetId,
            },
          },
        }
      } else {
        variables.data = {
          assignedOrganizations: {
            disconnect: {
              id: this.targetId,
            },
          },
        }
      }
      const res = await this.$apollo.mutate({
        mutation: UPDATE_CONFIDENTIAL_CLIENT_MUTATION,
        variables,
        update: () => {
          this.reloadClients()
        },
      })
      if (res) {
        FlashMessages.$emit(
          'success',
          `Successfully disconnected Configclient from ${
            this.mode === 'organization' ? this.$tc('organization', 1) : this.$tc('subsidiary', 1)
          }.`,
          {
            timeout: 3000,
          },
        )
        this.$root.$emit('closeOverlay')
        return
      }
    },
    async add(addable) {
      const targetId = this.targetId
      if (!targetId) {
        FlashMessages.$emit('error', new Error(`Missing targetId.`), {
          timeout: 3000,
        })
        return
      }
      const variables = {
        where: {
          clientId: addable.clientId,
        },
      }
      if (this.mode === 'subsidiary') {
        variables.create = {
          clientId: addable.clientId,
          assignedSubsidiaries: {
            connect: {
              id: this.targetId,
            },
          },
        }
        variables.update = {
          assignedSubsidiaries: {
            connect: {
              id: this.targetId,
            },
          },
        }
      } else {
        variables.create = {
          clientId: addable.clientId,
          assignedOrganizations: {
            connect: {
              id: this.targetId,
            },
          },
        }
        variables.update = {
          assignedOrganizations: {
            connect: {
              id: this.targetId,
            },
          },
        }
      }
      const res = await this.$apollo.mutate({
        mutation: UPSERT_CONFIDENTIAL_CLIENT_MUTATION,
        variables,
        update: () => {
          this.reloadClients()
        },
      })
      if (res) {
        FlashMessages.$emit(
          'success',
          `Successfully connected Configclient to ${
            this.mode === 'organization' ? this.$tc('organization', 1) : this.$tc('subsidiary', 1)
          }.`,
          {
            timeout: 3000,
          },
        )
        return
      }
    },
    reloadClients() {
      this.$apollo.queries.confidentialClients.refetch()
    },
  },
  apollo: {
    keycloakClients: {
      query: KEYCLOAK_CLIENTS_QUERY,
    },
    confidentialClients: {
      query: CONFIDENTIAL_CLIENTS_QUERY,
      variables() {
        if (this.mode === 'subsidiary') {
          return {
            where: {
              assignedSubsidiaries_some: {
                id: this.targetId,
              },
            },
          }
        } else {
          return {
            where: {
              assignedOrganizations_some: {
                id: this.targetId,
              },
            },
          }
        }
      },
      skip() {
        return !this.targetId
      },
    },
  },
}
</script>
