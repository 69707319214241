var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchResultGroupMolecule', [_c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.label)))]), _vm._l(_vm.group.items, function (item) {
    return _c('SearchResultMolecule', {
      key: item.id,
      attrs: {
        "item": item
      },
      on: {
        "goto-item": function gotoItem($event) {
          return _vm.$emit('goto-item', $event);
        }
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }