import { DateTime } from 'luxon'

export function interpreteTimeframe(tf, locale = 'de-de', intlToday) {
  const localNow = DateTime.local().toUTC()
  if (!tf || !tf.interval) {
    throw new Error(`invalid timeframe`)
  }
  if (tf.interval.hasSame('day')) {
    if (tf.interval.start.hasSame(localNow, 'day')) {
      return intlToday
    } else {
      return tf.interval.start.setLocale(locale).toLocaleString(Object.assign(DateTime.DATE_MED))
    }
  }
  if (tf.interval.hasSame('month')) {
    return `${tf.interval.start.day}. - ${tf.interval.end.day}. ${tf.interval.end.setLocale(locale).toLocaleString({
      month: 'short',
      year: '2-digit',
    })}`
  }
  return `${tf.interval.start.setLocale(locale).toLocaleString(Object.assign(DateTime.DATE_MED))} - ${tf.interval.end
    .setLocale(locale)
    .toLocaleString(Object.assign(DateTime.DATE_MED))}`
}
