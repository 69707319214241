import chroma from 'chroma-js'

export function input(p) {
  return `
    border: none;
    outline: none;
    padding: .5rem 1rem;
    background: ${chroma(p.theme.colors.white).alpha(0.25).css()};
    border-bottom: 2px solid ${p.theme.colors.white};

    &:disabled {
      color: ${p.theme.colors.white};
      border-color: ${p.theme.colors.darkGrey};
    }
  `
}
