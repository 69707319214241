export function flexCenter() {
  return `
    display: flex;
    justify-content: center;
    align-items: center;
  `
}

export function flexStartCenter() {
  return `
    display: flex;
    align-items: center;
  `
}

export function flexColumns() {
  return `
    display: flex;
    flex-direction: column;
    align-items: stretch;
  `
}
