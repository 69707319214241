<template>
  <DateSettingsStyled>
    <DateSettingStyled
      v-for="ts in dateSettings"
      :key="ts.id"
      :isActive="ts.isActive"
      :class="{ active: ts.isActive }"
      @click="setDateSetting(ts.id)"
    >
      <span>{{ ts.name }}</span>
      <transition name="fade">
        <CheckIcon v-if="ts.isActive" />
      </transition>
    </DateSettingStyled>
  </DateSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import UPDATE_UI_SETTINGS_MUTATION from '#/graphql/user/uiSettings/update.gql'

import { CheckIcon } from 'vue-feather-icons'

import { DateTime } from 'luxon'

import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'

import { getUserIdFromToken } from '@common/utils'

const DateSettingsStyled = styled('div')`
  ${flexColumns}
`

const DateSettingStyled = styled('div')`
  ${flexCenter}
  ${props => buttonReset(props)}
  justify-content: flex-start;
  position: relative;
  margin-top: 0.25rem;
  padding: 0.5rem 2rem;
  height: 2rem;
  align-items: flex-start;
  .feather {
    position: absolute;
    top: 0.5rem;
    right: 0;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    DateSettingsStyled,
    DateSettingStyled,
    CheckIcon,
  },
  inject: ['uiSettings'],
  computed: {
    dateSettings() {
      const activeDateSetting = get(this.uiSettings, 'dates')
      return ['DE_DE', 'EN_GB', 'EN_US', 'RU_RU'].map(id => ({
        id,
        name: `${DateTime.local()
          .setLocale(id.toLowerCase().replace('_', '-'))
          .toLocaleString(Object.assign(DateTime.DATETIME_SHORT))}, ${DateTime.local()
          .setLocale(id.toLowerCase().replace('_', '-'))
          .toFormat('MMM EEE')}`,
        isActive: activeDateSetting === id,
      }))
    },
  },
  methods: {
    async setDateSetting(id) {
      await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            dates: id,
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
    },
  },
}
</script>
