var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ScheduleOverviewMolecule', [_c('div', {}, [_c('ValidatableFieldMolecule', {
    attrs: {
      "validation": _vm.validations.scheduleLocal.name
    }
  }, [_c('EditableFieldMolecule', {
    attrs: {
      "label": _vm.$tc('name', 1),
      "value": _vm.validations.scheduleLocal.name.$model
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.validations.scheduleLocal.name, "$model", $event);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "type-selector"
  }, [_c('CustomMultiSelect', {
    staticClass: "multi-selector",
    attrs: {
      "track-by": "id",
      "openDirection": "bottom",
      "value": _vm.selectedScheduleType,
      "options": _vm.scheduleTypes,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true,
      "searchable": false,
      "multiple": false,
      "placeholder": _vm.$tc('planning.scheduleTypePlaceholder'),
      "maxHeight": 256,
      "optionsLimit": 6,
      "applyFilter": false,
      "internalSearch": true,
      "customNoOptionsSlot": true
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('setScheduleType', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabelAtom', {
          attrs: {
            "label": "".concat(_vm.$tc('planning.scheduleType'), ":")
          }
        })];
      },
      proxy: true
    }, {
      key: "noOptions",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t('messages.noData')))])];
      },
      proxy: true
    }])
  })], 1), _vm.validations.scheduleLocal.type.$model === 'SHIFT' ? _c('SectionStyled', [_c('TitleWithBorderAtom', {
    attrs: {
      "title": _vm.$tc('planning.reports')
    }
  }), _c('div', [_c('CheckboxItem', {
    attrs: {
      "label": _vm.$tc('planning.createShiftReport'),
      "isChecked": _vm.schedule.createShiftReport
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('setCreateShiftReport', !_vm.schedule.createShiftReport);
      }
    }
  })], 1)], 1) : _vm._e(), _c('SectionStyled', [_c('TitleWithBorderAtom', {
    attrs: {
      "title": _vm.$tc('planning.assignedAssets')
    }
  }), _c('div', [_c('RadioButtonMolecule', {
    attrs: {
      "label": _vm.$t('planning.allAssetsOfOwner', {
        x: _vm.assetsByRecipient.length
      }),
      "checked": !_vm.customAssignedAssets,
      "value": false
    },
    on: {
      "change": function change($event) {
        _vm.customAssignedAssets = false;
      }
    }
  }), _c('RadioButtonMolecule', {
    attrs: {
      "label": _vm.$t('planning.custom'),
      "checked": _vm.customAssignedAssets,
      "value": true
    },
    on: {
      "change": function change($event) {
        _vm.customAssignedAssets = true;
      }
    }
  })], 1), _vm.customAssignedAssets ? _c('div', [_c('CustomAssetSelectorStyled', [_c('CustomMultiSelect', {
    staticClass: "multi-selector",
    attrs: {
      "openDirection": "bottom",
      "label": 'label',
      "value": _vm.selectedAssets,
      "multiple": true,
      "options": _vm.assignableAssetsMapped,
      "showLabels": false,
      "close-on-select": false,
      "placeholder": _vm.$tc('selects.asset', 2)
    },
    on: {
      "select": _vm.handleSelectAssets,
      "remove": _vm.handleRemoveAssets
    }
  }), _c('div', {
    staticClass: "clear-button",
    on: {
      "click": _vm.clearAssets
    }
  }, [_c('XIcon')], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }