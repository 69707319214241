var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CountCircleStyled', {
    attrs: {
      "marginLeft": _vm.marginLeft
    }
  }, [_vm._v(" " + _vm._s(_vm.count) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }