import convert from '@common/convert-units'
import numberLocalized from './number-localized'
import round from './round'

function conv(value, si, ui, r = 2, attachUnit = true, revertToSI = false, isAdvancedRoundOff = false) {
  // Keep this implemented here due to significant digits after 0
  if (si === 'l/kg m' && ui === 'l/t km') {
    if (attachUnit) {
      if (revertToSI) {
        return round(value / (1000 * 1000), r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * (1000 * 1000), r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / (1000 * 1000), r, isAdvancedRoundOff)
    } else {
      return round(value * (1000 * 1000), r, isAdvancedRoundOff)
    }
  }
  // Keep this implemented here due to significant digits after 0
  if (si === 'l/kg m' && ui === 'gal/t mi') {
    if (attachUnit) {
      if (revertToSI) {
        return round((value / 3.785) * (1000 * 1609), r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * 3.785 * (1000 * 1609), r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round((value / 3.785) * (1000 * 1609), r, isAdvancedRoundOff)
    } else {
      return round(value * 3.785 * (1000 * 1609), r, isAdvancedRoundOff)
    }
  }
  // Needed since 1/s is not supported by convert-units library
  if (si === '1/s' && ui === '1/h') {
    if (attachUnit) {
      if (revertToSI) {
        return round(value / 3600, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * 3600, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / 3600, r, isAdvancedRoundOff)
    } else {
      return round(value * 3600, r, isAdvancedRoundOff)
    }
  }

  // Needed since Ws to kWh is not supported by convert-units library
  if (si === 'Ws' && ui === 'kWh/h') {
    const factor = 1 / 1000
    if (attachUnit) {
      if (revertToSI) {
        return round(value / factor, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * factor, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / factor, r, isAdvancedRoundOff)
    } else {
      return round(value * factor, r, isAdvancedRoundOff)
    }
  }
  // Needed since Ws to kWh is not supported by convert-units library
  if (si === 'Ws' && ui === 'kWh') {
    const factor = 1 / (3600 * 1000)
    if (attachUnit) {
      if (revertToSI) {
        return round(value / factor, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * factor, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / factor, r, isAdvancedRoundOff)
    } else {
      return round(value * factor, r, isAdvancedRoundOff)
    }
  }
  // Needed since Ws/m to kWh/km is not supported by convert-units library
  if (si === 'Ws/m' && (ui === 'kWh/km' || ui === 'kWh/mi')) {
    const factor = ui === 'kWh/km' ? 1 / 3600 : 1609 / (1000 * 3600)

    if (attachUnit) {
      if (revertToSI) {
        return round(value / factor, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * factor, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / factor, r, isAdvancedRoundOff)
    } else {
      return round(value * factor, r, isAdvancedRoundOff)
    }
  }
  // Needed since Ws/kg to kWh/mt is not supported by convert-units library
  if (si === 'Ws/kg' && (ui === 'kWh/mt' || ui === 'kWh/t')) {
    const factor = ui === 'kWh/mt' ? 1 / 3600 : 0.90718474 / 3600
    if (attachUnit) {
      if (revertToSI) {
        return round(value / factor, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * factor, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / factor, r, isAdvancedRoundOff)
    } else {
      return round(value * factor, r, isAdvancedRoundOff)
    }
  }
  // Needed since Ws/kg m to kWh/mt km is not supported by convert-units library
  if (si === 'Ws/kg m' && (ui === 'kWh/mt km' || ui === 'kWh/t mi')) {
    const factor = ui === 'kWh/mt km' ? 1 / 3.6 : (0.90718474 * 1.60934) / 3.6
    if (attachUnit) {
      if (revertToSI) {
        return round(value / factor, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * factor, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / factor, r, isAdvancedRoundOff)
    } else {
      return round(value * factor, r, isAdvancedRoundOff)
    }
  }
  // Needed since kg/s is not supported by convert-units library
  if (si === 'kg/s' && (ui === 'mt/h' || ui === 't/h')) {
    const factor = ui === 'mt/h' ? 1000 : 907.18474
    const convertedValue = (value * 3600) / factor

    if (attachUnit) {
      return round(convertedValue, r, isAdvancedRoundOff) + ` ${ui}`
    }
    return round(convertedValue, r, isAdvancedRoundOff)
  }
  // Needed since kg m/s is not supported by convert-units library
  if (si === 'kg m/s' && (ui === 'TKPH' || ui === 'mt km/h' || ui === 't mi/h' || ui === 'TMPH')) {
    const factor = ui === 'TKPH' || ui === 'mt km/h' ? 0.0036 : 0.00246579251
    if (attachUnit) {
      if (revertToSI) {
        return round(value / factor, r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(value * factor, r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(value / factor, r, isAdvancedRoundOff)
    } else {
      return round(value * factor, r, isAdvancedRoundOff)
    }
  }

  if (si === '%') {
    if (revertToSI) {
      return round(value / 100, r, isAdvancedRoundOff)
    } else {
      return round(value * 100, r, isAdvancedRoundOff)
    }
  }

  if (si && ui && si !== ui) {
    if (attachUnit) {
      if (revertToSI) {
        return round(convert(value).from(ui).to(si), r, isAdvancedRoundOff) + ` ${ui}`
      } else {
        return round(convert(value).from(si).to(ui), r, isAdvancedRoundOff) + ` ${ui}`
      }
    }
    if (revertToSI) {
      return round(convert(value).from(ui).to(si), r, isAdvancedRoundOff)
    } else {
      return round(convert(value).from(si).to(ui), r, isAdvancedRoundOff)
    }
  }
  if (ui) {
    if (attachUnit) {
      return round(value, r, isAdvancedRoundOff) + ` ${ui}`
    }
    return round(value, r, isAdvancedRoundOff)
  }
  return round(value, r, isAdvancedRoundOff)
}

export default function (
  value,
  si,
  ui,
  r = 2,
  attachUnit = true,
  format = false,
  revertToSI = false,
  thousandsSeperator = '.',
  decimalSeperator = ',',
  isAdvancedRoundOff = false,
) {
  if (!format) {
    return conv(value, si, ui, r, attachUnit, revertToSI, isAdvancedRoundOff)
  }
  const localisedValue = numberLocalized(
    conv(value, si, ui, r, false, revertToSI, isAdvancedRoundOff),
    thousandsSeperator,
    decimalSeperator,
  )

  if (attachUnit) {
    return `${localisedValue} ${ui ? ui.replace('mt', 't') : ''}`
  }
  return localisedValue
}

export function convertToLocalUnitSystem(selectedUIUnitSystem, unitMetric, unitImperial) {
  switch (selectedUIUnitSystem) {
    case 'METRIC':
      return unitMetric
    case 'IMPERIAL':
      return unitImperial
  }
}

export function getUIUnitsDescriptionFromSI(si) {
  const description = convert().describe(si)
  return convert().list(description?.measure)
}

export function resolveUnitUI(selectedUIUnitSystem, dimesnion) {
  const conversions = {
    mass: {
      METRIC: 'mt',
      IMPERIAL: 't',
    },
    length: {
      METRIC: 'km',
      IMPERIAL: 'mi',
    },
    time: {
      METRIC: 'h',
      IMPERIAL: 'h',
    },
    minutes: {
      METRIC: 'min',
      IMPERIAL: 'min',
    },
    massVelocity: {
      METRIC: 'mt/km',
      IMPERIAL: 't/mi',
    },
    volumeMass: {
      METRIC: 'l/mt',
      IMPERIAL: 'gal/t',
    },
    volumeFlowRates: {
      METRIC: 'l/h',
      IMPERIAL: 'gal/h',
    },
    meters: {
      METRIC: 'm',
      IMPERIAL: 'ft',
    },
    fullTime: {
      METRIC: 'HH:MM:SS',
      IMPERIAL: 'HH:MM:SS',
    },
    massPerTime: {
      METRIC: 'mt/h',
      IMPERIAL: 't/h',
    },
    energyMass: {
      METRIC: 'kWh/mt',
      IMPERIAL: 'kWh/t',
    },
  }
  return conversions[dimesnion][selectedUIUnitSystem]
}

/**
 * At the moment only used for cycle table
 */
export function resolveDimensionUnit(dimension) {
  const units = {
    sum_distance: {
      physicalUnitSI: 'm',
      METRIC: 'km',
      IMPERIAL: 'mi',
    },
    duration: {
      physicalUnitSI: 's',
      METRIC: 'min',
      IMPERIAL: 'min',
    },
    productive_time: {
      physicalUnitSI: 's',
      METRIC: 'min',
      IMPERIAL: 'min',
    },
    tonnage: {
      physicalUnitSI: 'kg',
      METRIC: 'mt',
      IMPERIAL: 't',
    },
    no_drills: {
      physicalUnitSI: '',
      METRIC: '',
      IMPERIAL: '',
    },
    no_bolts: {
      physicalUnitSI: '',
      METRIC: '',
      IMPERIAL: '',
    },
    no_drainage: {
      physicalUnitSI: '',
      METRIC: '',
      IMPERIAL: '',
    },
  }

  return units[dimension]
}
