<template>
  <NoDataAlertStyled>
    {{ $t('feed.card.noData') }}
    <SimplePopover
      :disabled="false"
      :displayActionButtons="true"
      :popoverContent="popoverContent"
      :placement="'top-end'"
      :trigger="'click'"
      :popoverTitle="popoverTitle"
    >
      <template v-slot:trigger> <HelpCircleIcon size="1.2x" /> {{ $t('feed.getHelp') }} </template>
      <template v-slot:titleIcon>
        <HelpCircleIcon size="1.2x" />
      </template>
      <template v-slot:closeButton>
        <ButtonSolidStyled>
          {{ $t('actions.ok') }}
        </ButtonSolidStyled>
      </template>
    </SimplePopover>
  </NoDataAlertStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import SimplePopover from '@common/components/src/SimplePopover'
import { HelpCircleIcon } from 'vue-feather-icons'
import { ButtonSolid } from '@styles/buttons'
import { flexCenter } from '@styles/mixins'

const NoDataAlertStyled = styled('div')`
  ${flexCenter}
  flex-direction: column;
  font-size: 14px;
  height: 16rem;
  color: ${p => chroma(p.theme.colors.title).css()};
  .v-popover {
    .trigger {
      margin: 1rem 0;
      grid-gap: 0.5rem;
      color: ${p => p.theme.colors.primary};
      cursor: pointer;
      &:hover {
        color: ${p => p.theme.colors.primaryActive};
      }
      > svg {
        margin: 0.2rem 0;
      }
    }
  }
`
const ButtonSolidStyled = styled(ButtonSolid)`
  padding: 0.5rem 1.5rem;
  color: ${props => props.theme.colors.white};
  background: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  border-color: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
`
export default {
  props: {
    popoverContent: {
      type: String,
      required: true,
    },
    popoverTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    NoDataAlertStyled,
    SimplePopover,
    HelpCircleIcon,
    ButtonSolidStyled,
  },
}
</script>
