var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsListMolecule', {
    attrs: {
      "isLoading": _vm.isLoadingNotifications,
      "canLoadMore": _vm.canLoadMoreNotifications,
      "notifications": _vm.notifications,
      "notificationsError": _vm.notificationsError,
      "infiniteId": _vm.infiniteId,
      "showAllNotifications": _vm.showAllNotifications,
      "unreadCount": _vm.myUnreadNotificationsCount
    },
    on: {
      "mark-all-notifications-as-read": _vm.markAllNotificationsAsRead,
      "toggle-show-all-notifications": _vm.toggleShowAllNotifications,
      "toggle-notification-is-unread": _vm.toggleNotificationIsUnread,
      "load-more": _vm.loadNotifications,
      "close-pop-over": function closePopOver($event) {
        return _vm.$emit('close-pop-over');
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }