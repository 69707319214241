<template>
  <ImageUploadStyled>
    <UploadingStyled v-if="isUploading">
      <LoadingDots />
    </UploadingStyled>
    <ImagePreviewStyled v-else-if="showPreview">
      <img :src="image.url" />
      <ButtonStyled @click="$emit('disconnect')">
        <XIcon />
      </ButtonStyled>
    </ImagePreviewStyled>
    <FileInputStyled v-else @click="triggerFileUpload">
      <div class="trigger">
        <ImageIcon />
      </div>
      <input ref="fileUpload" type="file" @change="upload" />
    </FileInputStyled>
  </ImageUploadStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import chroma from 'chroma-js'

import LoadingDots from './LoadingDots'

import UPLOAD_MUTATION from '#/graphql/uploads/uploadMutation.gql'

import { flexCenter, buttonReset } from '@styles/mixins'

import { ImageIcon, XIcon } from 'vue-feather-icons'

const ImageUploadStyled = styled('div')``

const FileInputStyled = styled('div')`
  ${flexCenter}
  ${buttonReset}
  width: 150px;
  height: 120px;
  border: 1px solid ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  border-radius: 0.5rem;
  transition: border-color 0.25s ease;

  &:hover,
  &:focus {
    border-color: ${p => p.theme.colors.primary};
  }

  .trigger {
    ${flexCenter}
    width: 100%;
    height: 100%;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const ImagePreviewStyled = styled('div')`
  ${flexCenter}
  position: relative;
  img {
    max-width: 100%;
  }
`

const UploadingStyled = styled('div')`
  ${flexCenter}
`

const ButtonStyled = styled('div')`
  ${buttonReset}
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  color: ${p => chroma(p.theme.colors.white).alpha(0.8).css()};
  background: ${p => chroma(p.theme.colors.black).alpha(0.25).css()};
  /* border: 1px solid ${p => chroma(p.theme.colors.white).alpha(0.1).css()}; */
`

export default {
  props: {
    image: {
      type: Object,
    },
    single: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    UploadingStyled,
    ImageUploadStyled,
    FileInputStyled,
    ImagePreviewStyled,
    ImageIcon,
    ButtonStyled,
    XIcon,
    LoadingDots,
  },
  data() {
    return {
      isUploading: false,
    }
  },
  computed: {
    showPreview() {
      return get(this.image, 'url', false)
    },
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileUpload.click()
    },
    async upload({ target: { files = [] } }) {
      if (!files.length) {
        return
      }
      this.isUploading = true
      const res = await this.$apollo.mutate({
        mutation: UPLOAD_MUTATION,
        variables: {
          file: files[0],
        },
        context: {
          hasUpload: true,
        },
      })
      this.isUploading = false
      const image = get(res, 'data.singleUpload', null)
      this.$emit('uploaded', image)
    },
  },
}
</script>
