<template>
  <SubsidiariesManagerWrapper :isAnySubsidiary="isAnySubsidiary">
    <div class="title">
      {{ $tc('subsidiary', 2) }}
    </div>
    <div class="subsidiaries" v-if="isAnySubsidiary">
      <div class="subsidiary-row">
        <div class="cell">
          <strong>{{ $tc('name', 1) }}</strong>
        </div>
        <div class="cell members">
          <strong>{{ $tc('user', 2) }}</strong>
        </div>
        <div class="cell assets">
          <strong>{{ $tc('asset', 2) }}</strong>
        </div>
        <div class="cell spacer"></div>
      </div>
      <div class="subsidiary-row" v-for="subsidiary in subsidiariesList" :key="subsidiary.id">
        <div class="cell name">
          {{ subsidiary.name }}
        </div>
        <div class="cell members">
          {{ subsidiary.memberships.length }}
        </div>
        <div class="cell assets">
          {{ subsidiary.assets.length }}
        </div>
        <div class="cell actions">
          <router-link :to="{ name: 'subsidiary', params: { id: subsidiary.id } }">
            <Edit2Icon />
          </router-link>
          <button @click="confirmDeleteSubsidiary(subsidiary)">
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
    <div class="subsidiaries-placeholder" v-else>
      {{ $tc('messages.noSubsidiaries') }}
    </div>
    <div class="create">
      <div class="add-icon" @click="activateCreateSubsidiaryOverlay">
        {{ $tc('messages.addSubsidiary') }}
        <PlusCircleIcon />
      </div>
    </div>
  </SubsidiariesManagerWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import chroma from 'chroma-js'
import { flexCenter, buttonReset } from '@styles/mixins'
import { TrashIcon, Edit2Icon, PlusCircleIcon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'

import DELETE_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/delete.gql'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'

const SubsidiariesManagerWrapper = styled('div')`
  display: grid;
  grid-template-areas: ${({ isAnySubsidiary }) =>
    `"title"
    "${isAnySubsidiary ? 'subsidiaries' : 'subsidiaries-placeholder'}"
    "create"`};
  grid-template-columns: 1fr;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};

  .title {
    ${flexCenter};
    justify-content: flex-start;
    padding: 0 1rem;
    grid-area: title;
    font-size: 1.2rem;
  }

  .subsidiaries-placeholder {
    grid-area: subsidiaries-placeholder;
    padding: 0 1rem;
  }

  .subsidiaries {
    grid-area: subsidiaries;

    .subsidiary-row {
      display: grid;
      grid-template-columns: minmax(10rem, min-content) repeat(2, minmax(6rem, min-content)) auto;
      grid-auto-rows: minmax(2rem, min-content);
      grid-gap: 1rem;
      > .title {
        display: flex;
        align-items: center;
        grid-column: span 4;
        font-size: 1.3rem;
        padding: 0.5rem;
        border-bottom: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
      }
      > .cell {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        word-break: break-all;
      }

      button,
      a {
        ${buttonReset};
        ${flexCenter};
      }
    }
  }

  .create {
    grid-area: create;

    .add-icon {
      cursor: pointer;
      ${flexCenter};
      height: 40px;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};

      svg {
        margin-left: 0.5rem;
      }

      &:hover {
        background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
      }
    }
  }
`

export default {
  props: {
    organization: {
      type: Object,
    },
  },
  components: {
    SubsidiariesManagerWrapper,
    TrashIcon,
    Edit2Icon,
    PlusCircleIcon,
  },
  computed: {
    organizationID() {
      return this.$route.params.id
    },
    subsidiariesList() {
      return get(this.organization, 'subsidiaries') || []
    },
    isAnySubsidiary() {
      return this.subsidiariesList.length > 0
    },
  },
  methods: {
    activateCreateSubsidiaryOverlay() {
      this.$root.$emit('activateOverlay', 'CreateSubsidiaryOverlay', {
        organizationID: this.organizationID,
      })
    },
    async deleteSubsidiary({ subsidiary }) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_SUBSIDIARY_MUTATION,
          variables: {
            where: {
              id: subsidiary.id,
            },
          },
          update: store => {
            const { organization } = store.readQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.organizationID,
                },
              },
            })

            organization.subsidiaries = organization.subsidiaries.filter(item => item.id !== subsidiary.id)

            store.writeQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.organizationID,
                },
              },
              data: {
                organization,
              },
            })
          },
        })

        FlashMessages.$emit('success', this.$t('messages.entityDeletedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
          timeout: 3000,
        })

        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    confirmDeleteSubsidiary(subsidiary) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Subsidiary',
        onConfirm: this.deleteSubsidiary,
        onConfirmArgs: {
          subsidiary,
        },
      })
    },
  },
}
</script>
