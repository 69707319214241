var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsListHeaderMoleculeStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('notifications.title')))]), _c('div', {
    staticClass: "toggle"
  }, [_c('span', {
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle-show-all-notifications');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('notifications.subtitle.all')) + " ")]), _c('OnOffToggle', {
    staticClass: "on-off-toggle",
    attrs: {
      "value": _vm.showAllNotifications
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.$emit('toggle-show-all-notifications');
      }
    }
  })], 1), _c('button', {
    class: {
      disabled: _vm.unreadCount < 1
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('mark-all-notifications-as-read');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('notifications.markAll')) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }