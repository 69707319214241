<template>
  <AdvancedSearchMolecule>
    <header>
      <SearchInputAtom :isSearching="isSearching" @blur="onBlur" @update="onUpdate" @focus="onFocus" />
    </header>
    <main>
      <SearchErrorAtom v-if="error" :error="error" />
      <SearchInstructionsAtom v-else-if="showInstructions" :searchableEntities="searchableEntities" />
      <NoSearchResultsAtom v-else-if="noResults" :searchParams="searchParams" />
      <SearchResultGroupMolecule
        v-else
        v-for="group in searchResultGroups"
        :key="group.id"
        :group="group"
        @goto-item="$emit('goto-item', $event)"
      />
    </main>
    <footer>
      <HotkeyExplanationAtom :explanation="$t('advancedSearch.hotkeyExplanations.enter')">
        <template slot="hotkey">
          <CornerDownLeftIcon />
        </template>
      </HotkeyExplanationAtom>
      <HotkeyExplanationAtom :explanation="$t('advancedSearch.hotkeyExplanations.arrows')">
        <template slot="hotkey">
          <ArrowDownIcon />
          <ArrowUpIcon />
        </template>
      </HotkeyExplanationAtom>
      <HotkeyExplanationAtom :explanation="$t('advancedSearch.hotkeyExplanations.esc')">
        <template slot="hotkey">
          <strong>esc</strong>
        </template>
      </HotkeyExplanationAtom>
    </footer>
  </AdvancedSearchMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { validate as isUuid } from 'uuid'
import { debounce } from 'vue-debounce'
import { ArrowDownIcon, ArrowUpIcon, CornerDownLeftIcon } from 'vue-feather-icons'

import SearchInputAtom from './SearchInputAtom'
import SearchErrorAtom from './SearchErrorAtom'
import SearchInstructionsAtom from './SearchInstructionsAtom'
import SearchResultGroupMolecule from './SearchResultGroupMolecule'
import HotkeyExplanationAtom from './HotkeyExplanationAtom'
import NoSearchResultsAtom from './NoSearchResultsAtom'

const CUID_REGEX = /^c[a-z0-9]{24}$/

const AdvancedSearchMolecule = styled('div')`
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-gap: 0.1rem;
  justify-content: center;

  > header,
  > main,
  > footer {
    background: ${({ theme }) => theme.colors.archonBlack};
  }

  > header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 0.5rem 1rem;
  }

  > main {
    padding: 1rem 0;
    overflow: auto;
  }

  > footer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    padding: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`

export default {
  props: {
    isSearching: {
      type: Boolean,
      required: true,
    },
    noResults: {
      type: Boolean,
      required: true,
    },
    searchResultGroups: {
      type: Array,
      required: true,
    },
    error: {
      type: Error,
    },
    searchParams: {
      type: Object,
    },
    searchableEntities: {
      type: Array,
      required: true,
    },
  },
  components: {
    AdvancedSearchMolecule,
    SearchInputAtom,
    SearchErrorAtom,
    SearchInstructionsAtom,
    SearchResultGroupMolecule,
    HotkeyExplanationAtom,
    NoSearchResultsAtom,
    CornerDownLeftIcon,
    ArrowDownIcon,
    ArrowUpIcon,
  },
  data() {
    return {
      searchOverlayVisible: false,
    }
  },
  computed: {
    showInstructions() {
      return !this.searchParams || this.searchParams?.query === ''
    },
  },
  created() {
    this.triggerSearch = debounce(params => {
      this.$emit('trigger-search', params)
    }, 500)
  },
  mounted() {
    document.addEventListener('keydown', this.onKeydown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    onKeydown(e) {
      if (e.code === 'ArrowUp') {
        e.preventDefault()
        this.handleArrowUp()
      } else if (e.code === 'ArrowDown') {
        e.preventDefault()
        this.handleArrowDown()
      } else if (e.code === 'Enter') {
        e.preventDefault()
        this.handleEnter()
      }
    },
    onBlur() {
    },
    onFocus() {
    },
    onUpdate(s) {
      const searchQuery = s.trim()
      if (isUuid(searchQuery) || CUID_REGEX.test(searchQuery)) {
        this.triggerSearch({
          id: searchQuery,
          isUuid: isUuid(searchQuery),
        })
      } else {
        this.triggerSearch({
          query: searchQuery,
        })
      }
    },
    handleArrowUp() {
      this.$emit('highlight-previous-result')
    },
    handleArrowDown() {
      this.$emit('highlight-next-result')
    },
    handleEnter() {
      this.$emit(`select-highlighted-result`)
    },
  },
}
</script>
