import base from './base'
import { styled } from '@egoist/vue-emotion'

export default styled(base)`
  font-size: 1rem;
  margin: 0.25rem;
  padding-bottom: 0.25rem;
  color: ${props => props.theme.selected.colors.primary};
  border-bottom: 1px solid ${props => (props.isActive ? props.theme.selected.colors.primary : 'transparent')};
  &:hover:enabled,
  &:focus:enabled {
    color: ${props => props.theme.selected.colors.primary};
    border-color: ${props => props.theme.selected.colors.primary};
  }

  &:disabled {
    color: ${props => props.theme.selected.colors.lightGrey};
    border-color: 'transparent';
  }
`
