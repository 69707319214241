import chroma from 'chroma-js'

import baseColors from './base-colors'

const colors = {
  ...baseColors,
  disabled: baseColors.mulledWine,
  transparentWhite: chroma(baseColors.white).alpha(0.4).css(),
  transparentBlack: chroma(baseColors.black).alpha(0.8).css(),
  primary: baseColors.azul,
  primaryLighter: baseColors.pastelBlue,
  primaryDarker: chroma(baseColors.orange).darken(0.2).hex(),
  primaryContrast: baseColors.white,
  secondary: baseColors.orange,
  secondaryLighter: chroma(baseColors.orange).brighten(1).hex(),
  secondaryDarker: chroma(baseColors.orange).darken(1).hex(),
  secondaryContrast: baseColors.white,
  tertiary: baseColors.darkGreyBlue,
  muted: baseColors.lightGrey,
}

const widgetColors = [baseColors.cyan, baseColors.mediumGrey, baseColors.green, baseColors.yellow, baseColors.purple]

const primaryGradientStart = chroma(colors.primaryDarker).alpha(0.9).css()
const primaryGradientEnd = chroma(colors.primaryDarker).darken(2).alpha(0.9).css()
const primaryGradientDarkerEnd = chroma(colors.primaryDarker).darken(4).alpha(0.9).css()
const primaryGradient = `linear-gradient(45deg, ${primaryGradientStart}, ${primaryGradientEnd})`
const primaryGradientDarker = `linear-gradient(45deg, ${primaryGradientStart}, ${primaryGradientDarkerEnd})`

export default {
  isDark: false,
  colors,
  colorSets: {
    primary: {
      background: primaryGradient,
      color: colors.white,
      interactive: colors.secondary,
      interactiveActive: colors.white,
      input: {
        color: colors.white,
        background: chroma(colors.transparentBlack).alpha(0.4).css(),
      },
      border: colors.transparentWhite,
    },
    primaryDarker: {
      background: primaryGradientDarker,
      color: colors.white,
      interactive: colors.secondary,
      interactiveActive: colors.white,
    },
  },
  widgetColors,
  misc: {
    defaultBoxShadow: `1px 2px 4px rgba(0, 0, 0, .25)`,
    boxShadowInversed: `-1px -2px 4px rgba(0, 0, 0, .25)`,
    iconSize: '1.25rem',
    iconSizeSmall: '.9rem',
  },
  app: {
    color: colors.black,
    background: colors.white,
  },
  header: {
    background: colors.white,
    remHeight: 2,
  },
  footer: {
    background: colors.white,
    remHeight: 2,
  },
  subheader: {
    color: colors.white,
    background: colors.darkGrey,
    remHeight: 2,
  },
  widget: {
    background: colors.white,
    backgroundContrast: colors.darkGrey,
    backgroundSlightContrast: colors.lightestGrey,
    backgroundAlt: colors.white,
    backgroundAltContrast: colors.darkGrey,
    footer: {
      background: colors.lightestGrey,
    },
  },
  typo: {
    fontSizeSmall: '.9rem',
  },
  text: {
    muted: colors.mediumGrey,
  },
  button: {
    // remHeight: 1.8,
    height: 30,
    icon: {
      width: 24,
      height: 24,
    },
  },
  aside: {
    top: 33, // header.remHeight: 2 = 32px + 1px border
    height: `calc(100% - 33px)`, // 100vh - header.outerHeight - footer.outerHeight
    heightWithFooter: `calc(100% - 66px)`, // 100vh - header.outerHeight - footer.outerHeight
    textColor: chroma(colors.darkGrey),
    linkColor: chroma(colors.primary),
    background: chroma(colors.white),
  },
  loader: {
    minWidth: 100,
    minHeight: 100,
    markerWidth: 50,
    donut: {
      stroke: chroma(colors.secondary).alpha(0.55).css(),
    },
  },
  zIndexes: {
    header: 1000,
    footer: 1000,
    navigation: 1000,
    notifications: 999,
    subHeader: 900,
    signalsFooter: 901,
    timeframes: {
      granularityItem: 800,
      granularityItemSelecting: 801,
    },
    editIndicator: 1001,
    widgetHeaderMenu: 1002,
    advancedInput: 1099,
    overlay: 1100,
  },
  sankey: {
    source: {
      fill: colors.cyan,
    },
    target: {
      crusher: {
        fill: colors.green,
      },
      dump: {
        fill: colors.yellow,
      },
      unknown: {
        fill: colors.mediumGrey,
      },
    },
    gradient: {
      start: chroma(colors.cyan).alpha(0.8).css(),
      endCrusher: chroma(colors.green).alpha(0.8).css(),
      endDump: chroma(colors.yellow).alpha(0.8).css(),
      endUnknown: chroma(colors.mediumGrey).alpha(0.8).css(),
    },
    text: {
      fill: colors.transparentBlack,
    },
  },
}
