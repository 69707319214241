<template>
  <NotificationsListHeaderMoleculeStyled>
    <div class="title">{{ $t('notifications.title') }}</div>
    <div class="toggle">
      <span @click="$emit('toggle-show-all-notifications')">
        {{ $t('notifications.subtitle.all') }}
      </span>
      <OnOffToggle class="on-off-toggle" :value="showAllNotifications" @toggle="$emit('toggle-show-all-notifications')" />
    </div>
    <button :class="{ disabled: unreadCount < 1 }" @click="$emit('mark-all-notifications-as-read')">
      {{ $t('notifications.markAll') }}
    </button>
  </NotificationsListHeaderMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter, buttonReset } from '@styles/mixins'
import { OnOffToggle } from '@common/components'

const NotificationsListHeaderMoleculeStyled = styled('div')`
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-rows: min-content min-content;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: end;
  > .title {
    justify-self: start;
    text-transform: capitalize;
    grid-row: span 2;
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
  > .toggle {
    ${flexCenter}
    > span {
      cursor: pointer;
      font-size: 12px;
      user-select: none;
      margin-right: 0.5rem;
    }
  }
  > button {
    ${buttonReset};
    ${flexCenter};
    justify-content: space-around;
    color: ${props => props.theme.colors.primary};
    padding: 0rem;
    gap: 0.5rem;
    height: 15px;
    > span {
      text-transform: uppercase;
      margin-left: 0.5rem;
    }
    border-bottom: 1px solid transparent;
    &.disabled {
      color: ${props => props.theme.colors.muted};
      pointer-events: none;
      &:active {
        transform: none;
        &:hover {
          color: ${props => props.theme.colors.muted};
        }
      }
    }

    &:hover {
      border-color: ${props => props.theme.colors.primaryActive};
    }
    &:active {
      &:hover {
        color: ${props => props.theme.colors.primaryActive};
      }
    }
  }
`

export default {
  props: {
    showAllNotifications: {
      type: Boolean,
      required: true,
    },
    unreadCount: {
      type: Number,
      required: true,
    },
  },
  components: {
    NotificationsListHeaderMoleculeStyled,
    OnOffToggle,
  },
}
</script>
