<template>
  <ScheduleOverviewMolecule>
    <div class="">
      <ValidatableFieldMolecule :validation="validations.scheduleLocal.name">
        <EditableFieldMolecule :label="$tc('name', 1)" :value.sync="validations.scheduleLocal.name.$model" />
      </ValidatableFieldMolecule>
    </div>
    <div class="type-selector">
      <CustomMultiSelect
        class="multi-selector"
        track-by="id"
        openDirection="bottom"
        :value="selectedScheduleType"
        :options="scheduleTypes"
        :shouldNotResetSelectedOption="true"
        :closeOnSelect="true"
        :searchable="false"
        :multiple="false"
        :placeholder="$tc('planning.scheduleTypePlaceholder')"
        :maxHeight="256"
        :optionsLimit="6"
        :applyFilter="false"
        :internalSearch="true"
        :customNoOptionsSlot="true"
        @select="$emit('setScheduleType', $event)"
      >
        <template v-slot:customLabelIcon>
          <ShowAsLabelAtom :label="`${$tc('planning.scheduleType')}:`" />
        </template>
        <template v-slot:noOptions>
          <strong>{{ $t('messages.noData') }}</strong>
        </template>
      </CustomMultiSelect>
    </div>
    <SectionStyled v-if="validations.scheduleLocal.type.$model === 'SHIFT'">
      <TitleWithBorderAtom :title="$tc('planning.reports')" />
      <div>
        <CheckboxItem
          :label="$tc('planning.createShiftReport')"
          :isChecked="schedule.createShiftReport"
          @change="$emit('setCreateShiftReport', !schedule.createShiftReport)"
        />
      </div>
    </SectionStyled>
    <SectionStyled>
      <TitleWithBorderAtom :title="$tc('planning.assignedAssets')" />
      <div>
        <RadioButtonMolecule
          :label="$t('planning.allAssetsOfOwner', { x: assetsByRecipient.length })"
          :checked="!customAssignedAssets"
          :value="false"
          @change="customAssignedAssets = false"
        />
        <RadioButtonMolecule
          :label="$t('planning.custom')"
          :checked="customAssignedAssets"
          :value="true"
          @change="customAssignedAssets = true"
        />
      </div>
      <div v-if="customAssignedAssets">
        <CustomAssetSelectorStyled>
          <CustomMultiSelect
            class="multi-selector"
            openDirection="bottom"
            :label="'label'"
            :value="selectedAssets"
            :multiple="true"
            :options="assignableAssetsMapped"
            :showLabels="false"
            :close-on-select="false"
            :placeholder="$tc('selects.asset', 2)"
            @select="handleSelectAssets"
            @remove="handleRemoveAssets"
          >
          </CustomMultiSelect>
          <div class="clear-button" @click="clearAssets">
            <XIcon />
          </div>
        </CustomAssetSelectorStyled>
      </div>
    </SectionStyled>
  </ScheduleOverviewMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { XIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'
import { CustomMultiSelect } from '@common/components'

import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'
import ValidatableFieldMolecule from '@/components/Atomic/Molecules/ValidatableFieldMolecule'
import ShowAsLabelAtom from '../../Atomic/Atoms/ShowAsLabelAtom'
import TitleWithBorderAtom from '../../Atomic/Atoms/TitleWithBorderAtom'
import RadioButtonMolecule from '../../Atomic/Molecules/RadioButtonMolecule'
import CheckboxItem from '../../Atomic/Atoms/CheckboxItem'

const ScheduleOverviewMolecule = styled('div')`
  padding: 1rem 2rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2em;
  .multi-selector {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
  }
`
const SectionStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const CustomAssetSelectorStyled = styled('div')`
  display: flex;
  flex-direction: row;
  .multi-selector {
    flex: auto;
    .multiselect__content-wrapper {
      max-height: none;
    }
  }
  .clear-button {
    ${buttonReset}
    ${flexCenter}
    flex: initial;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
  }
`

export default {
  props: {
    validations: {
      type: Object,
      required: true,
    },
    assetsByRecipient: {
      type: Array,
    },
    schedule: {
      type: Object,
      required: true,
    },
  },
  components: {
    ScheduleOverviewMolecule,
    ValidatableFieldMolecule,
    EditableFieldMolecule,
    RadioButtonMolecule,
    XIcon,
    CustomAssetSelectorStyled,
    SectionStyled,
    TitleWithBorderAtom,
    CheckboxItem,
    CustomMultiSelect,
    ShowAsLabelAtom,
  },
  data() {
    return {
      customAssignedAssets: false,
      scheduleTypes: [
        {
          id: 'SHIFT',
          label: this.$tc('dashboard.shift', 1),
        },
        {
          id: 'RECURRING_TASK',
          label: this.$tc('planning.recurringTask'),
        },
      ],
    }
  },
  watch: {
    schedule: {
      handler(schedule) {
        if (schedule?.assignedAssets.length > 0) {
          this.customAssignedAssets = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    selectedScheduleType() {
      if (this.schedule.type === null) {
        return null
      }
      return this.scheduleTypes.find(t => t.id === this.schedule.type)
    },
    assignableAssetsMapped() {
      return this.assetsByRecipient.map(asset => ({
        id: asset.id,
        label: asset.name,
        type: asset.__typename,
      }))
    },
    selectedAssets() {
      return this.schedule.assignedAssets.map(a => {
        return this.assignableAssetsMapped.find(asset => asset.id === a.assetId)
      })
    },
  },
  methods: {
    selectAssignableAsset(assignableAsset) {
      this.assignedAssets.push({
        asset: {
          id: assignableAsset.id,
          name: assignableAsset.label,
          assetType: assignableAsset.type,
        },
      })
    },
    handleSelectAssets(asset) {
      const selectedAssets = this.schedule.assignedAssets
      selectedAssets.push({
        assetId: asset.id,
        assetType: asset.type,
      })
      this.$emit('setAssignedAssets', selectedAssets)
    },
    handleRemoveAssets(asset) {
      let selectedAssets = this.schedule.assignedAssets
      selectedAssets = selectedAssets.filter(a => a.assetId !== asset.id)
      this.$emit('setAssignedAssets', selectedAssets)
    },
    clearAssets() {
      this.selectedAssets = []
    },
  },
}
</script>
