import { DateTime, Interval, Duration } from 'luxon'
import { defineStore } from 'pinia'
import { getScheduleOccurencesInInterval } from '@common/utils'
import hash from 'object-hash'

const getTimebuckets = (interval, granularity, shifts, shiftFiltersEnabled) => {
  const duration = Duration.fromISO(granularity)
  const timebuckets = interval.splitBy(duration)
  if (granularity === 'PT1H' && shiftFiltersEnabled) {
    const allShiftOccurrenceIntervals = shifts.reduce((acc, selectedShift) => {
      const shiftOccurrences = getScheduleOccurencesInInterval(selectedShift, interval).map(m => {
        const shiftStart = m.date.toUTC()
        return Interval.fromDateTimes(shiftStart, shiftStart.plus(m.duration))
      })
      acc.push(...shiftOccurrences)
      return acc
    }, [])
    return timebuckets.filter(timebucket => {
      return allShiftOccurrenceIntervals.some(interval => interval.overlaps(timebucket))
    })
  }
  return timebuckets
}

export const useStore = defineStore('dashboard', {
  state: () => {
    const defaultInterval = Interval.fromDateTimes(DateTime.local().minus({ day: 6 }).startOf('day'), DateTime.local().endOf('day'))
    return {
      defaultTimezoneId: 'local',
      selectedInterval: defaultInterval,
      shiftFiltersEnabled: false,
      selectedShiftplanId: null,
      selectedTimezoneId: 'local',
      selectedShiftIds: [],
      dateLocale: '',
    }
  },
  getters: {
    defaultInterval() {
      return Interval.fromDateTimes(
        DateTime.now().setZone(this.selectedTimezone).minus({ day: 6 }).startOf('day'),
        DateTime.now().setZone(this.selectedTimezone).endOf('day'),
      )
    },
    selectedIntervalPreviousTimeframe() {
      const interval = this.selectedInterval
      const duration = interval.toDuration('days').toObject()
      const days = Math.ceil(duration.days)
      const start = interval.start.minus({ days: days }).startOf('day')
      const end = interval.start.minus({ days: 1 }).endOf('day')
      return Interval.fromDateTimes(start, end)
    },
    selectedIntervalExtended() {
      const { start, end } = this.selectedInterval
      if (!this.shiftFiltersEnabled) {
        return {
          interval: this.selectedInterval,
        }
      }
      const { shifts, maxEnd } = this.selectedShifts.reduce(
        (acc, shift) => {
          const occurrences = getScheduleOccurencesInInterval(shift, this.selectedInterval)
          occurrences.forEach(occurrence => {
            const start = occurrence.date
            const end = occurrence.date.plus(occurrence.duration)
            const interval = Interval.fromDateTimes(start, end)
            const id = hash({
              scheduleId: shift.id,
              interval: interval.toISO(),
            })
            const name = shift.name
            if (end.toMillis() > acc.maxEnd.toMillis()) {
              acc.maxEnd = end
            }
            acc.shifts.push({ id, name, start: start.toISO(), end: end.toISO() })
          })
          return acc
        },
        { shifts: [], maxEnd: end },
      )

      const interval = Interval.fromDateTimes(start, maxEnd)
      return {
        interval,
        shifts,
      }
    },
    availableTimezones() {
      const availableTimezones = [
        {
          id: 'local',
          tz: DateTime.local().zoneName,
          label: `<browser> (${DateTime.local().zoneName})`,
        },
        {
          id: 'UTC',
          tz: 'UTC',
          label: 'UTC',
        },
      ]
      this.$myShiftplans.reduce((timezones, shiftplan) => {
        shiftplan.shifts?.forEach(shift => {
          if (shift.timezone) {
            const hasShiftPlan = timezones.find(f => f.shiftplanId === shiftplan.id)
            if (!hasShiftPlan || hasShiftPlan.tz !== shift.timezone) {
              timezones.push({
                id: shift.id,
                tz: shift.timezone,
                shiftplanId: shiftplan.id,
                label: `${shiftplan.name} (${shift.timezone})`,
              })
            }
          }
        })
        return timezones
      }, availableTimezones)
      return availableTimezones
    },
    selectedTimezoneOption() {
      return this.availableTimezones.find(f => f.id === this.selectedTimezoneId) ?? this.availableTimezones[0]
    },
    selectedTimezone() {
      return this.selectedTimezoneOption?.tz
    },
    selectedShiftplan() {
      return this.$myShiftplans.find(f => f.id === this.selectedShiftplanId)
    },
    selectedShifts() {
      return this.$myShiftplans.reduce((acc, shiftplan) => {
        shiftplan.shifts.forEach(shift => {
          if (this.selectedShiftIds.includes(shift.id)) {
            acc.push(shift)
          }
        })
        return acc
      }, [])
    },
    shiftOccurrencesInSelectedInterval() {},
    granularity() {
      return this.selectedInterval.length('days') > 2 ? 'P1D' : 'PT1H'
    },
    localeDates() {
      return (this.$profile.uiSettings?.dates ?? 'EN_GB').toLowerCase().replace('_', '-')
    },
    timebucketsSelectedInterval() {
      return getTimebuckets(this.selectedIntervalExtended.interval, this.granularity, this.selectedShifts, this.shiftFiltersEnabled)
    },
    timebucketKeysSelectedInterval() {
      return this.timebucketsSelectedInterval.map(m => m.start.toUTC().toISO())
    },
    timebucketsUI() {
      const locale = this.dateLocale ? this.dateLocale : this.localeDates
      const granularity = this.granularity
      const preset = granularity === 'PT1H' ? DateTime.TIME_SIMPLE : DateTime.DATE_SHORT
      const weekday = granularity === 'PT1H' ? undefined : 'short'
      const tb = this.timebucketsSelectedInterval.map(bucket => {
        return bucket.start.setLocale(locale).toLocaleString(
          Object.assign(preset, {
            weekday,
            year: undefined,
          }),
        )
      })
      return tb
    },
    timeframeParamSelectedInterval() {
      // return getTimeframeParams(this.selectedInterval, this.granularity, this.selectedTimezone, this.selectedShifts)
      return {
        start: this.selectedIntervalExtended.interval.start.toISO(),
        end: this.selectedIntervalExtended.interval.end.toISO(),
        shifts: this.selectedIntervalExtended.shifts,
        granularity: this.granularity,
        timezone: this.selectedTimezone,
      }
    },
  },
  actions: {
    updateSettings({ selectedIntervalIso, selectedShiftplanId, selectedShiftIds, selectedTimezoneId }) {
      this.shiftFiltersEnabled = selectedShiftIds.length > 0
      this.selectedShiftplanId = selectedShiftplanId
      this.selectedShiftIds = selectedShiftIds
      this.selectedTimezoneId = selectedTimezoneId
      const interval = selectedIntervalIso ? Interval.fromISO(selectedIntervalIso) : this.defaultInterval
      this.selectedInterval = interval.mapEndpoints(e => e.setZone(this.selectedTimezone))
    },
  },
})
