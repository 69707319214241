<template>
  <PerformanceGoalCreateStyled>
    <TitleWithBorderSimpleAtom :title="title">
      <template v-slot:right>
        <CloseButtonAtom @close="$emit('cancel')" />
      </template>
    </TitleWithBorderSimpleAtom>
    <div class="content">
      <div class="name">
        <ValidatableFieldMolecule :validation="$v.performanceGoal.name">
          <EditableFieldMolecule :label="$tc('name', 1)" :value.sync="$v.performanceGoal.name.$model" />
        </ValidatableFieldMolecule>
      </div>
      <SectionStyled>
        <HeadingStyled>{{ $t('planning.assignedAssetsGoal') }}</HeadingStyled>
        <div>
          <div>
            <RadioButtonMolecule
              :label="$t('planning.allAssetsOfSchedule', { x: availableAssetsForGoal.length })"
              :checked="!customAssignedAssets"
              :value="false"
              @change="customAssignedAssets = false"
            />
            <RadioButtonMolecule
              :label="$t('planning.custom')"
              :checked="customAssignedAssets"
              :value="true"
              @change="customAssignedAssets = true"
            />
          </div>
          <div v-if="customAssignedAssets">
            <CustomAssetSelectorStyled>
              <CustomMultiSelect
                class="multi-selector"
                openDirection="bottom"
                :label="'label'"
                :value="selectedAssets"
                :multiple="true"
                :options="assignableAssetsMapped"
                :showLabels="false"
                :close-on-select="false"
                :placeholder="'Select Assets'"
                @select="handleSelectAssets"
                @remove="handleRemoveAssets"
              >
              </CustomMultiSelect>
              <div class="clear-button" @click="clearAssets">
                <XIcon />
              </div>
            </CustomAssetSelectorStyled>
          </div>
        </div>
      </SectionStyled>
      <SectionStyled>
        <HeadingStyled>{{ $tc('planning.rule', 2) }}:</HeadingStyled>
        <PerformanceGoalRulesMolecule
          :rules="performanceGoal.performanceGoalRules"
          @ruleChange="performanceGoal.performanceGoalRules = $event"
        />
      </SectionStyled>
      <ActionStyled>
        <div class="preview-toggle">
          <label id="showPreview" for="showPreview">
            {{ $tc('titles.showPreview') }}
          </label>
          <OnOffToggle class="toggle" @toggle="showPreview = !showPreview" 
            :alternativeStyle="true" 
            :value="showPreview" 
            :disabled="performanceGoal.performanceGoalRules.length === 0"/>
        </div>
        <ButtonSolid :disabled="canCreate" @click="createGoal"> {{ createEditButtonLabel }} </ButtonSolid>
      </ActionStyled>
      <SectionStyled class="preview" v-if="showPreview">
        <HeadingStyled :title="$t('planning.previewMsg', { x: '7' })">
          {{ $tc('planning.preview') }}
          <InfoIcon class="info"/>
        </HeadingStyled>
        <PerformanceGoalPreviewMolecule :performanceGoal="performanceGoal" :schedule="schedule" :assetsByRecipient="assetsByRecipient"></PerformanceGoalPreviewMolecule>
      </SectionStyled>
    </div>
  </PerformanceGoalCreateStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { XIcon, InfoIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'
import { CustomMultiSelect } from '@common/components'
import OnOffToggle from '@common/components/src/OnOffToggle'

import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'
import ValidatableFieldMolecule from '@/components/Atomic/Molecules/ValidatableFieldMolecule'
import PerformanceGoalRulesMolecule from './PerformanceGoalRulesMolecule'
import PerformanceGoalPreviewMolecule from './PerformanceGoalPreviewMolecule'
import ButtonSolid from '../../Atomic/Atoms/ButtonSolid'
import CloseButtonAtom from '../../Atomic/Atoms/CloseButtonAtom'
import TitleWithBorderSimpleAtom from '../../Atomic/Atoms/TitleWithBorderSimpleAtom'
import RadioButtonMolecule from '../../Atomic/Molecules/RadioButtonMolecule'

const PerformanceGoalCreateStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2.5rem calc(100% - 2.5rem);
  width: 100%;
  border: solid 1px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.atomic.feedList};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 11.5rem 18rem 2.5rem auto;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    overflow-y: auto;
    .name {
      padding-top: 0.5rem;
    }
  }
`
const SectionStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem calc(100% - 3rem);
`
const HeadingStyled = styled('div')`
  display: flex;
  align-items: center;
  font-weight: 600;
  .info{
    margin-left: 5px;
    height: 16px;
  }
`

const CustomAssetSelectorStyled = styled('div')`
  display: flex;
  flex-direction: row;
  .multi-selector {
    flex: auto;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    .multiselect {
      .multiselect__tags {
        max-height: 70px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
  .clear-button {
    ${buttonReset}
    ${flexCenter}
    flex: initial;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
  }
`

const ActionStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  border-top: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  button {
    font-size: 14px;
    width: max-content;
  }
  .preview-toggle{
    display: flex;
    flex-direction: row;
    color: ${p => p.theme.colors.primary};
    align-items: center;
    .toggle{
      margin-left: 5px;
    }
  }
`

export default {
  mixins: [validationMixin],
  components: {
    PerformanceGoalCreateStyled,
    TitleWithBorderSimpleAtom,
    EditableFieldMolecule,
    ValidatableFieldMolecule,
    RadioButtonMolecule,
    XIcon,
    CustomAssetSelectorStyled,
    CustomMultiSelect,
    PerformanceGoalRulesMolecule,
    CloseButtonAtom,
    ActionStyled,
    ButtonSolid,
    SectionStyled,
    HeadingStyled,
    PerformanceGoalPreviewMolecule,
    OnOffToggle,
    InfoIcon,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    assetsByRecipient: {
      type: Array,
    },
    performanceGoalToEdit: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      name: null,
      performanceGoal: {
        name: '',
        assetFilterIds: [],
        performanceGoalRules: [],
      },
      customAssignedAssets: false,
      showPreview: false,
    }
  },
  watch: {
    schedule: {
      handler() {},
      deep: true,
      immediate: true,
    },
    performanceGoalToEdit: {
      handler(performanceGoalToEdit) {
        if (performanceGoalToEdit) {
          this.performanceGoal.name = performanceGoalToEdit.name
          this.performanceGoal.assetFilterIds = performanceGoalToEdit.assetFilterIds
          this.performanceGoal.performanceGoalRules = performanceGoalToEdit.performanceGoalRules
          if (this.performanceGoal.assetFilterIds.length > 0) {
            this.customAssignedAssets = true
          }
        }
      },
      deep: true,
      immediate: true,
    },
    mode: {
      handler(mode) {
        if (mode === 'create') {
          this.performanceGoal = {
            name: '',
            assetFilterIds: [],
            performanceGoalRules: [],
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    return {
      performanceGoal: {
        name: {
          required,
          minLength: minLength(3),
        },
        performanceGoalRules: {
          required,
          minLength: minLength(1),
        },
      },
    }
  },
  computed: {
    title() {
      return this.mode === 'create' ? this.$t('planning.createGoal') : this.$t('planning.editGoal')
    },
    createEditButtonLabel() {
      return this.mode === 'create' ? this.$t('actions.create') : this.$t('actions.update')
    },
    canCreate() {
      return this.$v.$invalid
    },
    availableAssetsForGoal() {
      if (this.schedule.assignedAssets.length > 0) {
        return this.assetsByRecipient.filter(a => this.schedule.assignedAssets.find(asset => asset.assetId === a.id))
      } else {
        return this.assetsByRecipient
      }
    },
    assignableAssetsMapped() {
      return this.availableAssetsForGoal.map(asset => ({
        id: asset.id,
        label: asset.name,
        type: asset.__typename,
      }))
    },
    selectedAssets() {
      return this.performanceGoal.assetFilterIds.map(a => {
        return this.assignableAssetsMapped.find(asset => asset.id === a)
      })
    },
  },
  methods: {
    handleSelectAssets(asset) {
      this.performanceGoal.assetFilterIds.push(asset.id)
    },
    handleRemoveAssets(asset) {
      let selectedAssets = this.performanceGoal.assetFilterIds
      this.performanceGoal.assetFilterIds = selectedAssets.filter(a => a !== asset.id)
    },
    clearAssets() {
      this.performanceGoal.assetFilterIds = []
    },
    createGoal() {
      this.$emit('appendGoal', this.performanceGoal)
    },
  },
}
</script>
