<template>
  <ModalStyled>
    <header>
      <slot name="header" />
    </header>
    <main :class="{ 'has-padding': hasPadding }">
      <slot name="main" />
    </main>
    <footer>
      <slot name="footer" />
      <transition-group tag="div" name="list" v-if="hasErrors">
        <ErrorStyled v-for="error in mappedErrors" :key="error.id">
          <div class="time">{{ error.time | time }}</div>
          <div class="message">{{ error.message }}</div>
          <ButtonStyleless @click="removeError(error.id)">
            <XIcon />
          </ButtonStyleless>
        </ErrorStyled>
      </transition-group>
    </footer>
  </ModalStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { DateTime } from 'luxon'

import { XIcon } from 'vue-feather-icons'

import { flexCenter, flexStartCenter, buttonReset } from '@styles/mixins'

const ModalStyled = styled('section')`
  width: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: min-content 1fr min-content;
  border-radius: 4px;
  color: ${p => p.theme.colors.navFontNormal};
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  max-height: 80vh;
  overflow-y: auto;
  > header {
    ${flexStartCenter};
    grid-area: header;
    color: ${p => p.theme.colors.navFontNormal};
    padding: 0.75rem;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 0.75px solid ${props => chroma(props.theme.colors.navFontNormal).alpha(0.2).css()};
  }
  > main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    &.has-padding {
      padding: 1rem;
    }
  }
  > footer {
    grid-area: footer;
    display: flex;
    flex-direction: column;
    border-top: 0.75px solid ${props => chroma(props.theme.colors.navFontNormal).alpha(0.2).css()};
  }
`

const ErrorStyled = styled('div')`
  ${flexCenter}
  color: ${p => chroma(p.theme.colors.red).brighten(1).css()};
  background: ${p => chroma(p.theme.colors.red).darken(5).alpha(0.25).css()};
  margin-top: 1px;
  > .time {
    padding: 0.25rem;
    color: ${p => p.theme.colors.white};
    font-size: 0.8rem;
  }
  > .message {
    padding: 0.25rem;
  }
  > button {
    padding: 0.25rem;
    color: ${p => p.theme.colors.darkGrey};
    &:hover {
      color: ${p => p.theme.colors.mediumGrey};
    }
  }
`

const ButtonStyleless = styled('button')`
  ${buttonReset}
`

export default {
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ModalStyled,
    ErrorStyled,
    ButtonStyleless,
    XIcon,
  },
  filters: {
    time: function (dt) {
      if (DateTime.isDateTime(dt)) {
        return dt.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false, second: '2-digit' })
      }
      return dt
    },
  },
  data() {
    return {
      hiddenErrorIDs: [],
    }
  },
  computed: {
    mappedErrors() {
      return this.errors
        .filter(err => !this.hiddenErrorIDs.includes(err.id))
        .map(err => {
          let message = err.rawError
          if (typeof err.rawError === 'string') {
            message = err.rawError.replace('Error: ', '')
          }
          if (typeof err.rawError === 'object' && err.rawError.message) {
            message = err.rawError.message.replace('Error: ', '')
          }
          return {
            id: err.id,
            message,
            time: err.time,
          }
        })
    },
    hasErrors() {
      return this.errors.length > 0
    },
  },
  methods: {
    removeError(id) {
      this.hiddenErrorIDs.push(id)
    },
  },
}
</script>
