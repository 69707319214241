var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ConfidentialClientManagerStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "clients"
  }, _vm._l(_vm.confidentialClientsMapped, function (client) {
    return _c('div', {
      key: client.id,
      staticClass: "client"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(client.label) + " ")]), _c('button', {
      staticClass: "delete",
      on: {
        "click": function click($event) {
          return _vm.disconnectConfirmation(client);
        }
      }
    }, [_c('XIcon')], 1)]);
  }), 0), _c('div', {
    staticClass: "add-icon"
  }, [_c('PlusCircleIcon')], 1), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "track-by": "id",
      "label": "label",
      "placeholder": "Type to search",
      "options": _vm.addables,
      "searchable": true,
      "multiple": true,
      "loading": _vm.addablesLoading,
      "internal-search": true,
      "clear-on-select": true,
      "close-on-select": true,
      "options-limit": 300,
      "limit": 3,
      "max-height": 600,
      "show-no-results": false,
      "hide-selected": true
    },
    on: {
      "select": function select($event) {
        return _vm.add($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }