var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HotkeyExplanationAtom', [_vm._t("hotkey"), _c('span', {
    staticClass: "explanation"
  }, [_vm._v(_vm._s(_vm.explanation))])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }