<template>
  <HeaderStyled>
    {{ title }}
  </HeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter } from '@styles/mixins'

const HeaderStyled = styled('header')`
  ${flexCenter}
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  height: calc(50px - 1rem);
  font-size: 14px;
  color: ${props => props.theme.colors.navFontNormal};
  background: ${props => props.theme.colors.solidBG};
`

export default {
  props: {
    emptyTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderStyled,
  },
  data() {
    return {
      headerComponent: null,
    }
  },
  computed: {
    title() {
      const routeName = this.$route.name
      if (!routeName || this.emptyTitle) {
        return ''
      }
      return this.$t(`navigation.cockpit.${routeName}`)
    },
  },
}
</script>
