<template>
  <ThemeSwitcherStyled :isMenuExpanded="isMenuExpanded">
    <button @click="toggle" :class="{ dark: theme.isDark }">
      <div class="circle">
        <MoonIcon />
      </div>
    </button>
  </ThemeSwitcherStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { MoonIcon } from 'vue-feather-icons'

import { flexCenter } from '@styles/mixins'
import { useStore } from '@/stores/userSettings'

const ThemeSwitcherStyled = styled('div')`
  ${flexCenter}
  width: 100%;
  transform: scale(0.65);
  @media (min-width: 768px) {
    ${props => (props.isMenuExpanded ? 'transform: scale(1);' : ' transform: scale(0.65);')};
  }
  button {
    cursor: pointer;
    position: relative;
    width: 3rem;
    height: 2rem;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    &:before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0rem;
      width: 3rem;
      height: 1rem;
      background-color: ${p => chroma(p.theme.colors.primary).alpha(0.2).desaturate(5).css()};
      border-radius: 5px;
      z-index: 10;
      transition: background-color 0.5s linear;
    }
    .circle {
      ${flexCenter}
      position: absolute;
      top: 0;
      left: 1.5rem;
      z-index: 100;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: ${p => chroma(p.theme.colors.primary).desaturate(5).brighten(3.8).css()};
      color: ${p => chroma(p.theme.colors.primary).desaturate(5).brighten(2).css()};
      transition: background-color 0.5s linear, color 0.5s linear, left 0.5s linear;
    }
    &.dark {
      &:before {
        background-color: ${p => chroma(p.theme.colors.primary).alpha(0.5).css()};
      }
      .circle {
        left: -0.5rem;
        background-color: ${p => chroma(p.theme.colors.primary).darken(1).css()};
        color: ${p => chroma(p.theme.colors.primary).brighten(2.0).css()};
      }
    }
  }
`

export default {
  inject: ['theme'],
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    ThemeSwitcherStyled,
    MoonIcon,
  },
  computed: {
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
  },
  methods: {
    toggle() {
      this.$root.$emit('toggleTheme')
    },
  },
}
</script>
