var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isVisible ? _c('ScrollToTopStyled', {
    staticClass: "scroll-to-top",
    attrs: {
      "title": _vm.$t('backToTop')
    },
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('ChevronUpIcon', {
    attrs: {
      "size": "1.5x"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }