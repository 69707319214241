<template>
  <PanelStyled>
    <header v-if="!noHeader">
      <slot name="header" />
    </header>
    <main>
      <LoadingDots v-if="isLoading" />
      <slot name="main" />
    </main>
    <footer>
      <slot name="footer" />
    </footer>
  </PanelStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

import LoadingDots from './LoadingDots'

import { flexStartCenter } from '@styles/mixins'

const PanelStyled = styled('section')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  border: 1px solid ${p => p.theme.colors.panelBorder};
  border-radius: 4px;
  background: ${p => p.theme.colors.panelBG};
  > header {
    ${flexStartCenter};
    color: ${p => p.theme.colors.panelHeader};
    padding: 0.75rem;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
  }
  > main {
  }
  > footer {
    display: flex;
    flex-direction: column;
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    PanelStyled,
    LoadingDots,
  },
}
</script>
