var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DefaultAppSettingsStyled', _vm._l(_vm.defaultAppSettings, function (app) {
    return _c('DefaultAppSettingStyled', {
      key: app.id,
      class: {
        active: app.isActive
      },
      attrs: {
        "isActive": app.isActive
      },
      on: {
        "click": function click($event) {
          return _vm.setDefaultAppSetting(app.id);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(app.name))]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [app.isActive ? _c('CheckIcon') : _vm._e()], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }