var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DateSettingsStyled', _vm._l(_vm.dateSettings, function (ts) {
    return _c('DateSettingStyled', {
      key: ts.id,
      class: {
        active: ts.isActive
      },
      attrs: {
        "isActive": ts.isActive
      },
      on: {
        "click": function click($event) {
          return _vm.setDateSetting(ts.id);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(ts.name))]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [ts.isActive ? _c('CheckIcon') : _vm._e()], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }