var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EntityLinkAtom', [_vm.to ? _c('router-link', {
    attrs: {
      "to": _vm.to
    }
  }, [_vm._v(_vm._s(_vm.computedLabel))]) : _c('span', [_vm._v(_vm._s(_vm.computedLabel))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }