var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PopoverWrapperStyled', [_c('VPopover', {
    attrs: {
      "placement": _vm.placement,
      "open": _vm.isActive,
      "popperOptions": _vm.popoverOptions,
      "popoverClass": _vm.popoverClass,
      "container": '#app',
      "disabled": _vm.disabled,
      "trigger": _vm.trigger
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverInnerWrapperStyled', {
          attrs: {
            "isShort": _vm.isShort
          }
        }, [_c('div', {
          staticClass: "content"
        }, [_vm.popoverTitle ? _c('div', {
          staticClass: "title"
        }, [_vm._t("titleIcon"), _vm._v(" " + _vm._s(_vm.popoverTitle) + " ")], 2) : _vm._e(), _c('div', {
          staticClass: "info"
        }, [_vm._t("icon"), _vm.popoverTextList ? _c('div', {
          staticClass: "popoverList"
        }, _vm._l(_vm.popoverTextList, function (list, i) {
          return _c('div', {
            key: i
          }, [_vm._v(_vm._s(list.text))]);
        }), 0) : _c('span', [_vm._v(" " + _vm._s(_vm.popoverContent) + " ")])], 2)]), _vm.displayActionButtons ? _c('ActionsStyled', [_c('div', {
          on: {
            "click": function click($event) {
              return _vm.closePopUp();
            }
          }
        }, [_vm._t("closeButton")], 2)]) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("trigger")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }