<template>
  <TabsMolecule :hasFooter="hasFooterSlot">
    <header>
      <TabHeadersStyled>
        <TabHeaderStyled v-for="(tab, index) in tabs" :key="index">
          <div
            class="switch"
            :class="{ disabled: tab.disabled, active: activeIndex === index }"
            :disabled="tab.disabled"
            @click="$emit('update:activeIndex', index)"
          >
            {{ tab.label }}
          </div>
        </TabHeaderStyled>
      </TabHeadersStyled>
    </header>
    <main>
      <slot name="main" />
    </main>
    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>
  </TabsMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const TabsMolecule = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ hasFooter }) => hasFooter ?  'minmax(3rem, min-content) calc(100% - 7rem) minmax(3rem, min-content)' : ' minmax(3rem, min-content) calc(100% - 3.5rem)'};
  grid-gap: 2px;
  border-radius: 0.5rem;
  height: 100%;
  > header,
  > footer {
    display: flex;
    align-items: center;
  }
  > header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
  }
`
const TabHeadersStyled = styled('div')`
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background: ${({ theme }) => theme.colors.togglerBodyInactive};
`

const TabHeaderStyled = styled('div')`
  > div {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5rem;
    font-weight: bold;
    border-bottom: 2px solid ${({ theme }) => theme.colors.togglerBodyInactive};
    &.active {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
    }
    &.disabled {
      opacity: 0.5;
    }
  }
`

export default {
  props: {
    activeIndex: {
      type: Number,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  components: {
    TabsMolecule,
    TabHeadersStyled,
    TabHeaderStyled,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasSubheaderSlot() {
      return !!this.$slots.subheader
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
</script>
