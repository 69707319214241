<template>
  <ScheduleTimeSettingsStyled>
    <div class="row1">
      <div class="date-picker">
        <h4>{{ $t('planning.scheduleStartDate') }}:</h4>
        <DatePicker type="datetime" v-model="scheduleStartDateTime" />
        <h4>{{ $tc('start', 1) }}:</h4>
        <DatePicker type="time" v-model="startTime" value-type="format" format="HH:mm:ss" />
        <h4>{{ $tc('end', 1) }}:</h4>
        <DatePicker type="time" v-model="endTime" value-type="format" format="HH:mm:ss" />
      </div>
      <div class="frequencies">
        <h4>{{ $t('planning.freqency') }}:</h4>
        <div>
          <RadioButtonMolecule
            v-for="frequency in availableFrequencies"
            :key="frequency.id"
            :label="frequency.label"
            :checked="schedule.frequency === frequency.id"
            :value="frequency.value"
            @change="selectFrequency(frequency.id)"
          />
        </div>
      </div>
    </div>
    <div>
      <h4>{{ $t('timezone.timezone') }}</h4>
      <CustomMultiSelect
        class="multi-selector"
        :label="'label'"
        :value="selectedTimezone"
        :openDirection="'below'"
        :options="availableTimezonesMapped"
        :close-on-select="true"
        :max-height="500"
        :show-no-results="true"
        :hide-selected="false"
        :show-labels="false"
        :searchable="true"
        :internalSearch="true"
        :resetAfter="true"
        @select="selectTimezone"
      >
        <template v-slot:customLabelIcon>
          <ShowAsLabelAtom :label="`${$t('timezone.timezone')}:`" />
        </template>
        <template v-slot:noOptions>
          <strong>{{ $t('messages.noData') }}</strong>
        </template>
      </CustomMultiSelect>
    </div>
    <ColSection>
      <h4>
        {{ $tc('duration', 1) }}:
        <span class="duration" v-if="duration">{{ duration.as('hours') }} {{ $tc('times.hour', 2) }}</span>
      </h4>
    </ColSection>
    <ColSection>
      <InputStyled>
        <label>
          <span>{{ $tc('planning.interval') }}:</span>
          <input type="number" min="1" :value="schedule.interval" @input="$emit('setInterval', $event.target.value)" />
        </label>
      </InputStyled>
    </ColSection>
    <RowSection class="weekdays">
      <h4>{{ $tc('planning.daysInWeek') }}:</h4>
      <ButtonStyled v-for="weekday in weekdays" :key="weekday.id" :class="{ active: weekday.active }" @click="toggleWeekday(weekday)">
        {{ weekday.label }}
      </ButtonStyled>
    </RowSection>
  </ScheduleTimeSettingsStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { getTimeZones } from '@vvo/tzdb'
import { DateTime } from 'luxon'

import { CustomMultiSelect } from '@common/components'

import RadioButtonMolecule from '../../Atomic/Molecules/RadioButtonMolecule'
import ShowAsLabelAtom from '../../Atomic/Atoms/ShowAsLabelAtom'

const ScheduleTimeSettingsStyled = styled('div')`
  padding: 1rem 2rem;
  padding-top: 2rem;
  height: calc(100% - 3rem);
  overflow-y: auto;
  .row1 {
    display: flex;
    flex-direction: row;
    .date-picker {
      .mx-input-wrapper {
        input {
          color: ${({ theme }) => theme.colors.primary};
          background: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
          border: 1px solid ${({ theme }) => theme.colors.solidBG};
          outline: none;
        }
        i {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    .frequencies {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .weekdays {
    gap: 0.5rem;
    align-items: center;
  }
  h4 {
    font-weight: 400;
    text-transform: capitalize;
  }
  .multi-selector {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
  }
`
const RowSection = styled('div')`
  display: flex;
  flex-direction: row;
`
const ColSection = styled('div')`
  display: flex;
  flex-direction: column;
`

const TIMEZONES = getTimeZones({
  includeUtc: true,
})

const InputStyled = styled('div')`
  display: grid;
  input {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border: 1px solid ${({ theme }) => theme.colors.solidBG};
    outline: none;
    padding: 0.5rem;
    padding-left: 1rem;
    height: 1.75rem;
    margin: 0.25rem;
    border-radius: 0.5rem;
    font-size: 1rem;
  }
`

const ButtonStyled = styled('button')`
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.solidBG};
  &.active {
    color: ${({ theme }) => theme.colors.atomic.white};
    background: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.atomic.white};
    background: ${({ theme }) => chroma(theme.colors.primary).alpha(0.5).css()};
    &.active:hover {
      background: ${({ theme }) => chroma(theme.colors.primary).css()};
    }
  }
`

export default {
  components: {
    ScheduleTimeSettingsStyled,
    DatePicker,
    RadioButtonMolecule,
    InputStyled,
    ButtonStyled,
    RowSection,
    ColSection,
    CustomMultiSelect,
    ShowAsLabelAtom,
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scheduleStartDateTime: null,
      startTime: null,
      endTime: null,
      availableTimezones: TIMEZONES,
    }
  },
  watch: {
    schedule: {
      handler(s) {
        this.scheduleStartDateTime = s.scheduleStartDateTime
        this.startTime = s.startTime
        this.endTime = s.endTime
      },
      immediate: true,
      deep: true,
    },
    scheduleStartDateTime: {
      handler(datetime) {
        this.$emit('setScheduleStartDateTime', datetime)
      },
      immediate: false,
    },
    startTime: {
      handler(time) {
        this.$emit('setStartTime', time)
      },
      immediate: false,
    },
    endTime: {
      handler(time) {
        this.$emit('setEndTime', time)
      },
      immediate: false,
    },
  },
  computed: {
    availableTimezonesMapped() {
      return [
        ...this.availableTimezones,
        {
          id: 'UTC',
          name: 'UTC',
          label: 'UTC',
        },
      ]
        .sort()
        .map(tz => {
          return {
            ...tz,
            id: tz.name,
            label: tz.name,
          }
        })
    },
    selectedTimezone() {
      return this.availableTimezonesMapped.find(tz => tz.name === this.schedule.timezone)
    },
    selectedTimezoneName() {
      return this.selectedTimezone?.name
    },
    day() {
      if (!this.scheduleStartDateTime || !this.selectedTimezoneName) {
        return null
      }
      return DateTime.fromJSDate(this.scheduleStartDateTime).setZone(this.selectedTimezoneName)
    },
    start() {
      const day = this.day
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      return DateTime.fromFormat(`${dayStr} ${this.startTime}`, 'yyyy-LL-dd HH:mm:ss')
    },
    end() {
      const day = this.day
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      const end = DateTime.fromFormat(`${dayStr} ${this.endTime}`, 'yyyy-LL-dd HH:mm:ss')
      if (end.toMillis() <= this.start.toMillis()) {
        return end.plus({ days: 1 })
      }
      return end
    },
    duration() {
      const start = this.start
      const end = this.end
      if (!start || !end) {
        return null
      }
      const duration = end.diff(start)
      if (duration.isLuxonDuration) {
        return duration
      }
      return null
    },
    availableFrequencies() {
      return [
        {
          id: 'DAILY',
          value: 'DAILY',
          label: this.$tc('planning.daily'),
        },
        {
          id: 'WEEKLY',
          value: 'WEEKLY',
          label: this.$tc('planning.weekly'),
        },
        {
          id: 'MONTHLY',
          value: 'MONTHLY',
          label: this.$tc('planning.monthly'),
        },
        {
          id: 'YEARLY',
          value: 'YEARLY',
          label: this.$tc('planning.yearly'),
        },
      ]
    },
    weekdays() {
      const weekdaysAvailable = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
      return weekdaysAvailable.map((weekday, i) => {
        return {
          id: i,
          weekday: weekday,
          label: this.$tc(`planning.weekDays.${weekday.toLowerCase()}`),
          active: this.schedule.byDayOfWeek.includes(weekday),
        }
      })
    },
  },
  methods: {
    selectTimezone(tz) {
      this.$emit('setTimezone', tz.name)
    },
    selectFrequency(frequencyId) {
      this.$emit('setFrequency', frequencyId)
    },
    toggleWeekday(weekday) {
      let selectedWeekDays = this.schedule.byDayOfWeek
      if (selectedWeekDays.includes(weekday.weekday)) {
        selectedWeekDays = selectedWeekDays.filter(f => f !== weekday.weekday)
      } else {
        selectedWeekDays.push(weekday.weekday)
      }
      this.$emit('setByDayOfWeek', selectedWeekDays)
    },
  },
}
</script>
