var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditableFieldMolecule', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": _vm.label,
      "isMuted": _vm.isReadonly
    }
  }), _vm.hasDefaultSlot ? _vm._t("default") : _c('InputStyled', {
    ref: "input",
    attrs: {
      "type": "text",
      "value": _vm.isDateTime ? _vm.formatDateTime(_vm.value) : _vm.value,
      "readonly": _vm.isReadonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:value', $event.target.value);
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }