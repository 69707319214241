var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimeAgoStyled', [_vm.isNull ? [_vm._v(" - ")] : !_vm.isValid ? [_vm._v(" " + _vm._s(_vm.$t('times.invalid')) + " ")] : _c('div', {
    staticClass: "count"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.count) + " ")]), _c('SimplePopover', {
    attrs: {
      "popoverContent": _vm.tooltip,
      "placement": _vm.placement,
      "trigger": _vm.trigger
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('ClockIcon', {
          staticClass: "icon",
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }