<template>
  <SessionExpiredOverlayStyled>
    <Modal :errors="errors">
      <template v-slot:header>
        {{ $t('titles.sessionEnded') }}
      </template>
      <template v-slot:main>
        <p>
          {{ $t('messages.sessionEnded') }}
        </p>
      </template>
      <template v-slot:footer>
        <ActionsStyled>
          <!-- <ButtonSolidStyled @click="$emit('close')">
            {{$t('actions.cancel')}}
          </ButtonSolidStyled> -->
          <ButtonBorderedStyled @click="redirectToLogin">
            {{ $t('actions.login') }}
          </ButtonBorderedStyled>
        </ActionsStyled>
      </template>
    </Modal>
  </SessionExpiredOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
// import get from 'lodash/get'

import { ButtonBordered as ButtonBorderedStyled } from '@styles/buttons'

import { flexCenter } from '@styles/mixins'

import { Modal } from '@common/components'

const SessionExpiredOverlayStyled = styled('div')`
  ${flexCenter}
  margin-top: 50px;
`

const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`

export default {
  components: {
    SessionExpiredOverlayStyled,
    ActionsStyled,
    // ButtonSolidStyled,
    ButtonBorderedStyled,
    Modal,
  },
  computed: {},
  methods: {
    redirectToLogin() {
      this.$root.$emit('redirectToLogin')
    },
  },
}
</script>
