var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsListMainMoleculeStyled', {
    ref: "scrollableContainer"
  }, [_c('div', {
    staticClass: "notification-list"
  }, [_vm.notificationsError === null ? _vm._l(_vm.notifications, function (notification, i) {
    return _c('AssetNotificationMolecule', {
      key: notification.id,
      class: 'notification-' + i,
      attrs: {
        "notification": notification
      },
      on: {
        "toggle-is-unread": function toggleIsUnread($event) {
          return _vm.$emit('toggle-notification-is-unread', notification.id);
        },
        "closed": function closed($event) {
          return _vm.$emit('close-pop-over');
        }
      }
    });
  }) : _vm._e(), _c('div', {
    ref: "sensor",
    staticClass: "sensor"
  }), _vm.isLoading ? _c('LoadingDots') : _vm.notificationsError !== null ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.$t('messages.errorLoadingWidget')) + " ")]) : !_vm.canLoadMore ? [_vm.notifications.length > 0 ? _c('div', {
    staticClass: "no-more"
  }, [_vm._v(_vm._s(_vm.$t('notifications.endOfList')))]) : _c('div', {
    staticClass: "no-data"
  }, [_c('BellOffIcon', {
    attrs: {
      "size": "2x"
    }
  }), _vm._v(_vm._s(_vm.$t('messages.noNotifications')))], 1)] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }