var render = function () {
  var _vm$selectedSchedule;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ScheduleDialogStyled', {
    attrs: {
      "hasDelete": _vm.canDelete
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "select select-type",
    class: _vm.selectedScheduleTypeClass
  }, [_c('CustomSingleSelect', {
    staticClass: "select-schedule-type",
    attrs: {
      "selectedOption": _vm.selectedScheduleTypeMapped,
      "options": _vm.availableScheduleTypesMapped,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true
    },
    on: {
      "selectedFilter": _vm.selectScheduleType
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('type', 1)) + ":")])];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "options"
  }, [_c('InputStyled', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.title,
      expression: "title"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.title = $event.target.value;
      }
    }
  })]), _vm.selectedScheduleType === 'SHIFT' ? _c('label', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createShiftReport,
      expression: "createShiftReport"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.createShiftReport) ? _vm._i(_vm.createShiftReport, null) > -1 : _vm.createShiftReport
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.createShiftReport,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.createShiftReport = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.createShiftReport = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.createShiftReport = $$c;
        }
      }
    }
  }), _c('span', [_vm._v("create shift report")])]) : _vm._e(), _vm.selectedScheduleType === 'SHIFT' ? _c('label', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createTyresHealthReport,
      expression: "createTyresHealthReport"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.createTyresHealthReport) ? _vm._i(_vm.createTyresHealthReport, null) > -1 : _vm.createTyresHealthReport
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.createTyresHealthReport,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.createTyresHealthReport = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.createTyresHealthReport = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.createTyresHealthReport = $$c;
        }
      }
    }
  }), _c('span', [_vm._v("create tyres health report")])]) : _vm._e(), (_vm$selectedSchedule = _vm.selectedSchedule) !== null && _vm$selectedSchedule !== void 0 && _vm$selectedSchedule.useTimezoneInRSchedule || _vm.mode === 'create' ? _c('Collapsable', {
    attrs: {
      "isCollapsed": !_vm.showTimeSettings
    },
    on: {
      "toggle": function toggle($event) {
        _vm.showTimeSettings = !_vm.showTimeSettings;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" Time Settings ")];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('h4', [_vm._v("Start schedule date:")]), _c('DatePicker', {
          attrs: {
            "type": "datetime"
          },
          model: {
            value: _vm.scheduleStartDateTime,
            callback: function callback($$v) {
              _vm.scheduleStartDateTime = $$v;
            },
            expression: "scheduleStartDateTime"
          }
        }), _c('h4', [_vm._v("Start:")]), _c('DatePicker', {
          attrs: {
            "type": "time",
            "value-type": "format",
            "format": "HH:mm:ss"
          },
          model: {
            value: _vm.startTime,
            callback: function callback($$v) {
              _vm.startTime = $$v;
            },
            expression: "startTime"
          }
        }), _c('h4', [_vm._v("End:")]), _c('DatePicker', {
          attrs: {
            "type": "time",
            "value-type": "format",
            "format": "HH:mm:ss"
          },
          model: {
            value: _vm.endTime,
            callback: function callback($$v) {
              _vm.endTime = $$v;
            },
            expression: "endTime"
          }
        }), _c('h4', [_vm._v("Timezone")]), _c('Multiselect', {
          staticClass: "select-timezone",
          attrs: {
            "track-by": "name",
            "label": "name",
            "value": _vm.selectedTimezone,
            "openDirection": 'below',
            "options": _vm.availableTimezones,
            "close-on-select": true,
            "max-height": 500,
            "show-no-results": true,
            "hide-selected": false,
            "show-labels": false,
            "searchable": true,
            "resetAfter": true
          },
          on: {
            "select": _vm.selectTimezone
          },
          scopedSlots: _vm._u([{
            key: "singleLabel",
            fn: function fn(_ref) {
              var option = _ref.option;
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$t('timezone.timezone')) + ":")]), _c('span', {
                staticClass: "labelText"
              }, [_vm._v(" " + _vm._s(option.name) + " ")])];
            }
          }], null, false, 2425956558)
        }), _c('h4', [_vm._v("Duration:")]), _vm.duration ? _c('div', {
          staticClass: "duration"
        }, [_vm._v(_vm._s(_vm.duration.as('hours')) + " hours")]) : _vm._e(), false ? _c('label', {
          staticClass: "checkbox"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.isRecurring,
            expression: "isRecurring"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.isRecurring) ? _vm._i(_vm.isRecurring, null) > -1 : _vm.isRecurring
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.isRecurring,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.isRecurring = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.isRecurring = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.isRecurring = $$c;
              }
            }
          }
        }), _c('span', [_vm._v("recurring")])]) : _vm._e(), true ? [_c('h4', [_vm._v("Freqency:")]), _c('div', {
          staticClass: "frequencies"
        }, _vm._l(_vm.availableFrequencies, function (frequency) {
          return _c('CustomRadioButtonStyled', {
            key: frequency.id
          }, [_c('input', {
            attrs: {
              "type": "radio",
              "id": "freqency_".concat(frequency.id)
            },
            domProps: {
              "checked": _vm.selectedFrequencyId === frequency.id,
              "value": frequency.value
            },
            on: {
              "change": function change($event) {
                return _vm.selectFrequency(frequency.id);
              }
            }
          }), _c('span', {
            staticClass: "label"
          }, [_vm._v(_vm._s(frequency.label))]), _c('span', {
            staticClass: "checkmark"
          })]);
        }), 1), _c('InputStyled', [_c('label', [_c('span', [_vm._v("Interval")]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.interval,
            expression: "interval"
          }],
          attrs: {
            "type": "number",
            "min": "1"
          },
          domProps: {
            "value": _vm.interval
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.interval = $event.target.value;
            }
          }
        })])]), _c('div', {
          staticClass: "weekdays"
        }, _vm._l(_vm.weekdays, function (weekday) {
          return _c('ButtonStyled', {
            key: weekday.id,
            class: {
              active: weekday.active
            },
            on: {
              "click": function click($event) {
                return _vm.toggleWeekday(weekday);
              }
            }
          }, [_vm._v(" " + _vm._s(weekday.label) + " ")]);
        }), 1)] : _vm._e()];
      },
      proxy: true
    }], null, false, 3926039317)
  }) : [_c('h5', [_c('p', [_vm._v("Time settings have been disabled, because schedule is still using timezone implemenation v1.")]), _c('p', [_vm._v(" Please configure the correct timezones first "), _c('EntityLinkAtom', {
    attrs: {
      "id": _vm.selectedSchedule.id,
      "type": 'Schedule',
      "label": 'here'
    }
  }), _vm._v(". ")], 1)])], _c('Collapsable', {
    attrs: {
      "isCollapsed": !_vm.showAssignedAssets
    },
    on: {
      "toggle": function toggle($event) {
        _vm.showAssignedAssets = !_vm.showAssignedAssets;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', [_vm._v(" Assigned Assets ")]), _vm.assignedAssets.length > 0 ? _c('CountCircle', {
          attrs: {
            "count": _vm.assignedAssets.length,
            "marginLeft": '1rem'
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('CustomRadioButtonStyled', [_c('input', {
          attrs: {
            "type": "radio",
            "id": "custom_assigned_assets_false"
          },
          domProps: {
            "checked": !_vm.customAssignedAssets,
            "value": false
          },
          on: {
            "change": function change($event) {
              _vm.customAssignedAssets = false;
            }
          }
        }), _c('span', {
          staticClass: "label"
        }, [_vm._v("All assets of owner (" + _vm._s(_vm.assetsByRecipient.length) + ")")]), _c('span', {
          staticClass: "checkmark"
        })]), _c('CustomRadioButtonStyled', [_c('input', {
          attrs: {
            "type": "radio",
            "id": "custom_assigned_assets_true"
          },
          domProps: {
            "checked": _vm.customAssignedAssets,
            "value": true
          },
          on: {
            "change": function change($event) {
              _vm.customAssignedAssets = true;
            }
          }
        }), _c('span', {
          staticClass: "label"
        }, [_vm._v("Custom (" + _vm._s(_vm.assignedAssets.length) + ")")]), _c('span', {
          staticClass: "checkmark"
        })]), _c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [_vm.customAssignedAssets ? [_c('div', {
          staticClass: "select select-assigned-assets"
        }, [_c('CustomMultiselectOther', {
          staticClass: "select-assigned-assets",
          attrs: {
            "track-by": "id",
            "openDirection": "bottom",
            "options": _vm.assignableAssetsMapped,
            "shouldNotResetSelectedOption": true,
            "disabled": false,
            "closeOnSelect": false,
            "searchable": true,
            "internalSearch": true,
            "multiple": true,
            "is-loading": _vm.$apollo.queries.assetsByRecipient.loading,
            "placeholder": 'Add Assets',
            "modelName": 'Asset',
            "maxHeight": 256,
            "optionsLimit": 6,
            "applyFilter": false
          },
          on: {
            "select": _vm.selectAssignableAsset
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('owner', 1)) + ":")])];
            },
            proxy: true
          }], null, false, 3885590097)
        })], 1)] : _vm._e()], 2), _c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [_vm.customAssignedAssets ? _c('div', {
          staticClass: "assigned-assets"
        }, _vm._l(_vm.assignedAssetsMapped, function (assignedAsset) {
          return _c('div', {
            key: assignedAsset.id,
            staticClass: "assigned-asset"
          }, [_c('span', {
            staticClass: "name"
          }, [_vm._v(" " + _vm._s(assignedAsset.name) + " ")]), _c('ButtonStyleless', {
            on: {
              "click": function click($event) {
                return _vm.removeAssignedAsset(assignedAsset);
              }
            }
          }, [_c('XIcon')], 1)], 1);
        }), 0) : _vm._e()])];
      },
      proxy: true
    }])
  })], 2), _c('div', {
    staticClass: "actions"
  }, [_vm.canDelete ? _c('ButtonStyleless', {
    on: {
      "click": function click($event) {
        return _vm.confirmDeleteSchedule(_vm.selectedSchedule);
      }
    }
  }, [_c('TrashIcon')], 1) : _vm._e(), _c('ActionButtonStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('close-dialog');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('actions.cancel')) + " ")]), _c('ActionButtonStyled', {
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }