export default function () {
  return `
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .list-enter-active, .list-leave-active {
    transition: opacity .5s ease;
  }
  .list-enter, .list-leave-to {
    opacity: 0;
  }

  .fade-in-right-enter-active, .fade-in-right-leave-active {
    transition: opacity .5s ease, transform .5s ease;
  }
  .fade-in-right-enter, .fade-in-right-leave-to {
    opacity: 0;
    transform: translateX(2rem);
  }

  .fade-in-left-enter-active, .fade-in-left-leave-active {
    transition: opacity .5s ease, transform .5s ease;
  }
  .fade-in-left-enter, .fade-in-left-leave-to {
    opacity: 0;
    transform: translateX(-2rem);
  }

  .fade-in-down-enter-active, .fade-in-down-leave-active {
    transition: opacity .5s ease, transform .5s ease;
  }
  .fade-in-down-enter, .fade-in-down-leave-to {
    opacity: 0;
    transform: translateY(-2rem);
  }

  .fade-in-up-enter-active, .fade-in-up-leave-active {
    transition: opacity .5s ease, transform .5s ease;
  }
  .fade-in-up-enter, .fade-in-up-leave-to {
    opacity: 0;
    transform: translateY(2rem);
  }


  `
}
