<template>
  <LanguageSelectorStyled>
    <LanguageStyled
      v-for="lang in languages"
      :key="lang.shortLang"
      :isActive="lang.isActive"
      :class="{ active: lang.isActive }"
      @click="setLocale(lang)"
    >
      <span>{{ $t('languages.' + lang.shortLang) }}</span>
      <transition name="fade">
        <CheckIcon v-if="lang.isActive" />
      </transition>
    </LanguageStyled>
  </LanguageSelectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { getUserIdFromToken } from '@common/utils'

import UPDATE_UI_SETTINGS_MUTATION from '#/graphql/user/uiSettings/update.gql'

import get from 'lodash/get'

import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'

import { CheckIcon } from 'vue-feather-icons'

const LanguageSelectorStyled = styled('div')`
  ${flexColumns}
`

const LanguageStyled = styled('button')`
  ${flexCenter}
  ${props => buttonReset(props)}
  position: relative;
  margin-top: 0.25rem;
  padding: 0.5rem 2rem;
  height: 2rem;
  align-items: flex-start;
  .feather {
    position: absolute;
    top: 0.25rem;
    right: 0;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    LanguageSelectorStyled,
    LanguageStyled,
    CheckIcon,
  },
  inject: ['uiSettings'],
  computed: {
    languages() {
      return [
        { shortLang: 'de', longLang: 'DE_DE' },
        { shortLang: 'en', longLang: 'EN_GB' },
        { shortLang: 'ru', longLang: 'RU_RU' },
      ].map(item => {
        return {
          ...item,
          isActive: this.$i18n.locale === item.shortLang,
        }
      })
    },
  },
  methods: {
    async setLocale(lang) {
      const res = await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            language: lang.shortLang.toUpperCase(),
            dates: lang.longLang,
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
      this.$i18n.locale = get(res, 'data.updateUiSettings.language', 'EN').toLowerCase()
    },
  },
}
</script>
