var render = function () {
  var _vm$event, _vm$event$recipient;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceGoalFeedItemMoleculeStyled', [_vm.$apollo.loading ? _c('TalpaLoaderWrapper', {
    attrs: {
      "transparent": true
    }
  }) : _vm.assetDimensionIds.length > 0 ? _c('TextWrapper', [_c('TitleStyled', [_c('div', {
    staticClass: "event-created"
  }, [_vm._v(_vm._s(_vm.eventTimeFormatted))]), _c('div', {
    staticClass: "highlight",
    domProps: {
      "innerHTML": _vm._s(_vm.overallGoalAchieved ? _vm.$t('PerformanceGoalEvents.goalAchieved', {
        x: _vm.event.name
      }) : _vm.$t('PerformanceGoalEvents.goalNotAchieved', {
        x: _vm.event.name
      }))
    }
  }), _c('div', [_vm._v(_vm._s((_vm$event = _vm.event) === null || _vm$event === void 0 ? void 0 : (_vm$event$recipient = _vm$event.recipient) === null || _vm$event$recipient === void 0 ? void 0 : _vm$event$recipient.name))]), _c('div', [_vm._v(_vm._s(_vm.performanceGoalRuleTimePeriod))])]), _c('PerformanceGoalResultStyled', {
    ref: "clampedText",
    attrs: {
      "showAll": _vm.showAll
    }
  }, [_c('div', [_c('div', {
    staticClass: "grid"
  }, _vm._l(_vm.headers, function (header, index) {
    return _c('div', {
      key: index,
      staticClass: "heading"
    }, [_vm._v(_vm._s(_vm.$t("PerformanceGoalEvents.".concat(header))))]);
  }), 0), _vm._l(_vm.calculatePerformanceGoalResults, function (result) {
    return _c('div', {
      key: result.name,
      staticClass: "table-row"
    }, [_c('div', {
      staticClass: "grid"
    }, [_c('div', [_vm._v(_vm._s(result.translatedName))]), _c('div', {
      class: result.color
    }, [_vm._v(_vm._s(result.value) + " " + _vm._s(result.unit))]), _c('div', [result.targetValue ? _c('span', [_vm._v(_vm._s(result.type.toLowerCase()))]) : _vm._e(), _vm._v(" " + _vm._s(result.targetValue) + " " + _vm._s(result.unit) + " ")]), _c('div', [_vm._v(_vm._s(result.percentageDeviation ? Number(result.percentageDeviation.toFixed(1)) : '-'))])])]);
  })], 2)]), _c('Footerstyled', [_vm.shouldExpand ? _c('a', {
    staticClass: "link-text",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleShowAll();
      }
    }
  }, [_vm.showAll ? [_c('ChevronRightIcon'), _vm._v(_vm._s(_vm.$t('actions.showLess')))] : [_c('ChevronDownIcon'), _vm._v(_vm._s(_vm.$t('actions.showMore')))]], 2) : _vm._e()])], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('MachineAssignmentEvents.titles.bannerNoData')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }