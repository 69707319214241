var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasSubMenu ? _c('a', {
    staticClass: "main-navigation-item",
    class: {
      active: _vm.isActive
    }
  }, [_c('MainNavigationItemIcon', {
    attrs: {
      "icon": _vm.icon
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("navigation.cockpit.".concat(_vm.routeMetaName))) + " ")]), _vm.count !== undefined ? _c('CountCircle', {
    staticClass: "count",
    attrs: {
      "count": _vm.count
    }
  }) : _vm._e()], 1) : _c('router-link', {
    staticClass: "main-navigation-item",
    class: {
      active: _vm.isActive
    },
    attrs: {
      "to": _vm.routeTo,
      "exact": _vm.routeMetaName === 'home',
      "data-testid": "menu-".concat(_vm.routeMetaName)
    }
  }, [_c('MainNavigationItemIcon', {
    attrs: {
      "icon": _vm.icon
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("navigation.cockpit.".concat(_vm.routeMetaName))) + " ")]), _vm.count !== undefined ? _c('CountCircle', {
    staticClass: "count",
    attrs: {
      "count": _vm.count
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }