<template>
  <NavigationStyled :isMenuExpanded="isMenuExpanded">
    <NavExpanderButton class="expander" :isMenuExpanded="isMenuExpanded" @toggleIsMenuExpanded="setNavState" />
    <div class="navItems">
      <MainNavigationItem v-for="(navigationItem, i) in navigationItems" :key="i" :navigationItem="navigationItem" />
    </div>
    <div class="footer">
      <ReleaseNotes />
      <PrivacyPolicy />
      <ThemeSwitcher class="theme-switcher" />
    </div>
  </NavigationStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import permissionsMixin from '@/mixins/permissions'
import ThemeSwitcher from '@/components/Misc/ThemeSwitcher'
import ReleaseNotes from '@/components/Misc/ReleaseNotes'
import NavExpanderButton from '@/components/Atomic/Atoms/NavExpanderButtonAtom'
import { useStore } from '@/stores/userSettings'
import MainNavigationItem from './Navigation/MainNavigationItem'
import PrivacyPolicy from '@/components/Atomic/Molecules/PrivacyPolicyMolecule'

function hasActiveChild(children, activeRouteName) {
  return children.some(child => {
    if (child?.name === activeRouteName) {
      return true
    }
    if ((child.children ?? []).length > 0) {
      return hasActiveChild(child.children, activeRouteName)
    }
    return false
  })
}

const NavigationStyled = styled('nav')`
  grid-area: navigation;
  display: grid;
  grid-template-rows: 1fr 10rem;
  grid-template-columns: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${props => props.theme.colors.solidBG};
  transition: width 0.4s linear;
  @media (min-width: 768px) {
    grid-template-rows: 3rem 1fr 10rem;
    grid-template-columns: ${({ isMenuExpanded }) => (isMenuExpanded ? '200px;' : '50px;')};
  }
  > .expander {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
  }
  > .navItems {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: ${({ isMenuExpanded }) => (isMenuExpanded ? 'block;' : 'none;')};
    }
  }
  > .footer {
    position: absolute;
    bottom: 5px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-self: center;
    width: -webkit-fill-available;
  }
`

export default {
  mixins: [permissionsMixin],
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    NavigationStyled,
    ThemeSwitcher,
    ReleaseNotes,
    NavExpanderButton,
    MainNavigationItem,
    PrivacyPolicy,
  },
  data() {
    return {
      dashboards: [],
    }
  },
  computed: {
    activeParent() {
      const activeRouteName = this.$route?.name
      const routes = this.$router?.options?.routes ?? []
      const activeParent = routes.find(route => route?.name === activeRouteName || hasActiveChild(route?.children ?? [], activeRouteName))
      return activeParent
    },
    routeName() {
      return this.$route.name
    },
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
    routes() {
      return this.$router?.options?.routes || []
    },
    navigationItems() {
      const permissions = this.permissions?.all
      const routes = this.routes
        .filter(route => {
          const requiresPermission = route.meta?.permissionsRequired
          const requiresPermissionAndHasPermission =
            (requiresPermission &&
              permissions.some(permission => {
                return route.meta.permissionsRequired.includes(permission?.name)
              })) ||
            !requiresPermission
          return route.meta?.isNavigationItem && requiresPermissionAndHasPermission
        })
        .map(route => {
          const activeByParentName = this.activeParent?.meta?.name ?? this.activeByParent?.name
          const routeName = route?.meta?.name ?? route?.name
          const activeByParent = routeName !== undefined && activeByParentName === routeName
          return {
            ...route,
            activeByParent,
          }
        })
      return routes
    },
  },
  methods: {
    setNavState(isExpanded) {
      this.userSettingsStore.setNavState(isExpanded)
    },
  },
}
</script>
