var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchInstructionsAtom', [_c('span', {
    staticClass: "muted"
  }, [_vm._v("Search will be conducted in the following entity types:")]), _vm._l(_vm.searchableEntities, function (searchableEntity) {
    return _c('ul', {
      key: searchableEntity.id
    }, [_c('li', [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(searchableEntity.label)))])])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }