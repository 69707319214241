var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CollapsableStyled', {
    staticClass: "collapsable",
    attrs: {
      "isCollapsed": _vm.isCollapsed
    }
  }, [_c('header', {
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle');
      }
    }
  }, [_c('ChevronRightIcon'), _vm._t("header")], 2), !_vm.isCollapsed ? _c('div', {
    staticClass: "collapsable-inner"
  }, [_vm._t("main")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }