<template>
  <LoadingOverlayStyled :class="blockPage ? 'block-page' : ''">
    <LoadingDots :class="blockPage ? 'center-loading-dots' : ''" />
  </LoadingOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'

const LoadingOverlayStyled = styled('div')``

export default {
  props: {
    blockPage: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    LoadingOverlayStyled,
    LoadingDots,
  },
}
</script>

<style>
.block-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.center-loading-dots {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
