export default function (number, thousandsSeperator = '.', decimalSeperator = ',') {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeperator)
  return parts.join(decimalSeperator)
}

export function parseLocaleFloat(number, mesureSystem) {
  if (Number.isInteger(number)) {
    return number
  } else {
    if (mesureSystem === 'METRIC') {
      const thousands = number.replace('.', '')
      const tenths = thousands.replace(',', '.')
      const result = isNaN(parseFloat(tenths)) ? 'null' : parseFloat(tenths)
      return result
    } else if (mesureSystem === 'IMPERIAL') {
      const thousands = number.replace(',', '')
      const result = isNaN(parseFloat(thousands)) ? 'null' : parseFloat(thousands)
      return result
    }
    return number
  }
}
