function roundValue(value, round = 2) {
  return Math.round(Math.pow(10, round) * value) / Math.pow(10, round)
}

export default function (value, r, isAdvancedRoundOff = false) {
  let roundedValue = value
  if (isAdvancedRoundOff) {
    const fraction = (Math.log(Math.abs(value)) * Math.LOG10E + 1) | 0
    if (fraction >= 3) {
      roundedValue = roundValue(value, 0)
    } else if (fraction === 2) {
      roundedValue = roundValue(value, 1)
    } else if (fraction === 1) {
      roundedValue = roundValue(value, 2)
    } else if (Math.abs(value) < 0.01 && Math.abs(value) > 0) {
      roundedValue = '<0.01'
    } else {
      roundedValue = roundValue(value, 2)
    }
  } else {
    roundedValue = roundValue(value, r)
  }
  return roundedValue
}
