var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsBellMolecule', {
    attrs: {
      "unreadCount": _vm.myUnreadNotificationsCount,
      "isActive": _vm.isActive
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }