import chroma from 'chroma-js'

const colors = {}

colors.white = '#fff'
colors.black = '#000'
colors.azul = '#0F59EC'
colors.darkGoldenRod = '#B8860B'
colors.sandyBrown = '#F4A460'
colors.pastelBlue = '#A4BFFF'
colors.deepSkyBlue = '#00BFFF'
colors.darkTurq = '#008385'
colors.airForceBlue = '#5A819E'
colors.teal = '#008080'
colors.indigo = '#4B0082'
colors.darkKhaki = '#BDB76B'
colors.gold = '#FFD700'
colors.lightGold = '#9c8a2e'
colors.paleVioletRed = '#DB7093'
colors.disabled = '#736C88'
colors.indianRed = '#CD5C5C'
colors.lightestGrey = '#eee'
colors.lighterGrey = '#d7d7d9'
colors.lightGrey = '#b4b4b4'
colors.mediumGrey = '#797979'
colors.grey88 = '#E0E0E0'
colors.darkGrey = '#333'
colors.darkerGrey = '#222'
colors.steelGrey = '#3f444d'
colors.midnightBlue = '#040820'
colors.darkestGrey = '#111'
colors.darkBlackBlue = '#04060C'
colors.mediumBlackBlue = '#0E111A'
colors.lightBlackBlue = '#12161D'
colors.richBlack = '#070e1a'
colors.blackPearl = '#030810'
colors.ebony = '#282E3A'
colors.tuna = '#464D5C'
colors.mischka = '#9FA2A9'
colors.licorice = '#303643'
colors.dimGrey = '#6B6B6B'
colors.matterHorn = '#4E4E4E'
colors.red = '#ca1515'
colors.salmon = '#FA8072'
colors.redPale = '#AD5237'
colors.redLightest = '#f79b9b'
colors.redDarker = '#771818'
colors.redDark = '#8B0000'
colors.chocolate = '#5B2A00'
colors.orange = '#fc8300'
colors.orangeLighter = '#eead68'
colors.mediumBrown = '#edae49'
colors.green = '#2ca964'
colors.olivedrab = '#6B8E23'
colors.mediumaquamarine = '#66CDAA'
colors.olive = '#808000'
colors.greenDarker = '#21623e'
colors.cyan = '#16c7e3'
colors.archonBlue = '#00d7ec'
colors.archonBlueTransparent = 'rgba(0, 215, 236, 0.2)'
colors.archonBlack = '#141414'
colors.archonDarkGrey = '#262626'
colors.archonBlackTransparent = 'rgba(0, 0, 0, 0.65)'
colors.archonWhiteTransparent = 'rgba(255, 255, 255, 0.1)'
colors.purple = '#ae2ebe'
colors.yellow = '#cde21f'
colors.cinnabar = ' #E44D2E'
colors.buffYellow = '#f0dc82'
colors.talpaGrey = '#191e26'
colors.darkSlateGrey = '#2F4F4F'
colors.talpaGreyDarker = '#121315'
colors.talpaGreyLighter = '#2C2F33'
colors.fontGrey = '#3E434B'
colors.greySuva = '#898989'
colors.lime = '#BFFF00'
colors.mint = '#3EB489'
colors.malachite = '#0BDA51'
colors.mulledWine = '#444A54'
colors.shuttleGrey = '#66696D'
colors.gainsBoro = '#DADADA'
colors.whiteSmoke = '#F8F8F8'
colors.whiteSmokeDark = '#F6F6F6'
colors.lavender = '#C4C3E7'
colors.seaBlue = '#114C53'
colors.babyBlue = '#89cff0'
colors.trafficLightRed = '#FB2121'
colors.trafficLightYellow = '#EBB113'
colors.trafficLightGreen = '#18DB38'
colors.primary = colors.orange
colors.textPrimary = colors.white
colors.textActivePrimary = colors.white
colors.backgroundPrimary = colors.darkGrey
colors.primaryLighter = chroma(colors.primary).brighten(0.7).css()
colors.primaryDarker = chroma(colors.primary).darken(0.5).css()
colors.primaryDarkest = chroma(colors.primary).darken(1).css()
colors.primaryActive = colors.primaryLighter
colors.secondary = colors.cyan
colors.secondaryLighter = chroma(colors.secondary).brighten(0.7).css()
colors.secondaryDarker = chroma(colors.secondary).darken(0.5).css()
colors.transparentBlack = chroma(colors.black).alpha(0.5).css()
colors.charts = {}
colors.charts.first = colors.primary
colors.charts.second = '#0099b7'
colors.charts.third = colors.mediumGrey
colors.muted = colors.lightGrey
colors.toggleInActive = '#736C88'
colors.toggleDisabled = '#393943'
colors.mainBG = chroma(colors.talpaGrey).darken(0.5).css()
colors.solidBG = colors.talpaGrey
colors.solidBGDarker = colors.talpaGrey
colors.normalFontColor = colors.white
colors.navFontNormal = '#e3ecfa'
colors.panelBorder = chroma(colors.white).alpha(0.1).css()
colors.panelBG = chroma(colors.white).alpha(0.025).css()
colors.assetPanelBG = colors.richBlack
colors.panelHeader = colors.white
colors.panelHeaderBorderBottom = chroma(colors.white).alpha(0.2).css()
colors.tableBorderBottom = chroma(colors.white).alpha(0.1).css()
colors.circleIconBG = chroma(colors.white).alpha(0.1).css()
colors.divBackground = chroma(colors.white).alpha(0.1).css()
colors.divBlackWhiteBackground = chroma(colors.black).alpha(0.8).css()
colors.widgetBorder = colors.darkerGrey
colors.widgetBG = colors.talpaGrey
colors.headerBG = '#252931'
colors.widgetShadow = 'none'
colors.widgetShadowEnforced = '1px 2px 4px rgba(0, 0, 0, .25)'
colors.widgetEditBG = chroma(colors.black).alpha(0.8).css()
colors.tableWidgetHightlight = chroma(colors.white).alpha(0.1).css()
colors.subheaderInactiveBG = chroma(colors.darkGrey).alpha(0.3).css()
colors.inputOnSolid = chroma(colors.solidBG).brighten(1).alpha(0.1).css()
colors.talpaLoaderMarkerFill = colors.white
colors.talpaLoaderBG = chroma(colors.darkestGrey).alpha(0.3).css()
colors.inputBG = colors.talpaGreyDarker
colors.inputColor = colors.primary
colors.inputPlaceholderColor = colors.white
colors.solidBGContrastColor = colors.navFontNormal
colors.solidBGContrastBG = colors.talpaGreyLighter
colors.selectBG = '#0E111A'
colors.selectBGDrop = '#24242F'
colors.selectText = '#EFEFEF'
colors.textBackground = chroma(colors.talpaGrey).brighten(2).css()
colors.map = {}
colors.map.h3hexRange = ['#00FF00', '#FFFF00', '#FFA500', '#FF0000']
colors.togglerBodyActive = colors.primary
colors.togglerBodyInactive = '#141414'
colors.togglerBallActive = '#141414'
colors.togglerBallInactive = colors.mulledWine
colors.title = '#8F8F95'

colors.atomic = {}
colors.atomic.green = '#00C853'
colors.atomic.red = '#D50000'
colors.atomic.white = '#FFFFFF'
colors.atomic.black = '#000000'
// Feed Colors
// Main pallette
colors.atomic.artylickOrange = '#FC8308' // primary 900-100%
colors.atomic.charcoal = '#020814'
colors.atomic.osloGrey = '#8F8F95' // white-50%
colors.atomic.pinkSwan = '#B8B8BC' // white-70%
colors.atomic.mirage = '#191E26' // surface-dp-01-5%
colors.atomic.dark = '#1B212C' // surface-dp-02-10%
colors.atomic.ebonyClay = '#272D37' // surface-dp-03-15%
colors.atomic.tuna = '#343943' // surface-dp-04-20%
colors.atomic.violet = '#4D2FC5' // surface-dp-04-20%
colors.atomic.bunker = '#10151d'
colors.atomic.bunkerLight = '#292E35'
colors.atomic.pineTree = '#010814'
colors.atomic.purpleHeart = '#673CD4' // secondary 500-100%
colors.atomic.whiteDarker = '#FFFFFFDE'
colors.atomic.whiteLighter = '#FFFFFFB3'
colors.atomic.chart = {}
colors.atomic.chart.green = '#69F0AE'
colors.atomic.chart.red = '#FF5252'
colors.atomic.chart.geraldine = '#FF8A80'
colors.atomic.chart.rosa = '#FF80AB'
colors.atomic.chart.darkRose = '#FF4081'
colors.atomic.chart.violet = '#EA80FC'
colors.atomic.chart.darkNeonPink = '#E040FB'
colors.atomic.chart.lightpurple = '#B388FF'
colors.atomic.chart.darkPurple = '#7C4DFF'
colors.atomic.chart.periwinkleBlue = '#8C9EFF'
colors.atomic.chart.ultraMarineBlue = '#536DFE'
colors.atomic.chart.jordyBlue = '#82B1FF'
colors.atomic.chart.dodgerBlue = '#448AFF'
colors.atomic.chart.turquoiseBlue = '#80D8FF'
colors.atomic.chart.crystalBlue = '#40C4FF'
colors.atomic.chart.tronBlue = '#84FFFF'
colors.atomic.chart.artyclickCycan = '#18FFFF'
colors.atomic.chart.lightCyan = '#A7FFEB'
colors.atomic.chart.aquamarine = '#64FFDA'
colors.atomic.chart.paleTurquoise = '#B9F6CA'
colors.atomic.chart.lightYellowishGreen = '#CCFF90'
colors.atomic.chart.lightlimeGreen = '#B2FF59'
colors.atomic.chart.yellowishTan = '#F4FF81'
colors.atomic.chart.goldenFizz = '#EEFF41'
colors.atomic.chart.butter = '#FFFF8D'
colors.atomic.chart.artyClickYellow = '#FFFF00'
colors.atomic.chart.marigoldYellow = '#FFE57F'
colors.atomic.chart.brightSun = '#FFD740'
colors.atomic.chart.grandis = '#FFD180'
colors.atomic.chart.lightOrange = '#FFAB40'
colors.atomic.chart.vividTangerine = '#FF9E80'
colors.atomic.chart.darkOrange = '#FF6E40'
colors.atomic.mildgrey = '#66696D'
colors.atomic.davyGrey = '#4e535b'
colors.atomic.statusRed = '#FB2121'
colors.atomic.statusYellow = '#EBB113'
colors.atomic.statusGreen = '#18DB38'
colors.atomic.severityNoData = '#757575'
// combinations
colors.atomic.primary = colors.atomic.artylickOrange
colors.atomic.primaryLighter = '#252931'
colors.atomic.hover = colors.atomic.ebonyClay
colors.atomic.dropdownButton = colors.atomic.violet
colors.atomic.circleIconBG = chroma(colors.white).alpha(0.1).css()
colors.atomic.feedListItemBg = colors.atomic.ebonyClay
colors.atomic.actionMenuBg = colors.atomic.bunker
colors.atomic.actionMenuItemBg = colors.atomic.tuna
colors.atomic.textMain = colors.atomic.white
colors.atomic.textSecondary = colors.atomic.whiteDarker
colors.atomic.filterButton = colors.atomic.ebonyClay
colors.atomic.insightPageBackground = colors.atomic.pineTree
colors.atomic.insightPageIcon = colors.atomic.purpleHeart
colors.atomic.multiChartColors = [colors.primary, '#0099b7', colors.mediumGrey]
colors.atomic.chartColorsV2 = ['#5470c6', '#91cc75', '#9e28cf', '#668bee', '#ea7ccc', '#3ba272', '#fc8452', '#9a60b4', '#73c0de']
colors.atomic.chartCardBG = colors.atomic.mirage
colors.atomic.borderMain = colors.atomic.mirage
colors.atomic.pieChartColors = [
  colors.atomic.chart.geraldine,
  colors.atomic.chart.red,
  colors.atomic.chart.periwinkleBlue,
  colors.atomic.chart.ultraMarineBlue,
  colors.atomic.chart.lightCyan,
  colors.atomic.chart.aquamarine,
  colors.atomic.chart.butter,
  colors.atomic.chart.artyClickYellow,
  colors.atomic.chart.rosa,
  colors.atomic.chart.darkRose,
  colors.atomic.chart.jordyBlue,
  colors.atomic.chart.dodgerBlue,
  colors.atomic.chart.paleTurquoise,
  colors.atomic.chart.green,
  colors.atomic.chart.marigoldYellow,
  colors.atomic.chart.brightSun,
  colors.atomic.chart.violet,
  colors.atomic.chart.darkNeonPink,
  colors.atomic.chart.turquoiseBlue,
  colors.atomic.chart.crystalBlue,
  colors.atomic.chart.lightYellowishGreen,
  colors.atomic.chart.lightlimeGreen,
  colors.atomic.chart.grandis,
  colors.atomic.chart.lightOrange,
  colors.atomic.chart.lightpurple,
  colors.atomic.chart.darkPurple,
  colors.atomic.chart.tronBlue,
  colors.atomic.chart.artyclickCycan,
  colors.atomic.chart.yellowishTan,
  colors.atomic.chart.goldenFizz,
  colors.atomic.chart.vividTangerine,
  colors.atomic.chart.darkOrange,
]
colors.atomic.border1 = '#3E4249'
colors.atomic.boxShadow1 = '#000000A3'
colors.atomic.arrowWrapperBg = colors.atomic.bunkerLight

colors.atomic.avatar = {}
colors.atomic.avatar.primaryBlue = '#1C1CB2' // default
colors.atomic.avatar.darkSkyBlue = '#304FFE'
colors.atomic.avatar.mediumSkyBlue = '#3D5AFE'
colors.atomic.avatar.ultraMarineBlue = colors.atomic.chart.ultraMarineBlue
colors.atomic.avatar.jordyBlue = colors.atomic.chart.jordyBlue
colors.atomic.avatar.seaBlue = '#00B8D4'
colors.atomic.avatar.mildPurple = '#6200ea'
colors.atomic.avatar.darkerPurple = '#651FFF'
colors.atomic.avatar.darkPurple = colors.atomic.chart.darkPurple
colors.atomic.avatar.purple = '#AA00FF'
colors.atomic.avatar.brightPurple = '#D500F9'
colors.atomic.avatar.pink = '#C51162'
colors.atomic.avatar.brightPink = '#F50057'
colors.atomic.avatar.darkRose = colors.atomic.chart.darkRose
colors.atomic.avatar.orange = '#FF3D00'
colors.atomic.avatar.darkOrange = colors.atomic.chart.darkOrange
colors.atomic.avatar.vividTangerine = colors.atomic.chart.vividTangerine
colors.atomic.avatar.darkSeaGreen = '#00BFA5'
colors.atomic.avatar.deepSeaGreen = '#1DE9B6'
colors.atomic.avatar.grassGreen = '#00BF59'
colors.atomic.avatar.springGreen = '#79BF00'
colors.atomic.avatar.fallGreen = '#A7BF00'
colors.atomic.avatar.goldenGreen = '#BFA600'
colors.atomic.avatar.grey = '#3E4249'
colors.atomic.healthStatusIconBG = colors.atomic.bunkerLight
colors.atomic.iconBG = colors.atomic.davyGrey
colors.atomic.divider = chroma(colors.atomic.white).alpha(0.3).css()
colors.atomic.toolsMenuBG = colors.headerBG
colors.atomic.title1 = chroma(colors.atomic.white).alpha(0.87).css()
colors.atomic.title2 = chroma(colors.atomic.white).alpha(0.7).css()
colors.atomic.toolMenuHover = colors.atomic.bunker
colors.atomic.tableBG = colors.atomic.mirage
colors.atomic.tableColumnHeaderBG = colors.atomic.ebonyClay
colors.atomic.feedItemIconColor = colors.white
colors.atomic.feedItemCategoryColor = '#808389'
colors.atomic.bigListItemBg = colors.atomic.bunker
colors.atomic.bigListItemHover = colors.atomic.bunkerLight
colors.atomic.gunmetal = '#2E323A'
colors.atomic.tableTotal = colors.atomic.bunker
colors.atomic.subTitle = '#b3b3b3'
colors.atomic.filterBadge = colors.atomic.ebonyClay
colors.atomic.feedList = colors.headerBG
colors.atomic.feedListCard = colors.talpaGrey
colors.atomic.notificationTime = colors.atomic.whiteLighter
colors.atomic.severityLampOff = '#303030'
colors.atomic.severityPriority = '#1C1CB2'
colors.atomic.severityRed = '#FB2121'
colors.atomic.severityAmber = '#FFC525'
colors.atomic.severityMalfunction = '#FA2FFE'
colors.atomic.severityProtection = '#00B8D4'
colors.atomic.badge = colors.atomic.bunkerLight
colors.atomic.cardHover = chroma(colors.solidBG).brighten(0.3).css()
colors.atomic.accordionBorder = chroma(colors.solidBG).brighten(0.5).css()
colors.atomic.accordionBG = chroma(colors.solidBG).brighten(0.5).css()

colors.atomic.healthyFill = '#66CDAA'
colors.atomic.healthyStroke = '#66CDAA'
colors.atomic.healthyBorder = '#66CDAA'
colors.atomic.seperator = '#181C22'
colors.atomic.sickFill = '#FF9999'
colors.atomic.sickStroke = '#D16060'
colors.atomic.sickBorder = '#D16060'
colors.atomic.selection = colors.atomic.primary
colors.atomic.noData = '#2E323A'
colors.atomic.unknownFill = '#2E323A'
colors.atomic.unknownBorder = '#2E323A'
colors.atomic.icon = colors.atomic.white
colors.atomic.trendLabel = colors.atomic.textMain
colors.atomic.sicknessCardBorder = '#4D4D4D'
colors.atomic.positiveTrend = colors.atomic.avatar.grassGreen
colors.atomic.negativeTrend = colors.atomic.chart.red
colors.atomic.hourSeperator = colors.atomic.seperator

export default colors
