import get from 'lodash/get'
import { decode } from 'jsonwebtoken'

function getUserIdFromToken(token) {
  const payload = getPayloadFromToken(token)
  const id = get(payload, 'sub', null)
  return id
}

function getassetIdsFromToken(token) {
  const payload = getPayloadFromToken(token)
  return get(payload, 'assets', [])
}

function getEmailFromToken(token) {
  const payload = getPayloadFromToken(token)
  return get(payload, 'email', null)
}

function getUsernameFromToken(token) {
  const payload = getPayloadFromToken(token)
  return get(payload, 'preferred_username', null)
}

function getRolesFromToken(token) {
  const payload = getPayloadFromToken(token)
  return get(payload, ['realm_access', 'roles'], [])
}

function hasArchonRoleInToken(token) {
  const roles = getRolesFromToken(token)
  return roles.includes('archon')
}

function getFirstnameFromToken(token) {
  const payload = getPayloadFromToken(token)
  return get(payload, 'given_name', null)
}

function hasAnyTalpaRoleInToken(token) {
  const adminRoles = ['talpa-basic', 'talpa-pipeline', 'talpa-product', 'archon']
  const roles = getRolesFromToken(token)
  return roles.some(role => adminRoles.includes(role))
}

function hasTalpaProductOrArchonRoleInToken(token) {
  const adminRoles = ['talpa-product', 'archon']
  const roles = getRolesFromToken(token)
  return roles.some(role => adminRoles.includes(role))
}

function getPayloadFromToken(token) {
  if (!token) {
    return {}
  }
  try {
    const payload = decode(token)
    return payload
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error while decoding the token!', err)
  }
}

export {
  getUserIdFromToken,
  getEmailFromToken,
  getassetIdsFromToken,
  getUsernameFromToken,
  getRolesFromToken,
  hasArchonRoleInToken,
  getPayloadFromToken,
  getFirstnameFromToken,
  hasAnyTalpaRoleInToken,
  hasTalpaProductOrArchonRoleInToken,
}
