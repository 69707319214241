var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelAssociatorStyled', [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('Badge', {
    attrs: {
      "label": _vm.models.length + ''
    }
  })], 1), _vm.canBulkAddAssets ? _vm._t("bulkAddAssets") : _vm._e()], 2), _c('div', {
    staticClass: "models"
  }, _vm._l(_vm.models, function (model) {
    return _c('div', {
      key: model.id,
      staticClass: "model"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(model.label) + " ")]), _c('button', {
      staticClass: "delete",
      on: {
        "click": function click($event) {
          return _vm.$emit('disconnect', model);
        }
      }
    }, [_c('XIcon')], 1)]);
  }), 0), _c('div', {
    staticClass: "add-icon"
  }, [_c('PlusCircleIcon')], 1), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "track-by": "id",
      "placeholder": "Type to search",
      "options": _vm.targetsFiltered,
      "label": _vm.targetModelLabelName,
      "searchable": true,
      "multiple": _vm.multipleSelect,
      "loading": _vm.$apollo.loading,
      "internal-search": true,
      "clear-on-select": false,
      "close-on-select": false,
      "options-limit": 300,
      "limit": 3,
      "limit-text": _vm.limitText,
      "max-height": 600,
      "show-no-results": false,
      "hide-selected": true
    },
    on: {
      "search-change": _vm.findTargets,
      "select": function select($event) {
        return _vm.$emit('connect', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }