import { defineStore } from 'pinia'

export const useStore = defineStore('userSettings', {
  state: () => {
    return {
      isMenuExpanded: false,
    }
  },
  actions: {
    setNavState(isExpanded) {
      this.isMenuExpanded = isExpanded
    },
  },
})
