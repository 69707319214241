var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('LoadMoreContainerStyled', [_vm.isLoading ? _c('LoadingDots') : _c('ButtonSolidStyled', {
    staticClass: "load-more",
    on: {
      "click": function click($event) {
        return _vm.$emit('load-more-rows');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('loadmore')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }