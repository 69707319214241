import base from './base'
import { styled } from '@egoist/vue-emotion'

export default styled(base)`
  font-size: 1rem;
  padding: ${props => (props.padding !== undefined ? props.padding : '.5rem 1rem')};
  margin: ${props => (props.margin !== undefined ? props.margin : '.25rem')};
  color: ${props => (props.transparent ? props.theme.selected.colors.secondary : props.theme.selected.colors.white)};
  transition: border-color 0.25s, color 0.25s;
  background: ${props => {
    if (props.transparent) {
      return 'transparent'
    }
    if (props.isActive) {
      return props.theme.selected.colors.secondary
    }
    return props.theme.selected.colors.primary
  }};

  ${props => (props.round ? 'border-radius: .5rem;' : '')}
  ${props => (props.border ? 'border: 1px solid ' + props.theme.selected.colors.secondary + ';' : '')}

  &.active, &:hover, &:focus {
    color: ${props => (props.transparent ? props.theme.selected.colors.primary : props.theme.selected.colors.white)};
    border-color: ${props => (props.transparent ? props.theme.selected.colors.primary : props.theme.selected.colors.white)};
    background: ${props => (props.transparent ? 'transparent' : props.theme.selected.colors.secondary)};
  }

  &:disabled {
    color: ${props => (props.transparent ? props.theme.selected.colors.lightGrey : props.theme.selected.colors.darkGrey)};
    ${props => (props.border ? 'border-color: ' + props.theme.selected.colors.lightGrey + ';' : '')}
    background: ${props => (props.transparent ? 'transparent' : props.theme.selected.colors.lightGrey)};
  }
`
