var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AutoFormStyled', [_vm._l(_vm.fields, function (field) {
    return [field.isVisible ? _c('label', {
      key: 'label_' + field.name,
      attrs: {
        "for": field.name
      }
    }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(field.name)) + " ")]) : _vm._e(), field.dataType === 'String' ? _c('input', {
      key: 'value_' + field.name,
      attrs: {
        "type": "text",
        "id": field.name,
        "disabled": field.isDisabled
      },
      domProps: {
        "value": field.value
      },
      on: {
        "input": function input($event) {
          return _vm.fieldParser(field.name, $event.target.value);
        }
      }
    }) : _vm._e(), field.dataType === 'Float' ? _c('input', {
      key: 'value_' + field.name,
      attrs: {
        "type": "text",
        "id": field.name,
        "disabled": field.isDisabled
      },
      domProps: {
        "value": field.value
      },
      on: {
        "input": function input($event) {
          return _vm.fieldParser(field.name, $event.target.value, parseFloat);
        }
      }
    }) : _vm._e(), field.dataType === 'Int' ? _c('input', {
      key: 'value_' + field.name,
      attrs: {
        "type": "number",
        "disabled": field.isDisabled,
        "id": field.name
      },
      domProps: {
        "value": field.value
      }
    }) : _vm._e(), field.dataType === 'Boolean' ? _c('OnOffToggle', {
      key: 'value_' + field.name,
      attrs: {
        "id": field.name,
        "value": field.value
      },
      on: {
        "toggle": function toggle($event) {
          return _vm.fieldParser(field.name, !field.value);
        }
      }
    }) : _vm._e(), field.dataType === 'DateTime' ? _c('input', {
      key: 'value_' + field.name,
      attrs: {
        "id": field.name,
        "type": "text",
        "disabled": ""
      },
      domProps: {
        "value": _vm._f("timeAgo")(field.value)
      }
    }) : _vm._e(), field.dataType === 'ID' ? _c('input', {
      key: 'value_' + field.name,
      attrs: {
        "id": field.name,
        "type": "text",
        "disabled": ""
      },
      domProps: {
        "value": field.value
      }
    }) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }