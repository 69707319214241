<template>
  <LoadMoreContainerStyled>
    <LoadingDots v-if="isLoading" />
    <ButtonSolidStyled class="load-more" @click="$emit('load-more-rows')" v-else>
      {{ $t('loadmore') }}
    </ButtonSolidStyled>
  </LoadMoreContainerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'
import { ButtonSolid } from '@styles/buttons'

const LoadMoreContainerStyled = styled('div')`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.solidBG};
  width: 100%;
  height: 5rem;
`

const ButtonSolidStyled = styled(ButtonSolid)`
  height: 2.5rem;
  min-width: 6rem;
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadMoreContainerStyled,
    ButtonSolidStyled,
    LoadingDots,
  },
}
</script>
