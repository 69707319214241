<template>
  <SubNavigationItemStyled>
    <router-link :to="{ name: navigationItem.name }">
      <span> {{ $t(`navigation.cockpit.${navigationItem.name}`) }} </span>
    </router-link>
  </SubNavigationItemStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const SubNavigationItemStyled = styled('div')`
  > a {
    width: 10rem;
    display: block;
    font-size: 0.85rem;
    text-decoration: none;
    color: ${p => p.theme.colors.navFontNormal};
    padding: 0.5rem;
    &:hover {
      color: ${p => p.theme.colors.primary};
      background: ${p => (p.theme.isDark ? chroma(p.theme.colors.solidBG).darken(0.5).css() : p.theme.colors.mainBG)};
    }
    &.router-link-active {
      font-weight: bold;
    }
    > span {
      justify-content: center;
      display: flex;
    }
  }
`

export default {
  props: {
    navigationItem: {
      type: Object,
    },
  },
  components: {
    SubNavigationItemStyled,
  },
}
</script>
