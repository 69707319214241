<template>
  <ValidationErrorAtom>
    {{ $t(`validationErrors.${validationError.type}`, validationError) }}
  </ValidationErrorAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const ValidationErrorAtom = styled('div')`
  font-size: 12px;
  color: red;
  float: right;
`

export default {
  props: {
    validationError: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationErrorAtom,
  },
}
</script>
