<template>
  <HotkeyExplanationAtom>
    <slot name="hotkey" />
    <span class="explanation">{{ explanation }}</span>
  </HotkeyExplanationAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const HotkeyExplanationAtom = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .explanation {
    font-size: 12px;
  }
  > strong {
    margin-bottom: 0.1rem;
  }
`

export default {
  props: {
    explanation: {
      type: String,
      required: true,
    },
  },
  components: {
    HotkeyExplanationAtom,
  },
}
</script>
