<template>
  <ScrollToTopStyled v-if="isVisible" class="scroll-to-top" @click="scrollToTop" :title="$t('backToTop')">
    <ChevronUpIcon size="1.5x" />
  </ScrollToTopStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronUpIcon } from 'vue-feather-icons'

const ScrollToTopStyled = styled('div')`
  display: flex;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  position: fixed;
  bottom: 5rem;
  right: 2.5rem;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
  z-index: 9999;
`
export default {
  props: {
    containerId: {
      type: String,
      required: true,
    },
    scrollThreshold: {
      type: Number,
      default: 300,
    },
  },
  components: {
    ScrollToTopStyled,
    ChevronUpIcon,
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    const container = document.getElementById(this.containerId)
    if (container) {
      container.addEventListener('scroll', this.handleScroll)
    }
  },
  beforeDestroy() {
    const container = document.getElementById(this.containerId)
    if (container) {
      container.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll() {
      const container = document.getElementById(this.containerId)
      if (container.scrollTop > this.scrollThreshold) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },
    scrollToTop() {
      const container = document.getElementById(this.containerId)
      if (container) {
        container.scroll({ top: 0, behavior: 'smooth' })
      }
    },
  },
}
</script>
