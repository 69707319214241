var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsBellMoleculeStyled', {
    class: {
      active: _vm.isActive
    }
  }, [_c('BellIcon'), _vm.unreadCount > 0 ? _c('CountStyled', {
    class: {
      max: _vm.unreadCount > 99
    }
  }, [_vm._v(" " + _vm._s(_vm.unreadCount > 99 ? '99+' : _vm.unreadCount) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }