var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ThresholdValueStyled', [_c('div', {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.$t("planning.".concat(_vm.thresholdType.toLowerCase()))))]), _c('div', {
    staticClass: "value"
  }, [_c('div', [_vm._v(_vm._s(_vm.value))]), _vm.unit ? _c('div', [_vm._v(_vm._s(_vm.unit))]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }