<template>
  <PasteIdsStyled @click="activatePasteIdsOverlay">
    Paste IDs
    <FileTextIcon />
  </PasteIdsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { FileTextIcon } from 'vue-feather-icons'
import { flexCenter } from '@styles/mixins'

const PasteIdsStyled = styled('div')`
  font-size: 14px;
  cursor: pointer;
  ${flexCenter};
  height: 40px;
  background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  border-radius: 0.5rem;
  padding: 10px;
  svg {
    margin-left: 0.5rem;
  }
  &:hover {
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
  }
`

export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    PasteIdsStyled,
    FileTextIcon,
  },
  methods: {
    activatePasteIdsOverlay() {
      this.$root.$emit('activateOverlay', 'PasteIdsOverlay', { mode: this.mode }, () => this.$emit('refetch'))
    },
  },
}
</script>
