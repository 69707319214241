<template>
  <OpenSearchButtonAtom :class="{ hidden: searchOverlayVisible }">
    <button @click="toggleSearchOverlayVisible" :class="{ active: searchOverlayVisible }">
      <SearchIcon />
      <span>{{ $t('actions.search') }}</span>
      <kbd>{{ hotkeyCombination }}</kbd>
    </button>
  </OpenSearchButtonAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { SearchIcon } from 'vue-feather-icons'

import { buttonReset, flexCenter } from '@styles/mixins'

const OpenSearchButtonAtom = styled('div')`
  ${flexCenter}
  opacity: 1;
  transition: opacity 0.25s;
  &.hidden {
    opacity: 0;
  }
  > button {
    ${buttonReset}
    ${flexCenter}
    gap: 0.5rem;
    background: ${({ theme }) => theme.colors.archonBlack};
    border: 1px solid ${({ theme }) => theme.colors.darkGrey};
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.archonBlue};
    }
    .feather {
      width: 18px;
      height: 18px;
    }
    > span {
      margin-top: 2px;
    }
    > kbd {
      color: ${({ theme }) => theme.colors.mediumGrey};
      border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
      border-radius: 0.25rem;
      padding: 6px 4px 2px 4px;
      font-size: 11px;
    }
  }
`

export default {
  components: {
    OpenSearchButtonAtom,
    SearchIcon,
  },
  data() {
    return {
      searchOverlayVisible: false,
    }
  },
  created() {
    this.$root.$on('overlayClosed', () => {
      this.searchOverlayVisible = false
    })
  },
  mounted() {
    document.addEventListener('keydown', this.onKeydown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeydown)
  },
  computed: {
    hotkeyCombination() {
      return this.isMacLike ? 'cmd+K' : 'ctrl+K'
    },
    isMacLike() {
      return /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)
    },
  },
  methods: {
    toggleSearchOverlayVisible() {
      if (this.searchOverlayVisible === true) {
        this.searchOverlayVisible = false
        this.$emit('close-search')
      } else {
        this.searchOverlayVisible = true
        this.$emit('open-search')
      }
    },
    onKeydown(e) {
      if (e.code === 'KeyK' && (e.ctrlKey === true || e.metaKey === true)) {
        e.preventDefault()
        this.toggleSearchOverlayVisible()
      }
      if (this.searchOverlayVisible) {
        if (e.code === 'Escape') {
          this.searchOverlayVisible = false
          this.$emit('close-search')
          e.preventDefault()
        }
      }
    },
  },
}
</script>
