var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceGoalsListStyled', _vm._l(_vm.performanceGoals, function (goal, index) {
    return _c('PerformanceGoalItemMolecule', {
      key: index,
      attrs: {
        "performanceGoal": goal,
        "index": index
      },
      on: {
        "removeGoal": function removeGoal($event) {
          return _vm.$emit('removeGoal', $event);
        },
        "updateGoal": function updateGoal($event) {
          return _vm.$emit('updateGoal', $event);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }