<template>
  <TimeSettingsStyled>
    <TimeSettingStyled
      v-for="ts in timeSettings"
      :key="ts.id"
      :isActive="ts.isActive"
      :class="{ active: ts.isActive }"
      @click="setTimeSetting(ts.id)"
    >
      <span>{{ ts.name }}</span>
      <transition name="fade">
        <CheckIcon v-if="ts.isActive" />
      </transition>
    </TimeSettingStyled>
  </TimeSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { getUserIdFromToken } from '@common/utils'

import UPDATE_UI_SETTINGS_MUTATION from '#/graphql/user/uiSettings/update.gql'

import { CheckIcon } from 'vue-feather-icons'

import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'

const TimeSettingsStyled = styled('div')`
  ${flexColumns}
`

const TimeSettingStyled = styled('div')`
  ${flexCenter}
  ${props => buttonReset(props)}
  position: relative;
  margin-top: 0.25rem;
  padding: 0.5rem 2rem;
  height: 2rem;
  align-items: center;
  .feather {
    position: absolute;
    top: 0.75rem;
    right: 0;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    TimeSettingsStyled,
    TimeSettingStyled,
    CheckIcon,
  },
  inject: ['uiSettings'],
  computed: {
    timeSettings() {
      const activeTimeSetting = get(this.uiSettings, 'time')
      return ['AM_PM_FORMAT', 'TWENTYFOUR_FORMAT'].map(id => ({
        id,
        name: this.$t(`uiSettings.time.${id}`),
        isActive: activeTimeSetting === id,
      }))
    },
  },
  methods: {
    async setTimeSetting(id) {
      await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            time: id,
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
    },
  },
}
</script>
