<template>
  <ButtonSolid @click="$emit('click', $event)" :disabled="disabled" v-tooltip.bottom-end="tooltip">
    <slot />
  </ButtonSolid>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import chroma from 'chroma-js'
import { VTooltip } from 'v-tooltip'

const ButtonSolid = styled('button')`
  ${flexCenter}
  width: min-content;
  cursor: pointer;
  outline: none;
  user-select: none;
  background: none;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  transition: 0.5s background-color;
  gap: 0.5rem;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }

  &:disabled {
    cursor: default;
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    tooltipText: {
      type: String,
    },
  },
  components: {
    ButtonSolid,
  },
  directives: {
    tooltip: VTooltip,
  },
  computed: {
    tooltip() {
      return {
        content: this.tooltipText,
        boundariesElement: '#app',
        classes: 'count',
        trigger: 'hover',
      }
    },
  },
}
</script>
