import { distinctColors } from '@styles/themes'

const data = {
  uiToggleables: [
    {
      id: 'header',
      isAvailable: true,
      isActive: true,
      __typename: 'UIToggleable',
    },
    {
      id: 'footer',
      isAvailable: false,
      isActive: false,
      __typename: 'UIToggleable',
    },
    {
      id: 'navigation',
      isAvailable: true,
      isActive: false,
      __typename: 'UIToggleable',
    },
    {
      id: 'notifications',
      isAvailable: true,
      isActive: false,
      __typename: 'UIToggleable',
    },
  ],
  selectedSettings: null,
  feedCardSelectedTime: null,
  selectedAssetFilter: [],
  selectedSignals: [],
  hiddenSignals: [],
  expandedSignals: [],
  loadingSignals: [],
  loadedSignals: [],
  yAxisBeginZeroSignals: [],
  hiddenAssets: [],
  distinctColors: distinctColors(10, true),
  colorMapping: [],
  signalIntervals: [],
  signalSelectedAsset: null,
  aggregatorMapping: [],
  dashboardsViewMode: 'grid',
}

export default data
