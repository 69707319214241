var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ButtonSolid', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom-end",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "bottom-end": true
      }
    }],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }