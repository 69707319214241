var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchResultMolecule', {
    class: {
      'is-highlighted': _vm.item.isHighlighted,
      'is-current-route': _vm.item.isCurrentRoute
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm.item.__typename === 'User' ? _c('UserIcon') : _vm._e(), _vm.item.__typename === 'Organization' ? _c('BriefcaseIcon') : _vm._e(), _vm.item.__typename === 'Subsidiary' ? _c('UsersIcon') : _vm._e(), _vm.item.__typename === 'Site' ? _c('MapIcon') : _vm._e(), _vm.isAsset ? _c('TalpaIcon', {
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.assetTypeName
    }
  }) : _vm._e(), _vm.item.__typename === 'App' || _vm.item.__typename === 'Bundle' ? _c('ShoppingBagIcon') : _vm._e(), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('CopyToClipboardAtom', {
    staticClass: "copy-to-clipboard",
    attrs: {
      "value": _vm.item.id,
      "tooltipContent": 'Copy ID to clipboard'
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }