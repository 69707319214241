<template>
  <PopoverWrapperStyled>
    <VPopover
      :placement="placement"
      :open.sync="isActive"
      :popperOptions="popoverOptions"
      :popoverClass="popoverClass"
      :container="'#app'"
      :disabled="disabled"
      :trigger="trigger"
    >
      <slot name="trigger" class="trigger" />
      <template v-slot:popover>
        <PopoverInnerWrapperStyled :isShort="isShort">
          <div class="content">
            <div class="title" v-if="popoverTitle">
              <slot name="titleIcon" />
              {{ popoverTitle }}
            </div>
            <div class="info">
              <slot name="icon" />
              <div v-if="popoverTextList" class="popoverList"><div  v-for="(list,i ) in popoverTextList" :key="i">{{list.text}}</div></div>
              <span v-else> {{ popoverContent }} </span>
            </div>
          </div>
          <ActionsStyled v-if="displayActionButtons">
            <div @click="closePopUp()">
              <slot name="closeButton" />
            </div>
          </ActionsStyled>
        </PopoverInnerWrapperStyled>
      </template>
    </VPopover>
  </PopoverWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VPopover, VClosePopover } from 'v-tooltip'
import chroma from 'chroma-js'
import { flexCenter } from '@styles/mixins'

const PopoverWrapperStyled = styled('div')`
  ${flexCenter}
  .v-popover {
    width: 100%;
  }
  .trigger {
    width: 100%;
    display: flex !important;
  }
`
const PopoverInnerWrapperStyled = styled('div')`
  width: 14rem;
  justify-content: left;
  box-sizing: border-box;
  padding: 1rem;
  color: ${p => p.theme.colors.navFontNormal};
  background-color: ${p => chroma(p.theme.colors.solidBG).brighter(0.2).css()};
  border-radius: 10px;

  .content {
    display: flex;
    justify-content: flex-start;
    padding: ${p => (p.isShort ? 0 : '0.4rem 0.8rem 0.4rem 0.8rem')};
    font-size: 12px;
    font-weight: lighter;
    flex-direction: column;
    grid-gap: ${p => (p.isShort ? '0.2rem' : '1rem')};
  }

  .title {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    grid-gap: 0.5rem;
    font-weight: normal;
    align-items: center;
    > .feather {
      stroke: ${p => p.theme.colors.primary};
    }
  }

  .info {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    > .feather {
      padding-right: 8px;
      stroke: ${({ theme }) => theme.colors.primary};
    }
    > span {
      width: 100%;
    }
    > .popoverList {
      overflow:auto;
      max-height: 8rem;
    }
  }

  @media (min-width: 768px) {
    width: auto;
    max-width: 20rem;
  }
`
const ActionsStyled = styled('div')`
  display: flex;
  justify-content: center;
  padding: 0.2rem;
`

export default {
  props: {
    displayActionButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    popperOptions: {
      type: Boolean,
      required: false,
    },
    popoverContent: {
      type: String,
      required: true,
    },
    popoverTitle: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placement: {
      type: String,
      required: false,
      default: 'top-start',
    },
    trigger: {
      type: String,
      required: false,
      default: 'hover',
    },
    isShort: {
      type: Boolean,
      required: false,
      default: false,
    },
    popoverClass: {
      type: String,
      required: false,
      default: 'userGuidePopOver',
    },
    popoverTextList: {
      type: Array,
      required: false,
    },
  },
  directives: {
    closePopover: VClosePopover,
  },
  components: {
    PopoverWrapperStyled,
    PopoverInnerWrapperStyled,
    VPopover,
    ActionsStyled,
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    popoverOptions() {
      if (this.popperOptions) {
        return this.popperOptions
      }
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    closePopUp() {
      this.isActive = false
    },
  },
}
</script>
