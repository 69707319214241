<template>
  <SearchResultMolecule @click="handleClick" :class="{ 'is-highlighted': item.isHighlighted, 'is-current-route': item.isCurrentRoute }">
    <UserIcon v-if="item.__typename === 'User'" />
    <BriefcaseIcon v-if="item.__typename === 'Organization'" />
    <UsersIcon v-if="item.__typename === 'Subsidiary'" />
    <MapIcon v-if="item.__typename === 'Site'" />
    <TalpaIcon v-if="isAsset" :scope="'AssetTypes'" :name="assetTypeName" />
    <ShoppingBagIcon v-if="item.__typename === 'App' || item.__typename === 'Bundle'" />
    <div class="label">{{ label }}</div>
    <CopyToClipboardAtom class="copy-to-clipboard" :value="item.id" :tooltipContent="'Copy ID to clipboard'" />
  </SearchResultMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { UserIcon, BriefcaseIcon, UsersIcon, MapIcon, ShoppingBagIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'

import { TalpaIcon } from '@common/components'
import { buttonReset } from '@styles/mixins'

import CopyToClipboardAtom from '../CopyToClipboardAtom'

import ASSET_IMPLEMENTATIONS_QUERY from '#/graphql/advancedSearch/assetImplementations.gql'

const SearchResultMolecule = styled('button')`
  ${buttonReset}
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 2.5rem 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: ${({ theme }) => theme.colors.widgetShadowEnforced};
  color: ${({ theme }) => theme.colors.archonBlue};
  background: ${({ theme }) => chroma(theme.colors.archonBlack).darker(0.3).css()};

  &.is-highlighted,
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.archonBlue};
  }

  &.is-current-route {
    cursor: default;
    color: ${({ theme }) => theme.colors.lightGrey};
    background: ${({ theme }) => chroma(theme.colors.archonBlack).darker(0.15).css()};
  }

  > .copy-to-clipboard {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
    &:focus {
      outline: none;
    }
  }
`

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    SearchResultMolecule,
    UserIcon,
    BriefcaseIcon,
    UsersIcon,
    MapIcon,
    TalpaIcon,
    CopyToClipboardAtom,
    ShoppingBagIcon,
  },
  data() {
    return {
      assetImplementations: [],
    }
  },
  computed: {
    isAsset() {
      return (this.assetImplementations?.possibleTypes ?? []).some(s => s.name === this.item.__typename)
    },
    assetTypeName() {
      if (!this.isAsset) {
        return ''
      }
      return (this.item?.type?.name ?? '').split(' ').join('')
    },
    label() {
      if (this.item.__typename === 'User') {
        return `${this.item.name} - ${this.item.email}`
      }
      if (this.item.__typename === 'Organization') {
        return `${this.item.alias} - ${this.item.name}`
      }
      if (this.item.__typename === 'Subsidiary') {
        return `${this.item.name} (${this.item?.organization.name})`
      }
      return this.item?.name ?? 'unknown'
    },
    isCurrentRoute() {
      return this.$route?.name === this.item?.routeName && this.$route?.params?.id === this.item?.id
    },
  },
  methods: {
    handleClick() {
      if (!this.item?.isCurrentRoute) {
        this.$emit('goto-item', this.item)
      }
    },
  },
  apollo: {
    assetImplementations: {
      query: ASSET_IMPLEMENTATIONS_QUERY,
    },
  },
}
</script>
