<template>
  <WheelAssetManagerStyled>
    <div>
      <h3>{{ title }}</h3>
    </div>
    <FormWrapperStyled>
      <AssetWheelForm v-if="isadding">
        <div>
          <div class="input-fields">
            <InputField :labelWidth="6" class="input">
              <template v-slot:input>
                <input type="number" v-model.trim="$v.axle.$model" placeholder="Axle" />
              </template>
              <template v-slot:label>
                <span>Axle</span>
              </template>
              <template v-slot:errors v-if="$v.axle.$dirty">
                <div class="error" v-if="!$v.axle.required">{{ $tc('messages.required') }}</div>
              </template>
            </InputField>
            <InputField :labelWidth="8" class="input">
              <template v-slot:input>
                <input type="number" v-model.trim="$v.axlePosition.$model" placeholder="Axle Position" />
              </template>
              <template v-slot:label>
                <span>Axle Position</span>
              </template>
              <template v-slot:errors v-if="$v.axlePosition.$dirty">
                <div class="error" v-if="!$v.axlePosition.required">{{ $tc('messages.required') }}</div>
                <div class="error" v-if="!$v.axlePosition.minLength">
                  {{ $t('messages.minValue', { value: $v.axlePosition.$params.minLength.min }) }}
                </div>
                <div class="error" v-if="!$v.axlePosition.maxLength">
                  {{ $t('messages.maxValue', { value: $v.axlePosition.$params.maxLength.max }) }}
                </div>
                <div class="error" v-if="!$v.axlePosition.isUnique">{{ $tc('messages.notUnique') }}</div>
              </template>
            </InputField>
            <div class="input">
              <BasicSelect
                :target="'Tyre'"
                :options="usableTyres"
                :selected="tyreSelectedWhileCreation"
                @change="tyreSelectedWhileCreation = $event"
              />
            </div>
          </div>
          <div class="signals">
            <h4>Select ULAN Signals</h4>
            <div>
              <span class="title"> Temperature </span>
              <Multiselect
                :options="signalsNames"
                :value="ulanTemperatureSignalName"
                :loading="$apollo.queries.signalsByAsset.loading"
                @select="ulanTemperatureSignalName = $event"
              />
            </div>
            <div>
              <span class="title"> Pressure </span>
              <Multiselect
                :options="signalsNames"
                :value="ulanPressureSignalName"
                :loading="$apollo.queries.signalsByAsset.loading"
                @select="ulanPressureSignalName = $event"
              />
            </div>
          </div>
        </div>
        <div @click="createWheelPosition" class="add-icon">
          <div>Add</div>
          <PlusCircleIcon />
        </div>
      </AssetWheelForm>
      <div v-else class="add-button add-icon" @click="isadding = true">Add a new wheel Position</div>
      <TalpaLoaderWrapper v-if="$apollo.loading" />
      <div v-else-if="assetWheelPositions.length < 1" class="alert">No wheel Positions, use above button to add one.</div>
      <table v-else>
        <tr>
          <th>Axle</th>
          <th>Axle Position</th>
          <th>Wheel Assigned</th>
          <th>Temperature Signal</th>
          <th>Pressure Signal</th>
          <th>Tyre</th>
          <th>Mounted At</th>
          <th>Unmounted At</th>
        </tr>
        <WheelPositionStyled
          v-for="wheelPosition in assetWheelPositions"
          :key="wheelPosition.id"
          :class="{ disabled: wheelPosition.unmountedAt }"
        >
          <td>{{ wheelPosition.axle }}</td>
          <td>{{ wheelPosition.axlePosition }}</td>
          <td>
            <span v-if="wheelPosition.wheel">{{ wheelPosition.wheel.id }}</span>
            <span v-else>false</span>
          </td>
          <td>
            <Multiselect
              :options="signalsNames"
              :value="wheelPosition.ulanTemperatureSignalName"
              @select="updateSignalNames($event, 'ulanTemperatureSignalName', wheelPosition)"
              :disabled="!!wheelPosition.unmountedAt"
            />
          </td>
          <td>
            <Multiselect
              :options="signalsNames"
              :value="wheelPosition.ulanPressureSignalName"
              @select="updateSignalNames($event, 'ulanPressureSignalName', wheelPosition)"
              :disabled="!!wheelPosition.unmountedAt"
            />
          </td>
          <td>
            <div class="tyre-cell" v-if="getFittedTyre(wheelPosition)">
              {{ getFittedTyre(wheelPosition).tyre.model }}-{{ getFittedTyre(wheelPosition).tyre.serialNumber }}
              <XCircleIcon class="add-icon remove-tyre" @click="confirmDelete(getFittedTyre(wheelPosition), 'tyreUnmount', unMountTyre)">
              </XCircleIcon>
            </div>
            <div v-else-if="wheelPosition.unmountedAt !== null">Tyre Unmounted</div>
            <div v-else>
              <BasicSelect :target="''" :options="usableTyres" @change="assignTyre($event, wheelPosition)" />
            </div>
          </td>
          <td>{{ getTime(wheelPosition.mountedAt) }}</td>
          <td>
            <div
              class="add-button add-icon unmount-button"
              @click="confirmDelete(wheelPosition, 'wheelPosition', unMountWheelPosition)"
              v-if="getTime(wheelPosition.unmountedAt) === '-'"
            >
              Unmount
            </div>
            <span v-else>
              {{ getTime(wheelPosition.unmountedAt) }}
            </span>
          </td>
        </WheelPositionStyled>
      </table>
    </FormWrapperStyled>
  </WheelAssetManagerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexCenter } from '@styles/mixins'

import { FlashMessages } from '@common/singletons'
import { InputField, BasicSelect } from '@common/components'
import TalpaLoaderWrapper from './TalpaLoaderWrapper'
import { XCircleIcon, PlusCircleIcon } from 'vue-feather-icons'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'
import get from 'lodash/get'
import ASSET_WHEELPOSITION_QUERY from '#/graphql/operations/asset/wheelposition/assetWheelPositions.gql'
import ALL_TYRES from '#/graphql/tyres/allTyres.gql'
import SIGNALS_LIST_QUERY from '#/graphql/signals/list.gql'
import Multiselect from 'vue-multiselect'

import CREATE_ASSET_WHEEL_POSITION_MUTATION from '#/graphql/operations/asset/wheelposition/createAssetWheelPosition.gql'
import UPDATE_ASSET_WHEEL_POSITION_MUTATION from '#/graphql/operations/asset/wheelposition/updateAssetWheelPosition.gql'
import UPDATE_WHEEL_MUTATION from '#/graphql/wheels/updateWheel.gql'
import UPDATE_TYRE_WHEEL_FIT_MUTATION from '#/graphql/tyreWheelFits/updateTyreWheelFit.gql'

const WheelAssetManagerStyled = styled('div')`
  grid-area: asset-wheel-relation;
  padding: 0 2rem;
  overflow-x: auto;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  .tyre-cell {
    ${flexCenter}
    .remove-tyre {
      border-radius: 50%;
      margin-left: 0.5rem;
      padding: 0.25rem 0.25rem;
      height: 20px;
      width: 20px;
    }
  }
`
const FormWrapperStyled = styled('div')`
  .alert {
    padding: 10px 0;
  }
  .add-icon {
    ${flexCenter}
    width: fit-content;
    padding: 0 0.5rem;
    height: 40px;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    cursor: pointer;
    > div {
      margin-right: 8px;
    }
    &:hover {
      background: ${p => p.theme.colors.archonBlue};
    }
  }
  .save-icon {
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    cursor: pointer;
    margin-left: 0.5rem;
    &:hover {
      background: ${p => p.theme.colors.archonBlue};
    }
  }
  .add-button {
    margin: 0.5rem 0rem;
  }
  .unmount-button {
    display: inline-flex;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 8px;
  }

  table,
  th,
  td {
    border: ${p => `2px solid ${p.theme.colors.archonBlue}`};
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    padding: 0.5rem;
  }
`
const AssetWheelForm = styled('div')`
  .input {
    padding-right: 0.5rem;
  }
  .input-fields {
    display: flex;
  }
  .signals {
    width: 50%;
    .title {
      width: 10rem;
    }
    > div {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }
    .multiselect__option--highlight {
      background: ${p => p.theme.colors.archonBlue};
    }
  }
`
const WheelPositionStyled = styled('tr')`
  text-align: center;
  &.disabled {
    color: ${p => p.theme.colors.mediumGrey};
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    assetID: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
  },
  components: {
    WheelAssetManagerStyled,
    FormWrapperStyled,
    InputField,
    AssetWheelForm,
    PlusCircleIcon,
    XCircleIcon,
    WheelPositionStyled,
    BasicSelect,
    Multiselect,
    TalpaLoaderWrapper,
  },
  validations: {
    axle: {
      required,
      minLength: minLength(1),
    },
    axlePosition: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      isUnique() {
        const found = this.assetWheelPositions.find(
          wheelPosition => wheelPosition.axlePosition === parseInt(this.axlePosition) && !wheelPosition.unmountedAt,
        )
        if (found) {
          return false
        }
        return true
      },
    },
  },
  data() {
    return {
      assetWheelPositions: [],
      usableTyres: [],
      isadding: false,
      axle: null,
      axlePosition: null,
      tyreSelectedWhileCreation: null,
      ulanTemperatureSignalName: null,
      ulanPressureSignalName: null,
      isSignalNameEditing: false,
      signalsByAsset: [],
    }
  },
  mounted() {
    this.$apollo.queries.usableTyres.refetch()
  },
  computed: {
    signalsNames() {
      return this.signalsByAsset.map(({ longName }) => longName)
    },
  },
  methods: {
    updateSignal(wheelPosition, field, value) {
      wheelPosition[field] = value
      wheelPosition.isSignalNameEditing = true
    },
    getTime(dateTime) {
      if (dateTime === null) {
        return '-'
      }
      const locale = get(this.uiSettings, 'dates').toLowerCase().replace('_', '-')
      return DateTime.fromISO(dateTime).setLocale(locale).toLocaleString(DateTime.DATE_MED)
    },
    confirmDelete(model, type, fn) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: type,
        instance: model,
        onConfirm: fn,
        onConfirmArgs: {
          ...model,
        },
      })
    },
    onCloseSettings() {
      this.$root.$emit('closeOverlay', true)
    },
    async createWheelPosition() {
      this.isadding = false
      let tyreFits = []
      const found = this.assetWheelPositions.find(
        w => w.unmountedAt === null && w.axle === parseInt(this.axle) && w.axlePosition === parseInt(this.axlePosition),
      )
      if (found) {
        return FlashMessages.$emit('error', new Error(`Wheel position already exists on axle`))
      }
      if (!this.axle || !this.axlePosition) {
        return FlashMessages.$emit('error', new Error(`Wheel position cannot be created without axle and axlePosition`))
      }
      if (this.tyreSelectedWhileCreation !== null) {
        tyreFits = {
          create: [
            {
              tyre: {
                connect: {
                  id: this.tyreSelectedWhileCreation.id,
                },
              },
            },
          ],
        }
      }
      await this.$apollo.mutate({
        mutation: CREATE_ASSET_WHEEL_POSITION_MUTATION,
        variables: {
          data: {
            axle: parseInt(this.axle),
            axlePosition: parseInt(this.axlePosition),
            assetId: this.assetID,
            ulanTemperatureSignalName: this.ulanTemperatureSignalName,
            ulanPressureSignalName: this.ulanPressureSignalName,
            wheel: {
              create: {
                tyreFits: tyreFits,
              },
            },
          },
        },
        update: () => {
          this.ulanTemperatureSignalName = null
          this.ulanPressureSignalName = null
          this.axle = null
          this.axlePosition = null
          this.tyreSelectedWhileCreation = null
          this.$v.$reset()
        },
      })
      this.$apollo.queries.assetWheelPositions.refetch()
      this.$apollo.queries.usableTyres.refetch()
    },
    getFittedTyre(wheelPosition) {
      const tyreFits = get(wheelPosition, 'wheel.tyreFits', [])
      return tyreFits.find(t => t.unfittedAt === null)
    },
    async assignTyre(tyre, wheelPosition) {
      await this.$apollo.mutate({
        mutation: UPDATE_WHEEL_MUTATION,
        variables: {
          where: {
            id: wheelPosition.wheel.id,
          },
          data: {
            updatedAt: {
              set: DateTime.now(),
            },
            tyreFits: {
              create: {
                tyre: {
                  connect: {
                    id: tyre.id,
                  },
                },
              },
            },
          },
        },
      })
      this.$apollo.queries.assetWheelPositions.refetch()
      this.$apollo.queries.usableTyres.refetch()
    },
    async unMountWheelPosition(wheelPosition) {
      //get the current tyre wheel fit
      try {
        const tyreFit = wheelPosition.wheel.tyreFits.find(f => f.unfittedAt === null)
        const data = {}
        if (tyreFit) {
          data.wheel = {
            update: {
              tyreFits: {
                update: [
                  {
                    where: {
                      id: tyreFit.id,
                    },
                    data: {
                      unfittedAt: {
                        set: DateTime.now(),
                      },
                    },
                  },
                ],
              },
            },
          }
        }
        data.unmountedAt = {
          set: DateTime.now(),
        }
        await this.$apollo.mutate({
          mutation: UPDATE_ASSET_WHEEL_POSITION_MUTATION,
          variables: {
            where: {
              id: wheelPosition.id,
            },
            data,
          },
        })
        this.$root.$emit('closeOverlay')
        this.$apollo.queries.assetWheelPositions.refetch()
        this.$apollo.queries.usableTyres.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async unMountTyre(tyreFit) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_TYRE_WHEEL_FIT_MUTATION,
          variables: {
            where: {
              id: tyreFit.id,
            },
            data: {
              unfittedAt: {
                set: DateTime.now(),
              },
            },
          },
        })
        this.$root.$emit('closeOverlay')
        this.$apollo.queries.assetWheelPositions.refetch()
        this.$apollo.queries.usableTyres.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async updateSignalNames(event, signal, wheelPosition) {
      wheelPosition[signal] = event
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_ASSET_WHEEL_POSITION_MUTATION,
          variables: {
            where: {
              id: wheelPosition.id,
            },
            data: {
              ulanTemperatureSignalName: {
                set: wheelPosition.ulanTemperatureSignalName,
              },
              ulanPressureSignalName: {
                set: wheelPosition.ulanPressureSignalName,
              },
            },
          },
        })
        wheelPosition.isSignalNameEditing = false
        this.$apollo.queries.assetWheelPositions.refetch()
        this.$apollo.queries.usableTyres.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    assetWheelPositions: {
      query: ASSET_WHEELPOSITION_QUERY,
      variables() {
        return {
          id: this.assetID,
        }
      },
      skip() {
        return !this.assetID
      },
    },
    usableTyres: {
      query: ALL_TYRES,
      update({ tyres }) {
        return tyres
          .filter(t => !t.isEndOfLife && (t.wheelFits.length === 0 || t.wheelFits.every(w => w.unfittedAt != null)))
          .map(t => {
            return {
              ...t,
              label: `${t.model}-${t.serialNumber}`,
            }
          })
      },
    },
    signalsByAsset: {
      query: SIGNALS_LIST_QUERY,
      variables() {
        return {
          assetId: this.assetID,
        }
      },
      skip() {
        return !this.assetID
      },
    },
  },
  watch: {
    assetID: {
      handler() {
        this.isadding = false
      },
    },
  },
}
</script>
