<template>
  <MinMaxValueStyled>
    <div>{{ $t('planning.min') }}</div>
    <div class="input">
      <div class="type">{{ min }}</div>
      <div class="unit" v-if="unit" :title="unit">{{ unit }}</div>
    </div>
    <div>{{ $t('planning.max') }}</div>
    <div class="input">
      <div class="type">{{ max }}</div>
      <div class="unit" v-if="unit" :title="unit">{{ unit }}</div>
    </div>
  </MinMaxValueStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const MinMaxValueStyled = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.atomic.iconBG};
  color: ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.7).css()};
  font-size: 14px;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  width: auto;
  .input {
    display: flex;
    flex-direction: row;
    background: ${p => chroma(p.theme.colors.atomic.healthStatusIconBG).css()};
    align-items: center;
    justify-content: center;
    padding: 0 0.2rem;
    font-size: 12px;
    flex-wrap: wrap;
    gap: 0.25rem;
    .type{
      text-transform: capitalize;
    }
    .unit{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`

export default {
  components: {
    MinMaxValueStyled,
  },
  props: {
    thresholdType: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    unit: {
      type: String,
    },
  },
}
</script>
