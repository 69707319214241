var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModalStyled', [_c('header', [_vm._t("header")], 2), _c('main', {
    class: {
      'has-padding': _vm.hasPadding
    }
  }, [_vm._t("main")], 2), _c('footer', [_vm._t("footer"), _vm.hasErrors ? _c('transition-group', {
    attrs: {
      "tag": "div",
      "name": "list"
    }
  }, _vm._l(_vm.mappedErrors, function (error) {
    return _c('ErrorStyled', {
      key: error.id
    }, [_c('div', {
      staticClass: "time"
    }, [_vm._v(_vm._s(_vm._f("time")(error.time)))]), _c('div', {
      staticClass: "message"
    }, [_vm._v(_vm._s(error.message))]), _c('ButtonStyleless', {
      on: {
        "click": function click($event) {
          return _vm.removeError(error.id);
        }
      }
    }, [_c('XIcon')], 1)], 1);
  }), 1) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }