var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainNavigationItemStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_vm.subNavigationItems.length > 1 ? [_c('VPopover', {
    attrs: {
      "trigger": 'hover',
      "placement": 'right-start',
      "autoHide": false,
      "popperOptions": _vm.popperOptions,
      "container": '#app'
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('SubNavigationItemsWrapperStyled', [_c('SubNavigationItemsStyled', {
          attrs: {
            "isMenuExpanded": _vm.isMenuExpanded
          }
        }, _vm._l(_vm.subNavigationItems, function (subNavigationItem, i) {
          return _c('SubNavigationItem', {
            key: i,
            attrs: {
              "navigationItem": subNavigationItem
            }
          });
        }), 1)], 1)];
      },
      proxy: true
    }], null, false, 3075692397)
  }, [[_c('MainNavigationItemLink', {
    attrs: {
      "hasActiveSubMenuItem": _vm.hasActiveSubMenuItem,
      "routeMetaName": _vm.routeMetaName,
      "routeTo": _vm.to,
      "hasSubMenu": true,
      "icon": _vm.icon
    }
  })]], 2)] : _vm.subNavigationItems.length === 1 ? [_c('MainNavigationItemLink', {
    class: _vm.routeMetaName,
    attrs: {
      "routeMetaName": _vm.routeMetaName,
      "icon": _vm.icon,
      "routeTo": _vm.to,
      "hasSubMenu": false,
      "hasActiveParent": _vm.navigationItem.activeByParent
    }
  })] : [_c('MainNavigationItemLink', {
    attrs: {
      "routeMetaName": _vm.routeMetaName,
      "routeTo": _vm.to,
      "icon": _vm.icon,
      "count": _vm.routeMetaName === 'dashboards' ? _vm.dashboards.length : undefined
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }