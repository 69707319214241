// import chroma from 'chroma-js'

export function panel(p) {
  return `
    border-radius: 3px;
    padding: .5rem;
    background-color: ${p.theme.colors.solidBG};
    box-shadow: ${p.theme.colors.widgetShadow};

    h2 {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      margin-top: 0;
      text-transform: uppercase;
      color: ${p.theme.colors.muted};
    }
  `
}
