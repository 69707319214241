var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.query !== undefined ? _c('NoSearchResultsAtom', [_vm._v(" " + _vm._s(_vm.$t('messages.searchNoResults', {
    query: _vm.query
  })) + " ")]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }