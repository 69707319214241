var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SubsidiariesManagerWrapper', {
    attrs: {
      "isAnySubsidiary": _vm.isAnySubsidiary
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$tc('subsidiary', 2)) + " ")]), _vm.isAnySubsidiary ? _c('div', {
    staticClass: "subsidiaries"
  }, [_c('div', {
    staticClass: "subsidiary-row"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('name', 1)))])]), _c('div', {
    staticClass: "cell members"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('user', 2)))])]), _c('div', {
    staticClass: "cell assets"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('asset', 2)))])]), _c('div', {
    staticClass: "cell spacer"
  })]), _vm._l(_vm.subsidiariesList, function (subsidiary) {
    return _c('div', {
      key: subsidiary.id,
      staticClass: "subsidiary-row"
    }, [_c('div', {
      staticClass: "cell name"
    }, [_vm._v(" " + _vm._s(subsidiary.name) + " ")]), _c('div', {
      staticClass: "cell members"
    }, [_vm._v(" " + _vm._s(subsidiary.memberships.length) + " ")]), _c('div', {
      staticClass: "cell assets"
    }, [_vm._v(" " + _vm._s(subsidiary.assets.length) + " ")]), _c('div', {
      staticClass: "cell actions"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'subsidiary',
          params: {
            id: subsidiary.id
          }
        }
      }
    }, [_c('Edit2Icon')], 1), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.confirmDeleteSubsidiary(subsidiary);
        }
      }
    }, [_c('TrashIcon')], 1)], 1)]);
  })], 2) : _c('div', {
    staticClass: "subsidiaries-placeholder"
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.noSubsidiaries')) + " ")]), _c('div', {
    staticClass: "create"
  }, [_c('div', {
    staticClass: "add-icon",
    on: {
      "click": _vm.activateCreateSubsidiaryOverlay
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.addSubsidiary')) + " "), _c('PlusCircleIcon')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }