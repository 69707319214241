<template>
  <TextOverflowWrapperStyled>
    {{ text }}
  </TextOverflowWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const TextOverflowWrapperStyled = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default {
  props: ['text'],
  components: {
    TextOverflowWrapperStyled,
  },
}
</script>

<style></style>
