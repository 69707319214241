var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TalpaLoaderWrapperStyled', {
    class: {
      absolute: _vm.isPositionedAbsolute,
      transparent: _vm.transparent
    },
    attrs: {
      "isDarker": _vm.isDarker
    }
  }, [_c('TalpaLoader')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }