var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BannerStyled', [_c('p', [_vm._v(" " + _vm._s(_vm.$t('messages.cookieBanner')) + " ")]), _c('ButtonBorderedStyled', {
    on: {
      "click": _vm.closeBanner
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('actions.close')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }