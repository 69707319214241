<template>
  <SearchResultGroupMolecule>
    <span class="label">{{ label | capitalize }}</span>
    <SearchResultMolecule v-for="item in group.items" :item="item" :key="item.id" @goto-item="$emit('goto-item', $event)" />
  </SearchResultGroupMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import SearchResultMolecule from './SearchResultMolecule'

const SearchResultGroupMolecule = styled('div')`
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 0.25rem;

  > .label {
    margin-left: 0.5rem;
  }
`

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  components: {
    SearchResultGroupMolecule,
    SearchResultMolecule,
  },
  computed: {
    label() {
      return this.$tc(this.group.i18nKey, this.group.items)
    },
  },
}
</script>
