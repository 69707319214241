<template>
  <ErrorOverlayStyled>
    {{ $t('messages.errorLoadingOverlay') }}
  </ErrorOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const ErrorOverlayStyled = styled('div')`
  margin-top: 50px;
`

export default {
  components: {
    ErrorOverlayStyled,
  },
}
</script>
