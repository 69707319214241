var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NavExpanderStyled', [_c('button', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('toggleIsMenuExpanded', !_vm.isMenuExpanded);
      }
    }
  }, [_vm.customIcon ? [_vm.isMenuExpanded ? _vm._t("collapseIcon") : _vm._t("ExpandIcon")] : [_vm.isMenuExpanded ? _c('ChevronsLeftIcon') : _c('ChevronsRightIcon')]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }