var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TextOverflowEllipsisAtom', {
    attrs: {
      "title": _vm.text
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.text) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }