<template>
  <TalpaLoaderStyled class="talpa-loader">
    <MarkerStyled viewBox="0 0 365 365">
      <g class="marker-group">
        <polygon
          class="talpa-t"
          points="221.05,144.66 221.05,300.82 173.03,300.82 173.03,144.66 96.04,144.66 96.04,96.64 300.23,96.64 281.79,144.66 "
          id="talpa-t"
        />
      </g>
    </MarkerStyled>
    <DonutStyled width="100%" height="100%" viewBox="0 0 42 42" class="donut donut-1">
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="1"
        stroke-dasharray="70 30"
        stroke-dashoffset="35"
      ></circle>
    </DonutStyled>
    <DonutStyled width="100%" height="100%" viewBox="0 0 42 42" class="donut donut-2">
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="1"
        stroke-dasharray="70 30"
        stroke-dashoffset="35"
      ></circle>
    </DonutStyled>
    <DonutStyled width="100%" height="100%" viewBox="0 0 42 42" class="donut donut-3">
      <circle
        class="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="1"
        stroke-dasharray="70 30"
        stroke-dashoffset="35"
      ></circle>
    </DonutStyled>
    <span class="text">loading</span>
  </TalpaLoaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const TalpaLoaderStyled = styled('div')`
  position: relative;
  width: 100px;
  height: 100px;
  .text {
    display: none;
  }
`

const MarkerStyled = styled('svg')`
  position: absolute;
  top: calc(50% - ${50 / 4}px);
  left: calc(50% - ${50 / 4}px);
  width: ${50 / 2}px;
  height: ${50 / 2}px;
  .talpa-t {
    fill: ${props => props.theme.colors.talpaLoaderMarkerFill};
  }
`

const DonutStyled = styled('svg')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  stroke: ${({ theme }) => (theme.isDark ? chroma(theme.colors.primary).alpha(0.55).css() : theme.colors.primary)};

  @keyframes rotate1 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes rotate2 {
    from {
      transform: rotate(90deg) scale(0.75);
    }
    to {
      transform: rotate(450deg) scale(0.75);
    }
  }

  @keyframes rotate3 {
    from {
      transform: rotate(180deg) scale(0.55);
    }
    to {
      transform: rotate(540deg) scale(0.55);
    }
  }

  &.donut-1 {
    animation: rotate1 3s linear infinite;
  }
  &.donut-2 {
    animation: rotate2 3s linear infinite;
  }
  &.donut-3 {
    animation: rotate3 3s linear infinite;
  }
`

export default {
  components: {
    TalpaLoaderStyled,
    MarkerStyled,
    DonutStyled,
  },
}
</script>
