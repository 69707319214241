var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SectionTitleAtom', {
    class: {
      'is-muted': _vm.isMuted
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }