var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceGoalCreateStyled', [_c('TitleWithBorderSimpleAtom', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function fn() {
        return [_c('CloseButtonAtom', {
          on: {
            "close": function close($event) {
              return _vm.$emit('cancel');
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "name"
  }, [_c('ValidatableFieldMolecule', {
    attrs: {
      "validation": _vm.$v.performanceGoal.name
    }
  }, [_c('EditableFieldMolecule', {
    attrs: {
      "label": _vm.$tc('name', 1),
      "value": _vm.$v.performanceGoal.name.$model
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.$v.performanceGoal.name, "$model", $event);
      }
    }
  })], 1)], 1), _c('SectionStyled', [_c('HeadingStyled', [_vm._v(_vm._s(_vm.$t('planning.assignedAssetsGoal')))]), _c('div', [_c('div', [_c('RadioButtonMolecule', {
    attrs: {
      "label": _vm.$t('planning.allAssetsOfSchedule', {
        x: _vm.availableAssetsForGoal.length
      }),
      "checked": !_vm.customAssignedAssets,
      "value": false
    },
    on: {
      "change": function change($event) {
        _vm.customAssignedAssets = false;
      }
    }
  }), _c('RadioButtonMolecule', {
    attrs: {
      "label": _vm.$t('planning.custom'),
      "checked": _vm.customAssignedAssets,
      "value": true
    },
    on: {
      "change": function change($event) {
        _vm.customAssignedAssets = true;
      }
    }
  })], 1), _vm.customAssignedAssets ? _c('div', [_c('CustomAssetSelectorStyled', [_c('CustomMultiSelect', {
    staticClass: "multi-selector",
    attrs: {
      "openDirection": "bottom",
      "label": 'label',
      "value": _vm.selectedAssets,
      "multiple": true,
      "options": _vm.assignableAssetsMapped,
      "showLabels": false,
      "close-on-select": false,
      "placeholder": 'Select Assets'
    },
    on: {
      "select": _vm.handleSelectAssets,
      "remove": _vm.handleRemoveAssets
    }
  }), _c('div', {
    staticClass: "clear-button",
    on: {
      "click": _vm.clearAssets
    }
  }, [_c('XIcon')], 1)], 1)], 1) : _vm._e()])], 1), _c('SectionStyled', [_c('HeadingStyled', [_vm._v(_vm._s(_vm.$tc('planning.rule', 2)) + ":")]), _c('PerformanceGoalRulesMolecule', {
    attrs: {
      "rules": _vm.performanceGoal.performanceGoalRules
    },
    on: {
      "ruleChange": function ruleChange($event) {
        _vm.performanceGoal.performanceGoalRules = $event;
      }
    }
  })], 1), _c('ActionStyled', [_c('div', {
    staticClass: "preview-toggle"
  }, [_c('label', {
    attrs: {
      "id": "showPreview",
      "for": "showPreview"
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('titles.showPreview')) + " ")]), _c('OnOffToggle', {
    staticClass: "toggle",
    attrs: {
      "alternativeStyle": true,
      "value": _vm.showPreview,
      "disabled": _vm.performanceGoal.performanceGoalRules.length === 0
    },
    on: {
      "toggle": function toggle($event) {
        _vm.showPreview = !_vm.showPreview;
      }
    }
  })], 1), _c('ButtonSolid', {
    attrs: {
      "disabled": _vm.canCreate
    },
    on: {
      "click": _vm.createGoal
    }
  }, [_vm._v(" " + _vm._s(_vm.createEditButtonLabel) + " ")])], 1), _vm.showPreview ? _c('SectionStyled', {
    staticClass: "preview"
  }, [_c('HeadingStyled', {
    attrs: {
      "title": _vm.$t('planning.previewMsg', {
        x: '7'
      })
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('planning.preview')) + " "), _c('InfoIcon', {
    staticClass: "info"
  })], 1), _c('PerformanceGoalPreviewMolecule', {
    attrs: {
      "performanceGoal": _vm.performanceGoal,
      "schedule": _vm.schedule,
      "assetsByRecipient": _vm.assetsByRecipient
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }