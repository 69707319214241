const SSOMixin = {
  created() {
    this.$root.$on('logout', this.logout)
    this.$root.$on('redirectToAccountManagementConsole', this.redirectToAccountManagementConsole)
    this.$root.$on('redirectToLogin', this.redirectToLogin)
  },
  methods: {
    logout() {
      if (this.$keycloak) {
        this.$keycloak.logout()
      }
    },
    redirectToAccountManagementConsole() {
      if (this.$keycloak) {
        this.$keycloak.accountManagement()
      }
    },
    redirectToLogin() {
      if (this.$keycloak) {
        this.$keycloak.login()
      }
    },
  },
}

export default SSOMixin
