<template>
  <PerformanceGoalPreviewStyled>
    <template v-for="(event, index) of sampleEvents">
      <div class="card" :key="index">
        <PerformanceGoalFeedItem :event="event"/>
      </div>
    </template>
  </PerformanceGoalPreviewStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'

import { getnScheduleOccurences } from '@common/utils/src'

import PerformanceGoalFeedItem from '../../Atomic/Molecules/PerformanceGoalFeedItem'

const PerformanceGoalPreviewStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableTotal};
  border-radius: 0.5rem;
  .card {
    margin: 1rem 0.25rem;
  }
`

export default {
  components: {
    PerformanceGoalPreviewStyled,
    PerformanceGoalFeedItem,
  },
  props:{
    performanceGoal:{
      type: Object,
    },
    schedule:{
      type: Object,
    },
    assetsByRecipient: {
      type: Array,
      default: () => [],
    },
  },
  computed:{
    sampleEvents(){
      const rrule = this.getRRule(this.schedule)
      if(!rrule){
        return []
      }
      const recurrenceData = {
        start: rrule?.start?.toISO(),
        end: rrule?.end?.toISO(),
        frequency: rrule?.frequency,
        interval: rrule?.interval,
        duration: rrule?.duration,
        bySecondOfMinute: rrule?.bySecondOfMinute,
        byMinuteOfHour: rrule?.byMinuteOfHour,
        byHourOfDay: rrule?.byHourOfDay,
        byDayOfWeek: rrule?.byDayOfWeek,
        byDayOfMonth: [],
        byMonthOfYear: [],
      }
      const lastSevenOcurrances = getnScheduleOccurences({timezone: this.schedule.timezone,recurrences: [recurrenceData]}, 7, true, null, DateTime.now())
      const performanceGoalRuleResults = this.performanceGoal?.performanceGoalRules.map(({min, max, threshold, thresholdTypeEnum, assetDimensionId}) => {
        return {
          min, max, threshold, thresholdTypeEnum, assetDimensionId,
        }
      })
      const assetIds = this.performanceGoal?.assetFilterIds.length === 0 ? this.assetsByRecipient.map(a => a.id) : this.performanceGoal?.assetFilterIds
      const events = lastSevenOcurrances.map((occ,i) => {
        return {
          id: i,
          createdAt: occ.date.plus(occ.duration).toISO(),
          type: "PERFORMANCE_GOAL_RESULT",
          category: "PRODUCTION",
          recipient: {
            name: "-",
            __typename: "Subsidiary",
          },
          assetIds: assetIds,
          name: this.performanceGoal?.name,
          start: occ.date.toISO(),
          end: occ.date.plus(occ.duration).toISO(),
          performanceGoalRuleResults: performanceGoalRuleResults,
        }
      })
      return events
    },
  },
  methods:{
    getDay(schedule) {
      if (!schedule.scheduleStartDateTime || !schedule.timezone) {
        return null
      }
      return DateTime.fromJSDate(schedule.scheduleStartDateTime).setZone(schedule.timezone)
    },
    getStart(schedule) {
      const day = this.getDay(schedule)
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      return DateTime.fromFormat(`${dayStr} ${schedule.startTime}`, 'yyyy-LL-dd HH:mm:ss').setZone(schedule.timezone, {keepLocalTime: true})
    },
    getEnd(schedule) {
      const day = this.getDay(schedule)
      const start = this.getStart(schedule)
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      const end = DateTime.fromFormat(`${dayStr} ${schedule.endTime}`, 'yyyy-LL-dd HH:mm:ss').setZone(schedule.timezone, {keepLocalTime: true})
      if (end.toMillis() <= start.toMillis()) {
        return end.plus({ days: 1 })
      }
      return end
    },
    getDuration(schedule) {
      const start = this.getStart(schedule)
      const end = this.getEnd(schedule)
      if (!start || !end) {
        return null
      }
      const duration = end.diff(start)
      if (duration.isLuxonDuration) {
        return duration
      }
      return null
    },
    getRRule(schedule) {
      const scheduleStartDateTime = DateTime.fromJSDate(schedule.scheduleStartDateTime).setZone(schedule.timezone, {
        keepLocalTime: true,
      })
      const start = this.getStart(schedule)
      const duration = this.getDuration(schedule)
      if (scheduleStartDateTime.invalid || !duration || !start) {
        return null
      }
      const durationInMillis = duration.toMillis()
      if (durationInMillis < 1) {
        return null
      }
      const rrule = {
        frequency: schedule.frequency,
        interval: parseInt(schedule.interval),
        start: start,
        duration: durationInMillis,
        byDayOfWeek: schedule.byDayOfWeek,
        byHourOfDay: [start.hour],
        byMinuteOfHour: [start.minute],
        bySecondOfMinute: [start.second],
      }
      return rrule
    },
  },
}
</script>