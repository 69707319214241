<template>
  <InputFieldStyled :labelWidth="labelWidth">
    <slot name="input" />
    <div class="label">
      <slot name="label" />
    </div>
    <div class="errors">
      <slot name="errors" />
    </div>
  </InputFieldStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { flexCenter } from '@styles/mixins'

const InputFieldStyled = styled('div')`
  position: relative;
  input {
    border: none;
    outline: none;
    color: ${p => p.theme.colors.archonBlue};
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    backdrop-filter: blur(7px);
    padding: 0.5rem;
    padding-left: ${p => p.labelWidth + 1}rem;
    height: 2.5rem;
    &:disabled {
      color: ${p => p.theme.colors.white};
    }
  }
  > .label {
    ${flexCenter}
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    color: ${p => p.theme.colors.archonBlue};
    width: ${p => p.labelWidth}rem;
    height: 2rem;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  }
  .errors {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`

export default {
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: Number,
      default: 2,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputFieldStyled,
  },
  data() {
    return {
      valueLocal: '',
    }
  },
  watch: {
    value: {
      handler(v) {
        this.valueLocal = v
      },
      immediate: true,
    },
  },
  methods: {
    keyup(e) {
      this.$emit('update', e.target.value)
    },
  },
}
</script>
