<template>
  <NotificationsBellMoleculeStyled :class="{ active: isActive }">
    <BellIcon />
    <CountStyled v-if="unreadCount > 0" :class="{ max: unreadCount > 99 }">
      {{ unreadCount > 99 ? '99+' : unreadCount }}
    </CountStyled>
  </NotificationsBellMoleculeStyled>
</template>

<script>
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
import { BellIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'

const NotificationsBellMoleculeStyled = styled('div')`
  ${flexCenter}
  ${p => buttonReset(p)}
   position: relative;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.solidBG};
  &:hover,
  &.active {
    background: ${props => chroma(props.theme.colors.solidBG).darken(0.2).css()};
  }
`

const CountStyled = styled('div')`
  ${flexCenter}
  position: absolute;
  top: 5px;
  right: 8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  font-size: 11px;
  &.muted {
    background: ${({ theme }) => theme.colors.muted};
  }
  &.max {
    top: 4px;
    right: 4px;
    width: 25px;
    height: 20px;
  }
`

export default {
  props: {
    unreadCount: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    NotificationsBellMoleculeStyled,
    BellIcon,
    CountStyled,
  },
}
</script>
