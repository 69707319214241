const resolvers = {
  Query: {
    getLocale: () => {
      return navigator.language || navigator.userLanguage
    },
  },
  Mutation: {},
}

export { resolvers }
