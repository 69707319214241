<template>
  <PerformanceGoalItemStyled>
    <ActionMessageAtom v-if="showGoalRemoveMessage" :message="removeMessage" @cancel="showGoalRemoveMessage = false" @accept="removeGoal" />
    <template v-else>
      <GoalDetailsStyled :size="performanceGoal?.performanceGoalRules.length">
        <div class="goal-name">
          {{ performanceGoal?.name }}
        </div>
        <div class="rule-names">
          <TextTagAtom
            v-for="rule of performanceGoal?.performanceGoalRules"
            :key="rule?.id"
            :text="rule?.assetDimension?.translatedName"
          />
        </div>
      </GoalDetailsStyled>
      <div class="actions">
        <ActionIconAtom>
          <template slot="icon">
            <Edit2Icon @click="$emit('updateGoal', index)" />
          </template>
        </ActionIconAtom>
        <ActionIconAtom>
          <template slot="icon">
            <Trash2Icon @click="initiateGoalRemoval" />
          </template>
        </ActionIconAtom>
      </div>
    </template>
  </PerformanceGoalItemStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { Trash2Icon, Edit2Icon } from 'vue-feather-icons'

import ActionMessageAtom from '../Atoms/ActionMessageAtom.vue'
import TextTagAtom from '../../Atomic/Atoms/TextTagAtom'
import ActionIconAtom from '../../Atomic/Atoms/ActionIconAtom'

const PerformanceGoalItemStyled = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr min-content;
  gap: 0.75rem;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  border: solid 1px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  align-items: center;
  .actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`
const GoalDetailsStyled = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr;
  .goal-name {
    padding: 0.25rem;
  }
  .rule-names {
    display: flex;
    flex-flow: row wrap;
    padding: 0.25rem 0rem;
    gap: 0.5rem;
    font-size: 12px;
  }
`

export default {
  components: {
    PerformanceGoalItemStyled,
    ActionIconAtom,
    Trash2Icon,
    Edit2Icon,
    GoalDetailsStyled,
    TextTagAtom,
    ActionMessageAtom,
  },
  props: {
    performanceGoal: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      showGoalRemoveMessage: false,
    }
  },
  computed: {
    removeMessage() {
      return this.$t('planning.messages.removeGoal', { x: this.performanceGoal?.name })
    },
  },
  methods: {
    initiateGoalRemoval() {
      this.showGoalRemoveMessage = true
    },
    removeGoal() {
      this.$emit('removeGoal', this.index)
      this.showGoalRemoveMessage = false
    },
  },
}
</script>
