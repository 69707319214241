import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

export default styled('button')`
  ${flexCenter}
  cursor: pointer;
  padding: 0.1rem;
  outline: none;
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.primary};

  &.active,
  &:hover {
    color: ${props => props.theme.colors.secondary};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.colors.primary};
  }
`
