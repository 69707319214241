import chroma from 'chroma-js'

export default function (p) {
  return `
  .tooltip {
    display: block !important;
    z-index: 10000;
    outline: none;
    .tooltip-inner {
      //background: ${p.theme.colors.talpaGrey};
      color: ${p.theme.colors.white};
    }

    &.table-header-info {
      width: auto;
      padding: .25rem;
      box-sizing: border-box;
      border: 1px solid ${chroma(p.theme.colors.white).alpha(0.5).css()};
      background: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
      .tooltip-inner {
        width: auto;
        max-width: calc(225px - .5rem);
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
      }
    }

    &.padding {
      .tooltip-inner {
        box-sizing: border-box;
        padding: .25em;
      }
    }
    &.dashboardTitle {
      .tooltip-inner {
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
        padding: 5px !important;
        font-size: 12px;
        text-align: center;
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
      }
      &[x-placement^="top"] {
        margin: -1rem;
        top: -18px !important;
        .tooltip-arrow {
          border-width: 5px 5px 0 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          bottom: -5px;
          left: calc(50% - 5px);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    &.widget {
      .tooltip-inner {
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
        padding: 5px !important;
        font-size: 12px;
        text-align: center;
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
      }
      &[x-placement^="bottom"] {
        margin: -1rem;
        .tooltip-arrow {
          border-width: 0 5px 5px 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          top: -5px;
          left: calc(50%);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      &[x-placement^="top"] {
        margin: -1rem;
        .tooltip-arrow {
          border-width: 5px 5px 0 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          bottom: -5px;
          left: calc(50% - 5px);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    &.count {
      .tooltip-inner {
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
        padding: 10px !important;
        font-size: 12px;
        border-radius: 4px;
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${chroma(p.theme.colors.talpaGrey).darken(0.5).css()};
      }
      &[x-placement^="bottom"] {
        top: 7px !important;
        .tooltip-arrow {
          border-width: 0 8px 8px 8px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          top: -8px;
          left: calc(50%);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      &[x-placement^="top"] {
        top: -10px !important;
        .tooltip-arrow {
          border-width: 8px 8px 0 8px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          left: calc(50%);
          margin-top: 37px;
          margin-bottom: 0;
        }
      }
    }

    &.addCard {
      width: 150px;
      .tooltip-inner {
        background: ${p.theme.colors.talpaGrey};
        padding: 10px !important;
        font-size: 12px;
        border-radius: 4px;
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${p.theme.colors.talpaGrey};
      }
      &[x-placement^="bottom"] {
        top: 7px !important;
        .tooltip-arrow {
          border-width: 0 8px 8px 8px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          top: -8px;
          left: calc(50%);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      &[x-placement^="top"] {
        top: -10px !important;
        .tooltip-arrow {
          border-width: 8px 8px 0 8px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          left: calc(50%);
          margin-top: 37px;
          margin-bottom: 0;
        }
      }
    }

    &.dashboard {
      .tooltip-inner {
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.2).css()};
        padding: 10px !important;
        font-size: 12px;
        border-radius: 4px;
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${chroma(p.theme.colors.talpaGrey).darken(0.2).css()};
      }
      &[x-placement^="top"] {
        top: -10px !important;
        .tooltip-arrow {
          border-width: 8px 8px 0 8px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          left: calc(50%);
          margin-top: 37px;
          margin-bottom: 0;
        }
      }
      &[x-placement^="bottom"] {
        top: 0.5rem !important;
        .tooltip-arrow {
          border-width: 0 5px 5px 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          top: -5px;
          left: calc(50%);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    &.sicknessMeanTooltip {
      .tooltip-inner {
        background: ${chroma(p.theme.colors.solidBG).alpha(1).css()};
        padding: 10px !important;
        font-size: 12px;
        border-radius: 4px;
        color: ${p.theme.colors.navFontNormal};
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${chroma(p.theme.colors.solidBG).alpha(1).css()}; 
      }
      &[x-placement^="top"] {
        top: -0px !important;
        .tooltip-arrow {
          border-width: 8px 8px 0 8px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          left: calc(50%);
          margin-top: 37px;
          margin-bottom: 0;
        }
      }
    }
    
    &.shiftpicker-tooltip {
      .tooltip-inner {
        font-size: 12px;
        line-height: 18px;
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.2).css()};
        padding: 5px !important;
        border-radius: 4px;
      }
      &[x-placement^="top"] {
        top: -10px !important;
      }
    }

    &.notification {
      .tooltip-inner {
        background: ${chroma(p.theme.colors.talpaGrey).darken(0.2).css()};
        padding: 8px !important;
        font-size: 12px;
        border-radius: 4px;
      }
      .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: ${chroma(p.theme.colors.talpaGrey).darken(0.2).css()};
      }
      &[x-placement^="left"] {
        margin-right: 0.5rem;
        .tooltip-arrow {
          border-width: 5px 0 5px 5px;
          border-top-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          right: -5px;
          top: calc(50% - 5px);
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    &[x-placement^="top"] {
      margin-bottom: 1px;
    }
  
    &[x-placement^="bottom"] {
      margin-top: 1px;
    }
  
    &[x-placement^="right"] {
      margin-left: 1px;
    }
  
    &[x-placement^="left"] {
      margin-right: 1px;
    }
  
    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }
  
    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }

    &.asset-link {
      .tooltip-inner {
        background: none;
      }
    }
  }

  &.popover {
    .popover-arrow {
      border-color: ${chroma(p.theme.colors.talpaGrey)};
    }
  }

  .tooltip-arrow {
    z-index: 1;
  }

   

  .userGuidePopOver {
    // backdrop-filter: blur(15px); //glassmorphism
    // filter: brightness(100%);
    border-radius: 10px;    
    .tooltip-inner {   
      border-radius: 10px;      
      box-shadow: 0 8px 32px 0 ${chroma(p.theme.colors.black).alpha(0.3).css()};
      background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.5).css()};
    }
    &[x-placement^="bottom"] {
      margin-top: 25px;
      // backdrop-filter: blur(5px);
      background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.5).css()};
      .popover-arrow {
        border-width: 0 25px 25px 25px;        
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        top: -24px;
        left: calc(80%);                     
      }
    }
    &[x-placement^="top"] {
      margin: 0 0 25px 35px; 
      @media (min-width: 768px) {
        margin-left: 0;
        .popover-arrow {
          left: calc(8%);
        }
      }
      .popover-arrow {
        border-width: 25px 25px 0px 25px;        
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;          
      }      
    }

    &[x-placement^="right"] {
      margin-left: 2rem;
      .popover-arrow {   
        left: -30px;
        border-width: 25px 25px 25px 0px;        
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        border-top-color: transparent !important;          
      }      
    }
    .popover-arrow {      
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      margin-top: 0;
      margin-bottom: 0;
      border-color: ${chroma(p.theme.colors.solidBG).alpha(1).css()};
    }
  }

   .sicknessDetailsPopOver {
    border-radius: 10px;    
    .tooltip-inner {   
      border-radius: 10px;      
      box-shadow: 0 8px 32px 0 ${chroma(p.theme.colors.black).alpha(0.3).css()};
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
      background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.5).css()};
    }
    &[x-placement^="bottom"] {
      margin-top: 18px;
      background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.5).css()};
      .popover-arrow {
        border-width: 0 10px 10px 10px;        
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        top: -8px;
        left: calc(80%);        
      }
    }
    &[x-placement^="top"] {
      margin: 0 0 25px 35px; 
      @media (min-width: 768px) {
        margin-left: 0;
        .popover-arrow {
          left: calc(8%);
        }
      }
      .popover-arrow {
        border-width: 25px 25px 0px 25px;        
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;          
      }      
    }
    .popover-arrow {      
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      margin-top: 0;
      margin-bottom: 0;
      border-color: ${chroma(p.theme.colors.solidBG).alpha(1).css()};
    }
  }

  .shiftPopOver {
    backdrop-filter: blur(10px); //glassmorphism
    border-radius: 10px;
    .tooltip-inner {   
      border-radius: 10px;      
      box-shadow: 0 8px 32px 0 ${chroma(p.theme.colors.black).alpha(0.3).css()};
      background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.5).css()};
    }
    &[x-placement^="top"] {
      margin-bottom: 15px;     
      .popover-arrow {
        border-width: 25px 25px 0px 25px;        
        border-bottom-color: transparent !important;           
      }
    }
    .popover-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      margin-top: 0;
      margin-bottom: 0;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-color: ${chroma(p.theme.colors.solidBG).alpha(1).css()};
    }
  }

  .dashboardSharePopOver {
    display: block !important;
    z-index: 10000;

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: ${chroma(p.theme.colors.atomic.feedListItemBg).alpha(1).css()};
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 15px 15px 0 15px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -10px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      .popover-arrow {
        border-color: ${chroma(p.theme.colors.atomic.feedListItemBg).alpha(1).css()};
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }
  .avatarPopOver {
    display: block !important;
    z-index: 10000;

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: ${chroma(p.theme.colors.atomic.feedListItemBg).alpha(1).css()};
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 15px;
      .tooltip-arrow {
        border-width: 15px 15px 0 15px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -10px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }   

    &.popover {
      .popover-arrow {
        border-color: ${chroma(p.theme.colors.atomic.feedListItemBg).alpha(1).css()};;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }
  .notificationPopOver {
    @media (max-width: 768px) {
      &[x-placement^="bottom"] {
        left: 3.4rem !important;
      }
    }
     
  }
  `
}
