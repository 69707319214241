<template>
  <ButtonStyleless @click="$emit('click', $event)" :disabled="disabled">
    <slot />
  </ButtonStyleless>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import chroma from 'chroma-js'

const ButtonStyleless = styled('button')`
  ${flexCenter}
  cursor: pointer;
  outline: none;
  user-select: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
    border-radius: 0.5rem;
    pointer-events: none;
    &:hover {
      color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
      background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
    }
  }
`

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonStyleless,
  },
}
</script>
