<template>
  <PerformanceGoalRulesStyled>
    <RuleListStyled>
      <RulesListMolecule :performanceGoalRules="performanceGoalRules" @removeRule="initiateRuleRemoval" @updateRule="updateRule" />
      <AddRuleStyled>
        <ButtonSolid @click="addRule">
          <PlusIcon class="icon" />
          {{ $tc('planning.addRule') }}
        </ButtonSolid>
      </AddRuleStyled>
    </RuleListStyled>
    <RuleCreateMolecule
      v-if="isCreatingNewRule"
      :rule="ruleToUpdate"
      :mode="mode"
      @ruleCreated="appendRule"
      @cancel="isCreatingNewRule = false"
    ></RuleCreateMolecule>
    <RemoveRuleStyled v-if="showRemoveRuleMessage">
      <span v-html="$t('planning.messages.removeRule', { x: ruleToRemove?.assetDimension?.name })"> </span>
      <ActionIconAtom :size="'24px'">
        <template slot="icon">
          <XIcon @click="cancelRuleRemoval" />
        </template>
      </ActionIconAtom>
      <ActionIconAtom :size="'24px'">
        <template slot="icon">
          <CheckIcon @click="removeRule" />
        </template>
      </ActionIconAtom>
    </RemoveRuleStyled>
  </PerformanceGoalRulesStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { PlusIcon, CheckIcon, XIcon } from 'vue-feather-icons'

import RulesListMolecule from './RulesListMolecule.vue'
import RuleCreateMolecule from './RuleCreateMolecule.vue'
import ButtonSolid from '../../Atomic/Atoms/ButtonSolid'
import ActionIconAtom from '../../Atomic/Atoms/ActionIconAtom'

const PerformanceGoalRulesStyled = styled('div')`
  display: flex;
  background: ${({ theme }) => theme.colors.atomic.tableTotal};
  border-radius: 0.5rem;
  position: relative;
`

const RuleListStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc(100% - 2.5rem) 2.5rem;
  width: 100%;
`

const AddRuleStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0.5rem 0rem;
  button {
    width: 90%;
    height: 30px;
    font-size: 14px;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
`

const RemoveRuleStyled = styled('div')`
  display: flex;
  font-size: 14px;
  margin: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
  justify-content: space-evenly;
  background-color: ${({ theme }) =>
    theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  position: absolute;
  top: 18px;
  left: -24px;
  width: 100%;
  height: 50px;
  align-items: center;
  z-index: 1000;
`

export default {
  components: {
    PerformanceGoalRulesStyled,
    RuleListStyled,
    RulesListMolecule,
    AddRuleStyled,
    PlusIcon,
    ButtonSolid,
    RuleCreateMolecule,
    RemoveRuleStyled,
    CheckIcon,
    XIcon,
    ActionIconAtom,
  },
  props: {
    rules: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isCreatingNewRule: false,
      showRemoveRuleMessage: false,
      mode: 'create',
      performanceGoalRules: [],
      ruleIndexToRemove: -1,
      ruleIndexToUpdate: -1,
    }
  },
  watch: {
    rules: {
      handler(rules) {
        if (rules && rules.length > 0) {
          this.performanceGoalRules = rules
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ruleToRemove() {
      if (this.ruleIndexToRemove === -1) {
        return null
      } else {
        return this.performanceGoalRules[this.ruleIndexToRemove]
      }
    },
    ruleToUpdate() {
      if (this.ruleIndexToUpdate === -1) {
        return null
      } else {
        return this.performanceGoalRules[this.ruleIndexToUpdate]
      }
    },
  },
  methods: {
    addRule() {
      this.mode = 'create'
      this.isCreatingNewRule = true
    },
    appendRule(rule) {
      if (this.mode === 'create') {
        this.performanceGoalRules.push(rule)
      } else {
        this.performanceGoalRules[this.ruleIndexToUpdate] = rule
      }
      this.isCreatingNewRule = false
      this.$emit('ruleChange', this.performanceGoalRules)
    },
    updateRule(ruleIndex) {
      this.isCreatingNewRule = true
      this.ruleIndexToUpdate = ruleIndex
      this.mode = 'edit'
    },
    initiateRuleRemoval(ruleIndex) {
      this.ruleIndexToRemove = ruleIndex
      this.showRemoveRuleMessage = true
    },
    cancelRuleRemoval() {
      this.showRemoveRuleMessage = false
      this.ruleIndexToRemove = -1
    },
    removeRule() {
      this.performanceGoalRules.splice(this.ruleIndexToRemove, 1)
      this.showRemoveRuleMessage = false
      this.ruleIndexToRemove = -1
    },
  },
}
</script>
