import localizeNumber from '@/utils/number-localized'
import units from '@/utils/units'
import get from 'lodash/get'

const localesMixin = {
  inject: ['uiSettings'],
  filters: {
    units,
    localizeNumber,
  },
  computed: {
    selectedUIUnitSystem() {
      return get(this.uiSettings, 'units', 'METRIC')
    },
    thousandsSeperator() {
      return get(this.uiSettings, 'units', 'METRIC') === 'METRIC' ? '.' : ','
    },
    decimalSeperator() {
      return get(this.uiSettings, 'units', 'METRIC') === 'METRIC' ? ',' : '.'
    },
  },
  methods: {
    convUnit(value, unitSI, unitUI, r, attachUnit, format, revertToSI = false) {
      return units(value, unitSI, unitUI, r, attachUnit, format, revertToSI, this.thousandsSeperator, this.decimalSeperator, true)
    },
    numberLocalized(value) {
      return localizeNumber(value, this.thousandsSeperator, this.decimalSeperator)
    },
    getCustomLocale(languageCodeInLowerCase = true, countryCodeInLowerCase = true) {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      if (languageCodeInLowerCase && !countryCodeInLowerCase) {
        //returns as de-DE
        return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
      } else {
        //returns as de-de
        return replacedDates.toLowerCase()
      }
    },
  },
}

export default localesMixin
