<template>
  <MainNavigationItemStyled :isMenuExpanded="isMenuExpanded">
    <template v-if="subNavigationItems.length > 1">
      <VPopover :trigger="'hover'" :placement="'right-start'" :autoHide="false" :popperOptions="popperOptions" :container="'#app'">
        <template>
          <MainNavigationItemLink
            :hasActiveSubMenuItem="hasActiveSubMenuItem"
            :routeMetaName="routeMetaName"
            :routeTo="to"
            :hasSubMenu="true"
            :icon="icon"
          />
        </template>
        <template v-slot:popover>
          <SubNavigationItemsWrapperStyled>
            <SubNavigationItemsStyled :isMenuExpanded="isMenuExpanded">
              <SubNavigationItem v-for="(subNavigationItem, i) in subNavigationItems" :key="i" :navigationItem="subNavigationItem" />
            </SubNavigationItemsStyled>
          </SubNavigationItemsWrapperStyled>
        </template>
      </VPopover>
    </template>
    <template v-else-if="subNavigationItems.length === 1">
      <MainNavigationItemLink
        :class="routeMetaName"
        :routeMetaName="routeMetaName"
        :icon="icon"
        :routeTo="to"
        :hasSubMenu="false"
        :hasActiveParent="navigationItem.activeByParent"
      />
    </template>
    <template v-else>
      <MainNavigationItemLink
        :routeMetaName="routeMetaName"
        :routeTo="to"
        :icon="icon"
        :count="routeMetaName === 'dashboards' ? dashboards.length : undefined"
      />
    </template>
  </MainNavigationItemStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { VPopover } from 'v-tooltip'

import MainNavigationItemLink from './MainNavigationItemLink.vue'
import SubNavigationItem from './SubNavigationItem.vue'

import permissionsMixin from '@/mixins/permissions'
import { useStore } from '@/stores/userSettings'
import DASHBOARDS_MINIMAL_QUERY from '#/graphql/operations/dashboards/dashboardsMinimal.gql'

const MainNavigationItemStyled = styled('div')`
  .v-popover > .trigger {
    width: 100%;
  }
  .main-navigation-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 0.5rem;
    @media (min-width: 768px) {
      margin-left: ${props => (props.isMenuExpanded ? '1rem;' : '0.5rem;')};
    }
    color: ${p => p.theme.colors.navFontNormal};
    padding: 0.5rem;
    text-decoration: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    cursor: pointer;
    span {
      display: none;
      @media (min-width: 768px) {
        ${props => (props.isMenuExpanded ? 'display: inline;' : 'display: none;')};
      }
      flex-grow: 1;
    }

    .count {
      position: absolute;
      right: 0.2rem;
      bottom: 0rem;
      transform: scale(0.7);
      @media (min-width: 768px) {
        ${props =>
          props.isMenuExpanded
            ? `position: static;
        transform: none;`
            : `position: absolute;
      transform: scale(0.7);`};
      }
    }

    .feather,
    .icon,
    .icon svg {
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;
    }

    &.router-link-active,
    &.active {
      color: ${props => props.theme.colors.primary};
      background: ${p => (p.theme.isDark ? chroma(p.theme.colors.solidBG).darken(0.5).css() : p.theme.colors.mainBG)};
    }
  }

  .v-popover.open .main-navigation-item,
  .main-navigation-item:hover {
    color: ${p => p.theme.colors.navFontNormal};
    background: ${p => (p.theme.isDark ? chroma(p.theme.colors.solidBG).darken(0.5).css() : p.theme.colors.mainBG)};
    &.router-link-active {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const SubNavigationItemsWrapperStyled = styled('div')`
  // account for outer div with id=app having grid-gap: 1px
  margin-left: -1px;
  padding-left: 0.5rem;
`

const SubNavigationItemsStyled = styled('div')`
  align-items: center;
  display: grid;
  background: ${p => (p.theme.isDark ? chroma(p.theme.colors.solidBG).darken(0.5).css() : p.theme.colors.mainBG)};
  border-radius: 0.5rem;
  color: ${p => p.theme.colors.navFontNormal};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  overflow: hidden;
`

function findRouteName(route) {
  if (route) {
    if (route.children?.length > 0) {
      const found = route.children.find(child => findRouteName(child))
      if (found) {
        return found.name
      }
    } else {
      if (route.name) {
        return route.name
      } else {
        return false
      }
    }
  } else {
    false
  }
}

export default {
  mixins: [permissionsMixin],
  props: {
    navigationItem: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  data() {
    return {
      dashboards: [],
      showPopup: false,
    }
  },
  components: {
    MainNavigationItemStyled,
    SubNavigationItemsWrapperStyled,
    SubNavigationItemsStyled,
    SubNavigationItem,
    MainNavigationItemLink,
    VPopover,
  },
  computed: {
    hasActiveSubMenuItem() {
      const activeRouteName = this.$route?.name
      return this.subNavigationItems.some(item => item.name === activeRouteName)
    },
    subNavigationItems() {
      const permissions = this.permissions?.all || []
      const subNavigationItems =
        this.navigationItem.children?.filter(f => {
          const permissionsRequired = f.meta?.permissionsRequired || []
          const doesRequirePermissions = permissionsRequired.length > 0
          const hasPermission = permissions.some(permission => permissionsRequired.includes(permission?.name))
          return f.meta?.isSubNavItem && (hasPermission || doesRequirePermissions === false)
        }) || []
      return subNavigationItems
    },
    routeMetaName() {
      if (this.subNavigationItems.length === 1) {
        return this.subNavigationItems[0]?.meta?.name
      }
      return this.navigationItem?.meta?.name
    },
    icon() {
      return this.navigationItem?.meta?.icon
    },
    to() {
      if (this.subNavigationItems.length === 1) {
        return {
          name: this.subNavigationItems[0].name,
        }
      }
      const name = findRouteName(this.navigationItem)
      return {
        name,
      }
    },
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  apollo: {
    dashboards: {
      query: DASHBOARDS_MINIMAL_QUERY,
    },
  },
}
</script>
