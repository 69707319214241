var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedSelectStyled', [_c('Multiselect', {
    staticClass: "feed_multiselect",
    attrs: {
      "track-by": "id",
      "label": "label",
      "value": _vm.filter,
      "disabled": !_vm.options,
      "openDirection": 'below',
      "placeholder": _vm.placeholder,
      "options": _vm.options,
      "close-on-select": _vm.closeOnSelect,
      "max-height": 500,
      "show-no-results": false,
      "hide-selected": false,
      "show-labels": false,
      "searchable": false,
      "resetAfter": true
    },
    on: {
      "select": _vm.selectedFilter
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var option = _ref.option;
        return [_vm.categoryIcon ? _c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Feed',
            "name": "".concat(option.label)
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.customOptions ? _vm.$t(_vm.customOptions + option.label) : option.label) + " "), _c('CheckIcon')];
      }
    }, {
      key: "singleLabel",
      fn: function fn(_ref2) {
        var option = _ref2.option;
        return [_vm._t("customLabelIcon"), _vm.categoryIcon ? _c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Feed',
            "name": "".concat(option.label)
          }
        }) : _vm._e(), _c('span', {
          staticClass: "labelText"
        }, [_vm._v(" " + _vm._s(_vm.customLabels ? _vm.$t(_vm.customLabels + option.label) : option.label) + " ")])];
      }
    }, {
      key: "caret",
      fn: function fn(_ref3) {
        var toggle = _ref3.toggle;
        return _vm.customCaretIcon ? _c('MoreHorizontalIcon', {
          staticClass: "multiselect__select caretIcon",
          on: {
            "mousedown": function mousedown($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }) : _c('ChevronDownIcon', {
          staticClass: "multiselect__select caretIcon",
          on: {
            "mousedown": function mousedown($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        });
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }