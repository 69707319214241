<template>
  <PerformanceGoalsListStyled>
    <PerformanceGoalItemMolecule
      v-for="(goal, index) of performanceGoals"
      :key="index"
      :performanceGoal="goal"
      :index="index"
      @removeGoal="$emit('removeGoal', $event)"
      @updateGoal="$emit('updateGoal', $event)"
    />
  </PerformanceGoalsListStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import PerformanceGoalItemMolecule from './PerformanceGoalItemMolecule.vue'

const PerformanceGoalsListStyled = styled('div')`
  overflow-y: auto;
`

export default {
  components: {
    PerformanceGoalsListStyled,
    PerformanceGoalItemMolecule,
  },
  props: {
    performanceGoals: {
      type: Array,
    },
  },
}
</script>
