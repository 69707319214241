<template>
  <AvatarBadgeStyled
    id="sensitive"
    :size="size"
    :color="avatarColor"
    :fontSize="fontSize"
    :class="{
      edit: isEditing,
      select: isSelected,
    }"
  >
    {{ avatarName }}
    <CircleIcon v-if="isOwner" class="icon" />
  </AvatarBadgeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { CircleIcon } from 'vue-feather-icons'

const AvatarBadgeStyled = styled('div')`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  font-size: ${({ fontSize }) => fontSize};
  color: #fff;
  line-height: ${({ size }) => size};
  text-align: center;
  background: ${({ color }) => color};
  text-transform: uppercase;
  position: relative;
  &.select {
    border: 1px solid ${({ theme }) => theme.colors.atomic.textMain};
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  &.edit {
    border: 1px solid ${({ theme }) => theme.colors.atomic.chart.red};
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.white};
  }
  .icon {
    fill: ${({ theme }) => theme.colors.atomic.artylickOrange};
    color: ${({ theme }) => theme.colors.atomic.artylickOrange};
    width: 25%;
    height: auto;
    position: absolute;
    left: 15%;
    top: -10%;
  }
`
export default {
  inject: ['theme'],
  props: {
    size: {
      type: String,
      require: false,
      default: '25px',
    },
    userData: {
      type: Object,
      require: true,
    },
    fontSize: {
      type: String,
      require: false,
      default: '12px',
    },
    isEditing: {
      type: Boolean,
      require: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      require: false,
      default: false,
    },
    isOwner: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    AvatarBadgeStyled,
    CircleIcon,
  },
  computed: {
    fullName() {
      const firstName = get(this.userData, 'firstName', null)
      const lastName = get(this.userData, 'lastName', null)
      return firstName && lastName ? firstName + ' ' + lastName : null
    },
    userName() {
      return get(this.userData, 'username', '')
    },
    avatarName() {
      if (this.fullName) {
        return this.fullName
          .split(' ')
          .slice(0, 2)
          .map(s => s.charAt(0).toUpperCase())
          .join('')
      } else {
        return this.userName.substring(0, 2)
      }
    },
    avatarColor() {
      const color = get(this.userData, 'uiSettings.avatarColor', '')
      return this.theme.colors.atomic.avatar[color]
    },
  },
}
</script>
