var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TalpaLoaderStyled', {
    staticClass: "talpa-loader"
  }, [_c('MarkerStyled', {
    attrs: {
      "viewBox": "0 0 365 365"
    }
  }, [_c('g', {
    staticClass: "marker-group"
  }, [_c('polygon', {
    staticClass: "talpa-t",
    attrs: {
      "points": "221.05,144.66 221.05,300.82 173.03,300.82 173.03,144.66 96.04,144.66 96.04,96.64 300.23,96.64 281.79,144.66 ",
      "id": "talpa-t"
    }
  })])]), _c('DonutStyled', {
    staticClass: "donut donut-1",
    attrs: {
      "width": "100%",
      "height": "100%",
      "viewBox": "0 0 42 42"
    }
  }, [_c('circle', {
    staticClass: "donut-segment",
    attrs: {
      "cx": "21",
      "cy": "21",
      "r": "15.91549430918954",
      "fill": "transparent",
      "stroke-width": "1",
      "stroke-dasharray": "70 30",
      "stroke-dashoffset": "35"
    }
  })]), _c('DonutStyled', {
    staticClass: "donut donut-2",
    attrs: {
      "width": "100%",
      "height": "100%",
      "viewBox": "0 0 42 42"
    }
  }, [_c('circle', {
    staticClass: "donut-segment",
    attrs: {
      "cx": "21",
      "cy": "21",
      "r": "15.91549430918954",
      "fill": "transparent",
      "stroke-width": "1",
      "stroke-dasharray": "70 30",
      "stroke-dashoffset": "35"
    }
  })]), _c('DonutStyled', {
    staticClass: "donut donut-3",
    attrs: {
      "width": "100%",
      "height": "100%",
      "viewBox": "0 0 42 42"
    }
  }, [_c('circle', {
    staticClass: "donut-segment",
    attrs: {
      "cx": "21",
      "cy": "21",
      "r": "15.91549430918954",
      "fill": "transparent",
      "stroke-width": "1",
      "stroke-dasharray": "70 30",
      "stroke-dashoffset": "35"
    }
  })]), _c('span', {
    staticClass: "text"
  }, [_vm._v("loading")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }