import base from './base'
import { styled } from '@egoist/vue-emotion'

export default styled(base)`
  height: ${props => props.theme.selected.button.height}px;
  padding: 0.25rem;
  margin: 0.25rem;
  color: ${props => props.theme.selected.colors.secondary};
  border: 1px solid ${props => props.theme.selected.colors.secondary};
  &:hover,
  &:focus {
    color: ${props => props.theme.selected.colors.primary};
    border-color: ${props => props.theme.selected.colors.primary};
  }

  &:disabled {
    border-color: ${props => props.theme.selected.colors.primary};
  }
`
