<template>
  <NotificationsListMainMoleculeStyled ref="scrollableContainer">
    <div class="notification-list">
      <template v-if="notificationsError === null">
        <AssetNotificationMolecule
          v-for="(notification, i) in notifications"
          :key="notification.id"
          :class="'notification-' + i"
          :notification="notification"
          @toggle-is-unread="$emit('toggle-notification-is-unread', notification.id)"
          @closed="$emit('close-pop-over')"
        />
      </template>
      <div class="sensor" ref="sensor" />
      <LoadingDots v-if="isLoading" />
      <div class="error" v-else-if="notificationsError !== null">
        {{ $t('messages.errorLoadingWidget') }}
      </div>
      <template v-else-if="!canLoadMore">
        <div class="no-more" v-if="notifications.length > 0">{{ $t('notifications.endOfList') }}</div>
        <div class="no-data" v-else><BellOffIcon size="2x" />{{ $t('messages.noNotifications') }}</div>
      </template>
    </div>
  </NotificationsListMainMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import scrollMonitor from 'scrollmonitor'
import { BellOffIcon } from 'vue-feather-icons'

import { flexCenter } from '@styles/mixins'
import { LoadingDots } from '@common/components'

import AssetNotificationMolecule from '@/components/Atomic/Molecules/Notifications/AssetNotificationMolecule'

const NotificationsListMainMoleculeStyled = styled('div')`
  overflow-y: auto;
  padding: 0 1rem;
  .notification-list {
    display: grid;
    min-height: 5rem;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 0.75rem;
    justify-items: center;

    .no-data {
      ${flexCenter}
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    .no-more {
      ${flexCenter}
      font-size: 14px;
      color: rgb(102, 102, 102);
    }

    > .sensor {
      height: 2rem;
    }
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    canLoadMore: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
    notificationsError: {
      type: Error,
    },
  },
  components: {
    NotificationsListMainMoleculeStyled,
    AssetNotificationMolecule,
    LoadingDots,
    BellOffIcon,
  },
  data() {
    return {
      containerMonitor: null,
      elementWatcher: null,
    }
  },
  watch: {
    notifications: {
      handler() {
        if (this.elementWatcher) {
          this.elementWatcher.recalculateLocation()
        }
      },
    },
  },
  mounted() {
    const sensor = this.$refs?.sensor
    const scrollableContainer = this.$refs?.scrollableContainer
    if (sensor && scrollableContainer) {
      this.containerMonitor = scrollMonitor.createContainer(scrollableContainer)
      this.elementWatcher = this.containerMonitor.create(sensor)
      this.elementWatcher.enterViewport(() => {
        if (this.canLoadMore) {
          this.$emit('load-more')
        }
      })
    }
  },
  destroyed() {
    if (this.elementWatcher) {
      this.elementWatcher.destroy()
    }
    if (this.containerMonitor) {
      this.containerMonitor.destroy()
    }
  },
}
</script>
