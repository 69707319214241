<template>
  <RulesListStyled>
    <RuleItemMolecule
      v-for="(rule, index) of performanceGoalRules"
      :key="index"
      :rule="rule"
      :index="index + 1"
      @removeRule="$emit('removeRule', $event)"
      @updateRule="$emit('updateRule', $event)"
    />
  </RulesListStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

import RuleItemMolecule from './RuleItemMolecule.vue'

const RulesListStyled = styled('div')`
  border-radius: 5px;
  overflow-y: auto;
`

export default {
  components: {
    RulesListStyled,
    RuleItemMolecule,
  },
  props: {
    performanceGoalRules: {
      type: Array,
    },
  },
}
</script>
