<template>
  <DefaultAppSettingsStyled>
    <DefaultAppSettingStyled
      v-for="app in defaultAppSettings"
      :key="app.id"
      :isActive="app.isActive"
      :class="{ active: app.isActive }"
      @click="setDefaultAppSetting(app.id)"
    >
      <span>{{ app.name }}</span>
      <transition name="fade">
        <CheckIcon v-if="app.isActive" />
      </transition>
    </DefaultAppSettingStyled>
  </DefaultAppSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { getUserIdFromToken } from '@common/utils'

import UPDATE_UI_SETTINGS_MUTATION from '#/graphql/user/uiSettings/update.gql'

import { CheckIcon } from 'vue-feather-icons'

import { flexCenter, flexColumns, buttonReset } from '@styles/mixins'

const DefaultAppSettingsStyled = styled('div')`
  ${flexColumns}
`

const DefaultAppSettingStyled = styled('div')`
  ${flexCenter}
  ${props => buttonReset(props)}
  position: relative;
  margin-top: 0.75rem;
  padding: 0.5rem 2rem;
  height: 2rem;
  align-items: flex-start;
  .feather {
    position: absolute;
    top: 0.25rem;
    right: 0;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    DefaultAppSettingsStyled,
    DefaultAppSettingStyled,
    CheckIcon,
  },
  inject: ['uiSettings'],
  computed: {
    defaultAppSettings() {
      const activeDefaultAppSetting = get(this.uiSettings, 'defaultApp')
      return ['MAINTENANCE_APP', 'SD_APP'].map(id => ({
        id,
        name: this.$t(`uiSettings.defaultApp.${id}`),
        isActive: activeDefaultAppSetting === id,
      }))
    },
  },
  methods: {
    async setDefaultAppSetting(id) {
      await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            defaultApp: id,
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
    },
  },
}
</script>
