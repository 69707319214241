<template>
  <SearchErrorAtom>
    {{ error }}
  </SearchErrorAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const SearchErrorAtom = styled('div')`
  padding: 1rem;
`

export default {
  props: {
    error: {
      type: Error,
      required: true,
    },
  },
  components: {
    SearchErrorAtom,
  },
}
</script>
