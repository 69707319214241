var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('LoadingOverlayStyled', {
    class: _vm.blockPage ? 'block-page' : ''
  }, [_c('LoadingDots', {
    class: _vm.blockPage ? 'center-loading-dots' : ''
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }