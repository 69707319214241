<template>
  <CountCircleStyled :marginLeft="marginLeft">
    {{ count }}
  </CountCircleStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter } from '@styles/mixins'

const CountCircleStyled = styled('div')`
  ${flexCenter}
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  color: ${p => p.theme.colors.primary};
  background: ${p => p.theme.colors.circleIconBG};
  ${p => (p.marginLeft ? 'margin-left: ' + p.marginLeft + ';' : '')}
`

export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    marginLeft: {
      type: String,
    },
  },
  components: {
    CountCircleStyled,
  },
}
</script>
