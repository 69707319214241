<template>
  <AutoFormStyled>
    <template v-for="field in fields">
      <label v-if="field.isVisible" :key="'label_' + field.name" :for="field.name"> {{ field.name | capitalize }} </label>
      <input
        v-if="field.dataType === 'String'"
        type="text"
        :key="'value_' + field.name"
        :id="field.name"
        :value="field.value"
        :disabled="field.isDisabled"
        @input="fieldParser(field.name, $event.target.value)"
      />
      <input
        v-if="field.dataType === 'Float'"
        type="text"
        :key="'value_' + field.name"
        :id="field.name"
        :value="field.value"
        :disabled="field.isDisabled"
        @input="fieldParser(field.name, $event.target.value, parseFloat)"
      />
      <input
        v-if="field.dataType === 'Int'"
        type="number"
        :value="field.value"
        :key="'value_' + field.name"
        :disabled="field.isDisabled"
        :id="field.name"
      />
      <OnOffToggle
        :key="'value_' + field.name"
        :id="field.name"
        v-if="field.dataType === 'Boolean'"
        @toggle="fieldParser(field.name, !field.value)"
        :value="field.value"
      >
      </OnOffToggle>
      <input
        v-if="field.dataType === 'DateTime'"
        :key="'value_' + field.name"
        :id="field.name"
        type="text"
        disabled
        :value="field.value | timeAgo"
      />
      <input v-if="field.dataType === 'ID'" :key="'value_' + field.name" :id="field.name" type="text" disabled :value="field.value" />
    </template>
  </AutoFormStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import OnOffToggle from '@common/components/src/OnOffToggle'

// import {
//   flexCenter,
// } from '@styles/mixins'

import { timeAgo } from '@/utils/time'

import TYPEFIELDS_QUERY from '#/graphql/misc/typefields.gql'

const AutoFormStyled = styled('div')`
  display: grid;
  justify-items: flex-start;
  align-items: center;
  grid-template-columns: min-content auto;
  label {
    cursor: pointer;
    user-select: none;
    justify-self: flex-end;
    padding: 1rem;
  }
  input {
    margin: 0.5rem;
    padding: 0.5rem;
  }
`

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    modelName: {
      type: String,
      required: true,
    },
    filterFields: {
      type: Array,
      required: true,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AutoFormStyled,
    OnOffToggle,
  },
  data() {
    return {
      typefields: null,
    }
  },
  filters: {
    timeAgo,
  },
  computed: {
    fields() {
      return get(this.typefields, 'fields', [])
        .map(f => {
          const dataType = get(f, 'type.name') || get(f, 'type.ofType.name')
          const isVisible =
            dataType === 'ID' ||
            dataType === 'String' ||
            dataType === 'Boolean' ||
            dataType === 'Float' ||
            dataType === 'Int' ||
            dataType === 'DateTime'
          return {
            ...f,
            isVisible,
            isDisabled: this.disabledFields.includes(f.name),
            dataType,
            value: get(this.model, f.name),
          }
        })
        .filter(f => {
          return f.isVisible && !this.filterFields.includes(f.name)
        })
    },
  },
  methods: {
    fieldParser(name, val, parser) {
      const model = { ...this.model }
      if (parser !== undefined && typeof parser === 'function') {
        model[name] = parser(val)
      } else {
        model[name] = val
      }
      this.$emit('update:model', model)
    },
  },
  apollo: {
    typefields: {
      query: TYPEFIELDS_QUERY,
      update: ({ __type }) => __type,
      variables() {
        return {
          name: this.modelName,
        }
      },
      skip() {
        return !this.modelName
      },
    },
  },
}
</script>
