<template>
  <ProfileWrapperStyled>
    <VPopover :placement="'bottom-start'" :open.sync="isActive" :popperOptions="popperOptions" data-testid="profile-menu">
      <ProfileTriggerStyled :class="{ active: isActive }">
        <transition name="fade">
          <AvatarBadge :size="'30px'" :fontSize="'14px'" class="profile" :userData="profile" />
        </transition>
        <div class="details">
          <span id="sensitive" :title="username">{{ username }}</span>
          <small id="sensitive">{{ email }}</small>
        </div>
      </ProfileTriggerStyled>
      <template v-slot:popover>
        <ProfileStyled>
          <ButtonStyled @click="redirectToAccountManagementConsole">{{ $tc('myAccount', 1) }}</ButtonStyled>
        </ProfileStyled>
        <ProfileStyled>
          <ButtonStyled @click="redirectToOrganizationManagement">{{ $tc('myOrganizations', membershipCount) }}</ButtonStyled>
        </ProfileStyled>
        <ProfileStyled>
          <ButtonStyled @click="signOut" data-testid="logout">{{ $t('actions.logout') }}</ButtonStyled>
        </ProfileStyled>
      </template>
    </VPopover>
  </ProfileWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'
import PROFILE_QUERY from '#/graphql/profile/show.gql'
import AvatarBadge from '@/components/Atomic/Atoms/AvatarBadge'

import { VPopover } from 'v-tooltip'

import { flexCenter, buttonReset } from '@styles/mixins'

import { getEmailFromToken, getUsernameFromToken } from '@common/utils'

const ProfileWrapperStyled = styled('div')`
  grid-area: profile;
  background: ${props => props.theme.colors.solidBG};
  width: 100%;
  height: 100%;
  .v-popover,
  .trigger {
    width: 100%;
    height: 100%;
  }
`

const ProfileTriggerStyled = styled('div')`
  ${flexCenter}
  ${p => buttonReset(p)}
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.solidBG};
  &:hover,
  &.active {
    background: ${props => chroma(props.theme.colors.solidBG).darken(0.2).css()};
  }

  .profile {
    @media (min-width: 768px) {
      margin-right: 0.5rem;
    }
  }
  .details {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      width: 140px;
    }
    flex-direction: column;
    font-size: 15px;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      cursor: default;
    }
    small {
      font-size: 9px;
    }
  }
`

const ProfileStyled = styled('div')`
  width: 190px;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: ${props => chroma(props.theme.colors.solidBG).darken(0.2).css()};
  > div {
    display: flex;
    align-items: center;
  }
`

const ButtonStyled = styled('div')`
  ${props => buttonReset(props)}
`

export default {
  components: {
    ProfileWrapperStyled,
    VPopover,
    ProfileTriggerStyled,
    ProfileStyled,
    AvatarBadge,
    ButtonStyled,
  },
  computed: {
    membershipCount() {
      return get(this.profile, 'memberships', []).length
    },
    username() {
      return getUsernameFromToken(this.$keycloak.token)
    },
    email() {
      return getEmailFromToken(this.$keycloak.token)
    },
    profilePictureURL() {
      return get(this.profile, 'picture.url')
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  data() {
    return {
      isActive: false,
      profile: null,
    }
  },
  methods: {
    async signOut() {
      this.$root.$emit('logout')
    },
    activateProfileSettingsOverlay() {
      this.$root.$emit('activateOverlay', 'ProfileSettingsOverlay')
      this.isActive = false
    },
    redirectToAccountManagementConsole() {
      this.$root.$emit('redirectToAccountManagementConsole')
    },
    redirectToOrganizationManagement() {
      this.isActive = false
      this.$router.push({
        name: 'organizations',
      })
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
