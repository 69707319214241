<template>
  <HomeIcon v-if="icon === 'HomeIcon'" />
  <ToolIcon v-else-if="icon === 'ToolIcon'" />
  <MapIcon v-else-if="icon === 'MapIcon'" />
  <FolderIcon v-else-if="icon === 'FolderIcon'" />
  <ActivityIcon v-else-if="icon === 'ActivityIcon'" />
  <TrendingUpIcon v-else-if="icon === 'TrendingUpIcon'" />
  <TalpaIcon v-else-if="icon === 'FleetIcon'" class="icon" :scope="'Misc'" :name="'FleetIcon'" />
  <ShoppingBagIcon v-else-if="icon === 'ShoppingBagIcon'" />
  <BriefcaseIcon v-else-if="icon === 'BriefcaseIcon'" />
  <TalpaIcon v-else-if="icon === 'ServiceDealerIcon'" class="icon" :scope="'Misc'" :name="'ServiceDealerIcon'" />
  <CalendarIcon v-else-if="icon === 'CalendarIcon'" />
  <SlidersIcon v-else-if="icon === 'SlidersIcon'" />
  <TalpaIcon v-else-if="icon === 'HealthIcon'" class="icon" :scope="'Misc'" :name="'HealthIcon'" />
</template>

<script>
import {
  HomeIcon,
  MapIcon,
  TrendingUpIcon,
  ActivityIcon,
  FolderIcon,
  ToolIcon,
  ShoppingBagIcon,
  BriefcaseIcon,
  CalendarIcon,
  SlidersIcon,
} from 'vue-feather-icons'
import { TalpaIcon } from '@common/components'

export default {
  props: {
    icon: {
      type: String,
    },
  },
  components: {
    HomeIcon,
    MapIcon,
    TrendingUpIcon,
    ActivityIcon,
    FolderIcon,
    ToolIcon,
    TalpaIcon,
    ShoppingBagIcon,
    BriefcaseIcon,
    CalendarIcon,
    SlidersIcon,
  },
}
</script>
