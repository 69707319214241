<template>
  <PerformanceGoalFeedItemMoleculeStyled>
    <TalpaLoaderWrapper v-if="$apollo.loading" :transparent="true" />
    <TextWrapper v-else-if="assetDimensionIds.length > 0">
      <TitleStyled>
        <div class="event-created">{{ eventTimeFormatted }}</div>
        <div
          class="highlight"
          v-html="
            overallGoalAchieved
              ? $t('PerformanceGoalEvents.goalAchieved', { x: event.name })
              : $t('PerformanceGoalEvents.goalNotAchieved', { x: event.name })
          "
        ></div>
        <div>{{ event?.recipient?.name }}</div>
        <div>{{ performanceGoalRuleTimePeriod }}</div>
      </TitleStyled>
      <PerformanceGoalResultStyled :showAll="showAll" ref="clampedText">
        <div>
          <div class="grid">
            <div v-for="(header, index) in headers" :key="index" class="heading">{{ $t(`PerformanceGoalEvents.${header}`) }}</div>
          </div>
          <div v-for="result in calculatePerformanceGoalResults" :key="result.name" class="table-row">
            <div class="grid">
              <div>{{ result.translatedName }}</div>
              <div :class="result.color">{{ result.value }} {{ result.unit }}</div>
              <div>
                <span v-if="result.targetValue">{{ result.type.toLowerCase() }}</span> {{ result.targetValue }} {{ result.unit }}
              </div>
              <div>{{ result.percentageDeviation ? Number(result.percentageDeviation.toFixed(1)) : '-' }}</div>
            </div>
          </div>
        </div>
      </PerformanceGoalResultStyled>
      <Footerstyled>
        <a v-if="shouldExpand" class="link-text" href="#" @click="toggleShowAll()">
          <template v-if="showAll"><ChevronRightIcon />{{ $t('actions.showLess') }}</template>
          <template v-else><ChevronDownIcon />{{ $t('actions.showMore') }}</template>
        </a>
      </Footerstyled>
    </TextWrapper>
    <div v-else>
      {{ $t('MachineAssignmentEvents.titles.bannerNoData') }}
    </div>
  </PerformanceGoalFeedItemMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Interval } from 'luxon'
import { ObserveVisibility } from 'vue-observe-visibility'
import { TalpaLoaderWrapper } from '@common/components'
import units from '@/utils/units'
import { ChevronRightIcon, ChevronDownIcon } from 'vue-feather-icons'
import round from '@/utils/round'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/assetDimensionDataBasic.gql'

const PerformanceGoalFeedItemMoleculeStyled = styled('div')`
  background-color: ${({ theme }) => theme.colors.atomic.feedList};
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 6px #00000026;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const TitleStyled = styled('div')`
  height: 8rem;
  .highlight {
    font-weight: 600;
    font-size: 20px;
    padding: 2px 0px;
  }
  div {
    padding: 4px 0px;
  }
`
const PerformanceGoalResultStyled = styled('div')`
  height: ${({ showAll }) => (showAll ? 'max-content' : 'min-content')};
  max-height: ${({ showAll }) => (showAll ? 'none' : '90px')};
  overflow-y: ${({ showAll }) => (showAll ? 'unset' : 'hidden')};
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 3rem;
    padding: 5px;
    border-bottom: 0.75px solid ${p => p.theme.colors.tableBorderBottom};
  }
  .heading {
    font-weight: bold;
  }
  .red {
    color: ${p => p.theme.colors.red};
  }
  .grey {
    color: ${p => p.theme.colors.lightGrey};
  }
  .green {
    color: ${p => p.theme.colors.green};
  }
`
const Footerstyled = styled('div')`
  padding-top: 16px;
  .link-text {
    display: flex;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  svg {
    padding-right: 4px;
    path {
      stroke: ${({ theme }) => theme.colors.atomic.primary};
    }
  }
`
const TextWrapper = styled('div')`
  grid-area: text;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  .event-created::first-letter {
    text-transform: capitalize;
  }
`

export default {
  inject: ['uiSettings'],
  components: {
    PerformanceGoalFeedItemMoleculeStyled,
    TextWrapper,
    TalpaLoaderWrapper,
    ChevronRightIcon,
    ChevronDownIcon,
    TitleStyled,
    PerformanceGoalResultStyled,
    Footerstyled,
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
  filters: {
    round,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      assetDimensionData: new Map(),
      shouldExpand: false,
      showAll: false,
      headers: ['assetDimension', 'achieved', 'targetValue', 'change'],
    }
  },
  mounted() {
    this.checkIsTruncated()
  },
  updated() {
    this.checkIsTruncated()
  },
  computed: {
    locale() {
      const ds = this.uiSettings?.dates || 'DE_DE'
      return ds.replace('_', '-').toLowerCase()
    },
    eventTimeFormatted() {
      const dt = DateTime.fromISO(this.event?.createdAt)
      if (dt.diffNow().as('days') >= -3) {
        return `${dt.setLocale(this.locale).toRelativeCalendar()} : ${dt
          .setLocale(this.locale)
          .toLocaleString({ ...DateTime.TIME_SIMPLE })}`
      }
      return dt.setLocale(this.locale).toLocaleString({ ...DateTime.DATETIME_SHORT })
    },
    assetIds() {
      return this.event?.assetIds
    },
    selectedTimeframeParam() {
      const start = DateTime.fromISO(this.event?.start)
      const end = DateTime.fromISO(this.event?.end)
      const interval = Interval.fromDateTimes(start, end)
      const granularity = interval?.length('days') > 2 ? 'P1D' : 'PT1H'

      return {
        start,
        end,
        granularity,
      }
    },
    assetDimensionIds() {
      const assetDimensionIds = this.event?.performanceGoalRuleResults.map(({ assetDimensionId }) => assetDimensionId)
      return assetDimensionIds
    },
    overallGoalAchieved() {
      const hasAchieved = this.calculatePerformanceGoalResults.find(goalRuleResult => goalRuleResult.ruleAchieved === false)
      return !hasAchieved
    },
    performanceGoalRuleTimePeriod() {
      return `${DateTime.fromISO(this.event?.start).setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT)}
      - ${DateTime.fromISO(this.event?.end).setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT)}`
    },
    calculatePerformanceGoalResults() {
      return this.event.performanceGoalRuleResults.map(performanceGoalRuleResult => {
        const assetDimensionData = this.assetDimensionData[performanceGoalRuleResult.assetDimensionId]
        const locale = this.locale.slice(0, 2).toUpperCase()
        const translatedName  = assetDimensionData?.nameTranslations ? getAssetDimensionNameByLocale(assetDimensionData?.nameTranslations, locale) : assetDimensionData?.name
        const unitSI = assetDimensionData?.physicalUnitSI
        const unitUIMetric = assetDimensionData?.physicalUnitUIMetric ?? null
        const unitUIImperial = assetDimensionData?.physicalUnitUIImperial ?? null
        const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
        let unit = unitUI
        if (unit) {
          unit = unit.replace('mt', 't')
        } else {
          unit = unitSI
        }
        const assetDimensionDataTotal = assetDimensionData?.total ?? null
        const total =
          assetDimensionDataTotal !== null
            ? units(assetDimensionDataTotal, unitSI, unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, true)
            : '-'
        let ruleAchieved = false
        let targetValue = ''
        let percentageDeviation = null
        let color = assetDimensionDataTotal === null ? 'grey' : ''
        const singlethreshold = units(
          performanceGoalRuleResult.threshold,
          unitSI,
          unitUI,
          2,
          false,
          false,
          false,
          this.thousandsSeperator,
          this.decimalSeperator,
          true,
        )
        if (assetDimensionDataTotal !== null) {
          switch (performanceGoalRuleResult.thresholdTypeEnum) {
            case 'ABOVE':
              targetValue = singlethreshold
              // Calculate percentage deviation
              percentageDeviation =
                ((assetDimensionDataTotal - performanceGoalRuleResult.threshold) / performanceGoalRuleResult.threshold) * 100
              if (assetDimensionDataTotal > performanceGoalRuleResult.threshold) {
                ruleAchieved = true
                color = 'green'
              } else {
                color = 'red'
              }
              break
            case 'BELOW':
              targetValue = singlethreshold
              percentageDeviation =
                ((assetDimensionDataTotal - performanceGoalRuleResult.threshold) / performanceGoalRuleResult.threshold) * 100
              if (assetDimensionDataTotal < performanceGoalRuleResult.threshold) {
                ruleAchieved = true
                color = 'green'
              } else {
                color = 'red'
              }
              break
            default: {
              const targetMin = units(
                performanceGoalRuleResult.min,
                unitSI,
                unitUI,
                2,
                false,
                false,
                false,
                this.thousandsSeperator,
                this.decimalSeperator,
                true,
              )
              const targetMax = units(
                performanceGoalRuleResult.max,
                unitSI,
                unitUI,
                2,
                false,
                false,
                false,
                this.thousandsSeperator,
                this.decimalSeperator,
                true,
              )
              targetValue = targetMin + ' - ' + targetMax

              if (assetDimensionDataTotal >= performanceGoalRuleResult.min && assetDimensionDataTotal <= performanceGoalRuleResult.max) {
                ruleAchieved = true
                color = 'green'
              } else {
                color = 'red'
                if (assetDimensionDataTotal < performanceGoalRuleResult.min) {
                  percentageDeviation =
                    performanceGoalRuleResult.min !== 0
                      ? ((assetDimensionDataTotal - performanceGoalRuleResult.min) / performanceGoalRuleResult.min) * 100
                      : null
                } else {
                  percentageDeviation =
                    performanceGoalRuleResult.max !== 0
                      ? ((assetDimensionDataTotal - performanceGoalRuleResult.max) / performanceGoalRuleResult.max) * 100
                      : null
                }
              }
              break
            }
          }
        }
        return {
          name: assetDimensionData?.name,
          translatedName,
          value: total,
          unit,
          ruleAchieved,
          targetValue,
          percentageDeviation: percentageDeviation,
          color,
          type: this.$t(`planning.${performanceGoalRuleResult.thresholdTypeEnum.toLowerCase()}`),
        }
      })
    },
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll
    },
    checkIsTruncated() {
      this.shouldExpand = this.$refs.clampedText?.scrollHeight > 90
    },
  },
  watch: {
    assetDimensionIds: {
      handler() {
        this.assetDimensionIds.forEach(id => {
          this.$apollo.addSmartQuery(id, {
            query: ASSET_DIMENSION_DATA_QUERY,
            variables() {
              return {
                where: {
                  assetDimension: {
                    id,
                  },
                  timeframe: this.selectedTimeframeParam,
                  assets: {
                    id_in: this.assetIds,
                  },
                },
              }
            },
            skip() {
              return this.assetDimensionIds.length < 1 || !this.selectedTimeframeParam
            },
            result(res) {
              const total = res.data.assetDimensionData.total
              const assetDimension = res.data.assetDimensionData.assetDimension
              this.$set(this.assetDimensionData, id, { total: total, ...assetDimension })
            },
            manual: true,
          })
        })
      },
      immediate: true,
    },
  },
}
</script>
