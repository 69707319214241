var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OpenSearchButtonAtom', {
    class: {
      hidden: _vm.searchOverlayVisible
    }
  }, [_c('button', {
    class: {
      active: _vm.searchOverlayVisible
    },
    on: {
      "click": _vm.toggleSearchOverlayVisible
    }
  }, [_c('SearchIcon'), _c('span', [_vm._v(_vm._s(_vm.$t('actions.search')))]), _c('kbd', [_vm._v(_vm._s(_vm.hotkeyCombination))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }