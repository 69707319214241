<template>
  <SearchInstructionsAtom>
    <span class="muted">Search will be conducted in the following entity types:</span>
    <ul v-for="searchableEntity in searchableEntities" :key="searchableEntity.id">
      <li>
        <strong>{{ searchableEntity.label | capitalize }}</strong>
      </li>
    </ul>
  </SearchInstructionsAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const SearchInstructionsAtom = styled('div')`
  padding: 1rem;
`

export default {
  props: {
    searchableEntities: {
      type: Array,
      required: true,
    },
  },
  components: {
    SearchInstructionsAtom,
  },
}
</script>
