import chroma from 'chroma-js'

export function interactiveColors(props) {
  return `
    color: ${props.isActive ? props.theme.selected.colors.white : props.theme.selected.colors.orange};
    background-color: ${chroma(props.theme.selected.colors.talpaGreyLighter).alpha(0.6).css()};
    transition: background-color .25s;

    &:hover {
      color: ${props.theme.selected.colors.white};
      background-color: ${chroma(props.theme.selected.colors.talpaGreyLighter).alpha(1).css()};
    }

    &:disabled {
      cursor: default;
      color: ${props.theme.selected.colors.muted};
      background: ${chroma(props.theme.selected.colors.talpaGreyLighter).alpha(0.6).css()};
    }
  `
}
