var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ThemeSwitcherStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_c('button', {
    class: {
      dark: _vm.theme.isDark
    },
    on: {
      "click": _vm.toggle
    }
  }, [_c('div', {
    staticClass: "circle"
  }, [_c('MoonIcon')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }