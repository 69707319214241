<template>
  <MinMaxInputStyled>
    <div>{{ $t('planning.min') }}</div>
    <div class="input">
      <InputStyled type="number" min="0" :value="min" @input="$emit('update:min', Number($event.target.value))" />
      <div v-if="unit">{{ unit }}</div>
    </div>
    <div>{{ $t('planning.max') }}</div>
    <div class="input">
      <InputStyled
        type="number"
        :min="Number(min) + 1"
        :value="max"
        @input="$emit('update:max', Number($event.target.value))"
      ></InputStyled>
      <div v-if="unit">{{ unit }}</div>
    </div>
  </MinMaxInputStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const MinMaxInputStyled = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.atomic.iconBG};
  color: ${({ theme }) => chroma(theme.colors.atomic.textMain).alpha(0.7).css()};
  font-size: 14px;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  .input {
    display: flex;
    flex-direction: row;
    background: ${p => chroma(p.theme.colors.atomic.healthStatusIconBG).css()};
    align-items: center;
    padding: 0 0.2rem;
    font-size: 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
`
const InputStyled = styled('input')`
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: ${p => p.theme.colors.primary};
  background: ${p => chroma(p.theme.colors.atomic.healthStatusIconBG).css()};
  border-bottom: 1px solid ${p => chroma(p.theme.colors.navFontNormal).alpha(0.8).css()};
  transition: border-color 0.5s ease;
  &:focus {
    border-color: ${p => p.theme.colors.primary};
  }
  &::placeholder {
    color: ${p => p.theme.colors.primary};
  }
`

export default {
  components: {
    MinMaxInputStyled,
    InputStyled,
  },
  props: {
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    unit: {
      type: String,
    },
  },
}
</script>
