var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceGoalPreviewStyled', [_vm._l(_vm.sampleEvents, function (event, index) {
    return [_c('div', {
      key: index,
      staticClass: "card"
    }, [_c('PerformanceGoalFeedItem', {
      attrs: {
        "event": event
      }
    })], 1)];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }