<template>
  <ScheduleDialogStyled :hasDelete="canDelete">
    <div class="header">
      <div class="select select-type" :class="selectedScheduleTypeClass">
        <CustomSingleSelect
          class="select-schedule-type"
          :selectedOption="selectedScheduleTypeMapped"
          @selectedFilter="selectScheduleType"
          :options="availableScheduleTypesMapped"
          :shouldNotResetSelectedOption="true"
          :closeOnSelect="true"
        >
          <template v-slot:customLabelIcon>
            <ShowAsLabel>{{ $tc('type', 1) }}:</ShowAsLabel>
          </template>
        </CustomSingleSelect>
      </div>
    </div>
    <div class="options">
      <InputStyled>
        <input type="text" v-model="title" />
      </InputStyled>
      <label class="checkbox" v-if="selectedScheduleType === 'SHIFT'">
        <input type="checkbox" v-model="createShiftReport" />
        <span>create shift report</span>
      </label>
      <label class="checkbox" v-if="selectedScheduleType === 'SHIFT'">
        <input type="checkbox" v-model="createTyresHealthReport" />
        <span>create tyres health report</span>
      </label>
      <Collapsable
        v-if="selectedSchedule?.useTimezoneInRSchedule || mode === 'create'"
        :isCollapsed="!showTimeSettings"
        @toggle="showTimeSettings = !showTimeSettings"
      >
        <template #header> Time Settings </template>
        <template #main>
          <h4>Start schedule date:</h4>
          <DatePicker type="datetime" v-model="scheduleStartDateTime" />
          <h4>Start:</h4>
          <DatePicker type="time" v-model="startTime" value-type="format" format="HH:mm:ss" />
          <h4>End:</h4>
          <DatePicker type="time" v-model="endTime" value-type="format" format="HH:mm:ss" />
          <h4>Timezone</h4>
          <Multiselect
            class="select-timezone"
            track-by="name"
            label="name"
            :value="selectedTimezone"
            :openDirection="'below'"
            :options="availableTimezones"
            :close-on-select="true"
            :max-height="500"
            :show-no-results="true"
            :hide-selected="false"
            :show-labels="false"
            :searchable="true"
            :resetAfter="true"
            @select="selectTimezone"
          >
            <template v-slot:singleLabel="{ option }">
              <ShowAsLabel>{{ $t('timezone.timezone') }}:</ShowAsLabel>
              <span class="labelText"> {{ option.name }} </span>
            </template>
          </Multiselect>
          <h4>Duration:</h4>
          <div class="duration" v-if="duration">{{ duration.as('hours') }} hours</div>
          <label class="checkbox" v-if="false">
            <input type="checkbox" v-model="isRecurring" />
            <span>recurring</span>
          </label>
          <template v-if="true">
            <h4>Freqency:</h4>
            <div class="frequencies">
              <CustomRadioButtonStyled v-for="frequency in availableFrequencies" :key="frequency.id">
                <input
                  type="radio"
                  :id="`freqency_${frequency.id}`"
                  :checked="selectedFrequencyId === frequency.id"
                  :value="frequency.value"
                  @change="selectFrequency(frequency.id)"
                />
                <span class="label">{{ frequency.label }}</span>
                <span class="checkmark" />
              </CustomRadioButtonStyled>
            </div>
            <InputStyled>
              <label>
                <span>Interval</span>
                <input type="number" min="1" v-model="interval" />
              </label>
            </InputStyled>
            <div class="weekdays">
              <ButtonStyled
                v-for="weekday in weekdays"
                :key="weekday.id"
                :class="{ active: weekday.active }"
                @click="toggleWeekday(weekday)"
              >
                {{ weekday.label }}
              </ButtonStyled>
            </div>
          </template>
        </template>
      </Collapsable>
      <template v-else>
        <h5>
          <p>Time settings have been disabled, because schedule is still using timezone implemenation v1.</p>
          <p>
            Please configure the correct timezones first
            <EntityLinkAtom :id="selectedSchedule.id" :type="'Schedule'" :label="'here'" />.
          </p>
        </h5>
      </template>
      <Collapsable :isCollapsed="!showAssignedAssets" @toggle="showAssignedAssets = !showAssignedAssets">
        <template #header>
          <span> Assigned Assets </span>
          <CountCircle v-if="assignedAssets.length > 0" :count="assignedAssets.length" :marginLeft="'1rem'" />
        </template>
        <template #main>
          <CustomRadioButtonStyled>
            <input
              type="radio"
              :id="`custom_assigned_assets_false`"
              :checked="!customAssignedAssets"
              :value="false"
              @change="customAssignedAssets = false"
            />
            <span class="label">All assets of owner ({{ assetsByRecipient.length }})</span>
            <span class="checkmark" />
          </CustomRadioButtonStyled>
          <CustomRadioButtonStyled>
            <input
              type="radio"
              :id="`custom_assigned_assets_true`"
              :checked="customAssignedAssets"
              :value="true"
              @change="customAssignedAssets = true"
            />
            <span class="label">Custom ({{ assignedAssets.length }})</span>
            <span class="checkmark" />
          </CustomRadioButtonStyled>
          <transition name="fade">
            <template v-if="customAssignedAssets">
              <div class="select select-assigned-assets">
                <CustomMultiselectOther
                  class="select-assigned-assets"
                  track-by="id"
                  openDirection="bottom"
                  :options="assignableAssetsMapped"
                  :shouldNotResetSelectedOption="true"
                  :disabled="false"
                  :closeOnSelect="false"
                  :searchable="true"
                  :internalSearch="true"
                  :multiple="true"
                  :is-loading="$apollo.queries.assetsByRecipient.loading"
                  :placeholder="'Add Assets'"
                  :modelName="'Asset'"
                  :maxHeight="256"
                  :optionsLimit="6"
                  :applyFilter="false"
                  @select="selectAssignableAsset"
                >
                  <template v-slot:customLabelIcon>
                    <ShowAsLabel>{{ $tc('owner', 1) }}:</ShowAsLabel>
                  </template>
                </CustomMultiselectOther>
              </div>
            </template>
          </transition>
          <transition name="fade">
            <div class="assigned-assets" v-if="customAssignedAssets">
              <div class="assigned-asset" v-for="assignedAsset in assignedAssetsMapped" :key="assignedAsset.id">
                <span class="name">
                  {{ assignedAsset.name }}
                </span>
                <ButtonStyleless @click="removeAssignedAsset(assignedAsset)">
                  <XIcon />
                </ButtonStyleless>
              </div>
            </div>
          </transition>
        </template>
      </Collapsable>
    </div>
    <div class="actions">
      <ButtonStyleless @click="confirmDeleteSchedule(selectedSchedule)" v-if="canDelete">
        <TrashIcon />
      </ButtonStyleless>
      <ActionButtonStyled @click="$emit('close-dialog')">
        {{ $t('actions.cancel') }}
      </ActionButtonStyled>
      <ActionButtonStyled @click="confirm">
        {{ $t('actions.ok') }}
      </ActionButtonStyled>
    </div>
  </ScheduleDialogStyled>
</template>

<script lang="js">
import { styled } from '@egoist/vue-emotion'
import { orderBy, cloneDeep } from 'lodash'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
// import chroma from 'chroma-js'
// import gql from 'graphql-tag'
// import { debounce } from 'vue-debounce'
import { DateTime, Duration } from 'luxon'
import Multiselect from 'vue-multiselect'
import { getTimeZones } from '@vvo/tzdb'
import { TrashIcon, XIcon } from 'vue-feather-icons'

import { CustomSingleSelect, CustomMultiSelect as CustomMultiselectOther } from '@common/components'

import Collapsable from '../Collapsable.vue'
import CountCircle from '../CountCircle.vue'

import ButtonStyleless from '../../../../cockpit/src/components/Atomic/Atoms/ButtonStyleless'

import { getUserIdFromToken, getUsernameFromToken } from '@common/utils'

import CREATE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/create.gql'
import DELETE_MANY_SCHEDULE_RECURRENCES_MUTATION from '#/graphql/calendar/schedule/scheduleRecurrence/deleteMany.gql'
import DELETE_MANY_SCHEDULE_ASSIGNED_ASSETS_MUTATION from '#/graphql/calendar/schedule/assignedAsset/deleteMany.gql'
import DELETE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/delete.gql'
// import MY_SCHEDULES_LIST_QUERY from '#/graphql/schedule/mySchedules.gql'
import ORGANIZATION_LIST_QUERY from '#/graphql/organizations/list.gql'
import ASSETS_BY_RECIPIENT_QUERY from '#/graphql/operations/assets/_assetsByRecipient.gql'
import UPDATE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/update.gql'
import EntityLinkAtom from '../../../../archon/src/components/Atomic/Atoms/EntityLinkAtom'
// import ASSIGNABLE_ASSETS_QUERY from '#/graphql/calendar/schedule/assignableAssets.gql'

const TIMEZONES = getTimeZones({
  includeUtc: true,
})

const InputStyled = styled('div')`
  display: grid;
  input {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.black};
    border: 1px solid ${({ theme }) => theme.colors.solidBG};
    outline: none;
    padding: 0.5rem;
    height: 1.75rem;
    margin: 0.25rem;
    border-radius: 0.5rem;
    font-size: 1rem;
  }
`

const CustomRadioButtonStyled = styled('label')`
  display: block;
  position: relative;
  height: 1.5rem;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1rem;
    width: 1rem;
    background-color: ${({ theme }) => theme.colors.solidBG};
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  & input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const ButtonStyled = styled('button')`
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.solidBG};
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.atomic.white};
    background: ${({ theme }) => theme.colors.primary};
    &.active:hover {
      color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.solidBG};
    }
  }
`

const ActionButtonStyled = styled(ButtonStyled)`
  border-radius: 0.5rem;
  width: auto;
  height: 2.5rem;
  padding: 0.5rem 1rem;
`

const ScheduleDialogStyled = styled('div')`
  {datetimeStyles}
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-gap: 2px;
  border-radius: .5rem;
  overflow: hidden;
  > div {
    padding: .5rem;
    background: ${({ theme }) => theme.colors.mainBG};
  }
  border-radius: .5rem;
  border: 1px solid ${({ theme }) => theme.colors.solidBG};
  top: calc(50% - 210px);
  left: calc(50% - 150px);
  width: 360px;
  height: 600px;
  .options, .collapsable > .collapsable-inner {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 1rem;
  }

  .select {
    border-radius: .5rem;
    background-color: ${({ theme }) => theme.colors.solidBG};
    transition: background-color 2s;
    &.shift {
      background-color: ${({ theme }) => theme.colors.atomic.dropdownButton};
    }
    &.planned_downtime {
      background-color: ${({ theme }) => theme.colors.atomic.avatar.pink}
    }
    .select-schedule-type {
      .multiselect__tags {
        .multiselect__single {
          color: ${({ theme }) => theme.colors.white}
        }
      }
    }
    .multiselect__option--disabled {
      background: transparent;
    }

    &.select-assigned-assets {
      .multiselect__content .multiselect__element .multiselect__option.multiselect__option--highlight {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.tableWidgetHightlight};
      }
    }
  }

  .weekdays {
    display: grid;
    grid-auto-flow: column dense;
    grid-gap: .5rem;
    justify-content: center;
  }

  .vdatetime {
    .vdatetime-popup {
      background: ${({ theme }) => theme.colors.solidBG};
      .vdatetime-popup__header {
        background: ${({ theme }) => theme.colors.solidBG};
      }
      .vdatetime-time-picker {
        .vdatetime-time-picker__item {
          color: ${({ theme }) => theme.colors.normalFontColor};
          &.vdatetime-time-picker__item--selected {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
      .vdatetime-popup__actions {
        margin: 0;
        padding: 1rem;
        display: grid;
        grid-gap: .5rem;
        grid-template-columns: 1fr 1fr;
        .vdatetime-popup__actions__button {
          display: flex;
          padding: 0;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
  .duration {
    font-size: .8rem;
  }
  .assigned-assets {
    min-height: 16rem;
    display: grid;
    grid-gap: 3px;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr;
    grid-auto-rows: 2rem;
    > .assigned-asset {
      display: flex;
      background: ${({ theme }) => theme.colors.solidBG};
      border-radius: 3px;
      > span {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 3px;
      }
      > button {
        height: 2rem;
      }
    }
  }
  .actions {
    position: sticky;
    bottom: 0;
    display: grid;
    grid-template-columns: ${({ hasDelete }) => hasDelete ? '3rem 1fr 1fr' : '1fr 1fr'} ;
    justify-content: center;
    grid-gap: 2rem;
  }

  h4 {
    margin-bottom: 0;
    font-weight: normal;
  }
`

export default {
  inject: ['uiSettings'],
  components: {
    DatePicker,
    ScheduleDialogStyled,
    ShowAsLabel,
    CustomSingleSelect,
    ButtonStyled,
    TrashIcon,
    XIcon,
    ButtonStyleless,
    Multiselect,
    CustomMultiselectOther,
    CustomRadioButtonStyled,
    ActionButtonStyled,
    InputStyled,
    Collapsable,
    CountCircle,
    EntityLinkAtom ,
},
  props: {
    calendarDates: {
      type: Object,
      required: true,
    },
    calendar: {
      type: Object,
      required: true,
    },
    selectedSchedule: {
      type: Object,
    },
  },
  data() {
    return {
      isRecurring: false,
      createShiftReport: false,
      createTyresHealthReport: false,
      customAssignedAssets: false,
      showTimeSettings: true,
      showAssignedAssets: false,
      mode: 'create',
      title: `${this.$tc('dashboard.shift', 1)} (1)`,
      availableScheduleTypes: [
        'SHIFT',
        'RECURRING_TASK',
        'ONE_TIME_TASK',
        'PLANNED_DOWNTIME',
      ],
      availableTimezones: orderBy(TIMEZONES, ['name']),
      selectedScheduleType: 'SHIFT',
      selectedTimezoneName: 'UTC',
      scheduleStartDateTime: null,
      startTime: null,
      endTime: null,
      interval: 1,
      organizations: [],
      assetsByRecipient: [],
      selectedOwnership: {
        id: getUserIdFromToken(this.$keycloak.token),
        type: 'User',
        label: `${this.$tc('user', 1)} - ${getUsernameFromToken(this.$keycloak.token)}`,
      },
      ownershipMyself: {
        id: getUserIdFromToken(this.$keycloak.token),
        type: 'User',
        label: `${this.$tc('user', 1)} - ${getUsernameFromToken(this.$keycloak.token)}`,
      },
      selectedFrequencyId: 'DAILY',
      selectedWeekDays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      assignableAssets: [],
      assignedAssets: [],
      // assignableAssetsIsLoading: false,
      DATE_SHORT: DateTime.DATE_SHORT,
      TIME_SIMPLE: DateTime.TIME_SIMPLE,
    }
  },
  // created() {
  //   this.findAssignableAssets = debounce(search => {
  //     this.debouncedAssignableAssets(search)
  //   }, 150)
  //   this.findAssignableAssets()
  // },
  watch: {
    calendarDates: {
      handler() {
        this.startTime = DateTime.fromJSDate(this.calendarDates.start).startOf('day').plus({ hours: 8 }).toISO()
        this.endTime = DateTime.fromJSDate(this.calendarDates.end).startOf('day').plus({ hours: 16 }).toISO()
      },
      immediate: true,
    },
    preview: {
      handler() {
        this.$emit('preview', this.preview)
      },
    },
    selectedSchedule: {
      handler(schedule){
        if(schedule){
          const scheduleRecurrence = schedule.recurrences[0]
          this.mode = 'edit'
          this.title = schedule.name
          this.selectedScheduleType = schedule.type
          this.selectedTimezoneName = schedule.timezone
          const scheduleStartOn = DateTime.fromISO(scheduleRecurrence.start).setZone(scheduleRecurrence.timezone)
          const s = {
            hour: scheduleRecurrence?.byHourOfDay[0],
            minute: scheduleRecurrence?.byMinuteOfHour[0],
            second: scheduleRecurrence?.bySecondOfMinute[0],
          }
          const start = scheduleStartOn.setZone(schedule.timezone).set(s)
          this.scheduleStartDateTime = scheduleStartOn.toJSDate()
          const duration = Duration.fromMillis(scheduleRecurrence.duration)
          const end = start.plus(duration)
          this.startTime = start.toFormat('HH:mm:ss')
          this.endTime = end.toFormat('HH:mm:ss')
          this.selectedFrequencyId = scheduleRecurrence.frequency
          this.selectedWeekDays = scheduleRecurrence.byDayOfWeek
          this.interval = scheduleRecurrence.interval || 1
          this.customAssignedAssets = schedule.assignedAssets.length > 0
          this.assignedAssets = cloneDeep(schedule.assignedAssets)
          this.showTimeSettings = false
          this.createShiftReport = schedule.createShiftReport
          this.createTyresHealthReport = schedule.createTyresHealthReport
        } else {
          this.mode = 'create'
        }
      },
      immediate: true,
    },
  },
  computed: {
    day(){
      if(!this.scheduleStartDateTime || !this.selectedTimezoneName){
        return null
      }
      return DateTime.fromJSDate(this.scheduleStartDateTime).setZone(this.selectedTimezoneName)
    },
    start(){
      const day = this.day
      if(day === null){
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      return DateTime.fromFormat(`${dayStr} ${this.startTime}`, 'yyyy-LL-dd HH:mm:ss')
    },
    end(){
      const day = this.day
      if(day === null){
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      const end = DateTime.fromFormat(`${dayStr} ${this.endTime}`, 'yyyy-LL-dd HH:mm:ss')
      if(end.toMillis() <= this.start.toMillis()){
        return end.plus({days: 1})
      }
      return end
    },
    duration(){
      const start = this.start
      const end = this.end
      if(!start || !end){
        return null
      }
      const duration = end.diff(start)
      if(duration.isLuxonDuration){
        return duration
      }
      return null
    },
    selectedTimezone(){
      return this.availableTimezones.find(f => f.name === this.selectedTimezoneName)
    },
    startAndEndSameDay(){
      return this.start.hasSame(this.end,'day')
    },
    canDelete(){
      return this.selectedSchedule?.id
    },
    availableOwnerships() {
      return [
        this.ownershipMyself,
        ...this.organizations.reduce((acc, organization) => {
          acc.push({
            id: organization.id,
            type: 'Organization',
            label: `${this.$tc('organization', 1)} - ${organization.name}`,
          })
          organization.subsidiaries.forEach(subsidiary => {
            acc.push({
              id: subsidiary.id,
              type: 'Subsidiary',
              label: `${this.$tc('subsidiary', 1)} - ${subsidiary.name}`,
            })
          })
          return acc
        }, []),
      ]
    },
    rrule(){
      const scheduleStartDateTime = DateTime.fromJSDate(this.scheduleStartDateTime).setZone(this.selectedTimezoneName, { keepLocalTime: true })
      const start = this.start
      if(scheduleStartDateTime.invalid || !this.duration || !start){
        return null
      }
      const duration = this.duration.toMillis()
      if(duration < 1){
        return null
      }
      const rrule = {
        frequency: this.selectedFrequencyId,
        interval: parseInt(this.interval),
        start: scheduleStartDateTime,
        duration,
        byDayOfWeek: this.weekdays.filter(f => f.active).map(weekday => weekday.weekday),
        byHourOfDay: [this.start.hour],
        byMinuteOfHour: [this.start.minute],
        bySecondOfMinute: [this.start.second],
      }
      return rrule
    },
    preview() {
      if(!this.rrule){
        return null
      }
      const r = {
        title: this.title,
        rrule: this.rrule,
        timezone: this.selectedTimezoneName,
        duration: this.duration.toMillis(),
        type: this.selectedScheduleType,
      }
      return r
    },
    selectedScheduleTypeClass() {
      const name = this.selectedScheduleType.toLowerCase()
      return name
    },
    // duration() {
    //   const startTime = DateTime.fromISO(this.startTime)
    //   let endTime = DateTime.fromISO(this.endTime)
    //   const dayStart = DateTime.fromJSDate(this.calendar.start)
    //   const dayEnd = DateTime.fromJSDate(this.calendar.end)
    //   if(dayStart.hasSame(dayEnd, 'day') && endTime < startTime) {
    //     endTime = endTime.plus({ day: 1 })
    //   }
    //   return endTime.diff(startTime)
    // },
    locale() {
      return this.uiSettings?.dates?.toLowerCase().replace('_', '-')
    },
    availableFrequencies() {
      return [
        {
          id: 'DAILY',
          value: 'DAILY',
          label: 'daily',
        },
        {
          id: 'WEEKLY',
          value: 'WEEKLY',
          label: 'weekly',
        },
        {
          id: 'MONTHLY',
          value: 'MONTHLY',
          label: 'monthly',
        },
        {
          id: 'YEARLY',
          value: 'YEARLY',
          label: 'yearly',
        },
      ]
    },
    availableScheduleTypesMapped() {
      const a = this.availableScheduleTypes.map(scheduleType => ({
        id: scheduleType,
        label: scheduleType,
        $isDisabled: scheduleType !== 'SHIFT',
      }))
      return a
    },
    assignedAssetsMapped(){
      return this.assignedAssets.map(scheduleAssignedAsset => scheduleAssignedAsset.asset)
    },
    assignableAssetsMapped(){
      // return this.assignableAssets.filter(f => !this.assignedAssetsMapped.find(a => a.id === f.id)).map(asset => ({
      //   id: asset.id,
      //   label: asset.name,
      //   type: asset.__typename,
      // }))
      return this.assetsByRecipient
                .filter(f => !this.assignedAssetsMapped.find(a => a.id === f.id))
                .map(asset => ({
                  id: asset.id,
                  label: asset.name,
                  type: asset.__typename,
                }))
    },
    selectedScheduleTypeMapped(){
      return this.availableScheduleTypesMapped.find(f => f.id === this.selectedScheduleType)
    },
    firstRecurrenceId(){
      return this.selectedSchedule?.recurrences[0]?.id
    },
    weekdays() {
      // const locale = this.locale
      // const startOfWeek = DateTime.local().startOf('week')
      const weekdaysAvailable = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
      return weekdaysAvailable.map((weekday, i) => {
        return {
          id: i,
          weekday: weekday,
          label: weekday.toString(),
          active: this.selectedWeekDays.includes(weekday),
        }
      })
    },
  },
  methods: {
    selectTimezone({ name }){
      this.selectedTimezoneName = name
    },
    // async debouncedAssignableAssets(search){
    //   this.searchQuery = search
    //   this.assignableAssetsIsLoading = true
    //   const results = await this.$apollo.query({
    //     query: ASSIGNABLE_ASSETS_QUERY,
    //     variables: {
    //       search: this.searchQuery,
    //     },
    //   })
    //   this.assignableAssetsIsLoading = false
    //   this.assignableAssets = results?.data?.assignableAssets
    // },
    selectAssignableAsset(assignableAsset){
      this.assignedAssets.push({
        asset: {
          id: assignableAsset.id,
          name: assignableAsset.label,
          assetType: assignableAsset.type,
        },
      })
    },
    selectScheduleType(scheduleType) {
      this.selectedScheduleType = scheduleType.id
    },
    selectOwnership(ownership) {
      this.selectedOwnership = ownership
    },
    selectFrequency(frequencyId) {
      this.selectedFrequencyId = frequencyId
    },
    toggleWeekday(weekday) {
      if(this.selectedWeekDays.includes(weekday.weekday)) {
        this.selectedWeekDays = this.selectedWeekDays.filter(f => f !== weekday.weekday)
      } else {
        this.selectedWeekDays.push(weekday.weekday)
      }
    },
    handleTimeChange() {},
    async confirm(){
      if(this.mode === 'create'){
        return this.createSchedule()
      } else {
        return this.updateSchedule()
      }
    },
    confirmDeleteSchedule(model){
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Schedule',
        instance: model,
        labelKey: 'Schedule',
        onConfirm: this.deleteSchedule,
        onConfirmArgs: model,
      }, this.onCloseSettings)
    },
    async deleteSchedule(model){
      await this.$apollo.mutate({
        mutation: DELETE_MANY_SCHEDULE_RECURRENCES_MUTATION,
        variables: {
          scheduleId: model?.id,
        },
      })
      await this.$apollo.mutate({
        mutation: DELETE_MANY_SCHEDULE_ASSIGNED_ASSETS_MUTATION,
        variables: {
          scheduleId: model?.id,
        },
      })
      const res = await this.$apollo.mutate({
        mutation: DELETE_SCHEDULE_MUTATION,
        variables: {
          where: {
            id: model.id,
          },
        },
      })
      this.$emit('close-dialog')
      return !!res
    },
    async updateSchedule() {
      const recurrenceData = {}
      Object.keys(this.rrule).map(key => {
        if(this.rrule[key]?.isLuxonDateTime){
          recurrenceData[key] = {
            set: this.rrule[key].toISO(),
          }
        } else {
          recurrenceData[key] = {
            set: this.rrule[key],
          }
        }
      })

      const data = {
        type: {
          set: this.selectedScheduleType,
        },
        name: {
          set: this.title,
        },
        createShiftReport: {
          set: this.createShiftReport,
        },
        createTyresHealthReport: {
          set: this.createTyresHealthReport,
        },
        assignedAssets: {
          create: this.assignedAssets.filter(f => !this.selectedSchedule?.assignedAssets?.find(a => f.id === a.asset.id)).map(m => ({ assetId: m.asset.id, assetType: m.asset.assetType })),
          delete: this.selectedSchedule?.assignedAssets.filter(f => !this.assignedAssets.find(a => f.asset?.id === a.id)).map(m => ({ id: m.id })),
        },
      }

      if(this.selectedSchedule?.useTimezoneInRSchedule){
        data.timezone = {
          set: this.selectedTimezoneName,
        }
        data.recurrences = {
          update: {
            data: recurrenceData,
            where: {
              id: this.firstRecurrenceId,
            },
          },
        }
      }

      if(this.customAssignedAssets === false){
        data.assignedAssets = {
          delete: this.selectedSchedule?.assignedAssets.map(m => ({ id: m.id })),
        }
      }
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SCHEDULE_MUTATION,
        variables: {
          data,
          where: {
            id: this.selectedSchedule?.id,
          },
        },
      })
      this.$emit('close-dialog')
      return !!res
    },
    async createSchedule() {
      const recurrenceData = {
        start: this.rrule?.start?.toISO(),
        end: this.rrule?.end?.toISO(),
        frequency: this.rrule?.frequency,
        interval: this.rrule?.interval,
        duration: this.rrule?.duration,
        bySecondOfMinute: {
          set: this.rrule?.bySecondOfMinute,
        },
        byMinuteOfHour: {
          set: this.rrule?.byMinuteOfHour,
        },
        byHourOfDay: {
          set: this.rrule?.byHourOfDay,
        },
        byDayOfWeek: {
          set: this.rrule?.byDayOfWeek,
        },
      }

      const data = {
        type: this.selectedScheduleType,
        name: this.title,
        createShiftReport: this.createShiftReport,
        createTyresHealthReport: this.createTyresHealthReport,
        calendar: {
          connect: {
            id: this.calendar.id,
          },
        },
        timezone: this.selectedTimezoneName,
        useTimezoneInRSchedule: true,
        recurrences: {
          create: [recurrenceData],
        },
      }
      // data.recurrences.create.start = data.recurrences.create.start.toISO()
      // data.recurrences.create.end = data.recurrences.create.end?.toISO()
      // data.recurrences.create.byDayOfWeek = {
      //   set: data.recurrences.create.byDayOfWeek,
      // }
      await this.$apollo.mutate({
        mutation: CREATE_SCHEDULE_MUTATION,
        variables: {
          data,
        },
      })
      this.$emit('close-dialog')
    },
    removeAssignedAsset(assignedAsset){
      this.assignedAssets = this.assignedAssets.filter(f => f.asset.id !== assignedAsset.id)
    },
    openScheduleStartTime(){
      this.$refs.scheduleStartTime.isOpen = true
    },
    openScheduleEndTime(){
      this.$refs.scheduleEndTime.isOpen = true
    },
  },
  apollo: {
    organizations: {
      query: ORGANIZATION_LIST_QUERY,
      variables() {
        return {
          where: {
            memberships_some: {
              userId: getUserIdFromToken(this.$keycloak.token),
            },
          },
        }
      },
    },
    assetsByRecipient: {
      query: ASSETS_BY_RECIPIENT_QUERY,
      variables() {
        return {
          where: {
            recipientId: this.calendar.ownerId,
            recipientType: this.calendar.ownerType,
          },
        }
      },
      skip() {
        return !this.calendar?.ownerType || !this.calendar?.ownerId
      },
    },
  },
}
</script>
