<template>
  <BannerStyled>
    <p>
      {{ $t('messages.cookieBanner') }}
    </p>

    <ButtonBorderedStyled @click="closeBanner">
      {{ $t('actions.close') }}
    </ButtonBorderedStyled>
  </BannerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Vue from 'vue'
import chroma from 'chroma-js'
import { ButtonBordered } from '@styles/buttons'

const BannerStyled = styled('div')`
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  padding: 1rem;
  text-align: center;
  position: fixed;
  bottom: 0px;
  color: ${p => p.theme.colors.navFontNormal};
  background-color: ${p => chroma(p.theme.colors.solidBG).alpha(1).css()};
  justify-items: center;
  font-size: 0.9rem;
  width: -webkit-fill-available;
`
const ButtonBorderedStyled = styled(ButtonBordered)`
  margin: 0.5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)}!important;
  background: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 0.9rem;
  &:hover {
    color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)};
    background: ${props => props.theme.colors.primaryActive};
  }
`

export default {
  components: {
    BannerStyled,
    ButtonBorderedStyled,
  },
  methods: {
    closeBanner() {
      Vue.localStorage.set('closeBanner', true)
      this.$emit('close')
    },
  },
}
</script>
