var render = function () {
  var _vm$rule, _vm$rule$assetDimensi, _vm$rule2, _vm$rule2$assetDimens, _vm$rule3, _vm$rule4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RuleItemStyled', [_c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.index) + " ")]), _c('div', {
    staticClass: "dimension",
    attrs: {
      "title": (_vm$rule = _vm.rule) === null || _vm$rule === void 0 ? void 0 : (_vm$rule$assetDimensi = _vm$rule.assetDimension) === null || _vm$rule$assetDimensi === void 0 ? void 0 : _vm$rule$assetDimensi.translatedName
    }
  }, [_vm._v(" " + _vm._s((_vm$rule2 = _vm.rule) === null || _vm$rule2 === void 0 ? void 0 : (_vm$rule2$assetDimens = _vm$rule2.assetDimension) === null || _vm$rule2$assetDimens === void 0 ? void 0 : _vm$rule2$assetDimens.translatedName) + " ")]), ((_vm$rule3 = _vm.rule) === null || _vm$rule3 === void 0 ? void 0 : _vm$rule3.thresholdTypeEnum) === 'RANGE' ? [_c('MinMaxvalueAtom', {
    attrs: {
      "min": _vm.min,
      "max": _vm.max,
      "unit": _vm.unitUI
    }
  })] : [_c('ThresholdValueAtom', {
    attrs: {
      "value": _vm.threshold,
      "thresholdType": (_vm$rule4 = _vm.rule) === null || _vm$rule4 === void 0 ? void 0 : _vm$rule4.thresholdTypeEnum,
      "unit": _vm.unitUI
    }
  })], _c('div', {
    staticClass: "actions"
  }, [_c('ActionIconAtom', [_c('template', {
    slot: "icon"
  }, [_c('Edit2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('updateRule', _vm.index - 1);
      }
    }
  })], 1)], 2), _c('ActionIconAtom', [_c('template', {
    slot: "icon"
  }, [_c('Trash2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('removeRule', _vm.index - 1);
      }
    }
  })], 1)], 2)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }