<template>
  <LoaderStyled v-if="$apollo.loading">
    <TalpaLoaderWrapper />
    <GlobalStyle :theme="getTheme" />
  </LoaderStyled>
  <AppStyled id="app" v-else :isMenuExpanded="isMenuExpanded">
    <GlobalStyle :theme="getTheme" />
    <TopBar :overlayType="overlayType" @activateOverlay="activateOverlay" />
    <Navigation />
    <MainStyled id="app-main" ref="main">
      <router-view name="main" :hasScrolled="mainHasScrolled" />
      <FlashMessagesStyled>
        <flash-message transitionName="flash" />
      </FlashMessagesStyled>
      <CookieBanner v-if="!showBanner" @close="showBanner = true" />
    </MainStyled>
    <Overlay :type="overlayType" :args="overlayArgs" :onClose="overlayOnClose" @closeOverlay="closeOverlay" />
  </AppStyled>
</template>

<script>
import { styled, createGlobalStyle } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'
import { BaseColors, LightColors } from '@styles/themes'
import Navigation from '@/components/App/Navigation'
import Overlay from '@/components/App/Overlay'
import TopBar from '@/components/App/TopBar/TopBar'
import { TooltipStyles, TransitionStyles, MultiselectStyles, ScrollStyles } from '@styles/misc'
import { getUserIdFromToken } from '@common/utils'
import { flexCenter } from '@styles/mixins'
import { SSOMixin, ScrolledMixin, OverlayMixin, FlashMessagesMixin } from '@common/mixins'
import { TalpaLoaderWrapper } from '@common/components'
import { useStore } from '@/stores/userSettings'
import CookieBanner from '@/components/Atomic/Atoms/CookieBanner'
import Vue from 'vue'

import PROFILE_QUERY from '#/graphql/profile/show.gql'
import UPDATE_UI_SETTINGS_MUTATION from '#/graphql/user/uiSettings/update.gql'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    color: ${p => p.theme.colors.normalFontColor};
    font-family: 'Open Sans', sans-serif;
    background: ${p => (p.theme.isDark ? p.theme.colors.talpaGrey : p.theme.colors.white)};
    ${TooltipStyles}
    ${TransitionStyles}
  }
  body, html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow: hidden;
  }
`

const AppStyled = styled('div')`
  ${TooltipStyles}
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;

  grid-gap: 1px;
  background: ${p => p.theme.colors.mainBG};

  grid-template-areas:
    'top-bar top-bar'
    'navigation main';
  grid-template-rows: 50px 1fr;

  @media (min-width: 768px) {
    grid-gap: 1px;
    grid-template-columns: ${({ isMenuExpanded }) => (isMenuExpanded ? '200px 1fr;' : '50px 1fr;')};
    transition: width 0.4s linear;
  }

  main a {
    color: ${p => p.theme.colors.primary};
    text-decoration: none;
    &:hover,
    &:focus,
    &.router-link-exact-active {
      color: ${p => p.theme.colors.primaryLighter};
    }
  }

  div,
  section,
  main {
    ${ScrollStyles}
  }

  .mapboxgl-popup-content {
    color: black;
  }
  ${MultiselectStyles}
`

const FlashMessagesStyled = styled('div')`
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 9000;
  top: calc(50px + 1rem);
  left: calc(200px + 1rem);
  max-height: 400px;
  width: calc(100% - 200px - 4rem);
  @media (max-width: 767px) {
    top: calc(50px + 2rem);
    left: calc(60px + 1rem);
    width: calc(100% - 6rem);
  }
  .flash__message {
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: ${({ theme }) => chroma(theme.colors.white).alpha(0.1).css()};
    border: 1px solid ${({ theme }) => chroma(theme.colors.white).alpha(0.1).css()};
    border-radius: 3px;
    margin-bottom: 0.5rem;

    &.error {
      background: ${({ theme }) => chroma(theme.colors.red).alpha(0.7).css()};
    }

    &.success {
      background: ${({ theme }) => chroma(theme.colors.green).alpha(0.7).css()};
    }

    button {
      ${flexCenter}
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 0.5rem;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => chroma(theme.colors.white).alpha(0.25).css()};
      border: none;
      outline: none;

      &:hover {
        background: ${({ theme }) => chroma(theme.colors.black).alpha(0.25).css()};
      }
    }
  }
`

const MainStyled = styled('main')`
  grid-area: main;
  width: 100%;
  height: 100%;
  background: ${p => p.theme.colors.mainBG};
  grid-column: span 4;
  overflow: auto;
  color: ${p => p.theme.colors.normalFontColor};
`

const LoaderStyled = styled('div')`
  height: 100vh;
  max-height: 100vh;
  background: ${p => p.theme.colors.mainBG};
`
export default {
  mixins: [SSOMixin, ScrolledMixin, OverlayMixin, FlashMessagesMixin],
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    GlobalStyle,
    AppStyled,
    TopBar,
    Navigation,
    Overlay,
    MainStyled,
    FlashMessagesStyled,
    TalpaLoaderWrapper,
    LoaderStyled,
    CookieBanner,
  },
  localStorage: {
    isDarkTheme: {
      type: Boolean,
      default: true,
      bind: true,
    },
    viewReleaseNotes: {
      type: Boolean,
      default: true,
      bind: true,
    },
    tyreMessageAcknowleged: {
      type: Boolean,
      default: false,
      bind: true,
    },
    closeBanner: {
      type: Boolean,
      default: false,
      bind: true,
    },
  },
  provide() {
    const theme = {}
    Object.defineProperty(theme, 'colors', {
      enumerable: true,
      get: () => {
        return this.colors
      },
    })
    Object.defineProperty(theme, 'isDark', {
      enumerable: true,
      get: () => {
        return this.isDarkTheme
      },
    })
    const permissions = {}
    Object.defineProperty(permissions, 'read', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'permissions', []).filter(permission => permission.name.includes('_read'))
      },
    })
    Object.defineProperty(permissions, 'write', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'permissions', []).filter(permission => permission.name.includes('_write'))
      },
    })
    Object.defineProperty(permissions, 'all', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'permissions', [])
      },
    })
    const uiSettings = {}
    Object.defineProperty(uiSettings, 'language', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.language')
      },
    })
    Object.defineProperty(uiSettings, 'dates', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.dates')
      },
    })
    Object.defineProperty(uiSettings, 'units', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.units')
      },
    })
    Object.defineProperty(uiSettings, 'time', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.time')
      },
    })
    Object.defineProperty(uiSettings, 'hasAcceptedPrivacyPolicy', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.hasAcceptedPrivacyPolicy')
      },
    })
    Object.defineProperty(uiSettings, 'defaultApp', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.defaultApp')
      },
    })
    return {
      theme,
      uiSettings,
      permissions,
    }
  },
  data() {
    return {
      profile: {},
      errors: [],
      isReportPreview: false,
      ctrlDown: false,
      showBanner: false,
    }
  },
  mounted() {
    this.updateSelectedTheme()
    this.showBanner = Vue.localStorage.get('closeBanner')
  },
  created() {
    this.$root.$on('toggleTheme', this.toggleTheme)
    this.$root.$on('toggleReportView', this.toggleReportView)
    this.$root.$on('hasViewedPrivacyPolicy', this.updatePrivacyPolicyStatus)
  },
  computed: {
    getTheme() {
      return {
        colors: this.colors,
      }
    },
    colors() {
      return this.isDarkTheme
        ? {
            ...BaseColors,
          }
        : {
            ...BaseColors,
            ...LightColors,
          }
    },
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
  },
  watch: {
    profile: {
      handler(profile) {
        const lang = get(profile, 'uiSettings.language', 'EN').toLowerCase()
        this.$i18n.locale = lang
      },
      immediate: true,
    },
  },
  methods: {
    toggleReportView() {
      this.isReportPreview = !this.isReportPreview
    },
    toggleTheme() {
      const b = this.isDarkTheme
      this.isDarkTheme = !b
      this.updateSelectedTheme()
    },
    async updatePrivacyPolicyStatus() {
      await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            hasAcceptedPrivacyPolicy: true,
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
    },
    async updateSelectedTheme() {
      await this.$apollo.mutate({
        mutation: UPDATE_UI_SETTINGS_MUTATION,
        variables: {
          data: {
            theme: this.isDarkTheme ? 'DARK' : 'LIGHT',
          },
          where: {
            ownerUserId: getUserIdFromToken(this.$keycloak.token),
          },
        },
      })
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
