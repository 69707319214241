var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DropdownSelectorStyled', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.closeDropdownList,
      expression: "closeDropdownList"
    }]
  }, [_c('ToggleStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "bottom": true
      }
    }],
    ref: "toggle",
    class: {
      flash: _vm.doFlashing
    },
    attrs: {
      "disabled": _vm.isDisabled,
      "id": "addButton"
    },
    on: {
      "click": _vm.toggleShowList
    }
  }, [_c('PlusSquare', {
    staticClass: "icon",
    attrs: {
      "size": "1.4x"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('feed.card.addCard')))]), _vm.selectedCount ? _c('CountCircle', {
    attrs: {
      "count": _vm.selectedCount
    }
  }) : _vm._e()], 1), !_vm.isDisabled ? _c('ListWrapperStyled', [_c('transition', {
    attrs: {
      "name": "fade-in-down"
    }
  }, [_vm.showList ? _c('div', {
    class: _vm.noAssetDimensions ? 'list-empty' : 'list-inner'
  }, [!_vm.isLoading && !_vm.noAssetDimensions ? [_c('ListSearchStyled', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    attrs: {
      "placeholder": _vm.$t('feed.card.search')
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  })]), _c('DropdownList', {
    attrs: {
      "dataList": _vm.dataListFiltered,
      "totalCount": _vm.totalCount
    },
    on: {
      "selectedValue": _vm.selectedValue
    }
  })] : !_vm.isLoading && _vm.noAssetDimensions ? _c('NoDataAlert', {
    attrs: {
      "popoverContent": _vm.$t('feed.card.popover.info'),
      "popoverTitle": _vm.$t('feed.card.popover.title')
    }
  }) : _vm.isLoading ? [_c('TalpaLoaderWrapper')] : _vm._e()], 2) : _vm._e()])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }