<template>
  <MembershipManangerStyled>
    <div class="title">
      {{ title }}
    </div>
    <div class="memberships">
      <div class="membership" v-for="membership in membershipsMapped" :key="membership.id">
        <div class="label">
          <EntityLinkAtom :type="membership.entityType" :id="membership.entityId" :label="membership.label" />
        </div>
        <div class="role">
          <BasicSelect
            :target="$tc('role', 1)"
            :options="roles"
            :selected="membership.selectedRole"
            @change="setRole($event, membership)"
          />
        </div>
        <button class="delete" @click="deleteMemberConfirmation(membership)">
          <XIcon />
        </button>
      </div>
    </div>
    <div class="add-icon">
      <PlusCircleIcon />
    </div>
    <Multiselect
      class="multiselect"
      track-by="id"
      label="label"
      placeholder="Type to search"
      :options="addables"
      :searchable="true"
      :multiple="true"
      :loading="addablesLoading"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      :limit="3"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @search-change="findAddables"
      @select="add($event)"
    />
  </MembershipManangerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'
import chroma from 'chroma-js'
import { PlusCircleIcon, XIcon } from 'vue-feather-icons'

import EntityLinkAtom from '../../../archon/src/components/Atomic/Atoms/EntityLinkAtom'
import { BasicSelect } from '@common/components'

import { flexCenter, buttonReset } from '@styles/mixins'

import { FlashMessages } from '@common/singletons'

import LIST_ORGANIZATIONS_QUERY from '#/graphql/organizations/listMinimal.gql'
import SUBSIDIARY_QUERY from '#/graphql/organizations/subsidiary/show.gql'
import CREATE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/create.gql'
import DELETE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/delete.gql'
import UPDATE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/update.gql'
import USER_QUERY from '#/graphql/user/show.gql'
import USERS_QUERY from '#/graphql/users/users.gql'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'
import AVAILABLE_ROLES_QUERY from '#/graphql/misc/availableRoles.gql'

const MembershipManangerStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'title title'
    'memberships memberships'
    'add multiselect';
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  .add-icon {
    ${flexCenter}
    grid-area: add;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
  }
  .title {
    ${flexCenter}
    justify-content: flex-start;
    padding-left: 1rem;
    grid-area: title;
    font-size: 1.2rem;
  }
  .memberships {
    grid-area: memberships;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr;
    grid-auto-rows: 2.5rem;
    margin: 2px;
    overflow: auto;
    padding: 1rem 0;
    padding-bottom: 5rem;
    max-height: 800px;
    .membership {
      display: grid;
      grid-template-columns: 3fr 1fr 2rem;
      grid-template-rows: 2.5rem;
      grid-gap: 2px;
      > .label {
        ${flexCenter}
        justify-content: flex-start;
        padding-left: 0.5rem;
        background: ${props => chroma(props.theme.colors.black).alpha(0.5).css()};
      }
      .role {
        .basic-select {
          height: 2.5rem;
        }
      }
      button {
        ${buttonReset}
        ${flexCenter}
        background: ${({ theme }) => theme.colors.archonBlueTransparent};
      }
    }
  }
  .multiselect {
    grid-area: multiselect;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    memberships: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    subsidiaries: {
      type: Array,
      required: false,
    },
  },
  components: {
    MembershipManangerStyled,
    EntityLinkAtom,
    BasicSelect,
    Multiselect,
    XIcon,
    PlusCircleIcon,
  },
  data() {
    return {
      availableRoles: [],
      organizations: [],
      users: [],
      searchString: '',
    }
  },
  computed: {
    membershipsMapped() {
      return this.memberships.map(membership => {
        if (this.options?.mode === 'organization') {
          return {
            ...membership,
            label: membership?.organization?.name,
            selectedRole: this.roles.find(f => f.id === membership.role),
            entityType: membership?.organization?.__typename,
            entityId: membership?.organization?.id,
          }
        } else if (this.options?.mode === 'user') {
          return {
            ...membership,
            label: membership?.user?.username,
            selectedRole: this.roles.find(f => f.id === membership.role),
            entityType: membership?.user?.__typename,
            entityId: membership?.user?.id,
          }
        }
        throw new Error(`Unhandled mode '${this.options?.mode}'`)
      })
    },
    roles() {
      return (this.availableRoles?.enumValues ?? []).map(roleEnum => ({
        id: roleEnum.name,
        label: this.$tc(`membershipRoles.${roleEnum.name.toLowerCase()}`, 1),
      }))
    },
    addablesLoading() {
      // return this.options.mode === 'organization' ? this.$apollo.organizations.loading
      return false
    },
    addables() {
      if (this.options?.mode === 'user') {
        return this.users
          .filter(f => !this.membershipsMapped.find(membership => membership.userId === f.id))
          .map(user => ({
            ...user,
            label: `${user.username} - ${user.email}`,
          }))
      }
      if (this.options?.mode === 'organization') {
        return this.organizations
          .filter(f => !this.membershipsMapped.find(membership => membership.organization.id === f.id))
          .map(organization => ({
            ...organization,
            label: organization.name,
          }))
      }
      return []
    },
  },
  mounted() {
    this.findAddables()
  },
  methods: {
    deleteMemberConfirmation(membership) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Membership',
        instance: membership.user,
        labelKey: 'username',
        onConfirm: this.deleteMembership,
        onConfirmArgs: membership,
      })
    },

    async deleteMembership(membership) {
      if (!membership.id || !membership.userId) {
        return
      }
      if (membership?.__typename === 'OrganizationMembership') {
        const res = await this.$apollo.mutate({
          mutation: DELETE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            where: {
              id: membership.id,
              userId: membership.userId,
            },
          },
          update: (store, { data }) => {
            if (this.options?.mode === 'organization') {
              this.updateUserInStore(store, 'delete', data)
            }
            if (this.options?.mode === 'user') {
              this.updateOrganizationInStore(store, 'delete', data)
            }
            if (this.subsidiaries && this.subsidiaries.length > 0) {
              this.updateSubsidiaryInStore(store)
            }
          },
        })
        this.$root.$emit('closeOverlay')
        if (res) {
          FlashMessages.$emit('success', `Successfully deleted user from organization.`, {
            timeout: 3000,
          })
          return
        }
      }
      FlashMessages.$emit('error', new Error(`Unknown delete type '${membership?.__typename}'.`), {
        timeout: 3000,
      })
    },
    findAddables(searchString) {
      this.searchString = searchString
    },
    async setRole(role, membership) {
      if (membership?.__typename === 'OrganizationMembership') {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              role: role.id,
            },
            where: {
              id: membership.id,
            },
          },
        })
        if (res) {
          FlashMessages.$emit('success', `Successfully changed user role to ${res.data.updateOrganizationMembership.role}.`, {
            timeout: 3000,
          })
        }
      }
    },
    async add(addable) {
      const targetId = this.targetId
      if (!targetId) {
        FlashMessages.$emit('error', new Error(`Missing targetId.`), {
          timeout: 3000,
        })
        return
      }
      if (addable?.__typename === 'Organization') {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              userId: targetId,
              organization: {
                connect: {
                  id: addable.id,
                },
              },
              role: 'USER',
            },
          },
          update: (store, { data }) => {
            this.updateUserInStore(store, 'add', data)
          },
        })
        if (res) {
          FlashMessages.$emit('success', `Successfully added user to organization.`, {
            timeout: 3000,
          })
          return
        }
      }
      if (addable?.__typename === 'User') {
        const res = await this.$apollo.mutate({
          mutation: CREATE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              userId: addable.id,
              organization: {
                connect: {
                  id: targetId,
                },
              },
              role: 'USER',
            },
          },
          update: (store, { data }) => {
            this.updateOrganizationInStore(store, 'add', data)
          },
        })
        if (res) {
          FlashMessages.$emit('success', `Successfully added user to organization.`, {
            timeout: 3000,
          })
          return
        }
      }
      FlashMessages.$emit('error', new Error(`Unknown add type '${addable?.__typename}'.`), {
        timeout: 3000,
      })
    },
    updateUserInStore(store, mode, data) {
      const { user } = store.readQuery({
        query: USER_QUERY,
        variables: {
          where: {
            id: this.$route.params.id,
          },
        },
      })
      if (mode === 'add') {
        user.memberships.push(data.createOrganizationMembership)
      } else {
        user.memberships = user.memberships.filter(f => f.id !== data.deleteOrganizationMembership.id)
      }
      store.writeQuery({
        query: USER_QUERY,
        variables: {
          where: {
            id: this.$route.params.id,
          },
        },
        data: {
          user,
        },
      })
    },
    updateOrganizationInStore(store, mode, data) {
      const { organization } = store.readQuery({
        query: ORGANIZATION_QUERY,
        variables: {
          where: {
            id: this.$route.params.id,
          },
        },
      })
      if (mode === 'add') {
        organization.memberships.push(data.createOrganizationMembership)
      } else {
        organization.memberships = organization.memberships.filter(f => f.id !== data.deleteOrganizationMembership.id)
      }
      store.writeQuery({
        query: ORGANIZATION_QUERY,
        variables: {
          where: {
            id: this.$route.params.id,
          },
        },
        data: {
          organization,
        },
      })
    },
    updateSubsidiaryInStore(store) {
      const subsidiaries = this.subsidiaries.map(subsidiary => subsidiary.id)
      subsidiaries.forEach(async subsidiaryId => {
        const subsidiaryResponse = await this.$apollo.query({
          query: SUBSIDIARY_QUERY,
          variables: {
            where: {
              id: subsidiaryId,
            },
          },
          fetchPolicy: 'network-only',
        })

        const subsidiary = subsidiaryResponse?.data?.subsidiary ?? {}

        store.writeQuery({
          query: SUBSIDIARY_QUERY,
          variables: {
            where: {
              id: subsidiaryId,
            },
          },
          data: {
            subsidiary,
          },
        })
      })
    },
  },
  apollo: {
    availableRoles: {
      query: AVAILABLE_ROLES_QUERY,
      variables() {
        return {
          enumName: this.options.enumName,
        }
      },
    },
    organizations: {
      query: LIST_ORGANIZATIONS_QUERY,
      variables() {
        if (this.searchString === '') {
          return undefined
        }
        return {
          where: {
            OR: [
              {
                name_contains: this.searchString,
              },
              {
                alias_contains: this.searchString,
              },
            ],
          },
        }
      },
      skip() {
        return this.options?.mode !== 'organization'
      },
    },
    users: {
      query: USERS_QUERY,
      debounce: 500,
      variables() {
        if (this.searchString === '') {
          return undefined
        }
        return {
          where: {
            search: this.searchString,
          },
        }
      },
      skip() {
        return this.options?.mode !== 'user'
      },
    },
  },
}
</script>
