var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SessionExpiredOverlayStyled', [_c('Modal', {
    attrs: {
      "errors": _vm.errors
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('titles.sessionEnded')) + " ")];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('p', [_vm._v(" " + _vm._s(_vm.$t('messages.sessionEnded')) + " ")])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('ActionsStyled', [_c('ButtonBorderedStyled', {
          on: {
            "click": _vm.redirectToLogin
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.login')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }