<template>
  <CloseButtonStyled>
    <XIcon class="close-icon" @click="$emit('close')" />
  </CloseButtonStyled>
</template>
<script>
const CloseButtonStyled = styled('div')`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.primaryActive};
  }
`
import { styled } from '@egoist/vue-emotion'
import { XIcon } from 'vue-feather-icons'

export default {
  components: {
    CloseButtonStyled,
    XIcon,
  },
}
</script>
