<template>
  <TabbedPanelStyled>
    <HeaderStyled v-if="title">{{ title }}</HeaderStyled>
    <TabsStyled>
      <TabStyled v-for="tab in tabs" :key="tab.id" :isActive="tab.isActive" :class="{ active: tab.isActive }" @click="activateTab(tab.id)">
        {{ tab.name | capitalize }}
      </TabStyled>
    </TabsStyled>
    <ActiveTabStyled :firstIsActive="firstIsActive">
      <slot />
    </ActiveTabStyled>
  </TabbedPanelStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
// import chroma from 'chroma-js'
import get from 'lodash/get'
import {
  flexColumns,
  flexCenter,
  // flexStartCenter,
} from '@styles/mixins'

const TabbedPanelStyled = styled('div')`
  border-radius: 0.5rem;
  display: grid;
  width: 800px;
  max-width: calc(100vw - 0.5rem);
  grid-template-rows: 50px auto;
  grid-template-columns: minmax(100px, 1fr) minmax(auto, 4fr);
  grid-template-areas:
    'header header'
    'nav main';
`

const TabsStyled = styled('div')`
  ${flexColumns}
  margin: 0rem 0 1rem 1rem;
  padding-bottom: 3rem;
  grid-area: nav;
`

const TabStyled = styled('div')`
  ${flexCenter}
  cursor: pointer;
  user-select: none;
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 12px;
  border-top-left-radius: ${p => (p.theme.isDark ? '.5rem' : 0)};
  border-bottom-left-radius: ${p => (p.theme.isDark ? '.5rem' : 0)};
  color: ${p => p.theme.colors.primary};
  &:hover,
  &.active {
    color: ${p => p.theme.colors.primary};
  }
  &.active {
    box-shadow: ${p => p.theme.colors.widgetShadow};
    background: ${p => p.theme.colors.solidBG};
  }
  overflow: auto;
`

const HeaderStyled = styled('header')`
  ${flexCenter};
  color: ${p => p.theme.colors.white};
  padding: 0.75rem;
  grid-area: header;
`

const ActiveTabStyled = styled('div')`
  grid-area: main;
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin: 1rem 1rem 1rem 0;
  border-top-right-radius: ${p => (p.theme.isDark ? '.5rem' : 0)};
  border-bottom-right-radius: ${p => (p.theme.isDark ? '.5rem' : 0)};
  border-bottom-left-radius: ${p => (p.theme.isDark ? '.5rem' : 0)};
  border-top-left-radius: ${p => (p.firstIsActive ? '0' : p.theme.isDark ? '.5rem' : 0)};
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
`

export default {
  props: {
    title: {
      type: String,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  components: {
    TabbedPanelStyled,
    HeaderStyled,
    TabsStyled,
    TabStyled,
    ActiveTabStyled,
  },
  computed: {
    firstIsActive() {
      return get(this.tabs, '[0].isActive', false)
    },
  },
  methods: {
    activateTab(tab) {
      this.$emit('activateTab', tab)
    },
  },
}
</script>
