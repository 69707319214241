<template>
  <ActionMessageStyled>
    <span v-html="message"> </span>
    <ActionIconAtom :size="'24px'">
      <template slot="icon">
        <XIcon @click="$emit('cancel')" />
      </template>
    </ActionIconAtom>
    <ActionIconAtom :size="'24px'">
      <template slot="icon">
        <CheckIcon @click="$emit('accept')" />
      </template>
    </ActionIconAtom>
  </ActionMessageStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { CheckIcon, XIcon } from 'vue-feather-icons'

import ActionIconAtom from '../../Atomic/Atoms/ActionIconAtom'

const ActionMessageStyled = styled('div')`
  display: flex;
  font-size: 14px;
  border-radius: 5px;
  padding: 0.5rem;
  gap: 0.5rem;
  justify-content: space-evenly;
  background-color: ${({ theme }) =>
    theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
  box-shadow: ${p => p.theme.colors.widgetShadowEnforced};
  width: 100%;
  height: 50px;
  align-items: center;
`

export default {
  components: {
    ActionMessageStyled,
    ActionIconAtom,
    CheckIcon,
    XIcon,
  },
  props: {
    message: {
      type: String,
    },
  },
}
</script>
