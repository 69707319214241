var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TabsMolecule', {
    attrs: {
      "hasFooter": _vm.hasFooterSlot
    }
  }, [_c('header', [_c('TabHeadersStyled', _vm._l(_vm.tabs, function (tab, index) {
    return _c('TabHeaderStyled', {
      key: index
    }, [_c('div', {
      staticClass: "switch",
      class: {
        disabled: tab.disabled,
        active: _vm.activeIndex === index
      },
      attrs: {
        "disabled": tab.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('update:activeIndex', index);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.label) + " ")])]);
  }), 1)], 1), _c('main', [_vm._t("main")], 2), _vm.hasFooterSlot ? _c('footer', [_vm._t("footer")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }