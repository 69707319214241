var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Calendar', [_c('CalendarView', {
    attrs: {
      "items": _vm.items,
      "show-date": _vm.showDate,
      "time-format-options": {
        hour: 'numeric',
        minute: '2-digit'
      },
      "enable-drag-drop": true,
      "disable-past": false,
      "disable-future": false,
      "show-times": false,
      "display-period-uom": _vm.displayPeriodUom,
      "display-period-count": _vm.displayPeriodCount,
      "starting-day-of-week": _vm.startingDayOfWeek,
      "period-changed-callback": _vm.periodChanged,
      "current-period-label": _vm.useTodayIcons ? 'icons' : '',
      "displayWeekNumbers": _vm.displayWeekNumbers,
      "enable-date-selection": true,
      "selection-start": _vm.selectionStart,
      "selection-end": _vm.selectionEnd,
      "locale": _vm.locale,
      "itemContentHeight": '2.5rem'
    },
    on: {
      "date-selection-start": _vm.setSelection,
      "date-selection": _vm.setSelection,
      "date-selection-finish": _vm.finishSelection,
      "drop-on-date": _vm.onDrop,
      "click-date": _vm.onClickDay,
      "click-item": _vm.onClickItem
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headerProps = _ref.headerProps;
        return [_c('header', [_c('CalendarViewHeader', {
          attrs: {
            "header-props": headerProps
          },
          on: {
            "input": _vm.setShowDate
          }
        }), _c('CustomSingleSelect', {
          staticClass: "select-uom",
          attrs: {
            "selectedOption": _vm.selectedUom,
            "options": _vm.availableUoms,
            "shouldNotResetSelectedOption": true,
            "closeOnSelect": true
          },
          on: {
            "selectedFilter": _vm.selectUom
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$t('plan.showAs')) + ":")])];
            },
            proxy: true
          }], null, true)
        }), _c('CustomSingleSelect', {
          staticClass: "select-timezone",
          attrs: {
            "selectedOption": _vm.selectedTimezone,
            "options": _vm.availableTimezones,
            "shouldNotResetSelectedOption": true,
            "closeOnSelect": true
          },
          on: {
            "selectedFilter": _vm.selectTimezone
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$t('timezone.timezone')) + ":")])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }])
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showScheduleDialog ? _c('ScheduleDialog', {
    attrs: {
      "calendar": _vm.calendar,
      "calendarDates": _vm.calendarDates,
      "selectedSchedule": _vm.selectedSchedule
    },
    on: {
      "close-dialog": _vm.closeScheduleDialog,
      "preview": function preview($event) {
        _vm.preview = $event;
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }