<template>
  <NoSearchResultsAtom v-if="query !== undefined">
    {{ $t('messages.searchNoResults', { query: query }) }}
  </NoSearchResultsAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const NoSearchResultsAtom = styled('div')`
  padding: 1rem;
`

export default {
  props: {
    searchParams: {
      type: Object,
    },
  },
  components: {
    NoSearchResultsAtom,
  },
  computed: {
    query() {
      const q = this.searchParams?.id ?? this.searchParams?.query ?? ''
      if (q === '') {
        return undefined
      }
      return `'${q}'`
    },
  },
}
</script>
