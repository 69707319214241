import { createApp } from './app'

// client-specific bootstrapping logic...
async function main() {
  try {
    const { app } = await createApp()
    app.$mount('#app')
  } catch (err) {
    document
      .getElementById('app')
      .innerHTML('<div class="error"><h3>Authentication error</h3><strong>Please try to reload the page.</strong></div>')
  }
}

main()
