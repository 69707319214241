var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ListStyled', [_c('ListItemWrapperStyled', _vm._l(_vm.dataList, function (data) {
    return _c('ListItemStyled', {
      key: data.id,
      class: {
        disabled: data.isSelected
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('selectedValue', data);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [data.isSelected ? _c('CheckIcon', {
      attrs: {
        "size": "1.2x"
      }
    }) : _vm._e()], 1)], 1);
  }), 1), _vm.totalCount ? _c('FooterStyled', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.$tc('total', 1))) + " : " + _vm._s(_vm.totalCount) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }