var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NoDataAlertStyled', [_vm._v(" " + _vm._s(_vm.$t('feed.card.noData')) + " "), _c('SimplePopover', {
    attrs: {
      "disabled": false,
      "displayActionButtons": true,
      "popoverContent": _vm.popoverContent,
      "placement": 'top-end',
      "trigger": 'click',
      "popoverTitle": _vm.popoverTitle
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('feed.getHelp')) + " ")];
      },
      proxy: true
    }, {
      key: "titleIcon",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }, {
      key: "closeButton",
      fn: function fn() {
        return [_c('ButtonSolidStyled', [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }