export function buttonReset(p) {
  return `
    cursor: pointer;
    user-select: none;
    outline: none;
    border: none;
    background: none;
    color: ${p.isActive ? p.theme.colors.primary : p.theme.isDark ? p.theme.colors.white : p.theme.colors.navFontNormal};
    &.active {
      color: ${p.theme.colors.primary};
    }
    &:hover {
      color: ${p.theme.colors.primaryLighter};
    }
    &:disabled {
      cursor: default;
      color: ${p.theme.colors.muted};
    }
  `
}
