<template>
  <ErrorInspectorStyled>
    <VPopover
      v-if="hasErrors"
      :placement="'bottom-start'"
      :popoverClass="'notificationPopOver'"
      :open.sync="isActive"
      :popperOptions="popperOptions"
      :container="'#app'"
    >
      <ButtonStyleless class="tooltip-target">
        <AlertTriangleIcon />
      </ButtonStyleless>
      <template v-slot:popover>
        <ErrorListStyled>
          <div class="content">
            <div><strong>Application encountered the following errors:</strong></div>
            <div class="error-list">
              <template v-for="error in errors">
                <div class="type" :key="error[0] + '_type'">
                  <span>
                    {{ error[1]?.networkError !== undefined ? 'NetworkError' : '' }}
                  </span>
                  <span>
                    {{ error[1]?.graphQLErrors !== undefined ? 'GraphQLErrors' : '' }}
                  </span>
                  <span>in</span>
                </div>
                <div class="key toggle" v-if="error[1]?.graphQLErrors?.length > 0" @click="toggle(error[0])" :key="error[0] + '_key'">
                  {{ error[0] }}
                </div>
                <div v-else class="key" :key="error[0] + '_key'">
                  {{ error[0] }}
                </div>
                <div class="copy-trace-id" :key="error[0] + '_trace'">
                  <CopyIcon @click="copyTraceId(error[1]?.traceId)" v-if="error[1]?.traceId" />
                </div>
                <div class="time" :key="error[0] + '_time'">
                  {{
                    error[1]?.lastReceived?.toRelative({
                      style: 'narrow',
                    })
                  }}
                </div>
                <div class="details" v-if="showDetailsForKeys?.includes(error[0])" :key="error[0] + '_details'">
                  <span v-for="(error, i) in error[1]?.graphQLErrors ?? []" :key="i"> {{ i + 1 }}: {{ error.message }} </span>
                </div>
              </template>
            </div>
          </div>
        </ErrorListStyled>
      </template>
    </VPopover>
  </ErrorInspectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VPopover } from 'v-tooltip'
import { DateTime } from 'luxon'
import { AlertTriangleIcon, CopyIcon } from 'vue-feather-icons'

import { Errors } from '@common/singletons'
import ButtonStyleless from '../Atomic/Atoms/ButtonStyleless'

const ErrorInspectorStyled = styled('div')`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  button.tooltip-target {
    color: ${({ theme }) => theme.colors.muted};
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const ErrorListStyled = styled('div')`
  position: relative;
  margin-top: 2rem;
  margin-right: -1.5rem;
  .content {
    position: relative;
    padding: 1rem;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.normalFontColor};
    background: ${({ theme }) => theme.colors.solidBG};
    box-shadow: ${({ theme }) => theme.colors.widgetShadow};
    border: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.primary : 'transparent')};
    border-radius: 0.5rem;
    z-index: 10;
  }
  &::after {
    z-index: 5;
    content: '';
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin-left: -0.5em;
    top: -1.5rem;
    right: 0.25rem;

    background: ${({ theme }) => (theme.isDark ? theme.colors.primary : theme.colors.solidBG)};
    box-shadow: ${({ theme }) => theme.colors.widgetShadow};
    border: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.primary : 'transparent')};

    transform-origin: 0 0;
    transform: rotate(45deg);

    box-shadow: ${({ theme }) => theme.colors.widgetShadow};
  }
  .error-list {
    margin-top: 1rem;
    display: grid;
    max-height: 400px;
    overflow: auto;
    grid-template-columns: 8rem 24rem 2rem 4rem;
    grid-auto-rows: min-content;
    grid-gap: 0.5rem;
    .key {
      &.toggle {
        cursor: pointer;
        user-select: none;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
    .type {
      justify-self: end;
    }
    .time {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.muted};
    }
    .details {
      grid-column: span 4;
      padding-left: 2rem;
      max-height: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .copy-trace-id {
      > .feather {
        cursor: pointer;
        user-select: none;
        color: ${({ theme }) => theme.colors.primary};
        &:hover {
          color: ${({ theme }) => theme.colors.primaryLighter};
        }
      }
    }
  }
`

export default {
  components: {
    ErrorInspectorStyled,
    VPopover,
    ButtonStyleless,
    AlertTriangleIcon,
    CopyIcon,
    ErrorListStyled,
  },
  data() {
    return {
      errorMap: new Map(),
      isActive: false,
      showDetailsForKeys: [],
    }
  },
  created() {
    Errors.$on('error', ({ operation, networkError, graphQLErrors }) => {
      const operationType = operation?.query?.definitions?.[0]?.operation || 'unknown'
      const operationName = operation?.operationName
      const traceId = graphQLErrors?.[0]?.extensions?.tracing?.id
      const key = `${operationType}:${operationName}`
      const map = new Map(this.errorMap)
      map.set(key, { operation, networkError, graphQLErrors, lastReceived: DateTime.now(), traceId })
      this.errorMap = map
    })
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
    errors() {
      return Array.from(this.errorMap)
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text)
    },
    async copyTraceId(id) {
      this.copyToClipboard(id)
    },
    toggle(key) {
      if (this.showDetailsForKeys.includes(key)) {
        this.showDetailsForKeys = this.showDetailsForKeys.filter(f => f !== key)
      } else {
        this.showDetailsForKeys.push(key)
      }
    },
  },
}
</script>
