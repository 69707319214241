var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MinMaxValueStyled', [_c('div', [_vm._v(_vm._s(_vm.$t('planning.min')))]), _c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.min))]), _vm.unit ? _c('div', {
    staticClass: "unit",
    attrs: {
      "title": _vm.unit
    }
  }, [_vm._v(_vm._s(_vm.unit))]) : _vm._e()]), _c('div', [_vm._v(_vm._s(_vm.$t('planning.max')))]), _c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.max))]), _vm.unit ? _c('div', {
    staticClass: "unit",
    attrs: {
      "title": _vm.unit
    }
  }, [_vm._v(_vm._s(_vm.unit))]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }