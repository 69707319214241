import { useStore } from '../../../cockpit/src/stores/timepicker'

const TimeframeMixin = {
  inject: ['uiSettings'],
  setup() {
    const timepickerStore = useStore()
    return {
      timepickerStore,
    }
  },
  data() {
    return {
      myShiftplans: [],
    }
  },
  computed: {
    localeDates() {
      return (this.uiSettings?.dates ?? 'EN_GB').toLowerCase().replace('_', '-')
    },
    shiftsEnabled() {
      return this.timepickerStore.shiftFiltersEnabled
    },
    selectedShifts() {
      return this.timepickerStore.selectedShifts
    },
    availableTimezones() {
      return this.timepickerStore.availableTimezones
    },
    selectedTimezone() {
      return this.timepickerStore.selectedTimezone
    },
    selectedInterval() {
      return this.timepickerStore.selectedInterval
    },
    selectedIntervalPreviousTimeframe() {
      return this.timepickerStore.selectedIntervalPreviousTimeframe
    },
    selectedTimeframe() {
      return this.timepickerStore.selectedInterval.toISO()
    },
    selectedTimeframeParam() {
      return this.timepickerStore.timeframeParamSelectedInterval
    },
    granularity() {
      return this.timepickerStore.granularity
    },
    timeBuckets() {
      return this.timepickerStore.timebucketsSelectedInterval
    },
    timeBucketsKeys() {
      return this.timepickerStore.timebucketKeysSelectedInterval
    },
    timeBucketsUI() {
      this.timepickerStore.dateLocale = this.localeDates
      return this.timepickerStore.timebucketsUI
    },
  },
}

export default TimeframeMixin
