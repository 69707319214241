<template>
  <SearchInputAtom>
    <input
      type="text"
      ref="searchInput"
      :placeholder="$t('actions.search')"
      @input="$emit('update', $event?.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <SearchIcon :class="{ 'is-searching': isSearching }" />
  </SearchInputAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { SearchIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'

const SearchInputAtom = styled('div')`
  position: relative;
  width: 100%;
  > input {
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme }) => chroma(theme.colors.black).alpha(0.25).css()};
    border: 2px solid ${({ theme }) => theme.colors.archonBlue};
    border-radius: 0.5rem;
    padding: 0.75rem 0.5rem 0.75rem 2.5rem;
    color: ${({ theme }) => theme.colors.archonBlue};
    outline: none;
  }

  @keyframes searching {
    from {
      color: ${({ theme }) => theme.colors.archonBlue};
      transform: scale(1);
    }
    to {
      color: ${({ theme }) => theme.colors.archonBlack};
      transform: scale(0.75);
    }
  }
  > .feather-search {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    &.is-searching {
      animation-name: searching;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }
`

export default {
  props: {
    isSearching: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SearchInputAtom,
    SearchIcon,
  },
  data() {
    return {
      value: '',
    }
  },
  mounted() {
    const search = this.$refs.searchInput
    search?.focus()
  },
}
</script>
