<template>
  <MarkerIconStyled v-if="isMarker">
    <div class="circle">
      <component v-if="hasIcon" :is="icon" class="icon" />
    </div>
    <svg viewBox="0 0 400 450" class="triangle">
      <path d="M 0 0 q 200 100 400 0 L 200 225 Z" />
    </svg>
  </MarkerIconStyled>
  <TalpaIconStyled v-else>
    <component v-if="hasIcon" :is="icon" :class="isCustomColor ? 'customColor' : 'icon'" />
  </TalpaIconStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { AlertTriangleIcon } from 'vue-feather-icons'

import { flexCenter } from '@styles/mixins'

const TalpaIconStyled = styled('div')`
  width: 28px;
  height: 28px;

  svg.icon {
    width: 28px;
    height: 28px;
    path,
    circle {
      fill: currentColor;
    }
    &.feather {
      circle {
        fill: inherit;
      }
    }
  }
  svg.customColor {
    width: 28px;
    height: 28px;
    &.feather {
      circle {
        fill: inherit;
      }
    }
  }
`

const MarkerIconStyled = styled('div')`
  position: absolute;
  width: 28px;
  height: 40px;
  z-index: 100;
  .circle {
    ${flexCenter}
    width: 28px;
    height: 28px;
    background-color: ${p => chroma(p.theme.colors.talpaGrey).alpha(0.65).css()};
    border-radius: 50%;

    svg.icon {
      width: 20px;
      height: 20px;
      path,
      circle {
        fill: ${p => p.theme.colors.white};
      }
      &.feather {
        circle {
          fill: inherit;
        }
      }
    }
  }
  svg.triangle {
    position: absolute;
    top: 28px;
    width: 28px;
    height: 12px;
    path {
      fill: ${p => chroma(p.theme.colors.talpaGrey).alpha(0.65).css()};
    }
  }
  &.selected {
    z-index: 110;
    .circle {
      background-color: ${({ theme }) => theme.colors.primary};
    }
    svg.triangle path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

export default {
  props: {
    isMarker: {
      type: Boolean,
      default: false,
    },
    scope: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isCustomColor: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    TalpaIconStyled,
    MarkerIconStyled,
  },
  data() {
    return {
      icon: null,
    }
  },
  computed: {
    iconPath() {
      return `./TalpaIcons/${this.scope}/${this.name}.svg`
    },
    hasIcon() {
      return this.scope && this.name
    },
  },
  watch: {
    iconPath: {
      handler(path) {
        const importConst = path
        if (!this.hasIcon) {
          return
        }
        this.icon = () => {
          return {
            component: import(importConst + ''),
            error: AlertTriangleIcon,
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
