<template>
  <TalpaLoaderWrapperStyled :class="{ absolute: isPositionedAbsolute, transparent }" :isDarker="isDarker">
    <TalpaLoader />
  </TalpaLoaderWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import TalpaLoader from './TalpaLoader'

const TalpaLoaderWrapperStyled = styled('div')`
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.colors.talpaLoaderBG};
  &.transparent {
    background: transparent;
  }
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export default {
  props: {
    isPositionedAbsolute: {
      type: Boolean,
      default: false,
    },
    isDarker: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TalpaLoader,
    TalpaLoaderWrapperStyled,
  },
}
</script>

<style></style>
