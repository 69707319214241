<template>
  <LoadingDotsStyled :fill="fill">
    <svg id="dots" viewBox="0 0 132 58">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="dots">
          <circle id="dot1" cx="25" cy="30" r="13"></circle>
          <circle id="dot2" cx="65" cy="30" r="13"></circle>
          <circle id="dot3" cx="105" cy="30" r="13"></circle>
        </g>
      </g>
    </svg>
  </LoadingDotsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const LoadingDotsStyled = styled('div')`
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  svg {
    width: 100%;
    #dots {
      fill: ${props => (props.fill ? props.fill : '#A3A3A3')};
    }

    #dots #dot1 {
      animation: load 1s infinite;
    }

    #dots #dot2 {
      animation: load 1s infinite;
      animation-delay: 0.2s;
    }

    #dots #dot3 {
      animation: load 1s infinite;
      animation-delay: 0.4s;
    }
  }
`

export default {
  props: {
    fill: String,
  },
  components: {
    LoadingDotsStyled,
  },
}
</script>
