<template>
  <NotificationsListMolecule
    :isLoading="isLoadingNotifications"
    :canLoadMore="canLoadMoreNotifications"
    :notifications="notifications"
    :notificationsError="notificationsError"
    :infiniteId="infiniteId"
    :showAllNotifications="showAllNotifications"
    :unreadCount="myUnreadNotificationsCount"
    @mark-all-notifications-as-read="markAllNotificationsAsRead"
    @toggle-show-all-notifications="toggleShowAllNotifications"
    @toggle-notification-is-unread="toggleNotificationIsUnread"
    @load-more="loadNotifications"
    @close-pop-over="$emit('close-pop-over')"
  />
</template>

<script>
import { DateTime } from 'luxon'

import permissionsMixin from '@/mixins/permissions'
import NotificationsListMolecule from '../../Molecules/Notifications/NotificationsListMolecule'

import MY_NOTIFICATIONS_QUERY from '#/graphql/notifications/myNotificationsQuery.gql'
import MY_NOTIFICATIONS_COUNT_QUERY from '#/graphql/notifications/myNotificationsCountQuery.gql'
import MARK_ALL_MY_NOTIFICATIONS_AS_READ_MUTATION from '#/graphql/notifications/markAllMyNotificationsAsRead.gql'
import MARK_NOTIFICATION_AS_READ_MUTATION from '#/graphql/notifications/updateNotification.gql'
import MY_USER_NOTIFICATIONS_SUBSCRIPTION from '#/graphql/notifications/myUserNotificationsSubscription.gql'

const PAGE_SIZE = 20

export default {
  mixins: [permissionsMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    NotificationsListMolecule,
  },
  data() {
    return {
      showAllNotifications: false,
      myUnreadNotificationsCount: 0,
      notifications: [],
      isLoadingNotifications: false,
      canLoadMoreNotifications: false,
      lastNotificationId: null,
      notificationsError: null,
      infiniteId: 0,
    }
  },
  methods: {
    toggleShowAllNotifications() {
      this.showAllNotifications = !this.showAllNotifications
      this.notifications = []
      this.lastNotificationId = null
      this.loadNotifications()
    },
    async markAllNotificationsAsRead() {
      this.notifications = []
      this.lastNotificationId = null
      await this.$apollo.mutate({
        mutation: MARK_ALL_MY_NOTIFICATIONS_AS_READ_MUTATION,
      })
      this.loadNotifications()
    },
    async toggleNotificationIsUnread(notificationId) {
      const notification = this.notifications.find(f => f.id === notificationId)
      if (!notification) {
        return
      }
      await this.$apollo.mutate({
        mutation: MARK_NOTIFICATION_AS_READ_MUTATION,
        variables: {
          id: notification.id,
          isUnread: !notification.isUnread,
        },
        optimisticResponse: {
          updateIsUnReadUserNotificationStatus: {
            id: notification.id,
            isUnread: !notification.isUnread,
            __typename: 'UserNotification',
          },
        },
        update: (_, { data }) => {
          if (data.updateIsUnReadUserNotificationStatus) {
            notification.isUnread = data.updateIsUnReadUserNotificationStatus.isUnread
            if (!this.showAllNotifications) {
              this.notifications = this.notifications.filter(f => f.id !== notificationId)
            }
          }
        },
      })
    },
    async loadNotifications() {
      try {
        if (this.isLoadingNotifications) {
          return
        }
        this.isLoadingNotifications = true
        const res = await this.$apollo.query({
          query: MY_NOTIFICATIONS_QUERY,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            orderBy: {
              assetNotificationEvent: {
                updatedAt: 'desc',
              },
            },
            where: {
              isUnread: this.showAllNotifications
                ? undefined
                : {
                    equals: true,
                  },
              assetNotificationEvent: {
                is: {
                  updatedAt: {
                    gte: DateTime.now().minus({ day: 7 }).startOf('day').toUTC().toISO(),
                  },
                },
              },
            },
            take: PAGE_SIZE,
            skip: this.lastNotificationId !== null ? 1 : 0,
            cursor:
              this.lastNotificationId !== null
                ? {
                    id: this.lastNotificationId,
                  }
                : undefined,
          },
        })
        this.isLoadingNotifications = false
        this.notificationsError = null
        const notifications = res?.data?.myNotifications?.slice() || []
        this.lastNotificationId = notifications[notifications.length - 1]?.id
        this.notifications = this.notifications.concat(notifications)

        if (notifications.length < PAGE_SIZE) {
          this.canLoadMoreNotifications = false
        } else {
          this.canLoadMoreNotifications = true
        }
      } catch (err) {
        this.canLoadMore = false
        this.isLoadingNotifications = false
        this.notificationsError = err
      }
    },
  },
  async mounted() {
    await this.loadNotifications()
  },
  apollo: {
    myUnreadNotificationsCount: {
      query: MY_NOTIFICATIONS_COUNT_QUERY,
      skip() {
        return !this.hasNotificationsPermission
      },
      variables() {
        return {
          where: {
            isUnread: {
              equals: true,
            },
            assetNotificationEvent: {
              is: {
                updatedAt: {
                  gte: DateTime.now().minus({ day: 6 }).startOf('day').toUTC().toISO(),
                },
              },
            },
          },
        }
      },
      update(data) {
        if (data.myNotificationsCount === 0) {
          if (this.showAllNotifications) {
            this.notifications = this.notifications.map(m => ({
              ...m,
              isUnread: false,
            }))
          } else {
            this.notifications = []
          }
        }
        return data.myNotificationsCount
      },
    },
    $subscribe: {
      userNotificationMutation: {
        query: MY_USER_NOTIFICATIONS_SUBSCRIPTION,
        variables() {
          return {
            where: {
              isUnread: this.showAllNotifications
                ? undefined
                : {
                    equals: true,
                  },
              assetNotificationEvent: {
                is: {
                  updatedAt: {
                    gte: DateTime.now().minus({ day: 6 }).startOf('day').toUTC().toISO(),
                  },
                },
              },
            },
          }
        },
        error(err) {
          if (err?.message?.includes('ECONNREFUSED')) {
            return false
          } else {
            return true
          }
        },
        result({ data: { myUserNotifications } }) {
          const payload = myUserNotifications
          if (payload.payloadAction === 'CREATE') {
            if (!payload.userNotification?.id) {
              throw new Error(`missing userNotification object in payload`)
            }
            this.notifications = [payload.userNotification, ...this.notifications]
          } else if (payload.payloadAction === 'UPDATE') {
            if (!payload.userNotification?.id) {
              throw new Error(`missing userNotification object in payload`)
            }
            const previousIndex = this.notifications.findIndex(prev => prev?.id && prev.id === payload.userNotificationId)
            if (previousIndex !== -1) {
              if (
                DateTime.fromISO(this.notifications[previousIndex]?.updatedAt).toMillis() >
                DateTime.fromISO(payload.userNotification?.updatedAt).toMillis()
              ) {
                return
              }
              if (payload.userNotification.isUnread === false) {
                if (!this.showAllNotifications) {
                  this.notifications.splice(previousIndex, 1)
                } else {
                  this.$set(this.notifications, previousIndex, payload.userNotification)
                }
              } else {
                this.$set(this.notifications, previousIndex, payload.userNotification)
              }
            } else {
              if (!this.showAllNotifications && payload.userNotification.isUnread === true) {
                this.notifications = [payload.userNotification, ...this.notifications].sort((a, b) => {
                  return DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis()
                })
              }
            }
          } else if (payload.payloadAction === 'DELETE') {
            const previousIndex = this.notifications.findIndex(prev => prev?.id && prev.id === payload.userNotificationId)
            if (previousIndex !== -1) {
              this.notifications = this.notifications.splice(previousIndex, 1)
            }
          } else {
            throw new Error(`unknown payloadAction: '${payload.payloadAction}'`)
          }
        },
      },
    },
  },
}
</script>
