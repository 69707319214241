var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PrivacyPolicyStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded,
      "id": "privacy-policy"
    }
  }, [_c('div', {
    staticClass: "popover-wrapper"
  }, [_c('span', {
    on: {
      "click": function click($event) {
        return _vm.togglePopOver();
      }
    }
  }, [_c('span', {
    staticClass: "popover-trigger"
  }, [_vm._v(" " + _vm._s(_vm.$t('privacyPolicy.menu')) + " "), _c('ShieldIcon')], 1), _c('PrivacyPolicyMobileIcon', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  })], 1), _c('VPopover', {
    attrs: {
      "placement": 'right',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions,
      "popoverClass": 'userGuidePopOver',
      "container": '#privacy-policy',
      "trigger": 'manual',
      "autoHide": false
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverInnerWrapperStyled', [_c('div', {
          staticClass: "content"
        }, [_vm._v(" " + _vm._s(_vm.$t('privacyPolicy.bannerMessage')) + " ")]), _c('ActionsStyled', [_c('router-link', {
          attrs: {
            "to": {
              name: 'privacyPolicy'
            }
          }
        }, [_c('AButtonSolid', {
          on: {
            "click": _vm.closePopUp
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.read')) + " ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }