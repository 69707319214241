<template>
  <Dropdown>
    <ArrownDown :disabled="disabled" :show="showMenu" @click="toggleShowMenu" />
    <DropdownContent :show="showMenu">
      <DropdownItem @click="selectOption(option.value)" :key="index" v-for="(option, index) in options">
        <CheckCircleIcon v-if="option.value === selected" />
        <CircleIcon v-else />
        <span>{{ $t(option.label) }}</span>
      </DropdownItem>
    </DropdownContent>
  </Dropdown>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { CheckCircleIcon, CircleIcon } from 'vue-feather-icons'

const ArrownDown = styled('div')`
  border: solid ${p => (p.disabled ? 'grey' : p.theme.colors.primary)};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: ${p => (p.show ? 'rotate(-135deg)' : 'rotate(45deg)')};
  -webkit-transform: ${p => (p.show ? 'rotate(-135deg)' : 'rotate(45deg)')};
`

const Dropdown = styled('div')`
  position: relative;
  display: inline-block;
  cursor: pointer;
`

const DropdownContent = styled('div')`
  margin: 10px;
  display: ${props => (props.show ? 'block' : 'none')};
  right: 0;
  list-style-type: none;
  position: absolute;
  background-color: ${p => p.theme.colors.solidBG};
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  border: 0.5px solid;
  z-index: 1300;
  color: ${p => p.theme.colors.primary};
  margin-right: 2px;
`

const DropdownItem = styled('div')`
  margin-top: 5px;

  svg {
    width: 15px;
    height: 15px;
  }

  span {
    margin-left: 5px;
  }
`

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    ArrownDown,
    Dropdown,
    DropdownContent,
    CheckCircleIcon,
    CircleIcon,
    DropdownItem,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    toggleShowMenu() {
      if (!this.disabled) {
        this.showMenu = !this.showMenu
      }
    },
    selectOption(value) {
      this.$emit('selected', value)
      this.showMenu = false
    },
  },
}
</script>
