<template>
  <WizardStyled>
    <WizardHeader>
      <span>{{ title }}</span>
    </WizardHeader>
    <WizardStepsStyled>
      <WizardStepStyled
        v-for="step in steps"
        :key="step.id"
        :isEnabled="step.isEnabled"
        :isCompleted="step.isCompleted"
        :isRequired="step.isRequired"
        :isActive="step.isActive"
        :class="{ active: step.isActive, disabled: !step.isEnabled }"
        @click="$emit('activateStep', step.id)"
      >
        <StepLabel>{{ step.label | capitalize }}</StepLabel>
        <CircleStyled class="circle">{{ step.id }}</CircleStyled>
      </WizardStepStyled>
    </WizardStepsStyled>
    <WizardCurrentStepStyled>
      <slot />
    </WizardCurrentStepStyled>
    <WizardActionsStyled>
      <div>
        <ButtonRound :round="true" :disabled="!prevStepEnabled" @click="$emit('activatePrevStep')">
          <ChevronLeftIcon />
        </ButtonRound>
        <ButtonRound :round="true" :disabled="!nextStepEnabled" @click="$emit('activateNextStep')">
          <ChevronRightIcon />
        </ButtonRound>
      </div>
      <div>
        <ButtonRound class="confirm" :round="true" :disabled="!canConfirm" @click="$emit('confirm')">
          <CheckIcon />
        </ButtonRound>
      </div>
    </WizardActionsStyled>
  </WizardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from 'vue-feather-icons'

import { ButtonRound } from '@styles/buttons'

import { flexCenter, buttonReset } from '@styles/mixins'

const WizardStyled = styled('div')`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 50px auto 50px;
  grid-template-columns: 50px auto;
  grid-template-areas:
    'header header'
    'steps main'
    'footer footer';

  @media (min-width: 768px) {
    height: 100%;
    max-height: 800px;
    grid-template-columns: 200px auto;
  }
`

const WizardHeader = styled('div')`
  grid-area: header;
  ${flexCenter}
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

const WizardStepsStyled = styled('div')`
  grid-area: steps;
`

const WizardStepStyled = styled('div')`
  ${flexCenter}
  ${buttonReset}
  max-width: 200px;
  margin: 1rem 0;
  color: ${p => p.theme.colors.primary};
  &:hover,
  &.active {
    color: ${p => p.theme.colors.primary};
    .circle {
      color: ${p => p.theme.colors.white};
      background: ${p => p.theme.colors.primary};
    }
  }
  &.disabled {
    cursor: default;
    color: ${p => p.theme.colors.darkGrey};
    .circle {
      color: ${p => p.theme.colors.white};
      background: ${p => p.theme.colors.darkGrey};
      border: 1px solid ${p => p.theme.colors.darkGrey};
    }
    opacity: 0.8;
  }
`

const CircleStyled = styled('span')`
  ${flexCenter}
  width: 2rem;
  height: 2rem;
  color: ${p => p.theme.colors.primary};
  background: transparent;
  border: 1px solid ${p => p.theme.colors.primary};
  border-radius: 50%;
  @media (min-width: 768px) {
    margin-left: 0.5rem;
  }
`

const StepLabel = styled('div')`
  width: calc(200px - 2rem);
  text-align: right;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

const WizardActionsStyled = styled('div')`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
  > div {
    ${flexCenter}
  }
  button {
    border-radius: 50%;
    padding: 0;
    width: 1.7rem;
    height: 1.7rem;
    margin: 0 1rem;
    .feather-chevron-left {
      margin-left: -0.2rem;
    }
    .feather-chevron-right {
      margin-right: -0.2rem;
    }
    &.confirm {
      width: 2rem;
      height: 2rem;
    }
    &:disabled {
      background: ${props => props.theme.colors.talpaGrey};
      opacity: 0.8;
    }
  }
`

const WizardCurrentStepStyled = styled('div')`
  grid-area: main;
  flex-grow: 1;
  background: ${props => props.theme.colors.solidBG};
  border-radius: ${p => (p.theme.isDark ? '.5rem' : 0)};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  @media (min-width: 768px) {
    margin: 1rem 0 1rem 2rem;
  }
  padding: 1rem;
  overflow-y: auto;
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    prevStep: {
      type: Object,
    },
    currentStep: {
      type: Object,
    },
    nextStep: {
      type: Object,
    },
  },
  components: {
    ButtonRound,
    ChevronLeftIcon,
    ChevronRightIcon,
    CheckIcon,
    WizardStyled,
    WizardHeader,
    WizardStepsStyled,
    WizardStepStyled,
    WizardCurrentStepStyled,
    WizardActionsStyled,
    CircleStyled,
    StepLabel,
  },
  computed: {
    prevStepEnabled() {
      return get(this.prevStep, 'isEnabled', false)
    },
    nextStepEnabled() {
      return get(this.nextStep, 'isEnabled', false)
    },
    canConfirm() {
      return !this.steps.find(step => step.isRequired && !step.isCompleted)
    },
  },
}
</script>
