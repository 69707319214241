var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NavigationStyled', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_c('NavExpanderButton', {
    staticClass: "expander",
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    },
    on: {
      "toggleIsMenuExpanded": _vm.setNavState
    }
  }), _c('div', {
    staticClass: "navItems"
  }, _vm._l(_vm.navigationItems, function (navigationItem, i) {
    return _c('MainNavigationItem', {
      key: i,
      attrs: {
        "navigationItem": navigationItem
      }
    });
  }), 1), _c('div', {
    staticClass: "footer"
  }, [_c('ReleaseNotes'), _c('PrivacyPolicy'), _c('ThemeSwitcher', {
    staticClass: "theme-switcher"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }