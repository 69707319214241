<template>
  <LogoWrapper
    v-if="shouldShowLogo"
    :isIconHovered="isIconHovered"
    @mouseover="handleIconHover(true)"
    @mouseout="handleIconHover(false)"
    :isMenuExpanded="isMenuExpanded"
    :class="{ expanded: isMenuExpanded }"
  >
    <div class="top">
      <component class="desktop-icon" :is="organizationLogos.desktop"></component>
      <component class="mobile-icon" :is="organizationLogos.mobile"></component>
    </div>
    <div class="inner">
      <Brand class="adjust" />
    </div>
  </LogoWrapper>
  <PureBrandWrapper v-else :isMenuExpanded="isMenuExpanded">
    <Brand class="adjust scale" />
  </PureBrandWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import TalpaCockpitIcon from '@/assets/talpasolutions_white.svg'
import Brand from '@/components/App/Brand'
import logosMap from './logosMap'
import get from 'lodash/get'

const PROFILE_QUERY = require('#/graphql/profile/show.gql')
import { useStore } from '@/stores/userSettings'

const LogoWrapper = styled('div')`
  position: relative;
  transition: width 0.4s linear;
  width: ${({ isIconHovered }) => (isIconHovered ? '100px' : '50px')};

  & > .top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 100;
    background: ${props => props.theme.colors.solidBG};
  }

  & > .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    background: ${props => props.theme.colors.solidBG};
    position: absolute;
    z-index: 99;
  }

  .desktop-icon {
    display: none;
  }

  .mobile-icon {
    display: block;
  }

  @media (min-width: 768px) {
    &.expanded {
      ${props => (props.isIconHovered && props.isMenuExpanded ? 'width: 300px;' : 'width: 200px;')};
    }
    width: ${({ isIconHovered }) => (isIconHovered ? '100px' : '50px')};
    .desktop-icon {
      ${props => (props.isMenuExpanded ? 'display: block;' : 'display: none;')};
    }

    .mobile-icon {
      ${props => (props.isMenuExpanded ? 'display: none;' : 'display: block;')};
    }

    & > .top {
      ${props => (props.isMenuExpanded ? 'width:200px;' : 'width: 50px;')};
    }

    & > .inner {
      ${props => (props.isMenuExpanded ? 'width: 100px;' : 'width: 50px;')};
    }
  }
`

const PureBrandWrapper = styled('div')`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};

  @media (min-width: 768px) {
    ${props => (props.isMenuExpanded ? 'width: 200px;' : 'width: 50px;')};
  }
`

export default {
  inject: ['theme'],
  setup() {
    const userSettingsStore = useStore()
    return {
      userSettingsStore,
    }
  },
  components: {
    PureBrandWrapper,
    LogoWrapper,
    TalpaCockpitIcon,
    Brand,
  },
  data() {
    return {
      isIconHovered: false,
      profile: null,
    }
  },
  methods: {
    handleIconHover(value) {
      this.isIconHovered = value
    },
  },
  computed: {
    organizationAliases() {
      let alias = (get(this.profile, 'memberships') || []).map(mem => mem.organization.alias)
      alias = alias.includes('DeutzDays') ? ['DeutzDays'] : alias
      return alias
    },
    organizationLogos() {
      if (this.organizationAliases.length === 1) {
        const alias = get(this.organizationAliases, '0')
        const isDarkTheme = this.theme.isDark

        const logos = logosMap.find(({ organizations }) => organizations.includes(alias))

        if (logos) {
          return isDarkTheme ? logos.dark : logos.light
        }

        return null
      }

      return null
    },
    shouldShowLogo() {
      return Boolean(this.organizationLogos)
    },
    isMenuExpanded() {
      return this.userSettingsStore.isMenuExpanded
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
