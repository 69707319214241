var render = function () {
  var _vm$performanceGoal, _vm$performanceGoal2, _vm$performanceGoal3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceGoalItemStyled', [_vm.showGoalRemoveMessage ? _c('ActionMessageAtom', {
    attrs: {
      "message": _vm.removeMessage
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showGoalRemoveMessage = false;
      },
      "accept": _vm.removeGoal
    }
  }) : [_c('GoalDetailsStyled', {
    attrs: {
      "size": (_vm$performanceGoal = _vm.performanceGoal) === null || _vm$performanceGoal === void 0 ? void 0 : _vm$performanceGoal.performanceGoalRules.length
    }
  }, [_c('div', {
    staticClass: "goal-name"
  }, [_vm._v(" " + _vm._s((_vm$performanceGoal2 = _vm.performanceGoal) === null || _vm$performanceGoal2 === void 0 ? void 0 : _vm$performanceGoal2.name) + " ")]), _c('div', {
    staticClass: "rule-names"
  }, _vm._l((_vm$performanceGoal3 = _vm.performanceGoal) === null || _vm$performanceGoal3 === void 0 ? void 0 : _vm$performanceGoal3.performanceGoalRules, function (rule) {
    var _rule$assetDimension;
    return _c('TextTagAtom', {
      key: rule === null || rule === void 0 ? void 0 : rule.id,
      attrs: {
        "text": rule === null || rule === void 0 ? void 0 : (_rule$assetDimension = rule.assetDimension) === null || _rule$assetDimension === void 0 ? void 0 : _rule$assetDimension.translatedName
      }
    });
  }), 1)]), _c('div', {
    staticClass: "actions"
  }, [_c('ActionIconAtom', [_c('template', {
    slot: "icon"
  }, [_c('Edit2Icon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('updateGoal', _vm.index);
      }
    }
  })], 1)], 2), _c('ActionIconAtom', [_c('template', {
    slot: "icon"
  }, [_c('Trash2Icon', {
    on: {
      "click": _vm.initiateGoalRemoval
    }
  })], 1)], 2)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }