<template>
  <NotificationsWrapperStyled>
    <VPopover
      :placement="'bottom-start'"
      :popoverClass="'notificationPopOver'"
      :open.sync="isActive"
      :popperOptions="popperOptions"
      :container="'#app-main'"
    >
      <NotificationsBellOrganism :isActive="isActive" />
      <template v-slot:popover>
        <NotificationsListOrganism :isActive="isActive" @close-pop-over="closePopOver()" />
      </template>
    </VPopover>
  </NotificationsWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VPopover } from 'v-tooltip'

import NotificationsBellOrganism from '@/components/Atomic/Organisms/Notifications/NotificationsBellOrganism'
import NotificationsListOrganism from '@/components/Atomic/Organisms/Notifications/NotificationsListOrganism'

const NotificationsWrapperStyled = styled('div')`
  grid-area: notifications;
  width: 100%;
  height: 100%;
  .v-popover,
  .trigger {
    width: 100%;
    height: 100%;
  }
  &.disabled {
    .v-popover,
    .trigger {
      pointer-events: none;
    }
  }
`

export default {
  components: {
    NotificationsWrapperStyled,
    VPopover,
    NotificationsBellOrganism,
    NotificationsListOrganism,
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    showNotifications() {
      return this.hasNotificationsPermission && this.machineStatusRead
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    closePopOver() {
      this.isActive = false
    },
  },
}
</script>
