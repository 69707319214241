var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldShowLogo ? _c('LogoWrapper', {
    class: {
      expanded: _vm.isMenuExpanded
    },
    attrs: {
      "isIconHovered": _vm.isIconHovered,
      "isMenuExpanded": _vm.isMenuExpanded
    },
    on: {
      "mouseover": function mouseover($event) {
        return _vm.handleIconHover(true);
      },
      "mouseout": function mouseout($event) {
        return _vm.handleIconHover(false);
      }
    }
  }, [_c('div', {
    staticClass: "top"
  }, [_c(_vm.organizationLogos.desktop, {
    tag: "component",
    staticClass: "desktop-icon"
  }), _c(_vm.organizationLogos.mobile, {
    tag: "component",
    staticClass: "mobile-icon"
  })], 1), _c('div', {
    staticClass: "inner"
  }, [_c('Brand', {
    staticClass: "adjust"
  })], 1)]) : _c('PureBrandWrapper', {
    attrs: {
      "isMenuExpanded": _vm.isMenuExpanded
    }
  }, [_c('Brand', {
    staticClass: "adjust scale"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }