import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import data from './initialData'
import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const cache = new InMemoryCache({
  fragmentMatcher,
})

function initCache() {
  cache.writeData({
    data,
  })
}

initCache()

export { cache, initCache }
