var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RulesListStyled', _vm._l(_vm.performanceGoalRules, function (rule, index) {
    return _c('RuleItemMolecule', {
      key: index,
      attrs: {
        "rule": rule,
        "index": index + 1
      },
      on: {
        "removeRule": function removeRule($event) {
          return _vm.$emit('removeRule', $event);
        },
        "updateRule": function updateRule($event) {
          return _vm.$emit('updateRule', $event);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }