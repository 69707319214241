var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isActive ? _c('OverlayStyled', {
    attrs: {
      "shouldHover": _vm.shouldHover
    },
    on: {
      "mouseover": _vm.mouseover,
      "mouseleave": _vm.mouseleave,
      "click": _vm.closeFromOutside
    }
  }, [_c(_vm.overlayComponent, {
    tag: "component",
    attrs: {
      "args": _vm.args
    },
    on: {
      "close": _vm.close
    }
  }), _c('CloseOverlayStyled', {
    class: {
      hover: _vm.shouldHover
    },
    on: {
      "click": _vm.close
    }
  }, [_c('XIcon')], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }