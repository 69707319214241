<template>
  <RadioButtonStyled>
    <input type="radio" :checked="checked" :value="value" @change="$emit('change', $event)" />
    <span class="label">{{ label }}</span>
    <span class="checkmark" />
  </RadioButtonStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const RadioButtonStyled = styled('label')`
  display: block;
  position: relative;
  height: 1.5rem;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1rem;
    width: 1rem;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.black : theme.colors.togglerBodyInactive)};
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    background-color: ${({ theme }) => chroma(theme.colors.primary).alpha(0.5).css()};
  }

  & input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export default {
  components: {
    RadioButtonStyled,
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
