<template>
  <TextOverflowEllipsisAtom :title="text">
    <span>
      {{ text }}
    </span>
  </TextOverflowEllipsisAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
// import { VTooltip } from 'v-tooltip'

const TextOverflowEllipsisAtom = styled('div')`
  display: flex;
  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export default {
  props: {
    text: {
      type: String,
    },
    // tooltipContent: {
    //   type: String,
    //   default: function () {
    //     return this.value
    //   },
    // },
  },
  // directives: {
  //   tooltip: VTooltip,
  // },
  components: {
    TextOverflowEllipsisAtom,
  },
}
</script>
