<template>
  <ValidatableFieldMolecule>
    <slot />
    <div v-if="validationErrors.length > 0" class="validation-errors">
      <ValidationErrorAtom v-for="validationError in validationErrors" :key="validationError.id" :validationError="validationError" />
    </div>
  </ValidatableFieldMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import ValidationErrorAtom from '@/components/Atomic/Atoms/ValidationErrorAtom'

const ValidatableFieldMolecule = styled('div')`
  .validation-errors {
    padding: 0.25rem;
  }
`

export default {
  props: {
    validation: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidatableFieldMolecule,
    ValidationErrorAtom,
  },
  computed: {
    validationErrors() {
      return Object.keys(this.validation?.$params)
        .map(key => ({
          ...this.validation?.$params[key],
          id: key,
        }))
        .filter(f => this.validation[f.id] === false)
    },
  },
}
</script>
