<template>
  <SubpanelStyled>
    <header v-if="!noHeader">
      <slot name="header" />
    </header>
    <main>
      <LoadingDots v-if="isLoading" />
      <slot name="main" />
    </main>
    <footer>
      <slot name="footer" />
    </footer>
  </SubpanelStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

import LoadingDots from './LoadingDots'

import { flexStartCenter } from '@styles/mixins'

const SubpanelStyled = styled('section')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  border-radius: 4px;
  > header {
    ${flexStartCenter};
    padding: 0.5rem;
    font-weight: bold;
  }
  > main {
    padding: 0.5rem;
  }
  > footer {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: {
    SubpanelStyled,
    LoadingDots,
  },
}
</script>
