import chroma from 'chroma-js'

export default function (p) {
  return `
      &::-webkit-scrollbar{
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${chroma(p.theme.colors.darkGrey).alpha(0.8).css()};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${chroma(p.theme.colors.white).alpha(0.5).css()};
      transition: border-color .25s ease;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: ${chroma(p.theme.colors.primary).alpha(0.8).css()};
    }
    &:hover::-webkit-scrollbar-thumb:hover {
      background-color: ${chroma(p.theme.colors.primaryLighter).alpha(0.8).css()};
    }
  `
}
