<template>
  <ListStyled>
    <ListItemWrapperStyled>
      <ListItemStyled v-for="data in dataList" :key="data.id" @click="$emit('selectedValue', data)" :class="{ disabled: data.isSelected }">
        <span> {{ data.label }} </span>
        <transition name="fade">
          <CheckIcon size="1.2x" v-if="data.isSelected" />
        </transition>
      </ListItemStyled>
    </ListItemWrapperStyled>
    <FooterStyled v-if="totalCount"> {{ $tc('total', 1) | capitalize }} : {{ totalCount }} </FooterStyled>
  </ListStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { buttonReset } from '@styles/mixins'
import { TimeframeMixin } from '@common/mixins'
import { CheckIcon } from 'vue-feather-icons'
const ListStyled = styled('div')``

const FooterStyled = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  font-size: 10px;
  background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.dark : theme.colors.atomic.porcelain)};
  text-transform: uppercase;
  border-radius: 0 0 8px 8px;
`

const ListItemStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  ${buttonReset}
  color: ${p => p.theme.colors.textActivePrimary};
  padding: 0.75rem;
  font-size: 12px;

  &:hover {
    color: ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.primaryDarker};
  }
  .align {
    margin-right: 5px;
  }
`
const ListItemWrapperStyled = styled('div')`
  overflow: auto;
  max-height: 12rem;
  .disabled {
    pointer-events: none;
    cursor: none;
    color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.osloGrey : theme.colors.atomic.pastelGrey)};
  }
`
export default {
  mixins: [TimeframeMixin],
  props: {
    totalCount: {
      type: Number,
    },
    dataList: {
      type: Array,
    },
  },
  components: {
    ListStyled,
    ListItemStyled,
    ListItemWrapperStyled,
    FooterStyled,
    CheckIcon,
  },
}
</script>
