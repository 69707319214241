<template>
  <EditableFieldMolecule>
    <LabelAtom class="label" :label="label" :isMuted="isReadonly" />
    <slot v-if="hasDefaultSlot" />
    <InputStyled
      v-else
      type="text"
      ref="input"
      :value="isDateTime ? formatDateTime(value) : value"
      :readonly="isReadonly"
      @input="$emit('update:value', $event.target.value)"
    />
  </EditableFieldMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { FlashMessages } from '@common/singletons'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtomV2'
import { DateTime } from 'luxon'

const EditableFieldMolecule = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.5rem;
  align-items: center;
  overflow: hidden;
  > .label {
    margin: 0.25rem;
    font-size: 12px;
  }
  > input {
  }
`
const InputStyled = styled('input')`
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: ${p => p.theme.colors.primary};
  background: none;
  border-bottom: 1px solid ${p => chroma(p.theme.colors.navFontNormal).alpha(0.8).css()};
  transition: border-color 0.5s ease;
  &:focus {
    border-color: ${p => p.theme.colors.primary};
  }
  &::placeholder {
    color: ${p => p.theme.colors.primary};
  }
`

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isDateTime: {
      type: Boolean,
      default: false,
    },
    hasCopyToClipboard: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableFieldMolecule,
    LabelAtom,
    InputStyled,
  },
  computed: {
    hasDefaultSlot() {
      return this.$slots['default']
    },
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text)
      FlashMessages.$emit('success', `Copied to clipboard.`, { timeout: 1000 })
    },
    formatDateTime(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    },
  },
  mounted() {
    if (this.focus) {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
}
</script>
