import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

export default styled('button')`
  ${flexCenter}
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
  padding: 0;
  color: ${props => (props.isActive ? props.theme.selected.colors.primaryContrast : props.theme.selected.colors.primary)};
  ${props => (props.margin ? 'margin: .5rem;' : '')}

  &.active, &:hover {
    color: ${props => props.theme.selected.colors.primaryContrast};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    color: ${props => props.theme.selected.colors.primary};
  }
`
