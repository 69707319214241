import BaseColors from './base-colors'

export default {
  WORK: BaseColors.green,
  IDLE: BaseColors.red,
  UNLOADING: BaseColors.mediumGrey,
  DIESEL_IDLE: BaseColors.lightGold,
  TRAVELING: BaseColors.olivedrab,
  TRANSITIONAL_DELAY: BaseColors.yellow,
  STANDING: BaseColors.primary,
  SPOTTING: BaseColors.deepSkyBlue,
  QUEUE_BEFORE_LOAD: BaseColors.teal,
  QUEUE_BEFORE_DUMP: BaseColors.indigo,
  MOVING: BaseColors.darkKhaki,
  MANEUVERING: BaseColors.gold,
  MACHINE_UNLOADING: BaseColors.darkSlateGrey,
  MACHINE_OFF: BaseColors.buffYellow,
  MACHINE_LOADING: BaseColors.azul,
  LOADING: BaseColors.paleVioletRed,
  IDLE_UNLOADED: BaseColors.salmon,
  IDLE_LOADED: BaseColors.redDark,
  HOLE_SETUP: BaseColors.purple,
  DRIVING_UNLOADED: BaseColors.mediumaquamarine,
  DRIVING_LOADED: BaseColors.greenDarker,
  DRILLING: BaseColors.indianRed,
  ANCHORING: BaseColors.darkTurq,
  PUSH: BaseColors.darkGoldenRod,
  RETURN: BaseColors.olive,
  WITHDRAWING_BOOM: BaseColors.sandyBrown,
  HYDRAULIC_IDLE: BaseColors.lime,
  ELECTRICAL_SYSTEM_ON: BaseColors.mint,
  SCALING: BaseColors.malachite,
  REPOSITIONING: BaseColors.airForceBlue,
  SUPPORT_LEG_WARNING: BaseColors.redPale,
  AUXILIARY_WORK: BaseColors.seaBlue,
  WAITING: BaseColors.mediumBrown,
  NO_DATA: BaseColors.black,
  ROCK_CRUSHING: BaseColors.atomic.purpleHeart,
  ROD_CHANGE: BaseColors.atomic.chart.grandis,
  SWEEPING: BaseColors.atomic.chart.rosa,
  ELECTRIC_IDLE: BaseColors.babyBlue,
  ROD_REMOVAL: BaseColors.trafficLightYellow,
}
