const OverlayMixin = {
  data() {
    return {
      overlayType: null,
      overlayArgs: null,
      overlayOnClose: null,
    }
  },
  created() {
    this.$root.$on('activateOverlay', this.activateOverlay)
    this.$root.$on('closeOverlay', this.closeOverlay)
  },
  methods: {
    activateOverlay(type, args, overlayOnClose) {
      if (type) {
        this.overlayType = type
        this.overlayArgs = args
        this.overlayOnClose = overlayOnClose
      }
    },
    closeOverlay() {
      if (this.overlayOnClose && typeof this.overlayOnClose === 'function') {
        this.overlayOnClose()
      }
      this.overlayType = null
      this.overlayArgs = null
      this.overlayOnClose = null
      this.$root.$emit('overlayClosed')
    },
  },
}

export default OverlayMixin
