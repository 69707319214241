var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RadioButtonStyled', [_c('input', {
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm.checked,
      "value": _vm.value
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', $event);
      }
    }
  }), _c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('span', {
    staticClass: "checkmark"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }